import { SerializedStyles } from "@emotion/react";
import { CSSInterpolation } from "@mui/material";
import { ButtonHTMLAttributes } from "react";
import tw, { css, TwStyle } from "twin.macro";
import ButtonReset from "./ButtonReset";

interface IToolButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  position?: "left" | "center" | "right";
  small?: boolean;
  isActive?: boolean;
  styles?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles);
}
const style = (
  position: "left" | "center" | "right" | undefined,
  isSmall: boolean,
  isActive?: boolean,
  disabled?: boolean,
  css?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles)
) => [
  tw`bg-inputBackground px-1.5 hover:bg-hover border border-solid border-hover text-xs flex flex-col justify-center h-full`,

  position === "left" && tw`rounded-l`,
  position === "right" && tw`rounded-r`,
  position === undefined && tw`rounded`,
  isActive && tw`bg-border hover:bg-hover`,
  isSmall && tw`h-small`,
  css && css,
  disabled &&
    tw`bg-buttonDisabled hover:bg-buttonDisabled text-buttonTextDisabled`,
];

const ToolButton = ({
  position,
  isActive,
  children,
  small = false,
  styles,
  disabled = false,
  ...rest
}: IToolButtonProps) => {
  return (
    <ButtonReset
      {...rest}
      disabled={disabled}
      css={style(position, small, isActive, disabled, styles)}
    >
      {children}
    </ButtonReset>
  );
};

export default ToolButton;
