import React, { useEffect, useState } from "react";
import { getAsync } from "../../helpers/asyncFetch";
import QrCode, { newQrCode } from "./models/QrCode";
import LoadingWrapper from "../LoadingWrapper";
import QrCodeElement from "./components/QrCodeElement";

import tw, { css, GlobalStyles as BaseStyle } from "twin.macro";
import DesignerInput from "../../postcard-designer/features/DesignerInterface/shared/DesignerInput/DesignerInput";
import propertiesStyles from "../../postcard-designer/features/DesignerInterface/Properties/propertiesStyles";
import AssetSelector from "../AssetSelector/AssetSelector";
import IAsset from "../../data/models/IAsset";
import DesignerSelectOptions from "../../postcard-designer/features/DesignerInterface/shared/DesignerInput/DesignerSelectOptions";
import {
  DesignerSelect,
  DesignerSelectOption,
} from "../../postcard-designer/features/DesignerInterface/shared/NuDesignerInputs/DesignerSelect";
import SharedColorPicker from "../SharedColorPicker/SharedColorPicker";
import {
  QrCodeStylingCornersDotOptions,
  QrCodeStylingGradientColorStop,
  QrCodeStylingGradientOptions,
} from "./types/QrCodeStylingTypes";
import {
  IGradientColor,
  IGradientObject,
} from "../../postcard-designer/features/DesignerInterface/shared/ColorPicker/types";
import DesignerButton from "../../postcard-designer/features/DesignerInterface/shared/DesignerButton";
import ToolButton from "../../postcard-designer/features/DesignerInterface/shared/ToolButton";
import {
  LockIcon,
  UnlockIcon,
} from "../../postcard-designer/features/DesignerInterface/shared/SvgComponents";
import EditorTooltip from "../../postcard-designer/features/DesignerInterface/shared/EditorTooltip";
import TailwindLoadingButton from "../TailwindComponents/TailwindLoadingButton";
import {
  getQrCodeBase64,
  getQrCodeSvg,
} from "../../postcard-designer/features/QrCodes/functions/createCanvasQrCodeObject";

interface QrCodeEditorProps {
  qrCodeID?: number;
  loading: boolean;
  qrCodeOverride?: QrCode;
  onChangeOverride?: (qrCode: QrCode) => void;
  saveCallback?: ({
    config,
    url,
    preview,
  }: {
    config: string;
    url: string;
    preview: string;
  }) => void;
}

const QrCodeEditor = ({
  qrCodeID,
  saveCallback,
  qrCodeOverride,
  onChangeOverride,
  loading,
}: QrCodeEditorProps) => {
  const [qrCode, setQrCode] = useState<QrCode>(newQrCode);
  const [status, setStatus] = useState("loading");
  const [assetLibraryOpen, setAssetLibraryOpen] = useState(false);
  const [lockColors, setLockColors] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState<
    "dots" | "cornersSquare" | "cornersDot"
  >();
  async function getQrCode() {
    setStatus("loading");
    if (qrCodeID && !qrCodeOverride) {
      const res = await getAsync<QrCode>(`/qr-codes/${qrCodeID}`);
      if (res) {
        setQrCode(res);
        setStatus("idle");
      }
    } else if (qrCodeOverride) {
      setQrCode(qrCodeOverride);
    } else {
      setQrCode(newQrCode);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    handlePropertyChange(e.target.name, e.target.value);
  }

  function handlePropertyChange(property: string, value: string) {
    if (!property.includes(".")) {
      setQrCode({
        ...qrCode,
        config: { ...qrCode.config, [property]: value },
      });
    } else {
      setQrCode({
        ...qrCode,
        config: {
          ...qrCode.config,
          [property.split(".")[0]]: {
            // @ts-ignore
            ...qrCode.config[property.split(".")[0]],
            [property.split(".")[1]]: value,
          },
        },
      });
    }
  }

  function handleImageSelection(asset?: IAsset) {
    setQrCode({
      ...qrCode,
      config: {
        ...qrCode.config,
        image: asset ? asset.url : "",
        imageOptions: {
          hideBackgroundDots: true,
        },
      },
    });
  }

  function getColorVal(
    property:
      | "cornersSquareOptions"
      | "cornersDotOptions"
      | "backgroundOptions"
      | "dotsOptions"
  ) {
    const config = qrCode.config[property];

    if (config) {
      if (config.gradient) {
        return getCssFromGradient(config.gradient);
      } else {
        return config.color;
      }
    }
    return "#000000";
  }
  function getCssFromGradient(gradient: QrCodeStylingGradientOptions) {
    const degrees = getDegreesFromRadians(gradient.rotation);
    //linear-gradient(90deg, RGBA(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)
    return `linear-gradient(${degrees}deg, ${gradient.colorStops
      .map((stop) => `${stop.color} ${stop.offset * 100}%`)
      .join(",")})`;
  }

  async function onCreate() {
    if (saveCallback) {
      const preview = await getQrCodeSvg(qrCode.config);
      saveCallback({
        config: JSON.stringify(qrCode.config),
        url: qrCode.config.data as string,
        preview,
      });
    }
  }

  function handleSyncColors() {
    if (!lockColors) return;
    const baseColor = qrCode.config.dotsOptions.gradient
      ? qrCode.config.dotsOptions.gradient
      : qrCode.config.dotsOptions.color;

    if (typeof baseColor === "string") {
      setQrCode({
        ...qrCode,
        config: {
          ...qrCode.config,
          cornersDotOptions: {
            ...qrCode.config.cornersDotOptions,
            gradient: undefined,
            color: baseColor,
          },
          cornersSquareOptions: {
            ...qrCode.config.cornersSquareOptions,
            gradient: undefined,
            color: baseColor,
          },
        },
      });
    } else {
      setQrCode({
        ...qrCode,
        config: {
          ...qrCode.config,
          cornersDotOptions: {
            ...qrCode.config.cornersDotOptions,
            gradient: baseColor,
          },
          cornersSquareOptions: {
            ...qrCode.config.cornersSquareOptions,
            gradient: baseColor,
          },
        },
      });
    }
  }

  function handleColorPickerChange(
    name:
      | "cornersSquareOptions"
      | "cornersDotOptions"
      | "backgroundOptions"
      | "dotsOptions",
    value: string | IGradientObject
  ) {
    const config = qrCode.config[name];
    if (lockColors) {
      if (typeof value === "string") {
        setQrCode({
          ...qrCode,
          config: {
            ...qrCode.config,
            cornersSquareOptions: {
              ...qrCode.config.cornersSquareOptions,
              gradient: undefined,
              color: value,
            },
            cornersDotOptions: {
              ...qrCode.config.cornersDotOptions,
              gradient: undefined,
              color: value,
            },
            dotsOptions: {
              ...qrCode.config.dotsOptions,
              gradient: undefined,
              color: value,
            },
          },
        });
      } else {
        const gradient: QrCodeStylingGradientOptions = {
          type: value.gradientType.includes("linear") ? "linear" : "radial",
          rotation: getRadiansFromDegrees(value.degrees),
          colorStops: getColorStops(value.colors),
        };

        setQrCode({
          ...qrCode,
          config: {
            ...qrCode.config,
            cornersSquareOptions: {
              ...qrCode.config.cornersSquareOptions,
              gradient,
            },
            cornersDotOptions: {
              ...qrCode.config.cornersDotOptions,
              gradient,
            },
            dotsOptions: {
              ...qrCode.config.dotsOptions,
              gradient,
            },
          },
        });
      }
    } else {
      if (typeof value === "string") {
        setQrCode({
          ...qrCode,
          config: {
            ...qrCode.config,
            [name]: {
              // @ts-ignore
              ...config,
              color: value,
              gradient: undefined,
            },
          },
        });
      } else {
        const gradient: QrCodeStylingGradientOptions = {
          type: value.gradientType.includes("linear") ? "linear" : "radial",
          rotation: getRadiansFromDegrees(value.degrees),
          colorStops: getColorStops(value.colors),
        };

        setQrCode({
          ...qrCode,
          config: {
            ...qrCode.config,
            [name]: {
              // @ts-ignore
              ...config,
              color: undefined,
              gradient: gradient,
            },
          },
        });
      }
    }
  }

  function getColorStops(
    stops: IGradientColor[]
  ): QrCodeStylingGradientColorStop[] {
    return stops.map((stop) => ({
      color: stop.value,
      offset: stop.left / 100,
    }));
  }

  function getRadiansFromDegrees(degrees: string) {
    const deg = parseInt(degrees.replace("deg", ""));
    if (typeof deg === "number" && !Number.isNaN(deg)) {
      const radians = deg * (Math.PI / 180);
      return radians;
    }
    return 0;
  }

  function getDegreesFromRadians(radians: number) {
    return (radians * 180) / Math.PI;
  }

  useEffect(() => {
    getQrCode();
  }, [qrCodeID]);

  useEffect(() => {
    if (onChangeOverride) {
      onChangeOverride(qrCode);
    }
  }, [qrCode]);

  useEffect(handleSyncColors, [lockColors]);

  return (
    <div>
      {!qrCodeOverride && <BaseStyle />}
      <div tw="flex w-full mb-3">
        <div tw="w-1/2 pr-2">
          <div tw="mb-2">
            <div>URL (Required)</div>
            <DesignerInput
              disableAutoSelect
              value={qrCode.config.data ?? ""}
              onChange={handleChange}
              name="data"
              type="text"
            />
          </div>
          {/* <div tw="mb-2">
            <div>Background Color</div>
            <SharedColorPicker
              value={getColorVal("backgroundOptions")}
              onChange={(c) => handleColorPickerChange("backgroundOptions", c)}
            />
          </div> */}
          {((qrCodeOverride && !qrCodeOverride.published) ||
            !qrCodeOverride) && (
            <React.Fragment>
              <div tw="relative">
                <div tw="mb-2">
                  <div>QR Pattern</div>
                  <div tw="flex items-center">
                    <div tw="pr-1 w-48">
                      <DesignerSelect
                        value={qrCode.config.dotsOptions.type}
                        onChange={handlePropertyChange}
                        name="dotsOptions.type"
                      >
                        <DesignerSelectOption value="square">
                          Square
                        </DesignerSelectOption>
                        <DesignerSelectOption value="rounded">
                          Rounded
                        </DesignerSelectOption>
                        <DesignerSelectOption value="extra-rounded">
                          Extra Rounded
                        </DesignerSelectOption>
                        <DesignerSelectOption value="classy">
                          Classy
                        </DesignerSelectOption>
                        <DesignerSelectOption value="classy-rounded">
                          Classy Rounded
                        </DesignerSelectOption>
                        <DesignerSelectOption value="dots">
                          Dots
                        </DesignerSelectOption>
                      </DesignerSelect>
                    </div>
                    <div tw="pl-1">
                      <SharedColorPicker
                        openOverride={openColorPicker === "dots"}
                        openOverrideCallback={(isOpen) => {
                          if (isOpen) {
                            setOpenColorPicker("dots");
                          }
                        }}
                        value={getColorVal("dotsOptions")}
                        onChange={(c) => {
                          handleColorPickerChange("dotsOptions", c);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div tw="mb-2">
                  <div>Corner Borders</div>
                  <div tw="flex items-center">
                    <div tw="pr-1 w-48">
                      <DesignerSelect
                        value={qrCode.config.cornersSquareOptions.type}
                        onChange={handlePropertyChange}
                        name="cornersSquareOptions.type"
                      >
                        <DesignerSelectOption value="square">
                          Square
                        </DesignerSelectOption>
                        <DesignerSelectOption value="extra-rounded">
                          Rounded Square
                        </DesignerSelectOption>
                        <DesignerSelectOption value="dot">
                          Circle
                        </DesignerSelectOption>
                      </DesignerSelect>
                    </div>
                    <div tw="pl-1">
                      <SharedColorPicker
                        openOverride={openColorPicker === "cornersSquare"}
                        openOverrideCallback={(isOpen) => {
                          if (isOpen) {
                            setOpenColorPicker("cornersSquare");
                          }
                        }}
                        value={getColorVal("cornersSquareOptions")}
                        onChange={(c) => {
                          handleColorPickerChange("cornersSquareOptions", c);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div tw="mb-2">
                  <div>Corner Dots</div>
                  <div tw="flex items-center">
                    <div tw="pr-1 w-48">
                      <DesignerSelect
                        value={qrCode.config.cornersDotOptions.type}
                        onChange={handlePropertyChange}
                        name="cornersDotOptions.type"
                      >
                        <DesignerSelectOption value="square">
                          Square
                        </DesignerSelectOption>
                        <DesignerSelectOption value="dot">
                          Circle
                        </DesignerSelectOption>
                      </DesignerSelect>
                    </div>
                    <div tw="pl-1">
                      <SharedColorPicker
                        openOverride={openColorPicker === "cornersDot"}
                        openOverrideCallback={(isOpen) => {
                          if (isOpen) {
                            setOpenColorPicker("cornersDot");
                          }
                        }}
                        value={getColorVal("cornersDotOptions")}
                        onChange={(c) => {
                          handleColorPickerChange("cornersDotOptions", c);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    position: absolute;
                    width: 16px;
                    right: 35px;
                    bottom: 16px;
                    height: 71%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid rgba(0, 0, 0, 0.25);
                    border-top: 1px solid rgba(0, 0, 0, 0.25);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                  `}
                >
                  <div
                    css={css`
                      position: relative;
                      left: 7px;
                      background: white;
                    `}
                  >
                    <ToolButton
                      css={[
                        css`
                          height: 28px;
                          background: white;
                          border: none;
                        `,
                        tw`hover:bg-hover`,
                      ]}
                      onClick={() => setLockColors(!lockColors)}
                    >
                      <EditorTooltip
                        label={lockColors ? <LockIcon /> : <UnlockIcon />}
                        position="top"
                      >
                        <div
                          css={[
                            tw`bg-text rounded p-1 whitespace-nowrap bg-opacity-10 text-white`,
                            css`
                              font-size: 12px;
                            `,
                          ]}
                        >
                          {lockColors
                            ? "Use different colors"
                            : "Sync all colors"}
                        </div>
                      </EditorTooltip>
                    </ToolButton>
                  </div>
                </div>
              </div>
              {/* <div tw="mb-2">
            <DesignerButton onClick={handleSyncColors}>
              Match All Colors
            </DesignerButton>
          </div> */}
              <div>
                <div css={tw`mb-1`}>Image (Optional)</div>
                <AssetSelector onSelect={handleImageSelection} />
              </div>
            </React.Fragment>
          )}
          {qrCodeOverride && Boolean(qrCodeOverride.published) && (
            <div css={tw`text-sm`}>
              <em>
                This QR Code is already in use. Only the URL can be modified.{" "}
              </em>
            </div>
          )}
        </div>
        <div>
          <div>Preview</div>
          <QrCodeElement width={300} height={300} {...qrCode.config} debounce />
        </div>
      </div>
      {!qrCodeOverride && !onChangeOverride && (
        <div tw="flex items-center">
          <TailwindLoadingButton
            loading={loading}
            message="Saving"
            onClick={onCreate}
          >
            Create QR Code
          </TailwindLoadingButton>
        </div>
      )}
      {qrCodeOverride && saveCallback && (
        <div tw="flex items-center">
          <TailwindLoadingButton
            loading={loading}
            message="Saving"
            onClick={onCreate}
          >
            Save QR Code
          </TailwindLoadingButton>
        </div>
      )}
    </div>
  );
};

export default QrCodeEditor;
