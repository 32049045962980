import { Box, Button, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { fetchClient } from "../../../../helpers/fetchClient";
import { getObjectTruthyValues } from "../../../../helpers/getObjectTruthyValues";
import BaseErrorResponse from "../../../../data/models/BaseErrorResponse";
import Form from "../../../../shared/BasicHTML/Form";
import LoadingButton from "../../../../shared/LoadingButton";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";

const PasswordReset: FC = (props) => {
  /**
   * State Objects and Refs
   */
  const [passwordInput, setPasswordInput] = useState<Record<string, string>>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * Component Methods
   */

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPasswordInput({ ...passwordInput, [e.target.name]: e.target.value });
  }

  function handleSubmit(e: React.FormEvent) {
    setError("");
    setIsSuccess(false);
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = passwordInput;

    if (newPassword !== confirmPassword) {
      setError("Your new passwords must match");
      return;
    }

    setIsSubmitting(true);

    fetchClient.post({
      path: "/my-account",
      data: { currentPassword, password: newPassword, confirmPassword },
      onSuccess: onSubmitResponse,
      onError: onSubmitError,
    });
  }

  function onSubmitResponse() {
    setIsSubmitting(false);
    setIsSuccess(true);
    resetForm();
    setTimeout(() => {
      setIsSuccess(false);
    }, 15000);
  }

  function onSubmitError(e: BaseErrorResponse) {
    setIsSubmitting(false);
    setIsSuccess(false);
    setError(e.message);
  }

  function resetForm() {
    setPasswordInput({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Form onSubmit={handleSubmit}>
      <GridRow>
        <GridItem breakPoints={[12, 4]}>
          <TextField
            type="password"
            label="Current Password"
            fullWidth
            value={passwordInput.currentPassword}
            name="currentPassword"
            required
            onChange={handleChange}
          />
        </GridItem>
        <GridItem breakPoints={[12, 4]}>
          <TextField
            type="password"
            label="New Password"
            fullWidth
            value={passwordInput.newPassword}
            name="newPassword"
            required
            onChange={handleChange}
          />
        </GridItem>
        <GridItem breakPoints={[12, 4]}>
          <TextField
            type="password"
            label="Confirm New Password"
            fullWidth
            value={passwordInput.confirmPassword}
            name="confirmPassword"
            required
            onChange={handleChange}
          />
        </GridItem>
        <GridItem xs="auto">
          <Box>
            <LoadingButton
              color="primary"
              type="submit"
              loading={isSubmitting}
              success={isSuccess}
            >
              Update My Password
            </LoadingButton>
          </Box>
          {Boolean(error) && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {error}
            </Typography>
          )}
        </GridItem>
        <GridItem xs="auto">
          <Button
            color="primary"
            onClick={resetForm}
            type="button"
            disabled={getObjectTruthyValues(passwordInput).length === 0}
          >
            Reset Form
          </Button>
        </GridItem>
      </GridRow>
    </Form>
  );
};

export default PasswordReset;
