import React, { useContext, useState } from "react";
import { ReactComponent as TextIcon } from "../../../../assets/images/designer-svg/TextIcon.svg";
import { ReactComponent as DoodleIcon } from "../../../../assets/images/designer-svg/DoodleIcon.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/images/designer-svg/ImageIcon.svg";
import { ReactComponent as ShapeIcon } from "../../../../assets/images/designer-svg/ShapeIcon.svg";
import { ReactComponent as SelectIcon } from "../../../../assets/images/designer-svg/SelectIcon.svg";
import { ReactComponent as RectangleIcon } from "../../../../assets/images/designer-svg/Shapes/rectangle.svg";
import { ReactComponent as LayerIcon } from "../../../../assets/images/designer-svg/LayerIcon.svg";
import { ReactComponent as PenIcon } from "../../../../assets/images/designer-svg/PenIcon.svg";
import { ReactComponent as QrCodeIcon } from "../../../../assets/images/designer-svg/QrCode.svg";
import { Tool } from "../../../state/models/ICanvasTool";
import tw, { css } from "twin.macro";

import ToolSelectButton from "./ToolSelectButton";
import { Shape } from "../../../state/models/IToolSettings";

import UndoRedo from "../UndoRedo";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { IPenTool } from "../../../hooks/usePenTool/constants";

interface IToolMenuProps
  extends Omit<React.HtmlHTMLAttributes<HTMLButtonElement>, "onChange"> {
  currentTool: Tool;
  onChange: (tool: Tool, shape?: Shape) => void;
  layersActive?: boolean;
  currentShape: Shape;
  penTool: IPenTool;
  forceDisabled?: boolean;
}

const styles = {
  svg: (isActive: boolean) => [
    tw`w-5 h-5 m-auto`,
    isActive && tw`fill-blue`,
    !isActive && tw`fill-labels`,
  ],
  sidebar: [
    tw`h-full flex flex-col box-border border-0 border-solid border-border border-r bg-glacier w-[60px] min-w-[60px]`,
    css`
      z-index: 100;
    `,
  ],
  svgMenuItem: [tw`mr-2 ml-0`],
  activeTool: [
    tw`h-11 w-11 text-center   flex flex-col  border-border border-solid border-0 border-b box-border`,
  ],
  hr: [
    tw`border-0 w-full `,
    css`
      height: 1px;
      background: rgba(0, 0, 0, 0.25);
    `,
  ],
};

const ToolSidebar = ({
  currentTool,
  onChange,
  currentShape,
  layersActive = false,
  penTool,
  forceDisabled = false,
  ...props
}: IToolMenuProps) => {
  const canvas = useContext(CanvasContext);
  function getCurrentTool() {
    switch (currentTool) {
      case Tool.text:
        return <TextIcon css={styles.svg(true)} />;
      case Tool.shape:
        return <ShapeIcon css={styles.svg(true)} />;
      case Tool.doodle:
        return <DoodleIcon css={styles.svg(true)} />;
      case Tool.image:
        return <ImageIcon css={styles.svg(true)} />;
      case Tool.select:
        return <SelectIcon css={styles.svg(true)} />;
    }
  }
  const { penToolActive } = penTool;
  return (
    <div css={styles.sidebar} className="_toolSidebar">
      {canvas && (
        <React.Fragment>
          <ToolSelectButton
            onClick={() => onChange(Tool.select)}
            svg={SelectIcon}
            isActive={currentTool === Tool.select}
            tooltip="Select &amp; Move"
            label="Select"
            disabled={forceDisabled ? true : penToolActive}
          />
          <hr css={styles.hr} />
          <ToolSelectButton
            onClick={() => onChange(Tool.image)}
            svg={ImageIcon}
            isActive={currentTool === Tool.image}
            tooltip="Add Image"
            label="Images"
            disabled={forceDisabled ? true : penToolActive}
          />
          <ToolSelectButton
            onClick={() => onChange(Tool.shape)}
            svg={RectangleIcon}
            isActive={currentTool === Tool.shape}
            tooltip="Add Shape"
            label="Shapes"
            disabled={forceDisabled ? true : penToolActive}
          />
          <ToolSelectButton
            onClick={() => onChange(Tool.text)}
            svg={TextIcon}
            isActive={currentTool === Tool.text}
            tooltip="Add Text"
            label="Text"
            disabled={forceDisabled ? true : penToolActive}
          />
          <ToolSelectButton
            onClick={() => onChange(Tool.pen)}
            svg={PenIcon}
            isActive={currentTool === Tool.pen}
            tooltip="Pen Tool"
            label="Pen"
            disabled={
              forceDisabled ? true : penToolActive && currentTool !== Tool.pen
            }
          />
          <ToolSelectButton
            onClick={() => onChange(Tool.qrCode)}
            svg={QrCodeIcon}
            isActive={currentTool === Tool.qrCode}
            tooltip="QR Codes"
            label="QR Codes"
            disabled={forceDisabled ? true : penToolActive}
          />
          <hr css={styles.hr} />
          <UndoRedo forceDisabled={forceDisabled} />
          <ToolSelectButton
            onClick={() => onChange(Tool.layers)}
            svg={LayerIcon}
            isActive={Boolean(currentTool === Tool.layers)}
            tooltip="Show Layers"
            label="Layers"
            disabled={forceDisabled}
          />
          {/* <ToolSelectButton
        onClick={() => onChange(Tool.bullet)}
        svg={ListIcon}
        isActive={currentTool === Tool.bullet}
      /> */}
        </React.Fragment>
      )}
    </div>
  );
};

export default ToolSidebar;
