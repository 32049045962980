import { Object as IObject } from "fabric/fabric-impl";
import { IGradientObject } from "../types";
import { fabric } from "fabric";
export default function getGradientFromCss(
  object: IObject,
  gradientObject: IGradientObject
) {
  if (gradientObject.isGradient) {
    const width = object.width ?? 0;
    const height = object.height ?? 0;
    const colorStops = gradientObject.colors.map((x) => ({
      color: x.value,
      offset: x.left / 100,
    }));
    if (gradientObject.gradientType === "linear-gradient") {
      const angle = parseInt(gradientObject.degrees.replace("deg", ""));
      if (isNaN(angle)) return "#000000";
      const coords = getGradientCoords(object, angle);
      return new fabric.Gradient({
        type: "linear",
        coords,
        colorStops,
        gradientUnits: "pixels",
      });
    } else {
      return new fabric.Gradient({
        type: "radial",
        coords: {
          r1: height / 2 + width / 4,
          r2: width * 0.05,
          x1: width / 2,
          y1: height / 2,
          x2: width / 2,
          y2: height / 2,
        },
        colorStops,
        gradientUnits: "pixels",
      });
    }
  }
  return "#000000";
}

export function getGradientCoords(obj: IObject, angle: number) {
  // https://github.com/fabricjs/fabric.js/issues/3665#issuecomment-276994137
  const width = obj.width ?? 1;
  const height = obj.height ?? 1;

  const anglePI = -angle * (Math.PI / 180);

  const angleCoords = {
    x1: ((50 + Math.sin(anglePI) * 50) * width) / 100,
    y1: ((50 + Math.cos(anglePI) * 50) * height) / 100,
    x2: ((50 + Math.sin(anglePI + Math.PI) * 50) * width) / 100,
    y2: ((50 + Math.cos(anglePI + Math.PI) * 50) * height) / 100,
  };
  return angleCoords;
}
