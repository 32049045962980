import tw from "twin.macro";
import isAdminUser from "../../../helpers/isAdminUser";

const LoadingScreen = ({ mode }: { mode?: string }) => {
  const isAdmin = isAdminUser();
  return (
    <div
      style={{
        height:
          mode === "iframe" || mode === "embed"
            ? "100vh"
            : isAdmin && mode === "portalCustomer"
              ? "calc(100vh - 76px - 42px)"
              : "calc(100vh - 76px)",
        width: "100vw",
        background: "rgba(0,0,0,.25)",
        position: "fixed",
        top:
          mode === "iframe" || mode === "embed"
            ? "0"
            : isAdmin && mode === "portalCustomer"
              ? "calc(76px + 42px)"
              : "76px",
        left: 0,
        zIndex: "1000",
      }}
    >
      <div
        style={{ background: "#f1f1f1", height: "100%" }}
        tw="flex items-center justify-center flex-col w-full h-full"
      >
        <div
          tw="bg-white flex items-center justify-center flex-col"
          style={{ height: "6in", width: "8.5in" }}
        >
          <div
            tw="inline-block h-16 w-16 animate-spin rounded-full border-solid border-blue border-r-transparent align-[-0.125em]  motion-reduce:animate-[spin_1.5s_linear_infinite]"
            style={{ borderWidth: "6px" }}
          >
            <span tw="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
