import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import Design from "../../Designs/models/Design";
import IDesignCategory from "../../Designs/models/IDesignCategory";
import DesignModel from "../models/DesignModel";
import IActiveCategory from "../models/IActiveCategory";

const HtmlTemplates = () => {
  const { data: designCategories, error: categoryError } = useGetData<
    IDesignCategory[]
  >(`/api/design-category`, []);
  const {
    data: designs,
    isLoading,
    error,
  } = useGetData<Design[]>(`/api/html/templates`);
  const [activeCategory, setActiveCategory] = useState<IActiveCategory>({});

  const columns: TableColumn<Design>[] = [
    {
      name: "Design Proof",
      width: "300px",
      cell: (row) => (
        <div>
          <img src={row.proofFront} className="design__image img-fluid" />
        </div>
      ),
    },
    {
      name: "Design ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Design Name",
      selector: (design) => design.friendlyName,
      sortable: true,
    },
    {
      name: "Size",
      selector: (design) => design.size,
      sortable: true,
    },
    {
      name: "Approval Date",
      selector: (cell) => cell.approvalDate,
      sortable: true,
      format: (row) => moment(row.approvalDate).format("MM/DD/YYYY"),
    },
    {
      name: "Category",
      selector: (design) =>
        design.designCategory ? design.designCategory.categoryName : "",
      sortable: true,
    },
    {
      name: "Subcategory",
      selector: (design) =>
        design.designSubcategory
          ? design.designSubcategory.subcategoryName
          : "",
      sortable: true,
    },
    {
      cell: (design) => (
        <Button
          component={Link}
          to={`/admin/html/${design.designID}/edit`}
          color="primary"
        >
          Edit
        </Button>
      ),
    },
  ];

  function handleCategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories) {
      const categoryID = parseInt(e.target.value);
      const category = designCategories.find(
        (x) => x.categoryID === categoryID
      );
      setActiveCategory({ category });
    } else {
      setActiveCategory({});
    }
  }

  function handleSubcategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories && activeCategory.category) {
      const subcategoryID = parseInt(e.target.value);
      const subcategory = activeCategory.category.subcategories.find(
        (x) => x.subcategoryID === subcategoryID
      );
      setActiveCategory({ ...activeCategory, subcategory });
    } else {
      setActiveCategory({ category: activeCategory.category });
    }
  }

  function filterDesigns() {
    if (!activeCategory.category || !designs) return designs;
    return designs.filter((x) => {
      if (activeCategory.subcategory) {
        return (
          x.designSubcategory?.subcategoryID ===
            activeCategory.subcategory.subcategoryID &&
          x.designCategory?.categoryID === activeCategory.subcategory.categoryID
        );
      }
      return (
        x.designCategory?.categoryID === activeCategory.category?.categoryID
      );
    });
  }

  const customHeader = (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Link to="/admin">
          <i className="fas fa-chevron-left"></i> Back to Admin
        </Link>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Filter Categories</InputLabel>
            <Select
              fullWidth
              label="Filter Categories"
              value={activeCategory.category?.categoryID.toString() ?? ""}
              onChange={handleCategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {designCategories?.map((category) => (
                <MenuItem key={category.categoryID} value={category.categoryID}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Filter Subcategories</InputLabel>
            <Select
              fullWidth
              label="Filter Subcategories"
              disabled={!activeCategory.category}
              value={activeCategory.subcategory?.subcategoryID.toString() ?? ""}
              onChange={handleSubcategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {activeCategory.category?.subcategories?.map((category) => (
                <MenuItem
                  key={category.subcategoryID}
                  value={category.subcategoryID}
                >
                  {category.subcategoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <Button
            to={`/admin/html/new/edit`}
            component={Link}
            LinkComponent={Link}
            color="primary"
            variant="contained"
          >
            Add New Design
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <LoadingWrapper
        loading={isLoading}
        hasError={error.hasError}
        height={500}
      >
        <SearchableDataTable
          tableTitle=""
          customHeaderComponent={customHeader}
          searchableColumns={[
            "designID",
            "name",
            "designCategory.categoryName",
            "designSubcategory.subcategoryName",
          ]}
          columns={columns}
          data={filterDesigns() ?? ([] as DesignModel[])}
        />
      </LoadingWrapper>
    </Box>
  );
};

export default HtmlTemplates;
