import { Gradient, Object as IObject } from "fabric/fabric-impl";
import { IGradientColor } from "../types";
import getGradientAngleFromFabric from "./getGradientAngleFromFabric";

export default function getCssFromGradient(obj: IObject, gradient: Gradient) {
  const type = gradient.type;
  let css = "";
  if (!gradient.colorStops || !gradient.coords) return "000000";
  const colors: IGradientColor[] = gradient.colorStops.map((x) => ({
    value: x.color,
    left: x.offset,
  }));

  if (type === "linear") {
    const angle = getGradientAngleFromFabric(obj, gradient);

    css = `linear-gradient(${angle}deg`;
  } else {
    css = `radial-gradient(circle at center`;
  }
  colors.forEach((color) => {
    css += `,${color.value} ${color.left * 100}%`;
  });
  css += ")";
  return css;
}
