import { useHistory, useParams } from "react-router-dom";
import IDesign from "../../../data/models/IDesign";
import { getAsync, postAsync } from "../../../helpers/asyncFetch";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import Design from "../../Designs/models/Design";
import StandAloneProofGenerator from "../../Designs/Proof/components/StandAloneProofGenerator";
import buildFields from "../helpers/buildFields";
import getVars from "../helpers/getVars";

interface IHtmlProofParams {
  designId: string;
  htmlVersionId: string;
}

const HtmlProof = () => {
  const { designId, htmlVersionId } = useParams<IHtmlProofParams>();
  const {
    data: design,
    isLoading,
    updateData: setDesign,
    error,
  } = useGetData<Design>(`/api/html/${designId}`);

  const designVariables = getVars(design?.currentHtmlVersion?.html)?.filter(
    (x) => x !== "QrCode"
  );
  const designVarsBack = getVars(design?.currentHtmlVersion?.htmlBack)?.filter(
    (x) => x !== "QrCode"
  );
  const designFields = buildFields([...designVariables, ...designVarsBack]);
  const history = useHistory();

  async function handleSaveProofs(proofs: string[]) {
    const design = await postAsync(`/designs/${designId}/proofs`, proofs);
    if (design) {
      history.push(`/designs/html/${designId}`);
    }
  }

  return (
    <LoadingWrapper height={500} loading={isLoading} hasError={error.hasError}>
      {design && (
        <StandAloneProofGenerator
          design={design}
          fields={designFields}
          designId={designId}
          htmlVersionId={htmlVersionId}
          designerCallback={handleSaveProofs}
        />
      )}
    </LoadingWrapper>
  );
};

export default HtmlProof;
