import { Box, Paper, Skeleton, Typography } from "@mui/material";
import React, { FC, ReactChild } from "react";
import "./LoadingWrapper.scss";

interface LoadingScreenProps {
  height?: number | string;
  loading: boolean;
  message?: string | JSX.Element;
  container?: FC;
  hasError?: boolean;
  errorMessage?: string;
  unwrapped?: boolean;
}

const LoadingWrapper: FC<LoadingScreenProps> = ({
  children,
  height = 500,
  loading,
  message,
  container,
  hasError = false,
  errorMessage = "Something went wrong. Our team has been notified.",
  unwrapped = false,
}) => {
  /**
   * State Objects and Refs
   */

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  const messageIsString = typeof message === "string";
  const Container: FC = container
    ? container
    : (props) => <div>{props.children}</div>;
  if (hasError)
    return (
      <Container>
        <Paper sx={{ p: 3 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            {errorMessage}
          </Typography>
        </Paper>
      </Container>
    );
  if (loading && message) {
    return (
      <Container>
        <div className="loading-container-new" style={{ height }}>
          <div className="loading-new">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            {message && messageIsString === true && (
              <h3 className="pt-2">{message}</h3>
            )}
            {message && messageIsString === false && (
              <div className="pt-2">{message}</div>
            )}
          </div>
        </div>
      </Container>
    );
  }
  if (loading) {
    return (
      <Container>
        <Skeleton variant="rectangular" height={height} />
      </Container>
    );
  }
  if (unwrapped) return <React.Fragment>{children}</React.Fragment>;
  return <div>{children}</div>;
};

export default LoadingWrapper;
