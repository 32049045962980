import React, { useContext, useEffect, useRef, useState } from "react";
import { SelectInputIcon, SvgElement } from "../shared/SvgComponents";
import tw, { css } from "twin.macro";
import propertiesStyles from "./propertiesStyles";
import {
  PropertyDropdownContext,
  PropertyDropdownDispatchContext,
} from "../../../state/contexts/PropertyDropdownContext";

interface IPropertyDropdownProps {
  children: React.ReactFragment;
  defaultValue?: boolean;
  label: string;
  icon?: SvgElement;
  iconOpen?: SvgElement;
  disabled?: boolean;
  maxHeight?: number;
  isPopout?: boolean;
}

const styles = {
  button: (isPopoutOpen: boolean) => [
    tw`flex items-center w-full border-y border-solid border-border py-1`,
    isPopoutOpen && tw`bg-hover`,
  ],
  buttonIcon: (isOpen: boolean, isPopout: boolean) => [
    tw`ml-auto`,
    !isOpen && tw`-rotate-90`,
    isOpen && isPopout && tw`rotate-90`,
  ],
  container: (maxHeight?: number, isPopout?: boolean) => [
    tw`mt-2`,
    maxHeight !== undefined &&
      css({ maxHeight: `${maxHeight}px`, overflowY: "auto" }),
    maxHeight !== undefined && propertiesStyles.scrollbar,
    isPopout &&
      tw`absolute whitespace-nowrap border border-solid border-border rounded-r bg-white p-2`,
    isPopout &&
      css`
        min-width: 260px;
        left: 303px;
        bottom: 0;
      `,
  ],
};

const PropertyDropdown = ({
  children,
  defaultValue = false,
  label,
  disabled,
  maxHeight,
  isPopout = false,
}: IPropertyDropdownProps) => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const command = useContext(PropertyDropdownContext);
  const commandDispatch = useContext(PropertyDropdownDispatchContext);
  const shouldStayOpen = useRef(false);

  function toggleOpen() {
    if (isOpen) {
      setIsOpen(false);
      shouldStayOpen.current = false;
    } else {
      shouldStayOpen.current = true;
      issueCommand("close");

      setIsOpen(true);
    }
  }

  function issueCommand(command: string) {
    if (
      commandDispatch !== undefined &&
      typeof commandDispatch === "function"
    ) {
      commandDispatch({ type: "", payload: command });
    }
  }

  function handleCommand() {
    if (command === "close" && !shouldStayOpen.current) {
      setIsOpen(false);
    }
    if (shouldStayOpen.current && command === "close") {
      shouldStayOpen.current = false;
      issueCommand("open");
    }
    return () => {
      issueCommand("open");
    };
  }

  useEffect(handleCommand, [command]);
  return (
    <div css={tw`relative`}>
      <button onClick={toggleOpen} css={styles.button(isPopout && isOpen)}>
        {label}
        {!disabled && (
          <SelectInputIcon styles={styles.buttonIcon(isOpen, isPopout)} />
        )}
      </button>
      {isOpen && (
        <div css={styles.container(maxHeight, isPopout)}>{children}</div>
      )}
    </div>
  );
};

export default PropertyDropdown;
