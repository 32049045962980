import DesignerInput from "../shared/DesignerInput/DesignerInput";
import RoundedInputGroup from "../shared/RoundedInputGroup";
import IPropertiesProps from "./IPropertiesProps";
import styles from "./propertiesStyles";

import PropertyDropdown from "./PropertyDropdown";
import ColorPicker from "../shared/ColorPicker/ColorPicker";
import useProperties from "../../../hooks/useProperties/useProperties";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import { Object as IObject } from "fabric/fabric-impl";

interface IShadowPropertiesProps {
  selectedObject: IObject;
  properties: IObjectProperties;
  updateProperties: (property: string, value: string) => void;
}

const ShadowProperties = ({
  selectedObject,
  properties,
  updateProperties,
}: IShadowPropertiesProps) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    updateProperties(e.target.name, e.target.value);
  }

  return (
    <div>
      <PropertyDropdown label="Shadow Settings" isPopout>
        <div>
          <div css={styles.section}>
            <div style={{ width: "50%" }}>
              <ColorPicker
                type="shadow"
                selectedObject={selectedObject}
                positionOverride={{
                  top: "unset",
                  left: "198px",
                  bottom: "-75px",
                }}
              />
            </div>
          </div>
          <div css={styles.section}>
            <RoundedInputGroup>
              <DesignerInput
                position="left"
                name="shadowOffsetX"
                prefix="X"
                value={properties.shadowOffsetX}
                type="number"
                onChange={handleChange}
              />
              <DesignerInput
                position="center"
                name="shadowOffsetY"
                prefix="Y"
                value={properties.shadowOffsetY}
                type="number"
                onChange={handleChange}
              />
              <DesignerInput
                position="right"
                name="shadowBlur"
                prefix="Blur"
                value={properties.shadowBlur}
                type="number"
                onChange={handleChange}
              />
            </RoundedInputGroup>
          </div>
        </div>
      </PropertyDropdown>
    </div>
  );
};

export default ShadowProperties;
