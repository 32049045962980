import { ButtonHTMLAttributes, forwardRef } from "react";
import tw, { css } from "twin.macro";

const reset = (disabled = false) => [
  css`
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
    font-size: 13px;
    cursor: pointer;
  `,
  disabled &&
    tw`bg-buttonDisabled hover:bg-buttonDisabled text-buttonTextDisabled`,
];

const ButtonReset = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props: ButtonHTMLAttributes<HTMLButtonElement>, ref) => {
  return (
    <button css={reset(props.disabled)} {...props} ref={ref}>
      {props.children}
    </button>
  );
});

export default ButtonReset;
