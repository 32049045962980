/**
 * Takes an object and checks for truthy values at each key, then returns an array of those values in {key: value} form.
 * @example1 Use the return value to check if length === 0 for validation purposes
 * @example2 Use inside getObjectTruthyValueKeyNames to check for optional fields vs. required fields
 * @param obj Your object
 * @returns Record<string, T[keyof T]>[] Array of truthy values from your object
 */
export function getObjectTruthyValues<T>(obj: T): Record<string, T[keyof T]>[] {
  if (obj && typeof obj == "object") {
    const keys = Object.keys(obj);
    if (keys && keys.length) {
      const truthyKeys = keys.filter((key) => Boolean(obj[key as keyof T]));
      if (truthyKeys && truthyKeys.length) {
        const truthyValues = truthyKeys.map((key) => ({
          [key]: obj[key as keyof T],
        }));
        return truthyValues;
      }
      return [];
    }
  }
  return [];
}

/**
 * Takes a truthy values array from getObjectTruthyValues and returns the truthy keys only
 *
 * @param truthyValues
 * @returns string[]
 */
export function getObjectTruthyValueKeyNames<T>(
  truthyValues: Record<string, T[keyof T]>[]
): string[] {
  const truthyKeys = truthyValues.map((item) => Object.keys(item)[0]);
  return truthyKeys;
}
