import { useContext, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import tw, { css } from "twin.macro";

import {
  CanvasContext,
  CanvasDispatchContext,
} from "../../state/contexts/CanvasContext";
import { useDesignerDispatch, useDesignerSelector } from "../../state/store";

import useCanvas from "../../hooks/useCanvas";

import { createPages, loadLayers } from "../../state/slices/layers";
import {
  IPage,
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../../state/contexts/PagesContext";
import { SelectedPageDispatchContext } from "../../state/contexts/SelectedPageContext";
import { selectDesignInformation } from "../../state/slices/designInformation";

import useSaveData from "../../hooks/useSaveData";
import ImageDropzone from "../ImageDropzone/ImageDropzone";

import { AlignGuidelines } from "fabric-guideline-plugin";
import { GuidesDispatchContext } from "../../state/contexts/GuideContext";

import findTarget from "../../fabricExtensions/findTarget";

import loadSavedCanvasData from "./functions/loadSavedCanvasData";
import insertBackgroundAndOverlay from "./functions/insertBackgroundAndOverlay";
import useDelete from "../../hooks/useDelete";
import getDefaultZoom from "./functions/getDefaultZoom";

import shouldClearSelection from "./functions/customFabricFunctionality/shouldClearSelection";
import checkTarget from "./functions/customFabricFunctionality/checkTarget";
import { CONTROL_COLOR } from "../../constants/CANVAS_SETTINGS";
import debugSvgGenerator from "./functions/debugSvgGenerator";
import { createBleedClipPath } from "./functions/createBackgroundClipPath";
import getQrCodeId from "../QrCodes/functions/getQrCodeId";
import { setQrCodeId } from "../../state/slices/qrCode";
import useLayers from "../../hooks/useLayers";
import usePageUpdate from "../../hooks/usePageUpdate";
import {
  LetterFoldTypes,
  initializeLetterSettings,
  selectLetterSettings,
} from "../../state/slices/letterSettings";
import RESOLUTION from "../../constants/RESOLUTION";
import DesignerSizes from "../../../data/models/DesignerSizes";
import centerAllObjectsOnCanvas from "../../helpers/centerAllObjectsOnCanvas";

const Canvas = ({ loadCanvas }: { loadCanvas: () => void }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [initialized, setInitialized] = useState(false);
  const canvasDispatch = useContext(CanvasDispatchContext);
  const guidesDispatch = useContext(GuidesDispatchContext);
  const pagesDispatch = useContext(PagesDispatchContext);
  const selectedPageDispatch = useContext(SelectedPageDispatchContext);
  const canvas = useContext(CanvasContext);
  const canvasUtilities = useCanvas();
  const { loadSavedCanvas, saveData } = useSaveData();
  const dispatch = useDesignerDispatch();
  const saveDataLoadedRef = useRef<IPage[]>([]);
  const [saveDataLoaded, setSaveDataLoaded] = useState<IPage[]>([]);
  const marginListenerRef = useRef(false);
  usePageUpdate(initialized);
  useDelete();
  const designInformation = useDesignerSelector(selectDesignInformation);
  const letterSettings = useDesignerSelector(selectLetterSettings);
  const letterSettingsRef = useRef(letterSettings);
  letterSettingsRef.current = letterSettings;
  async function init() {
    const container = document.querySelector("._canvasDesignerRoot");

    if (
      designInformation &&
      designInformation.canvasSettings &&
      designInformation.canvasSettings?.height &&
      designInformation.pageSettings &&
      canvasRef.current
    ) {
      // grab the column that holds the canvas
      //fabric.Text.prototype._renderChars = renderCharsOverride;
      // @ts-ignore
      fabric.Canvas.prototype._shouldClearSelection = shouldClearSelection;
      // @ts-ignore
      fabric.Canvas.prototype._checkTarget = checkTarget;
      // @ts-ignore
      fabric.Canvas.prototype.findTarget = findTarget;
      if (!container) return;
      const zoom = getDefaultZoom(
        designInformation.canvasSettings.width,
        designInformation.canvasSettings.height,
      );
      const isLetter =
        designInformation.canvasSettings.width === 8.5 &&
        designInformation.canvasSettings.height === 11;

      fabric.Object.prototype.set({
        borderColor: CONTROL_COLOR,
        cornerColor: CONTROL_COLOR,
        cornerStrokeColor: CONTROL_COLOR,
        transparentCorners: false,
        cornerStyle: "circle",
        borderScaleFactor: 2.5,
        cornerSize: 9,
      });

      const canvas = new fabric.Canvas(canvasRef.current, {
        width: canvasRef.current.clientWidth,
        height: canvasRef.current.clientHeight,
        preserveObjectStacking: true,
        backgroundColor: "#f1f1f1",
        controlsAboveOverlay: true,
        uniformScaling: true,
        hoverCursor: "default",
      });

      canvas.on("mouse:over", (e) => {
        const target = e.target;
        if (target && target.selectable && target.canvas) {
          if (target.canvas.getActiveObjects().length) {
            return;
          }

          if (target instanceof fabric.Object) {
            const bound = target.getBoundingRect();
            const ctx = target.canvas.getContext();
            ctx.strokeStyle = CONTROL_COLOR;
            ctx.lineWidth = 2.5;
            ctx.strokeRect(bound.left, bound.top, bound.width, bound.height);
          }
        }
      });
      canvas.on("mouse:out", (e) => {
        if (e.target && e.target.selectable) {
          const target = e.target;
          if (target && target instanceof fabric.Object) {
            target.canvas?.renderAll();
          }
        }
      });

      const guides = new AlignGuidelines({
        canvas,
        pickObjTypes: [{ key: "evented", value: true }],
      });

      guides.init();
      if (saveData) {
        const pages = await loadSavedCanvasData(
          canvas,
          saveData.pages,
          designInformation.canvasSettings.overlay,
          designInformation.canvasSettings.width,
          designInformation.canvasSettings.height,
          saveData.layers,
          saveData.envelopeType,
          designInformation.canvasSettings.overlay === DesignerSizes.BROCHURE
            ? saveData.foldType ?? LetterFoldTypes.TRIFOLD
            : undefined,
          // saveData.margins
        );
        const qrCodeId: number | null = getQrCodeId(pages);
        dispatch(
          initializeLetterSettings({
            envelopeType: saveData.envelopeType ?? undefined,
            margins: saveData.margins ?? undefined,
            foldType: saveData.foldType ?? undefined,
          }),
        );
        dispatch(setQrCodeId(qrCodeId));
        dispatch(loadLayers(saveData.layers));
        pagesDispatch({ type: "createPages", payload: pages });
        selectedPageDispatch({
          type: "page",
          payload: pages[0].name,
        });
      } else {
        const pages: IPage[] = [];
        const pageLayers: string[] = [];

        const margins = isLetter
          ? {
              top: 0.5 * RESOLUTION,
              left: 0.5 * RESOLUTION,
              right: 0.5 * RESOLUTION,
              bottom: 0.5 * RESOLUTION,
            }
          : undefined;
        dispatch(
          initializeLetterSettings({
            envelopeType: isLetter ? "doubleWindow" : undefined,
            margins,
          }),
        );
        for (let i = designInformation.pageSettings.length - 1; i >= 0; i--) {
          const page = designInformation.pageSettings[i].name;
          canvas.remove(...canvas.getObjects());
          canvas.renderAll();

          await insertBackgroundAndOverlay(
            canvas,
            designInformation.canvasSettings.width,
            designInformation.canvasSettings.height,
            designInformation.pageSettings[i].name === "Page 1" ||
              designInformation.pageSettings[i].name === "Back" ||
              designInformation.canvasSettings.overlay ===
                DesignerSizes.BROCHURE
              ? designInformation.canvasSettings.overlay
              : undefined,
            "doubleWindow",
            LetterFoldTypes.TRIFOLD,
            designInformation.canvasSettings.overlay ===
              DesignerSizes.BROCHURE &&
              designInformation.pageSettings[i].name === "Front",
            // margins
          );

          const objects = [...canvas._objects];

          pageLayers.push(page);
          pages.push({ name: page, objects: [...objects] });
          centerAllObjectsOnCanvas(canvas, true);
        }

        // create our Page/Layer state
        dispatch(createPages(pageLayers));

        // Select the first page

        // Create our pages in Context
        pagesDispatch({ type: "createPages", payload: [...pages].reverse() });
        const page = pages.reverse()[0].name;

        selectedPageDispatch({
          type: "page",
          payload: page,
        });
      }

      // load fontAwesome
      const regular = new fabric.Text("\uF111", {
        fontFamily: "FontAwesome",
        fontWeight: 400,
        fontSize: 1,
      });
      const solid = new fabric.Text("\uF111", {
        fontFamily: "FontAwesome",
        fontWeight: 900,
        fontSize: 1,
      });

      canvas.add(regular, solid);

      // Setup the design background on the Canvas

      canvas.renderAll();

      canvas.remove(regular, solid);
      canvas.renderAll();

      // @ts-ignore
      window.debugCanvas = canvas;
      // @ts-ignore
      window.debugExportSvg = debugSvgGenerator(canvas);
      // setup our canvas context
      canvasDispatch({ type: "", payload: canvas });
      //guidesDispatch({ type: "", payload: guides });

      // canvas.zoomToPoint(
      //   { x: canvas.getWidth() / 2 + 128, y: canvas.getHeight() / 2 + 47 },
      //   zoom
      // );

      setTimeout(() => {
        setInitialized(true);
        loadCanvas();
      }, 1);
    }
  }

  useEffect(() => {
    init();
  }, [designInformation]);

  function handleMarginBounds() {}

  useEffect(() => {
    const a = 1;
    if (
      initialized &&
      letterSettings &&
      !marginListenerRef.current &&
      canvas &&
      letterSettingsRef.current &&
      a !== 1
    ) {
      canvas.on("object:moving", (e) => {
        const margins = letterSettingsRef.current?.margins;
        const isLetter =
          designInformation.canvasSettings?.width === 8.5 &&
          designInformation.canvasSettings?.height === 11;

        if (!isLetter || !margins) return;
        const background = canvas
          .getObjects()
          .find((x) => x.name === "background");
        if (!background) return;
        const bLeft = background.left ?? 0;
        const bTop = background.top ?? 0;
        const width = background.width ?? 0;
        const height = background.height ?? 0;
        const left = margins.left + bLeft;
        const top = margins.top + bTop;
        const right = bLeft + width - margins.right;
        const bottom = bTop + height - margins.bottom;

        if (e.target?.type?.includes("text")) {
          const obj = e.target;
          if (obj.left !== undefined && obj.left < left) {
            obj.left = left;
          }
          if (obj.top !== undefined && obj.top < top) {
            obj.top = top;
          }
          if (obj.left !== undefined && obj.width !== undefined) {
            if (obj.left + obj.width > right) {
              obj.left = right - obj.width;
            }
          }
          if (obj.top !== undefined && obj.height !== undefined) {
            if (obj.top + obj.height > bottom) {
              obj.top = bottom - obj.height;
            }
          }
          canvas.renderAll();
        }
      });
    }
  }, [letterSettings, canvas, initialized]);

  return (
    <div
      css={[
        css`
          width: calc(100vw - 60px);
          max-width: calc(100vw - 60px);

          overflow: auto;
          top: 47px;
          max-height: calc(100% - 47px);
          height: calc(100% - 47px);
          position: relative;
        `,
      ]}
      id="designer-canvas-parent"
    >
      <ImageDropzone>
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            maxHeight: "100%",
          }}
        />
      </ImageDropzone>
    </div>
  );
};

export default Canvas;
