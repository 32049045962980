import { useContext } from "react";
import { IntegratedContext } from "../../../../routes/Integrated/layout/IntegratedLayout";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import {
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../../../state/contexts/PagesContext";
import { SelectedPageContext } from "../../../state/contexts/SelectedPageContext";
import {
  selectLetterSettings,
  setEnvelopeType,
} from "../../../state/slices/letterSettings";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import getAddressBlockOverlay from "../../Canvas/functions/getAddressBlockOverlay";
import { SelectOption } from "../shared/DesignerInput/SelectOption";
import { DesignerSelect } from "../shared/NuDesignerInputs/DesignerSelect";
import toolbarStyles from "./toolbarStyles";

const LetterProperties = () => {
  const integratedContext = useContext(IntegratedContext);
  const letterProperties = useDesignerSelector(selectLetterSettings);
  const dispatch = useDesignerDispatch();
  const pages = useContext(PagesContext);
  const pagesDispatch = useContext(PagesDispatchContext);
  const selectedPage = useContext(SelectedPageContext);
  const canvas = useContext(CanvasContext);
  async function handleEnvelopeType(name: string, value: string) {
    if (!canvas) return;
    if (value === letterProperties.envelopeType) return;

    const newAddressBlock = await getAddressBlockOverlay(value, canvas);
    const newPages = pages.map((x) => ({
      ...x,
      // @ts-ignore
      objects:
        x.name === "Page 1"
          ? // @ts-ignore
            [...x.objects.filter((x) => !x.__addressBlock), ...newAddressBlock]
          : x.objects,
    }));
    pagesDispatch({ type: PageActions.setPages, payload: newPages });
    if (selectedPage === "Page 1") {
      const addressBlock = canvas.getObjects().filter(
        // @ts-ignore
        (x) => x.name === "overlay" && x.__addressBlock,
      );
      if (addressBlock) {
        canvas.remove(...addressBlock);
        if (newAddressBlock.length) {
          canvas.add(...newAddressBlock);
        }
        canvas.renderAll();
      }
    }
    dispatch(setEnvelopeType(value));
  }

  const envelopeTypes =
    !integratedContext.source || integratedContext.source === "portal"
      ? [
          {
            value: "regular",
            label: "Regular Envelope",
          },

          {
            value: "BiFold",
            label: "Bi-Fold Regular Envelope",
          },
          {
            value: "doubleWindow",
            label: "Double Window Envelope",
          },
          {
            value: "singleWindow",
            label: "Single Window Envelope",
          },
          {
            value: "fullWindow",
            label: "Full Window Envelope",
          },
        ]
      : [
          {
            value: "regular",
            label: "Regular Envelope",
          },
          {
            value: "BiFold",
            label: "Bi-Fold Regular Envelope",
          },
          {
            value: "doubleWindow",
            label: "Double Window Envelope",
          },
          {
            value: "fullWindow",
            label: "Full Window Envelope",
          },
        ];

  return (
    <div css={toolbarStyles.section}>
      <span css={toolbarStyles.propertyLabel}>Envelope Type: </span>
      <div>
        <DesignerSelect
          value={letterProperties.envelopeType ?? "doubleWindow"}
          onChange={handleEnvelopeType}
          name={"envelopeType"}
          width={200}
        >
          {envelopeTypes.map((type) => (
            <SelectOption key={type.value} value={type.value}>
              {type.label}
            </SelectOption>
          ))}
        </DesignerSelect>
      </div>
    </div>
  );
};

export default LetterProperties;
