import { Canvas, Image } from "fabric/fabric-impl";
import { fabric } from "fabric";

export default async function asyncImageFromUrl(canvas: Canvas, json: unknown) {
  return new Promise<void>((resolve) => {
    canvas.loadFromJSON(json, () => {
      resolve();
    });
  });
}
