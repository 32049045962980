import { getCookie } from "../routes/Integrated/helpers/cookies";
import LocalStorage from "./LocalStorage";

const _baseUrl = "/api";

enum StatusCodes {
  Ok = 200,
  NoContent = 204,
  CreatedAt = 201,
  Unauthorized = 401,
  NotFound = 404,
  BadRequest = 400,
  ServerError = 500,
}

function getHeaders(isFormData = false, overrideToken?: string) {
  let _token: string | null = null;
  const cookie = getCookie("token");
  if (overrideToken) {
    _token = overrideToken;
  } else if (cookie) {
    _token = cookie;
  } else _token = getApiToken();
  const headers: HeadersInit = {
    Authorization: `bearer ${_token}`,
    Accept: "application/json",
  };
  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }
  return headers;
}

async function handleResponse<T>(res: Response) {
  if (res.status === StatusCodes.Ok) {
    const data: T = await res.json();
    return data;
  }
  if (res.status === StatusCodes.NoContent) {
    return;
  }
  if (res.status === StatusCodes.Unauthorized) {
    window.location.href = "/login";
  } else {
    const error = res.statusText;
    console.error(error);
    throw error;
    return null;
  }
}

export async function getAsync<T>(path: string) {
  const res = await fetch(`${_baseUrl}${path}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return await handleResponse<T>(res);
}

export async function deleteAsync(path: string) {
  const res = await fetch(`${_baseUrl}${path}`, {
    method: "DELETE",
    headers: getHeaders(),
  });
  return await handleResponse(res);
}

export async function postAsync<T>(
  path: string,
  data?: unknown,
  isFormData?: boolean,
  overrideToken?: string
) {
  if (isFormData) {
    const formData = new FormData();
    for (const key in data as object) {
      formData.append(
        key,
        (data as Record<string, string>)[key] as string | Blob
      );
    }
    data = formData;
  }
  try {
    const res = await fetch(`${_baseUrl}${path}`, {
      method: "POST",
      headers: getHeaders(isFormData, overrideToken),
      body: isFormData ? (data as FormData) : JSON.stringify(data),
    });
    if (res.status === 200 || res.status === 201)
      return await handleResponse<T>(res);
    else return null;
  } catch (error) {
    throw error;
  }
}

export async function putAsync<T>(path: string, data?: unknown) {
  const res = await fetch(`${_baseUrl}${path}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  return await handleResponse<T>(res);
}

function getApiToken() {
  const token = LocalStorage.getToken();
  return token;
}
