import tw, { css } from "twin.macro";

const propertiesStyles = {
  propertiesGroup: [tw`flex items-center mb-2`],
  property: [tw`w-1/2 box-border pr-2 last:pr-0`],
  section: [tw`mb-4`],
  sectionSubheading: [tw`font-semibold mb-1 mt-1 text-sm`],
  sectionHeading: [tw`font-semibold mb-1 mt-2 text-sm`],
  label: [tw`text-xs italic`],
  input: [tw`w-full`],
  hr: [tw`w-full border-b border-solid border-border mt-2 mb-4`],
  inputWrapper: [
    tw`bg-inputBackground border border-solid border-hover flex items-center relative p-2 rounded text-text`,
  ],
  sidebarHeading: [
    tw`text-lg font-semibold uppercase  bg-glacier px-4 py-1 text-labels`,
  ],
  sidebarContainer: [tw`p-4`],
  scrollbar: [
    css(`&::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    transition: opacity 0.2s ease-in;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
    opacity: 0.25;
    transition: opacity 0.2s ease-in;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(85, 85, 85, 0.25);
    transition: background-color 0.2s ease-in;
  }`),
  ],
};

export default propertiesStyles;
