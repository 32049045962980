import { DesignField } from "./../../../Designs/models/Design";
import OrderRequest, {
  FieldMapping,
  ILetterConfig,
  OrderConfig,
  ReturnAddress,
} from "../models/OrderRequest";
import {
  APPROVE,
  CONFIGURE_DESIGN,
  MAP_LIST_TO_FIELDS,
  UPLOAD_LIST,
} from "./stepConstants";
import checkIfStringIsUrl from "../../../../helpers/checkIfStringIsUrl";
import getImageNameFromUrl from "../../../../helpers/getImagefileNameFromUrl";
import checkIfImageTypeIsValid from "../../../../helpers/checkIfImageTypeIsValid";

interface ValidateStep {
  (
    step: number,
    orderRequest: OrderRequest,
    designFields: DesignField[],
    isLetter: boolean,
  ): Record<string, boolean>;
}

interface ValidateFields {
  (fields: { key: string; value: string }[]): Record<string, boolean>;
}

interface ValidateConfigureDesign {
  (
    configureOrderObj: OrderConfig,
    designFields: DesignField[],
    isLetter: boolean,
    returnAddress?: ReturnAddress,
  ): Record<string, boolean>;
}

interface ValidateUploadList {
  (
    listFileId: number | null,
    listHeaders: string[] | undefined,
  ): Record<string, boolean>;
}

interface ValidateMapListToFields {
  (listFields: FieldMapping[]): Record<string, boolean>;
}

export const validateStep: ValidateStep = (
  step,
  orderRequest,
  designFields,
  isLetter = false,
) => {
  let errors = {};
  switch (step) {
    case CONFIGURE_DESIGN: {
      errors = validateConfigureDesign(
        orderRequest.orderconfig,
        designFields,
        isLetter,
        orderRequest.returnAddress,
      );
      break;
    }
    case UPLOAD_LIST: {
      errors = validateUploadList(
        orderRequest.listFileId,
        orderRequest.listAttributes?.listHeaders,
      );
      break;
    }
    case MAP_LIST_TO_FIELDS: {
      errors = validateMapListToFields(orderRequest.listMapping);
      break;
    }
    case APPROVE: {
      break;
    }
  }

  return errors;
};

const getLetterFields = (
  letterConfig?: ILetterConfig,
): { key: string; value: string }[] => {
  const fields = [
    { key: "color", value: letterConfig?.color !== undefined ? "true" : "" },
    {
      key: "printOnBothSides",
      value: letterConfig?.printOnBothSides !== undefined ? "true" : "",
    },
    {
      key: "insertAddressingPage",
      value: letterConfig?.insertAddressingPage !== undefined ? "true" : "",
    },
    { key: "envelopeType", value: letterConfig?.envelopeType ?? "" },
    { key: "envelopeFont", value: letterConfig?.envelopeFont ?? "" },
  ];
  if (!letterConfig) return fields;
  if (letterConfig.envelopeType === "Regular") {
    fields.push({
      key: "liveStamping",
      value: letterConfig.liveStamping !== undefined ? "true" : "",
    });
  }
  if (letterConfig.envelopeType !== "Regular" && letterConfig.color) {
    fields.push({
      key: "envelopeFontColor",
      value: letterConfig.envelopeFontColor ?? "",
    });
  }
  if (letterConfig.envelopeType === "Custom") {
    fields.push({
      key: "envelopeID",
      value: letterConfig.envelopeID ? letterConfig.envelopeID.toString() : "",
    });
  }
  return fields;
};

export const validateConfigureDesign: ValidateConfigureDesign = (
  configureOrderObj,
  designFields,
  isLetter,
  returnAddress,
) => {
  const fields = isLetter
    ? [...getLetterFields(configureOrderObj.letterConfig)]
    : [
        {
          key: "mailClass",
          value: configureOrderObj.mailClass,
        },
      ];

  let errors = validateFields(fields);
  const imageFields = getImageFieldValues(designFields, fields);
  errors = validateImageFields(imageFields, errors);
  errors = validateReturnAddress(errors, returnAddress);
  return errors;
};

const validateReturnAddress = (
  errors: Record<string, boolean>,
  returnAddress?: ReturnAddress,
) => {
  if (
    returnAddress &&
    (returnAddress.firstName ||
      returnAddress.address ||
      returnAddress.address2 ||
      returnAddress.city ||
      returnAddress.zipCode ||
      returnAddress.company)
  ) {
    if (!returnAddress.firstName && !returnAddress.company) {
      errors["returnaddressfirstname"] = true;
      errors["returnaddresscompany"] = true;
    }
    if (!returnAddress.address) errors["returnaddress"] = true;
    if (!returnAddress.city) errors["returnaddresscity"] = true;
    if (!returnAddress.state) errors["returnaddressstate"] = true;
    if (!returnAddress.zipCode) errors["returnaddresszipcode"] = true;
  }
  return errors;
};

const validateMapListToFields: ValidateMapListToFields = (fields) => {
  const errors: Record<string, boolean> = {};
  fields.forEach((field) => {
    if (
      !field.userListFieldName &&
      field.fieldName !== "extRefNbr" &&
      field.fieldName !== "address2" &&
      field.fieldName !== "company" &&
      field.fieldName !== "firstName" &&
      field.fieldName !== "lastName"
    )
      errors[field.fieldName] = true;
  });

  const firstName = fields.find((x) => x.fieldName === "firstName");
  const lastName = fields.find((x) => x.fieldName === "lastName");
  const company = fields.find((x) => x.fieldName === "company");

  console.log(firstName, lastName, company);

  if (!firstName?.userListFieldName && !company?.userListFieldName) {
    errors["firstName"] = true;
    errors["company"] = true;
    if (firstName?.userListFieldName) {
      if (!lastName?.userListFieldName) {
        errors["lastName"] = true;
      }
    }
  }

  if (firstName?.userListFieldName && !lastName?.userListFieldName) {
    errors["lastName"] = true;
  }
  console.log(errors);
  return errors;
};

const validateUploadList: ValidateUploadList = (listFileId, listHeaders) => {
  const errors: Record<string, boolean> = {};
  if (!listFileId) errors["listFile"] = true;
  if (!listHeaders || listHeaders.length === 0) errors["listInvalid"] = true;
  return errors;
};

const validateFields: ValidateFields = (fields) => {
  const errors: Record<string, boolean> = {};
  fields.forEach((field) => {
    if (!field.value && field.key !== "envelopeFont") {
      errors[field.key] = true;
    }
  });

  return errors;
};

const getImageFieldValues = (
  designFields: DesignField[],
  variableValues: { key: string; value: string }[],
): { key: string; value: string }[] => {
  const fieldKeys = designFields
    .filter((field) => field.fieldType === "image")
    .map((field) => field.fieldKey);
  return variableValues.filter((val) => fieldKeys.includes(val.key));
};

const validateImageFields = (
  variableValues: { key: string; value: string }[],
  errors: Record<string, boolean>,
): Record<string, boolean> => {
  const imageErrors = {} as Record<string, boolean>;
  variableValues.forEach((variable) => {
    if (!variable.value) return (imageErrors[variable.key] = true);
    const url = variable.value;
    const isValidUrl = checkIfStringIsUrl(url);
    if (!isValidUrl) return (imageErrors[variable.key] = true);
    const fileName = getImageNameFromUrl(url, true);

    const isValidImageType = checkIfImageTypeIsValid(fileName);

    if (!isValidImageType) return (imageErrors[variable.key] = true);
  });
  return { ...errors, ...imageErrors };
};
