import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import IHtmlVersion from "../../../../data/models/IHtmlVersion";
import DesignVariablesTable from "../../../Designs/Design/components/DesignVariablesTable";
import { DesignField } from "../../../Designs/models/Design";
import HtmlViewer from "../../components/HtmlViewer";
import buildFields from "../../helpers/buildFields";
import getVars from "../../helpers/getVars";

interface IHtmlVersionBodyProps {
  version: IHtmlVersion;
  currentVersionId: string;
}

const HtmlVersionBody = ({
  version,
  currentVersionId: string,
}: IHtmlVersionBodyProps) => {
  const [html, setHtml] = useState<string>("front");
  const hasBack = Boolean(version.htmlBack);

  const designVariables = getVars(version.html);
  const designVarsBack = getVars(version.htmlBack);
  const designFields = buildFields([...designVariables, ...designVarsBack]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} sm={7} lg={9}>
          {hasBack && (
            <Select
              value={html}
              onChange={(e) => setHtml(e.target.value)}
              fullWidth
            >
              <MenuItem value="front">Front HTML</MenuItem>
              <MenuItem value="back">Back HTML</MenuItem>
            </Select>
          )}
          {html === "front" && <HtmlViewer html={version.html} />}
          {html === "back" && <HtmlViewer html={version.htmlBack} />}
        </Grid>
        <Grid item xs={12} md={6} sm={5} lg={3}>
          <DesignVariablesTable designFields={designFields} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HtmlVersionBody;
