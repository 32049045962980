import { useEffect, useState } from "react";
import IDesign from "../../data/models/IDesign";
import { getAsync } from "../../helpers/asyncFetch";
import Design from "../../routes/Designs/models/Design";

export default function useHtmlDesigns(): [
  IDesign[],
  boolean,
  (tl: boolean) => void,
  (designs: IDesign[]) => void
] {
  const [designs, setDesigns] = useState<IDesign[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getDesigns(toggleLoading = false) {
    if (toggleLoading) setIsLoading(true);
    const designs = await getAsync<IDesign[]>(`/html`);
    if (designs) {
      setDesigns(
        designs.map((design) => ({
          ...design,
          type: design.printPDFSource?.includes("PC") ? "Postcard" : "Letter",
        }))
      );
    }
    setIsLoading(false);
  }

  function updateDesigns(designs: IDesign[]) {
    setDesigns(
      designs.map((design) => ({
        ...design,
        type: design.printPDFSource?.includes("PC")
          ? "Postcard"
          : design.printPDFSource?.includes("BRO")
          ? "Brochure"
          : "Letter",
      }))
    );
  }

  useEffect(() => {
    getDesigns();
  }, []);

  return [designs, isLoading, getDesigns, updateDesigns];
}
