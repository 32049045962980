import { Typography, TypographyProps } from "@mui/material";

type headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
interface HeadingProps extends Omit<TypographyProps, "variant"> {
  variant: headings;
  marginBottom?: number;
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000;
}

enum fontSize {
  "h1" = "2.5rem",
  "h2" = "2rem",
  "h3" = "1.75rem",
  "h4" = "1.5rem",
  "h5" = "1.25rem",
  "h6" = "1rem",
}

const Heading: React.FC<HeadingProps> = ({
  variant,
  children,
  marginBottom = 3,
  fontWeight = 600,
  ...props
}) => {
  return (
    <Typography
      {...props}
      variant={variant}
      sx={{
        ...props.sx,
        mb: marginBottom,
        fontSize: fontSize[variant],
        fontWeight,
      }}
    >
      {children}
    </Typography>
  );
};

export default Heading;
