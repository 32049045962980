import React, { useContext } from "react";
import { TableColumn } from "react-data-table-component";
import SearchableDataTable from "../../../../shared/SearchableDataTable";
import { Recipient } from "../models/OrderRequest";
import formattingHelpers from "../../../../helpers/formattingHelpers";
import { OrderRequestContext } from "../PlaceAnOrder";

interface ListValidationErrorProps {
  invalidListRecipients: Recipient[];
}

const ListValidationError: React.FC<ListValidationErrorProps> = ({
  invalidListRecipients,
}) => {
  const orderRequestStore = useContext(OrderRequestContext);
  const request = orderRequestStore.orderRequest;
  const validRecipients = request.recipients || [];

  const columns: TableColumn<Recipient>[] = [
    {
      name: "First Name",
      selector: (cell) => cell.firstName,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.company && !cell.firstName,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (cell) => cell.lastName,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.company && !cell.lastName,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
    {
      name: "Company",
      selector: (cell) => cell.company ?? "",
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.lastName && !cell.firstName && !cell.company,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
    {
      name: "Address",
      selector: (cell) => cell.address,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.address,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
    {
      name: "Address 2",
      selector: (cell) => cell.address2,
      sortable: true,
    },
    {
      name: "City",
      selector: (cell) => cell.city,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.city,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
    {
      name: "State",
      selector: (cell) => cell.state,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.state,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
    {
      name: "Zip Code",
      selector: (cell) => cell.zipCode,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell) => !cell.zipCode,
          style: {
            backgroundColor: "#FF9999",
          },
        },
      ],
    },
  ];

  return (
    <div>
      <h3>
        Your list contains{" "}
        {formattingHelpers.formatThousands(invalidListRecipients.length)}{" "}
        invalid recipients
      </h3>
      {validRecipients.length > 0 && (
        <p>
          If you wish to place an order with your{" "}
          {formattingHelpers.formatThousands(validRecipients.length)} valid
          recipients use the continue button below. Otherwise you may click
          &quot;Back&quot; to modify your mapping or upload a new list.
        </p>
      )}
      {validRecipients.length === 0 && (
        <p>
          You have no valid recipients. Please click &quot;Back&quot; to modify
          your mapping or upload a new list.
        </p>
      )}
      <SearchableDataTable
        data={invalidListRecipients}
        columns={columns}
        searchableColumns={[
          "firstName",
          "lastName",
          "company",
          "address",
          "address2",
          "city",
          "state",
          "zipCode",
        ]}
      />
    </div>
  );
};

export default ListValidationError;
