import { fabric, IPathPoint, Path } from "fabric";
import { Point } from "fabric/fabric-impl";

/**
 * @todo Rename to transformPointsFromPath
 * @param path
 * @param pts
 * @returns
 */
export default function updatePoints(path: Path, pts: IPathPoint[]) {
  const points = pts.map((p) => ({
    x: p.x,
    y: p.y,
    name: p.name,
    quadraticForward: p.quadraticForward
      ? { ...p.quadraticForward }
      : undefined,
    quadraticBackward: p.quadraticBackward
      ? { ...p.quadraticBackward }
      : undefined,
  }));

  const transformMatrix = path.calcOwnMatrix();
  const newPoints = points.map((p) => {
    const point = { ...p };
    const transformedPoint = fabric.util.transformPoint(
      {
        x: point.x - path.pathOffset.x,
        y: point.y - path.pathOffset.y,
      } as Point,
      transformMatrix
    );
    point.x = transformedPoint.x;
    point.y = transformedPoint.y;
    if (point.quadraticBackward) {
      const transformedPoint = fabric.util.transformPoint(
        {
          x: point.quadraticBackward.x - path.pathOffset.x,
          y: point.quadraticBackward.y - path.pathOffset.y,
        } as Point,
        transformMatrix
      );
      point.quadraticBackward.x = transformedPoint.x;
      point.quadraticBackward.y = transformedPoint.y;
    }
    if (point.quadraticForward) {
      const transformedPoint = fabric.util.transformPoint(
        {
          x: point.quadraticForward.x - path.pathOffset.x,
          y: point.quadraticForward.y - path.pathOffset.y,
        } as Point,
        transformMatrix
      );
      point.quadraticForward.x = transformedPoint.x;
      point.quadraticForward.y = transformedPoint.y;
    }
    return point;
  });

  return newPoints;
}
