import { Card, TextField, Grid, Box, Button } from "@mui/material";
import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
//import DataTable, { TableProps } from 'react-data-table-component';
import DataTable, { TableProps } from "react-data-table-component";
import "./SearchableDataTable.scss";

interface SearchableDataTableProps extends TableProps<any> {
  searchableColumns?: string[];
  customHeaderComponent?: JSX.Element;
  data: any[];
  dense?: boolean;
  tableTitle?: string;
  straightEdge?: boolean;
  hideSearchBar?: boolean;
  overrideSearchTerm?: string;
  elevation?: number;
  filters?: string[];
  filterCol?: string;
}

const SearchableDataTable: React.FC<SearchableDataTableProps> = (
  props: SearchableDataTableProps
) => {
  const {
    searchableColumns,
    tableTitle,
    customHeaderComponent,
    hideSearchBar,
    overrideSearchTerm,
    elevation = 3,
    filters,
    filterCol,

    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>(
    overrideSearchTerm ? overrideSearchTerm : ""
  );
  const [activeFilter, setActiveFilter] = useState("");
  const [filteredResults, setFilteredResults] = useState<any[]>(props.data);

  function handleSearch(e: React.ChangeEvent<{ value: string }>) {
    setSearchTerm(e.target.value);
  }

  function handleSearchOverride() {
    if (overrideSearchTerm !== null && overrideSearchTerm !== undefined) {
      if (overrideSearchTerm !== searchTerm) {
        setSearchTerm(overrideSearchTerm);
      }
    }
  }

  function exportResults() {}

  function filterResults() {
    let filterableData = props.data;
    if (activeFilter && filters && filterCol && activeFilter !== "All") {
      filterableData = filterableData.filter((x) => {
        console.log(activeFilter, filterCol, x);
        return x[filterCol as keyof any] === activeFilter;
      });
    }

    if (searchableColumns) {
      if (searchTerm === "") return setFilteredResults(filterableData);
      const fuseOptions = {
        keys: [...searchableColumns],
      };
      const fuse = new Fuse(filterableData, fuseOptions);
      const result = fuse.search(searchTerm);
      setFilteredResults(result.map((res) => res.item));
    }
  }

  useEffect(filterResults, [
    searchTerm,
    setFilteredResults,
    props.data,
    searchableColumns,
    activeFilter,
  ]);

  useEffect(handleSearchOverride, [overrideSearchTerm]);

  return (
    <div className="searchableDataTable">
      <Grid
        container
        spacing={2}
        alignItems="center"
        className="searchableDataTable__header"
      >
        <Grid item md={8}>
          {tableTitle && (
            <h4 className="searchableDataTable__title">{tableTitle}</h4>
          )}
          {customHeaderComponent !== null &&
            customHeaderComponent !== undefined &&
            customHeaderComponent}
          {filters && filters.length > 0 && (
            <Box
              sx={{
                display: "flex",
                fontSize: "12px",
                mt: 1,
              }}
            >
              <Box
                onClick={() => setActiveFilter("")}
                sx={{
                  textDecoration: activeFilter === "" ? "underline" : "none",
                  fontWeight: 500,
                  mr: 2,
                  cursor: "pointer",
                  color:
                    activeFilter === ""
                      ? (theme) => theme.palette.primary.main
                      : "rgba(0,0,0,.65)",
                  ":hover": {
                    color: (theme) => theme.palette.primary.dark,
                  },
                }}
              >
                Show All
              </Box>
              {filters.map((filter) => (
                <Box
                  key={filter}
                  onClick={() => setActiveFilter(filter)}
                  sx={{
                    textDecoration:
                      activeFilter === filter ? "underline" : "none",
                    fontWeight: 500,
                    cursor: "pointer",
                    color:
                      activeFilter === filter
                        ? (theme) => theme.palette.primary.main
                        : "rgba(0,0,0,.65)",
                    mr: 2,
                    ":hover": {
                      color: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  {filter}
                </Box>
              ))}
            </Box>
          )}
        </Grid>
        <Grid item md={4}>
          {!hideSearchBar && (
            <div className="searchableDataTable__search">
              <TextField
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
                variant="outlined"
                label="Filter Results"
                className="searchableDataTable__search__input"
                size="small"
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Card
        className={props.straightEdge ? "noRadius" : ""}
        elevation={elevation}
      >
        <DataTable {...rest} data={filteredResults} pagination />
      </Card>
    </div>
  );
};

export default SearchableDataTable;
