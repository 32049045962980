import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IAsset from "../../../data/models/IAsset";
import IAssetLibrary from "../models/ICustomerImageLibrary";
import ISvgShape from "../models/ISvgShape";
import { DesignerState } from "../store";

interface IAssetLibraryState extends IAssetLibrary {
  isLoaded: boolean;
}

const initialState: IAssetLibraryState = {
  customerAssets: [],
  designAssets: [],
  genericAssets: [],
  shapes: [],
  isLoaded: false,
};

const assetLibrary = createSlice({
  name: "assetLibrary",
  initialState,
  reducers: {
    loadLibrary: (state, action: PayloadAction<IAssetLibrary>) => {
      state.customerAssets = action.payload.customerAssets;
      state.designAssets = action.payload.designAssets;
      state.genericAssets = action.payload.genericAssets;
      state.shapes = action.payload.shapes;
      state.isLoaded = true;
    },
    insertNewAsset: (state, action: PayloadAction<IAsset>) => {
      if (action.payload.bAccountID) {
        state.customerAssets = [action.payload, ...state.customerAssets];
      } else {
        state.designAssets = [action.payload, ...state.designAssets];
      }
    },
    resetAssetLibrary: () => {
      return initialState;
    },
  },
});

export const { loadLibrary, insertNewAsset, resetAssetLibrary } =
  assetLibrary.actions;

export const selectAssetLibrary = (state: DesignerState) => state.assetLibrary;

export default assetLibrary.reducer;
