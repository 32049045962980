import { Canvas, Object as IObject } from "fabric/fabric-impl";
import RESOLUTION from "../../../constants/RESOLUTION";

/**
 * @typedef {object} IPositionObject Positioning information in inches
 * @property {string} x - string representation of the Left property
 * @property {string} y - string represnetation of the Top property
 * @property {number} xN - numeric representation of the Left property
 * @property {number} yN - numeric representation of the Top property
 */
export interface IPositionObject {
  x: string;
  y: string;
  xN: number;
  yN: number;
}

/**
 * @typedef {object} ISizeObject Size information in inches
 * @property {string} width - string representation of the Width property
 * @property {string} height - string represnetation of the Height property
 * @property {number} widthN - numeric representation of the Width property
 * @property {number} heightN - numeric representation of the Height property
 */
export interface ISizeObject {
  width: string;
  height: string;
  widthN: number;
  heightN: number;
}

/**
 * @typedef {object} IConvertedSize Size and scale information in pixels
 * @property {number} width
 * @property {number} height
 * @property {number} scaleX
 * @property {number} scaleY
 */
export interface ISizeAndScale {
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
}

/**
 * Get the background object from the canvas.
 * @param canvas The canvas
 * @returns {IObject | undefined} The background object or undefined
 */
export function getBackground(canvas: Canvas) {
  const background = canvas._objects.find((x) => x.name === "background");
  return background;
}

/**
 * This function will get the position of an object in inches. It can optionally get the relative position to another objects such as the Background or similar.
 * @param {IObject} dimObj The object we want the position for
 * @param {IObject=} boundObj OPTIONAL - The object we want to compare the position to for relative positioning (e.g. the Background object or another item on the canvas)
 * @returns {IPositionObject} Position Object
 */
export function getPositionInInches(
  dimObj: IObject,
  boundObj?: IObject
): IPositionObject {
  // Just return 0,0 if the correct properties aren't present on any of the objects.

  const x = dimObj.left ?? 0;
  const y = dimObj.top ?? 0;
  let boundX = 0;
  let boundY = 0;
  if (boundObj && boundObj.left && boundObj.top) {
    boundX = boundObj.left;
    boundY = boundObj.top;
  }
  const xN = (x - boundX) / RESOLUTION;
  const yN = (y - boundY) / RESOLUTION;

  return { x: xN.toFixed(3), xN, y: yN.toFixed(3), yN };
}

/**
 * Get the position in pixels from inches.
 * @param {{x: string, y:string}} position The coordinates in inches
 * @param {IObject=} boundObj OPTIONAL - The object we want to compare the position to for relative positioning (e.g. the Background object or another item on the canvas)
 * @return {{x: number, y: number} | undefined} Position x and y
 */
export function convertPositionToPixels(
  position: { x: string; y: string },
  boundObj?: IObject
): { x: number; y: number } | undefined {
  const x = parseFloat(position.x);
  const y = parseFloat(position.y);
  if (Number.isNaN(x) || Number.isNaN(y)) return undefined;
  let boundX = 0;
  let boundY = 0;
  if (boundObj && boundObj.left && boundObj.top) {
    boundX = boundObj.left;
    boundY = boundObj.top;
  }
  return {
    x: boundX + x * RESOLUTION,
    y: boundY + y * RESOLUTION,
  };
}

/**
 * Gets the size information for the object relative to scale.
 * @param {IObject} obj The object we want the sizing information for
 * @param {IObject} parent OPTIONAL - Object that would supply scaleX and scaleY if necessary (e.g. Grouped objects might need the Group passed to get the correct scaled width/height)
 * @returns
 */
export function getSizeInInches(obj: IObject, parent?: IObject): ISizeObject {
  const width = parent
    ? (obj.width ?? 0) * (parent.scaleX ?? 1)
    : obj.getScaledWidth();
  const height = parent
    ? (obj.height ?? 0) * (parent.scaleY ?? 1)
    : obj.getScaledHeight();
  const widthN = width / RESOLUTION;
  const heightN = height / RESOLUTION;
  return {
    width: widthN.toFixed(3),
    height: heightN.toFixed(3),
    widthN,
    heightN,
  };
}

/**
 * Converts the size from inches into pixels. Return object includes a converted scaleX/scaleY for objects that are sized with scale instead of width/height.
 * @param {{width: string, height: string}} size The width and height in inches
 * @param {{scaleX: number, scaleY: number}} scale Scale information
 * @returns {ISizeAndScale} Includes both width/height and the new scale if prefered to width/height.
 */
export function convertSizeToPixels(
  size: { width: string; height: string },
  scale: { scaleX: number; scaleY: number }
): ISizeAndScale | undefined {
  const width = parseFloat(size.width);
  const height = parseFloat(size.height);
  if (Number.isNaN(width) || Number.isNaN(height)) return undefined;
  return {
    width: (width * RESOLUTION) / scale.scaleX,
    height: (height * RESOLUTION) / scale.scaleY,
    scaleX: (width * RESOLUTION) / scale.scaleX,
    scaleY: (height * RESOLUTION) / scale.scaleY,
  };
}

/**
 * Takes the original object sizing information and produces the uniform width and scale. This should be performed before conversion
 * @param size
 * @param scale
 * @returns {ISizeAndScale}
 */
export function getSizeUniform(
  objSize: { width: number; height: number },
  objScale: { scaleX: number; scaleY: number },
  width?: number,
  height?: number
): ISizeAndScale {
  if (width) {
    const ratio = width / objSize.width;
    const height = objSize.height * ratio;
    const scale = {
      scaleX: ratio / objScale.scaleX,
      scaleY: ratio / objScale.scaleY,
    };
    return { width, height, ...scale };
  }
  if (height) {
    const ratio = height / objSize.height;
    const width = objSize.width * ratio;
    const scale = {
      scaleX: ratio / objScale.scaleX,
      scaleY: ratio / objScale.scaleY,
    };
    return { width, height, ...scale };
  }
  return {
    width: 0,
    height: 0,
    scaleX: 1,
    scaleY: 1,
  };
}
