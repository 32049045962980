import { Dispatch, useEffect, useState } from "react";
import nFetchClient from "../helpers/nFetchClient";

interface DataCallback<T> {
  (data: T): T;
}

const useLoadData = <T,>(
  path: string,
  defaultValue: T,
  onSuccess?: DataCallback<T>,
  onError?: (error: unknown) => void
): {
  data: T;
  isLoading: boolean;
  fetchData: (toggleLoad?: boolean) => void;
  setData: Dispatch<T>;
  error: boolean;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<T>(defaultValue);
  const [error, setError] = useState(false);

  async function fetchData(toggleLoad = false) {
    try {
      if (toggleLoad) setIsLoading(true);
      const res = await nFetchClient<T, undefined>(path, "get");
      if (res) {
        if (!onSuccess) setData(res);
        if (onSuccess) setData(onSuccess(res));
        setIsLoading(false);
      }
    } catch (Ex: unknown) {
      setError(true);
      if (onError) {
        onError(Ex);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  return {
    data,
    isLoading,
    fetchData,
    setData,
    error,
  };
};

export default useLoadData;
