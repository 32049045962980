import { Textbox } from "fabric";
import styles from "./propertiesStyles";
import tw, { css } from "twin.macro";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import { useEffect, useState } from "react";

interface ITextPropertiesProps {
  textObject: Textbox;
}

const TextProperties = ({ textObject }: ITextPropertiesProps) => {
  const [textProperties, setTextProperties] = useState(getTextProperties());

  function getTextProperties() {
    return {
      lineHeight: textObject.lineHeight ?? 0,
      charSpacing: textObject.charSpacing ?? 0,
    };
  }

  function handleChange(property: string, value: number) {
    setTextProperties({ ...textProperties, [property]: value });
    if (property === "charSpacing") {
      value = value;
    }
    textObject.set(property as keyof Textbox, value);
    textObject.canvas?.renderAll();
  }

  useEffect(() => {
    setTextProperties(getTextProperties());
  }, [textObject]);

  return (
    <div css={styles.section}>
      <div css={styles.propertiesGroup}>
        <div css={styles.property}>
          <div>
            <span css={styles.label}>Line Height</span>
          </div>
          <IncrementInput
            property={"lineHeight"}
            value={textProperties.lineHeight}
            onChange={handleChange}
            amount={0.16}
          />
        </div>
        <div css={styles.property}>
          <div>
            <span css={styles.label}>Character Spacing</span>
          </div>
          <IncrementInput
            property={"charSpacing"}
            value={textProperties.charSpacing}
            onChange={handleChange}
            amount={10}
          />
        </div>
      </div>
    </div>
  );
};

export default TextProperties;
