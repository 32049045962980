import { IEvent, Object as IObject, Text, Transform } from "fabric/fabric-impl";
import { IBulletedList, IListItem, Textbox, fabric } from "fabric";
import { createBleedClipPath } from "./createBackgroundClipPath";
import { getBulletTop, reAlignListAndItems } from "./createBulletedList";

export function resetActionHandlers(object: IObject) {}

export default function bulletResizer(list: IBulletedList) {
  list.controls = {
    ml: new fabric.Control({
      ...list.controls.ml,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeWidth),
    }),
    mr: new fabric.Control({
      ...list.controls.mr,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeWidth),
    }),
    mtr: new fabric.Control({
      ...list.controls.mtr,
    }),
  };
}

function changeWidth(
  eventData: MouseEvent,
  transform: Transform,
  x: number,
  y: number
) {
  const target = transform.target as IBulletedList;
  if (!target) return false;
  const localPoint = getLocalPoint(
    transform,
    transform.originX,
    transform.originY,
    x,
    y
  );
  const strokePadding =
    (target.strokeWidth || 0) /
    (target.strokeUniform && target.scaleX ? target.scaleX : 1);
  const multiplier = isTransformCentered(transform) ? 2 : 1;
  const oldWidth =
    // @ts-ignore
    target.width;
  const newWidth =
    Math.abs((localPoint.x * multiplier) / (target.scaleX ?? 1)) -
    strokePadding;
  // @ts-ignore

  target.set("width", Math.max(newWidth, 0));
  const listItems = target._objects as IListItem[];

  reAlignListAndItems(target);
  return oldWidth !== newWidth;
}

function getLocalPoint(
  transform: Transform,
  originX: string,
  originY: string,
  x: number,
  y: number
) {
  const target = transform.target;
  if (!target || !target.canvas) return { x: 0, y: 0 };
  const control = target.controls[transform.corner];
  const zoom = target.canvas.getZoom();
  const padding = (target.padding ?? 0) / zoom;
  const localPoint = target.toLocalPoint(
    new fabric.Point(x, y),
    originX,
    originY
  );
  if (localPoint.x >= padding) {
    localPoint.x -= padding;
  }
  if (localPoint.x <= -padding) {
    localPoint.x += padding;
  }
  if (localPoint.y >= padding) {
    localPoint.y -= padding;
  }
  if (localPoint.y <= padding) {
    localPoint.y += padding;
  }
  localPoint.x -= control.offsetX;
  localPoint.y -= control.offsetY;
  return localPoint;
}

function isTransformCentered(transform: Transform) {
  // @ts-ignore
  return transform.originX === "center" && transform.originY === "center";
}

function wrapWithFixedAnchor(
  actionHandler: (
    eventData: MouseEvent,
    transform: Transform,
    x: number,
    y: number
  ) => boolean
) {
  return function (
    eventData: MouseEvent,
    transform: Transform,
    x: number,
    y: number
  ) {
    const target = transform.target;

    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(
      centerPoint,
      transform.originX,
      transform.originY
    );
    const actionPerformed = actionHandler(eventData, transform, x, y);
    target.setPositionByOrigin(
      constraint,
      transform.originX,
      transform.originY
    );

    return actionPerformed;
  };
}
