import { Object as IObject } from "fabric/fabric-impl";
import useAlignment from "../../../hooks/useAlignment";
import tw, { css } from "twin.macro";
import { fabric } from "fabric";
import RoundedInputGroup from "../shared/RoundedInputGroup";
import ToolButton from "../shared/ToolButton";
import propertiesStyles from "./propertiesStyles";
import {
  AlignBottomIcon,
  AlignHCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignTopIcon,
  AlignVCenterIcon,
  SkewXIcon,
  SkewYIcon,
} from "../shared/SvgComponents";
interface IAlignmentProps {
  selectedObject?: IObject;
}

const styles = {
  container: [tw`flex items-center`],
  groupHeader: [tw`text-sm`],
};

const Alignment = ({ selectedObject }: IAlignmentProps) => {
  const {
    alignHCenter,
    alignVCenter,
    alignLeft,
    alignRight,
    alignTop,
    alignBottom,
  } = useAlignment(selectedObject);

  function flipX() {
    if (!selectedObject || !selectedObject.scaleX) return;

    selectedObject.flipX = selectedObject.flipX ? false : true;
    if (selectedObject.type === "path") {
      //@ts-ignore
      fabric.Polyline.prototype._setPositionDimensions.call(selectedObject, {});
    }
    selectedObject.dirty = true;
    selectedObject.setCoords();
    selectedObject.canvas?.renderAll();
    selectedObject.canvas?.fire("object:modified");
    // selectedObject.left = left;
    // selectedObject.originX = originX;
    // selectedObject.originY = originY;
    // selectedObject.canvas?.renderAll();
  }

  function flipY() {
    if (!selectedObject || !selectedObject.scaleY) return;
    selectedObject.flipY = selectedObject.flipY ? false : true;
    selectedObject.dirty = true;
    if (selectedObject.type === "path") {
      //@ts-ignore
      fabric.Polyline.prototype._setPositionDimensions.call(selectedObject, {});
    }
    selectedObject.setCoords();
    selectedObject.canvas?.renderAll();
    selectedObject.canvas?.fire("object:modified");
  }

  return (
    <div>
      <div css={propertiesStyles.section}>
        <div css={[styles.container, tw`items-end`]}>
          <div tw="mr-4">
            <div css={propertiesStyles.sectionHeading}>Alignment</div>
            <RoundedInputGroup>
              <ToolButton
                position="left"
                onClick={alignLeft}
                disabled={selectedObject === undefined}
              >
                <AlignLeftIcon size={4} />
              </ToolButton>
              <ToolButton
                position="center"
                onClick={alignHCenter}
                disabled={selectedObject === undefined}
              >
                <AlignHCenterIcon size={4} />
              </ToolButton>
              <ToolButton
                position="right"
                onClick={alignRight}
                disabled={selectedObject === undefined}
              >
                <AlignRightIcon size={4} />
              </ToolButton>
            </RoundedInputGroup>
          </div>
          <div tw="mr-4">
            <RoundedInputGroup>
              <ToolButton
                position="left"
                onClick={alignTop}
                disabled={selectedObject === undefined}
              >
                <AlignTopIcon size={4} />
              </ToolButton>
              <ToolButton
                position="center"
                onClick={alignVCenter}
                disabled={selectedObject === undefined}
              >
                <AlignVCenterIcon size={4} />
              </ToolButton>
              <ToolButton
                position="right"
                onClick={alignBottom}
                disabled={selectedObject === undefined}
              >
                <AlignBottomIcon size={4} />
              </ToolButton>
            </RoundedInputGroup>
          </div>
          {!selectedObject?.name?.includes("qrcode") && (
            <div>
              <div css={propertiesStyles.sectionHeading}>Flip</div>
              <RoundedInputGroup>
                <ToolButton position="left" onClick={flipX}>
                  <SkewXIcon />
                </ToolButton>
                <ToolButton position="right" onClick={flipY}>
                  <SkewYIcon />
                </ToolButton>
              </RoundedInputGroup>
            </div>
          )}
        </div>
      </div>
      <div css={propertiesStyles.section}></div>
    </div>
  );
};

export default Alignment;
