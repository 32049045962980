import tw, { css } from "twin.macro";

interface ISidebarContainerProps {
  isOpen: boolean;
  position: "left" | "right";
  children?: React.ReactFragment;
}

const styles = {
  sidebarContainer: (position: "left" | "right") => [
    position === "right" &&
      tw`absolute top-11 right-0 z-50 bg-white border-0 border-solid border-border border-l box-border`,
    position === "left" &&
      tw`absolute top-0 left-0 z-40 bg-white border-0 border-solid border-border border-r box-border`,
    css`
      height: 100%;
      width: 320px;
      max-height: 100vh;
      z-index: 100;
    `,
  ],
};

const SidebarContainer = ({
  isOpen,
  position,
  children,
}: ISidebarContainerProps) => {
  if (!isOpen) return null;
  return <div css={styles.sidebarContainer(position)}>{children}</div>;
};

export default SidebarContainer;
