import React, {
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import tw, { css } from "twin.macro";
import { HexColorPicker } from "react-colorful";
import { ReactComponent as SlashIcon } from "../../../../../assets/images/designer-svg/SlashIcon.svg";
import useDebounce from "../../../../../hooks/useDebounce";
import useClickOutside from "../../../../../hooks/useClickOutside";

interface IColorPickerProps {
  small: boolean;
  color?: string;
  onChange: (color?: string) => void;
  onClose?: (color?: string) => void;
}

const styles = {
  pickerButton: (isSmall: boolean, color?: string) => [
    tw`rounded z-20 cursor-pointer`,
    color !== undefined &&
      css`
        background: ${color};
      `,
    color === undefined &&
      tw`border border-solid border-border bg-white overflow-hidden`,
    isSmall && tw`w-4 h-4`,
    !isSmall && tw`w-5 h-5`,
  ],
  noColorSvg: (isSmall: boolean) => [
    tw`fill-[red]`,
    isSmall && tw`w-4 h-4`,
    !isSmall && tw`w-5 h-5`,
  ],
  colorPickerContainer: [
    tw`z-50`,
    css`
      .react-colorful {
        z-index: 500;
      }
    `,
  ],
};

const ColorPicker = ({
  small,
  color,
  onChange,
  onClose,
}: IColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(color);
  const shouldUpdate = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const close = useCallback(() => {
    setIsOpen(false);

    if (onClose) onClose(value);
  }, [value]);
  useClickOutside(ref, close);
  useDebounce(
    () => {
      if (shouldUpdate.current) {
        onChange(value);
        shouldUpdate.current = false;
      }
    },
    200,
    [value]
  );

  function handleChange(color: string) {
    shouldUpdate.current = true;
    setValue(color);
  }

  useEffect(() => {
    setValue(color);
  }, [color]);

  return (
    <div>
      <div
        css={styles.pickerButton(small, color)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {color === undefined && <SlashIcon css={styles.noColorSvg(small)} />}
        {isOpen && (
          <div
            css={styles.colorPickerContainer}
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={ref}
          >
            <HexColorPicker
              color={color}
              onChange={(color) => handleChange(color)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
