import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Paper,
} from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import LoadingButton from "../../../../shared/LoadingButton";
import { IPaymentMethod } from "../../models/AccountModel";

interface CreditCardDataTableProps {
  cards: IPaymentMethod[];
  onDefaultChange: (card: IPaymentMethod) => void;
  isSubmitting: boolean;
  hasError: boolean;
  isSuccess: boolean;
}

const CreditCardDataTable: FC<CreditCardDataTableProps> = ({
  cards,
  onDefaultChange,
  isSubmitting,
  hasError,
  isSuccess,
}) => {
  /**
   * State Objects and Refs
   */

  const [primaryPaymentMethodChange, setPrimaryPaymentMethodChange] =
    useState<IPaymentMethod | null>(null);

  const hasPrimary = cards.findIndex((x) => x.isPrimary);

  const columns: TableColumn<IPaymentMethod>[] = [
    {
      name: "Card number",
      cell: (row) =>
        `${row.cardType === "American Express" ? "Amex" : "Card"} ending in ${
          row.lastFour
        }`,
    },
    {
      name: "Expires",
      selector: (row) => row.expiration,
      sortFunction: (a, b) =>
        moment(a.expiration, "MM/YYYY").valueOf() -
        moment(b.expiration, "MM/YYYY").valueOf(),
      sortable: true,
      format: (row) =>
        row.expired ? (
          <Typography sx={{ color: "red", fontSize: "13px" }}>
            Expired {row.expiration}
          </Typography>
        ) : (
          row.expiration
        ),
    },
    {
      name: "Primary",
      sortable: true,
      selector: (row) => row.isPrimary,
      format: (row) => {
        if (hasPrimary !== -1 && !row.isPrimary && !row.expired) {
          return (
            <Box sx={{ textAlign: "center" }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setPrimaryPaymentMethodChange(row)}
              >
                Set As Primary
              </Button>
            </Box>
          );
        }
        if (row.isPrimary)
          return (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  pl: 2,
                  pr: 2,
                  color: "secondary.main",
                  fontSize: "14px",
                }}
              >
                Primary Card
              </Typography>
            </Box>
          );
        return null;
      },
    },
  ];

  /**
   * Component Methods
   */
  function handleConfirm() {
    if (primaryPaymentMethodChange !== null)
      onDefaultChange(primaryPaymentMethodChange);
  }

  function handleCloseModal() {
    setPrimaryPaymentMethodChange(null);
  }

  /**
   * Component Effects
   */

  useEffect(handleCloseModal, [isSuccess]);

  /**
   * Render
   */

  return (
    <Box>
      <DataTable
        data={cards}
        columns={columns}
        fixedHeader={true}
        fixedHeaderScrollHeight="250px"
      />

      <Dialog maxWidth="sm" open={primaryPaymentMethodChange !== null}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing your primary payment method affects any automatic billing
            and any API or Zapier orders
          </DialogContentText>
          {hasError && (
            <Typography sx={{ color: "red" }}>
              There was an error updating your default payment method. Please
              try again later.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isSubmitting}
            success={isSuccess}
            onClick={handleConfirm}
          >
            Confirm
          </LoadingButton>
          <Button color="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreditCardDataTable;
