import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import logger from "../../../../helpers/logger";
import DesignCreationModel, {
  DesignCreationField,
  DesignSize,
} from "../../models/DesignCreationModel";
import DesignForm from "../../components/DesignForm";
import { fetchClient } from "../../../../helpers/fetchClient";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import BaseErrorResponse from "../../../../data/models/BaseErrorResponse";

import { getAsync, postAsync } from "../../../../helpers/asyncFetch";
import IDesignCategory from "../../../Designs/models/IDesignCategory";
import Design from "../../../Designs/models/Design";

interface URLParams {
  designId: string;
  customerId: string;
}

interface SubmitResponse {
  designID: number;
}

const AdminDesign: React.FC = () => {
  /**
   * State Objects and Refs
   */
  const params = useParams<URLParams>();
  const history = useHistory();

  const [designForm, setDesignForm] = useState<DesignCreationModel>({
    name: "",
    size: "",
    documentID: "",
    nimbleDesignID: "",
    designFields: [],
  });
  const [designFields, setDesignFields] = useState<DesignCreationField[]>([]);
  const [designSizes, setDesignSizes] = useState<DesignSize[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>("");

  const isNew = params.designId === "new" ? true : false;
  const isTemplate = params.customerId === "0" ? true : false;
  const [designType, setDesignType] = useState<"" | "uproduce" | "designer">(
    isNew ? "" : "uproduce"
  );

  const [newDesignerDesign, setNewDesignerDesign] = useState({
    size: "46",
    name: "",
    isTemplate: false,
    bAccountID: params.customerId,
  });

  /**
   * Component Methods
   */

  function getDesignFormData() {
    fetchClient.get({
      path: "/admin/integrations/designfields",
      onSuccess: (data: DesignCreationField[]) => {
        setDesignFields(data);
      },
    });
    fetchClient.get({
      path: "/admin/integrations/design-sizes",
      onSuccess: (data: DesignSize[]) => {
        setDesignSizes(data);
      },
    });
    if (!isNew) {
      fetchClient.get({
        path: `/admin/integrations/${params.customerId}/designs/${params.designId}`,
        onSuccess: getDesignFormDataCallback,
      });
    }
  }

  function getDesignFormDataCallback(data: DesignCreationModel) {
    setDesignForm({
      ...data,
      // @ts-ignore
      name: data.friendlyName,
    });
  }

  function handleCategoryAdd(categoryID: number) {
    setDesignForm({ ...designForm, categoryID: categoryID });
  }

  function handleSubcategoryAdd(subCategoryID: number) {
    setDesignForm({ ...designForm, subcategoryID: subCategoryID });
  }

  function handleGeneralChange(
    e:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.name) {
      if (e.target.name === "categoryID" || e.target.name === "subcategoryID") {
        const value = parseInt(e.target.value);
        if (value) {
          setDesignForm({ ...designForm, [e.target.name]: value });
        } else setDesignForm({ ...designForm, [e.target.value]: undefined });
      } else
        setDesignForm({
          ...designForm,
          [e.target.name]: e.target.value,
        });
    }
  }

  function handleDesignerChange(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    setNewDesignerDesign({
      ...newDesignerDesign,
      [e.target.name]: e.target.value,
    });
  }

  function handleFieldChange(
    e:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    let val = e.target.value;
    if (
      typeof val === "string" &&
      e.target.name &&
      e.target.name === "fieldKey"
    ) {
      val = val.toLowerCase().replace(/ /g, "");
    }
    if (e.target.name) {
      const designFields = designForm.designFields;
      designFields[index] = {
        ...designFields[index],
        [e.target.name]: val,
      };
      setDesignForm({
        ...designForm,
        designFields: [...designFields],
      });
    }
  }

  function handleAutoComplete(value: string | null, index: number) {
    const designFields = designForm.designFields;
    if (value !== null) {
      designFields[index] = {
        ...designFields[index],
        fieldKey: value.toLowerCase(),
      };
    }
    setDesignForm({
      ...designForm,
      designFields,
    });
  }

  function handleMandatoryClick(index: number) {
    const designFields = designForm.designFields;
    designFields[index].mandatory = !designFields[index].mandatory;
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleFieldOptionChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldIndex: number,
    optionIndex: number
  ) {
    const designFields = designForm.designFields;
    const designFieldOptions = designFields[fieldIndex].options;
    designFieldOptions[optionIndex] = {
      ...designFieldOptions[optionIndex],
      [e.target.name]: e.target.value,
    };
    designFields[fieldIndex].options = [...designFieldOptions];
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleAddField() {
    logger.log("clicked");
    const designFields = designForm.designFields;
    designFields.push({
      fieldKey: "",
      fieldLabel: "",
      fieldType: "",
      mandatory: false,
      options: [],
    });
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleAddFieldOption(index: number) {
    const designFields = designForm.designFields;
    designFields[index].options.push({
      optionKey: designFields[index].fieldKey,
      optionValue: "",
      optionLabel: "",
    });
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleRemoveField(index: number) {
    const designFields = designForm.designFields;
    designFields.splice(index, 1);
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleRemoveFieldOption(fieldIndex: number, optionIndex: number) {
    const designFields = designForm.designFields;
    designFields[fieldIndex].options.splice(optionIndex, 1);
    setDesignForm({
      ...designForm,
      designFields: [...designFields],
    });
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionError("");
    if (isNew)
      return fetchClient.post({
        path: `/admin/integrations/${params.customerId}/designs`,
        data: { ...designForm, documentID: parseInt(designForm.documentID) },
        onSuccess: handleSubmitCallback,
      });
    return fetchClient.put({
      path: `/admin/integrations/${params.customerId}/designs/${params.designId}`,
      data: designForm,
      onSuccess: handleSubmitCallback,
      onError: handleSubmitError,
    });
  }

  function handleSubmitCallback(data: SubmitResponse) {
    history.push(
      `/admin/integrations/${params.customerId}/designs/${data.designID}/proof`
    );
  }

  function handleSubmitError(error: BaseErrorResponse) {
    setIsSubmitting(false);
    setSubmissionError(error.message);
  }
  async function handleNewDesignerDesignClick() {
    setIsSubmitting(true);
    const design = await postAsync<{ design: Design }>(
      `/designer`,
      newDesignerDesign
    );
    if (design && design.design) {
      history.push(`/admin/designer/${design.design.designID}`);
    }
  }

  /**
   * Component Effects
   */
  useEffect(getDesignFormData, [isNew, params.customerId, params.designId]);

  /**
   * Render
   */

  return (
    <div id="editDesignPage" className="page">
      <div className=" pb-4 pt-4">
        <div className="breadcrumbs">
          <Link to={`/admin/integrations/${params.customerId}/designs`}>
            <i className="fas fa-chevron-left"></i> Back To Designs
          </Link>
        </div>
        <div className="page__header">
          <h3 className="page__header__heading">
            {isNew ? "New Design" : `Design ID: ${params.designId}`}
          </h3>
        </div>
        <div className="page__content">
          {isNew && params.customerId && (
            <Box sx={{ mb: 3, width: "25%" }}>
              <Typography variant="h6" gutterBottom>
                Select A Design Type
              </Typography>
              <Select
                displayEmpty
                value={designType}
                onChange={(e) => setDesignType(e.target.value as "")}
                fullWidth
              >
                <MenuItem value="">Design Type</MenuItem>
                <MenuItem value="uproduce">uProduce Design</MenuItem>
                <MenuItem value="designer">Designer Design</MenuItem>
              </Select>
            </Box>
          )}
          {(!isNew || !params.customerId || designType === "uproduce") && (
            <div className="section" id="designForm">
              <DesignForm
                isTemplate={isTemplate}
                disabled={isSubmitting}
                formData={designForm}
                onSubmit={handleSubmit}
                onGeneralChange={handleGeneralChange}
                onFieldChange={handleFieldChange}
                onAutoCompleteFieldChange={handleAutoComplete}
                onFieldOptionChange={handleFieldOptionChange}
                onNewFieldClick={handleAddField}
                onNewFieldOptionClick={handleAddFieldOption}
                onRemoveFieldClick={handleRemoveField}
                onRemoveFieldOptionClick={handleRemoveFieldOption}
                onMandatoryClick={handleMandatoryClick}
                designFields={designFields}
                designSizes={designSizes}
                onNewCategory={handleCategoryAdd}
                onNewSubcategory={handleSubcategoryAdd}
              />
              {submissionError !== "" && (
                <div className="error pt-2">{submissionError}</div>
              )}
            </div>
          )}
          {isNew && designType === "designer" && (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    name="name"
                    label="Name"
                    value={newDesignerDesign.name}
                    onChange={handleDesignerChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Size</InputLabel>
                    <Select
                      label="Size"
                      name="size"
                      value={newDesignerDesign.size}
                      onChange={handleDesignerChange}
                    >
                      <MenuItem value="46">4.25&quot; x 6&quot;</MenuItem>
                      <MenuItem value="68">6&quot; x 8.5&quot;</MenuItem>
                      <MenuItem value="611">6&quot; x 11&quot;</MenuItem>
                      <MenuItem value="811">Letter</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleNewDesignerDesignClick}
                    disabled={
                      !newDesignerDesign.name ||
                      !newDesignerDesign.name.trim() ||
                      isSubmitting
                    }
                  >
                    Create Design
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDesign;
