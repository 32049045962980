import { Box, Typography } from "@mui/material";
import {
  AddressModel,
  ContactInformationModel,
} from "../../models/AccountModel";

interface NCOATextProps {
  contactInfo: ContactInformationModel;
  address: AddressModel;
}

const NCOAText: React.FC<NCOATextProps> = ({ contactInfo, address }) => {
  return (
    <Box>
      <Typography gutterBottom>
        I do hereby acknowledge that I have received and reviewed the NCOALink
        Information Package (below) supplied to me by AccuZIP, Inc., an NCOALink
        Service Provider. I also understand that the sole purpose of the
        NCOALink service is to provide a mailing list correction service for
        lists that will be used for preparation of mailings. Furthermore, I
        understand that NCOALink may not be used to create or maintain new
        movers&apos; lists.
      </Typography>
      <Typography variant="h5" gutterBottom>
        Mail List Owner
      </Typography>
      <Typography sx={{ mb: 3 }} variant="body1" component="div">
        <Box>{contactInfo.name}</Box>
        <Box>{contactInfo.companyName}</Box>
        <Box>{address.streetAddress}</Box>
        <Box>
          {address.city}, {address.state} {address.zip}
        </Box>
        <Box>{contactInfo.phone}</Box>
        <Box>{contactInfo.accountEmail}</Box>
      </Typography>
      <Typography variant="h5" gutterBottom>
        Broker
      </Typography>
      <Typography sx={{ mb: 3 }} variant="body1" component="div">
        <Box>CHANDRA FAZIO </Box>
        <Box>POSTCARDMANIA</Box>
        <Box>2145 SUNNYDALE BLVD BLDG 102</Box>
        <Box>CLEARWATER FL 33765-1294</Box>
        <Box>727-441-4704</Box>
        <Box>meghanh@postcardmania.com</Box>
        <Box>WEBSITE: http://www.postcardmania.com</Box>
        <Box>NAICS: 323122</Box>
      </Typography>
      <Typography variant="h5" gutterBottom>
        NCOALink Licensee
      </Typography>
      <Typography sx={{ mb: 3 }} variant="body1" component="div">
        <Box>AccuZIP Inc.</Box>
        <Box>3216 El Camino Real</Box>
        <Box>Atascadero CA 93422-2500</Box>
      </Typography>
      <Typography variant="h6" gutterBottom>
        NCOALink Service Provider Required Text Document
      </Typography>
      <Typography gutterBottom>
        AccuZIP Inc. is a non-exclusive Licensee of the USPS&reg; (United States
        Postal Service&reg;) to provide Limited Service NCOALink&reg;
        processing.
      </Typography>

      <Typography gutterBottom>
        It is important to note that not all Service Providers can offer the
        same level of service. Data quantity differs based on license level.
        Full Service Providers receive the full 48 month data set while Limited
        Service Providers receive an 18 month data set. All data fulfillments to
        Service Providers are provided weekly under direct license from the
        USPS.
      </Typography>

      <Typography gutterBottom>
        The full NCOALink file is a consolidated file of move information that
        on average contains approximately 160 million permanent
        changes-of-address (COAs) filed with the United States Postal Service
        (USPS). These COAs are retained on the file for a four-year period from
        the move-effective date and the file is updated weekly.
      </Typography>

      <Typography gutterBottom>
        Before being added to the NCOALink file, the Old address supplied by the
        Postal customer must be ZIP + 4&reg; coded. The New addresses must be
        ZIP + 4 coded and validated using the USPS&apos; proprietary database of
        actual delivery points. (NOTE: The delivery point database does not
        include NAMES or COA information.) Each delivery point confirmed New
        address is included on the NCOALink file. If unable to validate the New
        address, the NCOALink process will indicate that a move exists but will
        not provide the undeliverable New address.
      </Typography>

      <Typography gutterBottom>
        New address information is provided only when a match to the input name
        and address is attained. The typical profile of the New address
        information contained on the NCOALink file is as follows:
      </Typography>

      <Typography>
        89.68 % Forwardable moves containing delivery point confirmed New
        addresses - New address provided
      </Typography>
      <Typography>
        1.19% Moves containing unconfirmed New addresses - New address not
        provided
      </Typography>
      <Typography>
        7.44% Moved, left no address 1.63% PO Box Closed 0.06% Foreign moves
      </Typography>

      <Typography gutterBottom>
        When possible, postal customers who move multiple times within the
        NCOALink time period are &quot;linked&quot; or &quot;chained&quot; to
        ensure that the latest address is furnished when an NCOALink match is
        attained. This is not always possible if subsequent COAs are not filed
        in exactly the same manner as a COA filed previously (e.g., name
        spelling differences or conflicting secondary information).
      </Typography>

      <Typography gutterBottom>
        The provision of change of address information is controlled by strict
        name and address matching logic. NCOALink processing will only provide
        new address information when queried with a specific algorithm of the
        name and input address from a mailers address list which matches the
        information on the NCOALink Product. Data contained in and information
        returned by NCOALink is determined by the name and move type (Business,
        Individual, or Family) indicated on a Postal customer&apos;s Change of
        Address form.
      </Typography>

      <Typography gutterBottom>
        The data contained within the NCOALink Product is comprised of
        approximately 40% family moves, 54% individual moves, and 6% business
        moves.
      </Typography>

      <Typography gutterBottom>
        All matches made to the NCOALink file require a ZIP + 4 coded, parsed
        input address.
      </Typography>

      <Typography gutterBottom>
        The five types of processing modes are Standard (S); Business and
        Individual (C); Individual (I); Business (B); and Residential &reg;.
      </Typography>

      <Typography>Standard Processing Mode (S)</Typography>
      <Typography>
        * Standard Processing Mode requires inquiries in the following order:
      </Typography>
      <Typography>* Business - Match on business name.</Typography>
      <Typography>
        * Individual - Match on first name, middle name, surname and title
        required. Gender is checked and nickname possibilities are considered.
      </Typography>
      <Typography>* Family - Match on surname only.</Typography>
      <Typography gutterBottom>
        * Under no circumstances shall there be a &quot;Family&quot; match only
        option.
      </Typography>

      <Typography>Business and Individual Processing Mode (C)</Typography>
      <Typography gutterBottom>
        * The NCOALink customer may choose to omit all &quot;Family&quot; match
        inquiries and allow only &quot;Individual&quot; and &quot;Business&quot;
        matches to be acceptable. This matching process is also known as C
        Processing Mode.
      </Typography>

      <Typography>Individual Processing Mode (I)</Typography>
      <Typography gutterBottom>
        * The NCOALink customer may also choose to omit &quot;Business&quot;
        match inquiries when processing individual names for mailing lists that
        contain no business addresses.
      </Typography>

      <Typography>Business Processing Mode (B)</Typography>
      <Typography gutterBottom>
        * The NCOALink customer may choose to process for only
        &quot;Business&quot; matches when processing a
        &quot;Business-to-Business&quot; mailing list which contains no
        residential (Individual or Family) addresses.
      </Typography>

      <Typography>Residential Processing Mode &reg;</Typography>
      <Typography gutterBottom>
        * The NCOALink customer may choose to omit &quot;Business&quot; match
        inquiries and allow only &quot;Individual&quot; and &quot;Family&quot;
        matches to be acceptable under Residential Processing Mode. This
        matching process is also known as R Processing Mode.
      </Typography>

      <Typography gutterBottom>
        The USPS has opted to remove soundex from the matching logic process.
        Consequently, the USPS has established a process called the &quot;Rules
        Table.&quot; This process will produce matches that otherwise would not
        be possible, i.e. JOHNY and JOHNNY, without the risks associated with
        soundex.
      </Typography>

      <Typography gutterBottom>
        All nickname possibilities are derived from a standard USPS nickname
        list. In considering alternate presentations of an input name, only
        reasonable derivatives of the original input name are acceptable. If an
        input name and address do not match to NCOALink and alternative queries
        are attempted, any variations which obtain NCOALink matches will be
        provided to the NCOALink customer for analysis.
      </Typography>

      <Typography gutterBottom>
        When a match or a near match of an input name and address to NCOALink is
        identified, a standard NCOALink return code is provided indicating the
        type of match made or reason that a match could not be made.
      </Typography>

      <Typography gutterBottom>
        The standard output of a USPS NCOALink process is:
      </Typography>

      <Typography>
        a) Each original unaltered input name and address as it was presented.
      </Typography>
      <Typography>
        b) The standardized input address appended with the correct ZIP + 4/DPC,
        other postal values and any other intelligence flags or footnotes that
        result from the CASS(tm) processing segment.
      </Typography>
      <Typography>
        c) For each mailing address for which there is a match to the NCOALink
        Product, a standardized new address with 11-digit Delivery Point Barcode
        (DPBC) and standard return codes.
      </Typography>
      <Typography>
        d) When a match is made, the following elements must be returned: the
        move effective date, the specific name and address utilized in the query
        that obtained the match, and the move type. The move type is determined
        by the Interface based on the specific name inquiry utilized to obtain
        the match.
      </Typography>
      <Typography>
        e) For each mailing address for which there is not a match to the
        NCOALink Product, the Interface shall return all elements as appropriate
        under items a and b as well as any standard return codes as may be
        appropriate.
      </Typography>
      <Typography>
        f) The urbanization name information, when applicable.
      </Typography>
      <Typography>
        g) The carrier route information for new (updated) addresses.
      </Typography>
      <Typography>
        h) DPV&reg; results for the input address, if requested.
      </Typography>
      <Typography>i) LACSLink&reg; results, if requested.</Typography>
      <Typography>j) SuiteLink&reg; results, if requested.</Typography>
      <Typography gutterBottom>
        k) Processing summary report containing information to identify the
        specific list and the statistics resulting from the NCOALink process
        performed on the list.
      </Typography>

      <Typography gutterBottom>
        The presentation of name order is established using a pre-process before
        querying the NCOALink database. However, there are no restrictions on
        using a process to interchange the name order to yield the best possible
        results using the NCOALink database. It is ultimately the responsibility
        of the Mailer Owner working with the Licensee to determine the name
        order presentation correctly.
      </Typography>

      <Typography gutterBottom>
        The presentation of name order is established using a pre-process before
        querying the NCOALink database. However, there are no restrictions on
        using a process to interchange the name order to yield the best possible
        results using the NCOALink database. It is ultimately the responsibility
        of the Mailer Owner working with the Licensee to determine the name
        order presentation correctly.
      </Typography>

      <Typography gutterBottom>
        NCOALink processing has the potential to reduce returned mail, yet the
        USPS does not make any guarantees, express or implied, on the reduction
        of such mail. Thus any costs associated with returned mail are the
        Licensees&apos; and/or their customers&apos; sole responsibility.
      </Typography>

      <Typography gutterBottom>
        An NCOALink customer with questions about the specific results returned
        from an NCOALink process must first contact the processor for
        explanation and resolution.
      </Typography>

      <Typography gutterBottom>
        Prior to the processing of NCOALink data, every customer must have
        completed and returned to their NCOALink Licensee the &quot;NCOALink
        PROCESSING ACKNOWLEDGEMENT FORM&quot; provided to them by their Licensee
        or Agent. It is inappropriate to misrepresent any of the information on
        the form. Punitive action will be taken by the USPS if the customer,
        agent or licensee is found to have knowingly supplied false information.
        Depending on the severity of the offense, actions may include litigious
        or even criminal charges being brought against the offender.
      </Typography>

      <Typography gutterBottom>
        The ANKLink&reg; option is available through Limited Service Provider
        Licensees to enable mailers to make informed choices regarding a
        specific customer contact. If the data indicates a move, the mailer may
        choose to suppress the record from their list or attempt to determine
        the actual new address by engaging the services of an NCOALink Full
        Service Provider (FSP) Licensee.
      </Typography>

      <Typography>
        Mailers choosing to engage the services of an FSP Licensee may submit
        only those ANKLink matches for which they need additional processing
        provided that:
      </Typography>
      <Typography>
        1) The mailer informs the FSP Licensee that the list is derived from a
        prior ANKLink process.
      </Typography>
      <Typography>
        2) The list submitted to the FSP for processing meets the mailing list
        requirement of at least 100 unique names and addresses.
      </Typography>
      <Typography>
        3) The final results are incorporated back into the original list.
      </Typography>
      <Typography gutterBottom>
        4) The records separated for processing are not used to create a
        derivative product.
      </Typography>

      <Typography>
        The following trademarks are owned by the United States Postal
        Service&reg;: ANKLink, CASS, NCOALink, DPV, LACSLink, SuiteLink, United
        States Postal Service, USPS and ZIP + 4.
      </Typography>
    </Box>
  );
};

export default NCOAText;
