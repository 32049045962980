import tw, { css } from "twin.macro";

export default {
  section: [tw`flex align-middle mr-4`],
  property: [tw`mr-2 flex align-middle mt-auto mb-auto`],
  propertyLabel: [tw`text-xs mr-1 mt-auto mb-auto`],
  button: [
    css`
      height: 28px;
    `,
  ],
  utilityButton: [
    css`
      height: 28px;
      background: white;
      border: none;
    `,
    tw`hover:bg-hover`,
  ],
  seperator: [
    tw`bg-hover h-7 mt-auto mb-auto mr-2`,
    css`
      width: 2px;
    `,
  ],
};
