import {
  Gradient,
  Object as IObject,
  Pattern,
  Shadow,
} from "fabric/fabric-impl";
import getCssFromGradient from "./getCssFromGradient";

export function getColorFromObject(
  selectedObject: IObject,
  property: "stroke" | "fill" | "shadow"
) {
  if (property === "stroke") {
    return {
      color: selectedObject.stroke ?? "000000",
      fillType: "solid",
    };
  }
  let c: string | Gradient | undefined | Pattern = undefined;

  if (property === "fill") {
    c = selectedObject.fill;
  }
  if (property === "shadow") {
    const shadow = selectedObject.shadow as Shadow | undefined;
    if (shadow) {
      c = shadow.color;
      return {
        color: c as string,
        fillType: "solid",
      };
    } else {
      c = "#000000";
    }
  }
  if (!c || (typeof c !== "string" && !("coords" in c))) {
    return {
      color: "000000",
      fillType: "solid",
    };
  } else {
    if (typeof c === "string") {
      return {
        color: c,
        fillType: "solid",
      };
    }

    return {
      color: getCssFromGradient(selectedObject, c as Gradient),
      fillType: "gradient",
    };
  }
}
