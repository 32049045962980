import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@mui/material";
import {
  DataGrid,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useState } from "react";
import IOrder from "../../../data/models/IOrder";
import LocalStorage from "../../../helpers/LocalStorage";

import usePaginatedOrders from "../../../hooks/dataFetchers/usePaginatedOrders";
import Img from "../../../shared/BasicHTML/Img";

import RecipientListTable from "../../Batch/components/RecipientListTable";

interface IPaginatedOrdersTableProps {
  designID?: number;
  isSandbox?: boolean;
}

const PaginatedOrdersTable = ({
  designID,
  isSandbox = false,
}: IPaginatedOrdersTableProps) => {
  // const [orders, isLoading] = useOrders();
  const session = LocalStorage.getSession();
  const enableRecipientPrivacy = session?.enableRecipientPrivacy;
  const [activeOrder, setActiveOrder] = useState<IOrder>();

  const [open, setOpen] = useState(false);
  const handleOpen = (order: IOrder) => {
    setActiveOrder(order);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const {
    isLoading,
    orders,
    page,
    itemsPerPage,
    handleChangeRowsPerPage,
    handleNewPage,
    sortModel,
    totalItems,
    totalPages,
    status,
    handleChangeStatus,
    filter,
    handleSort,
    handleFilter,
  } = usePaginatedOrders(designID, isSandbox);

  function getNumberFilter() {
    return getGridNumericOperators().filter(
      (x) =>
        x.value === "=" ||
        x.value === ">=" ||
        x.value === ">" ||
        x.value === "<=" ||
        x.value === "<"
    );
  }
  function getStringFilter() {
    return getGridStringOperators().filter((x) => x.value !== "isAnyOf");
  }
  const cols: GridColDef<IOrder>[] = [
    {
      field: "orderID",
      headerName: "Order ID",
      sortable: true,
      filterable: true,
      filterOperators: getNumberFilter(),
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "batchID",
      headerName: "Batch ID",
      filterable: true,
      sortable: true,
      filterOperators: getNumberFilter(),
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "orderDate",
      headerName: "Date",
      sortable: true,
      filterable: false,
      valueGetter: (params) =>
        moment.utc(params.row.orderDate).local().format("MM/DD/YYYY hh:mm a"),
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "extRefNbr",
      headerName: "External Reference",
      sortable: true,
      filterable: true,
      filterOperators: getStringFilter(),
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "designID",
      headerName: "Design",
      sortable: true,
      filterable: true,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "size",
      headerName: "Size",
      sortable: true,
      filterable: true,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "mailClass",
      headerName: "Mail Class",
      sortable: true,
      filterable: true,
      valueGetter: (params) =>
        params.row.mailClass === "FirstClass"
          ? "First Class"
          : params.row.mailClass,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "recipients",
      headerName: "Recipients",
      sortable: true,
      filterable: false,
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) => params.row.recipients.length,
      renderCell: (params) => (
        <React.Fragment>
          {enableRecipientPrivacy &&
            params.row.recipients &&
            params.row.recipients.length}
          {!enableRecipientPrivacy && params.row.recipients && (
            <Button
              size="small"
              color="primary"
              onClick={() => handleOpen(params.row)}
              sx={{ ml: 2 }}
            >
              {params.row.recipients.length}
            </Button>
          )}
        </React.Fragment>
      ),
      flex: 1,
    },
  ];

  if (session?.storeProofs) {
    cols.splice(0, 0, {
      field: "proofPDF",
      headerName: "Order Proof",
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <Box sx={{ width: "200px" }}>
          {params.row.proof ? (
            <Box sx={{ maxHeight: "113px", overflow: "hidden" }}>
              <Img src={`/${params.row.proof}`} style={{ maxWidth: "200px" }} />
            </Box>
          ) : null}
          <Box sx={{ fontSize: "14px", textAlign: "center" }}>
            {params.row.proofBack ? (
              <Box>
                <a
                  href={`/${params.row.proof}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Front Proof
                </a>{" "}
                |{" "}
                <a
                  href={`/${params.row.proofBack}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Back Proof
                </a>
              </Box>
            ) : params.row.proof ? (
              <Box>
                <a
                  href={`/${params.row.proof}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Proof
                </a>{" "}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ),
    });
  }

  const filterStyles = {
    color: (theme: Theme) => theme.palette.primary.main,
    cursor: "pointer",
    display: "inline-block",
    mr: 2,
    ".active": {
      textDecoration: "underline",
    },
    ":hover": {
      color: (theme: Theme) => theme.palette.primary.light,
    },
  };

  if (orders.find((x) => Boolean(x.proof))) {
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ mb: 2 }}>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("all")}
              className={status === "all" ? "active" : ""}
            >
              Show All
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("active")}
              className={status === "active" ? "active" : ""}
            >
              In Progress
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("pending")}
              className={status === "pending" ? "active" : ""}
            >
              Pending
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("processing")}
              className={status === "processing" ? "active" : ""}
            >
              Processing
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("processed")}
              className={status === "processed" ? "active" : ""}
            >
              Processed
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("mailing")}
              className={status === "mailing" ? "active" : ""}
            >
              Mailing
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("delivered")}
              className={status === "delivered" ? "active" : ""}
            >
              Delivered
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("canceled")}
              className={status === "canceled" ? "active" : ""}
            >
              Canceled
            </Box>
            <Box
              sx={filterStyles}
              onClick={() => handleChangeStatus("undeliverable")}
              className={status === "undeliverable" ? "active" : ""}
            >
              Undeliverable
            </Box>
          </Box>
          {session?.storeProofs ? (
            <DataGrid
              sortModel={sortModel}
              rowHeight={150}
              autoHeight
              rows={orders}
              rowCount={totalItems}
              loading={isLoading}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              filterMode="server"
              sortingMode="server"
              page={page}
              pageSize={itemsPerPage}
              paginationMode="server"
              onPageChange={handleNewPage}
              onPageSizeChange={handleChangeRowsPerPage}
              onSortModelChange={handleSort}
              // disableColumnFilter
              columns={cols}
              onFilterModelChange={handleFilter}
              getRowId={(order) => order.orderID}
            />
          ) : (
            <DataGrid
              sx={{ backgroundColor: "#fff" }}
              sortModel={sortModel}
              autoHeight
              rows={orders}
              rowCount={totalItems}
              loading={isLoading}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              filterMode="server"
              sortingMode="server"
              page={page}
              pageSize={itemsPerPage}
              paginationMode="server"
              onPageChange={handleNewPage}
              onPageSizeChange={handleChangeRowsPerPage}
              onSortModelChange={handleSort}
              // disableColumnFilter
              columns={cols}
              onFilterModelChange={handleFilter}
              getRowId={(order) => order.orderID}
            />
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Recipients</DialogTitle>
        <DialogContent>
          {activeOrder && <RecipientListTable data={activeOrder?.recipients} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <OrdersTable orders={orders} /> */}
    </Box>
  );
};

export default PaginatedOrdersTable;
