import React, { useContext, useEffect, useState } from "react";
import useLayers, { ILayerAndObject } from "../../../hooks/useLayers";
import tw, { css } from "twin.macro";

import Layer from "./Layer";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import {
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../../../state/contexts/PagesContext";

interface ILayersProps {
  layersArray: ILayerAndObject[];
  disabled?: boolean;
  parent?: ILayerAndObject;
}

const Layers = ({ layersArray, disabled = false, parent }: ILayersProps) => {
  const {
    moveLayerToDestination,
    selectLayers,
    selectedLayers,
    changeLayerName,
    selectedPage,
    toggleLock,
    toggleVisibility,
    toggleCollapse,
    changePage,
    layers,
  } = useLayers();

  function handleDrop(itemId: string, destinationId: string) {
    moveLayerToDestination(selectedPage, itemId, destinationId);
  }

  function handleLayerNameChange(key: string, name: string, index: string) {
    changeLayerName(selectedPage, key, name, index);
  }

  function getLayers(layersArray: ILayerAndObject[]) {
    return layersArray.reverse().map((layer) => (
      <div
        key={layer.id}
        onClick={(e) => !disabled && handleSelectLayers(e, layer.id)}
      >
        <Layer
          onChange={handleLayerNameChange}
          layer={layer}
          onDrop={handleDrop}
          isActive={Boolean(selectedLayers.find((x) => x.index === layer.id))}
          toggleHide={toggleVisibility}
          toggleLock={toggleLock}
          toggleCollapse={toggleCollapse}
          isChild
          disabled={disabled}
          parent={parent}
        />
      </div>
    ));
  }

  function handleSelectLayers(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    selectLayers(id);
  }

  return (
    <div tw={"ml-4 border-l-border border-l border-solid"}>
      {getLayers(layersArray)}
    </div>
  );
};

export default Layers;
