import { Canvas, Group, Object as IObject } from "fabric/fabric-impl";
import { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import {
  HistoryContext,
  HistoryDispatchContext,
} from "../state/contexts/HistoryContext";
import { SelectedPageContext } from "../state/contexts/SelectedPageContext";
import { updateLayers } from "../state/slices/layers";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import { IHistoryEntry } from "../features/Canvas/models/IHistory";
import DATALESS_PROPERTIES from "../constants/DATALESS_PROPERTIES";
import { fabric } from "fabric";
import { Tool } from "../state/models/ICanvasTool";
import { hideTextObjectControls } from "../features/Canvas/functions/loadSavedCanvasData";
import getCleanCanvasData from "../features/Canvas/functions/getCleanCanvasData";
import asyncLoadFromJson from "../features/Canvas/functions/asyncLoadFromJson";

interface IHistory {
  page: string;
  history: IHistoryEntry[];
  cursorPosition: number;
}

/**
 * @description Our hook for history. Add to any component that needs access to undo/redo and updating our history object.
 * @returns { undoDisabled, redoDisabled, undo, redo, updateHistory }
 */
const useHistory = () => {
  const canvas = useContext(CanvasContext);
  const selectedPage = useContext(SelectedPageContext);
  const layerState = useDesignerSelector((state) => state.layers);
  const maxHistoryPos = 50;
  const history = useContext(HistoryContext);
  const historyRef = useRef(history);
  const setHistory = useContext(HistoryDispatchContext);
  const selectedPageRef = useRef(selectedPage);
  const [pendingHistoryUpdate, setPendingHistoryUpdate] = useState<IObject[]>(
    []
  );
  const currentTool = useDesignerSelector(
    (state) => state.toolSettings.currentTool
  );
  const dispatch = useDesignerDispatch();

  // find the current page's history in our history state
  const currentHistory = history.find((x) => x.page === selectedPage);
  // check if we should disabled the undo/redo functions.
  const undoDisabled = !currentHistory || currentHistory.cursorPosition <= 0;
  const redoDisabled =
    !currentHistory ||
    currentHistory.cursorPosition >= currentHistory.history.length - 1;

  /**
   * @description When initializing a new page, we need to grab its original state.]
   * In order to sync up with the canvas which is not part of the React lifecycle, we
   * wrap it in a timeout to move it to the end of the callstack.
   */
  function initHistory(force?: boolean) {
    if (!canvas || !selectedPage) return;
    selectedPageRef.current = selectedPage;
    if (
      history.length === 0 ||
      !history.find((x) => x.page === selectedPage) ||
      force
    ) {
      setTimeout(() => {
        const layers = layerState.pageLayers.find(
          (x) => x.name === selectedPage
        );
        if (!layers) return;

        const newHistory: IHistory = {
          page: selectedPage,
          history: [
            {
              layers: layers.layers,
              canvas: getCleanCanvasData(canvas),
            },
          ],
          cursorPosition: 0,
        };
        setHistory([...history, newHistory]);
      }, 1);
    }
  }

  /**
   * updateHistory
   * @param {IObject[]} objects
   * @description A simple function that we return below which allows other parts of the app
   * to send history updates. The pendingHistory state allows us to track a change and wait for the
   * ui to re-render before we commit the update to our global history state.
   */
  function updateHistory(objects: IObject[], event?: string) {
    if (objects.length === 0 && canvas) {
      objects = canvas.getObjects();
    }
    setPendingHistoryUpdate(objects);
  }

  /**
   * handleHistoryUpdate
   * @description The function that actually runs through and updates our history state getting
   * the latest canvas and layers.
   */
  function handleHistoryUpdate() {
    if (pendingHistoryUpdate.length === 0) return;
    const currentPageLayers = layerState.pageLayers.find(
      (x) => x.name === selectedPage
    );
    if (!currentPageLayers || !canvas) return;
    const newEntry: IHistoryEntry = {
      layers: currentPageLayers.layers,
      canvas: getCleanCanvasData(canvas),
    };

    const index = history.findIndex((x) => x.page === selectedPage);
    if (index !== -1) {
      const newHistory = [...history];
      const newHistoryPage = { ...newHistory[index] };

      newHistoryPage.history = [...newHistoryPage.history].slice(
        0,
        newHistoryPage.cursorPosition + 1
      );
      newHistoryPage.history = [...newHistoryPage.history, newEntry];
      if (newHistoryPage.cursorPosition + 1 > maxHistoryPos) {
        newHistoryPage.history.splice(0, 1);
      } else {
        newHistoryPage.cursorPosition += 1;
      }
      newHistory[index] = newHistoryPage;
      setHistory(newHistory);
      historyRef.current = newHistory;
      setPendingHistoryUpdate([]);
    }
  }

  function getSelection() {
    if (!canvas) return [];
    const activeObject = canvas._activeObject;
    const selected: IObject[] = [];
    if (activeObject) {
      if (!activeObject.name) {
        (activeObject as Group)._objects.forEach((obj) => {
          selected.push(obj);
        });
      } else {
        selected.push(activeObject);
      }
    }
    canvas.discardActiveObject();
    return selected;
  }

  function applySelection(selection: IObject[]) {
    if (!canvas) return;
    if (selection.length === 1) {
      const obj = canvas._objects.find((x) => x.name === selection[0].name);
      if (obj) {
        canvas.setActiveObject(obj);
        canvas.renderAll();
      }
    } else {
      const names = selection.map((x) => x.name);

      const newSelection = new fabric.ActiveSelection(
        canvas._objects.filter((x) => x.name && names.includes(x.name)),
        { canvas: canvas }
      );
      if (newSelection._objects.length) {
        canvas.setActiveObject(newSelection);
        canvas.renderAll();
      }
    }
  }

  function destroyPenToolHistory(length: number) {
    const cHistoryIndex = historyRef.current.findIndex(
      (x) => x.page === selectedPageRef.current
    );
    if (cHistoryIndex > -1) {
      const cHistory = historyRef.current[cHistoryIndex];

      if (cHistory && cHistory.history.length) {
        const pointStartIndex = cHistory.history.length - length;
        cHistory.history.splice(pointStartIndex, length);
        cHistory.cursorPosition = cHistory.cursorPosition - length;

        setHistory([...historyRef.current]);
        // if (index <= 0) {
        //   cHistory.history = [cHistory.history[0]];
        // } else {
        //   cHistory.history = cHistory.history.slice(0, index);
        // }
        // cHistory.cursorPosition = cHistory.cursorPosition - length - 1;
        // historyRef.current[cHistoryIndex] = cHistory;
        // setHistory([...historyRef.current]);
      }
    }
  }

  /**
   * undo
   * @description The undo function.
   */
  async function undo() {
    if (!currentHistory || !canvas || undoDisabled) return;

    const selection = getSelection();

    const canvasState =
      currentHistory.history[currentHistory.cursorPosition - 1];
    const objects = canvas.getObjects();
    await asyncLoadFromJson(canvas, canvasState.canvas);

    const newHistory = [...history];
    const index = newHistory.findIndex((x) => x.page === selectedPage);
    newHistory[index].cursorPosition = currentHistory.cursorPosition - 1;
    const layersIndex = layerState.pageLayers.findIndex(
      (x) => x.name === selectedPage
    );
    hideTextObjectControls(canvas.getObjects());
    if (layersIndex !== -1) {
      const pageLayers = { ...layerState.pageLayers[layersIndex] };
      pageLayers.layers = canvasState.layers;
      dispatch(updateLayers(pageLayers));
    }

    if (selection.length) {
      applySelection(selection);
    }
    setHistory(newHistory);
    canvas.remove(...objects);
    canvas.renderAll();
  }
  /**
   * undo
   * @description The redo function.
   */
  async function redo() {
    if (!currentHistory || !canvas || redoDisabled) return;
    const selection = getSelection();
    const canvasState =
      currentHistory.history[currentHistory.cursorPosition + 1];
    const objects = canvas.getObjects();
    await asyncLoadFromJson(canvas, canvasState.canvas);

    const newHistory = [...history];
    const index = newHistory.findIndex((x) => x.page === selectedPage);
    newHistory[index].cursorPosition = currentHistory.cursorPosition + 1;
    const layersIndex = layerState.pageLayers.findIndex(
      (x) => x.name === selectedPage
    );
    hideTextObjectControls(canvas.getObjects());
    if (layersIndex !== -1) {
      const pageLayers = { ...layerState.pageLayers[layersIndex] };
      pageLayers.layers = canvasState.layers;
      dispatch(updateLayers(pageLayers));
    }
    if (selection.length) {
      applySelection(selection);
    }
    setHistory(newHistory);
    canvas.remove(...objects);
  }

  useEffect(handleHistoryUpdate, [pendingHistoryUpdate]);

  useEffect(initHistory, [selectedPage, canvas]);

  return {
    undoDisabled,
    redoDisabled,
    undo,
    redo,
    updateHistory,
    initHistory,
    history,
    destroyPenToolHistory,
    cursorPosition: currentHistory?.cursorPosition ?? 0,
    currentHistory,
  };
};

// function deepCompare(
//   canvasState: IHistoryEntry,
//   canvas: Canvas
// ): { delete: IObject[]; add: IObject[]; update: IObject[] } {
//   const objects: { delete: IObject[]; add: IObject[]; update: IObject[] } = {
//     delete: [],
//     add: [],
//     update: [],
//   };
//   const currentCanvas = canvas.toDatalessJSON(DATALESS_PROPERTIES).objects;
//   const historyObjects = (canvasState.canvas as { objects: IObject[] })
//     .objects as IObject[];

//   currentCanvas.forEach((obj) => {
//     const historyObject = historyObjects.find((x) => x.name === obj.name);
//     if (!historyObject) {
//       objects.delete.push(obj);
//       return;
//     }
//     const keys = Object.keys(obj) as Array<keyof IObject>;
//     let differenceFound = false;
//     keys.forEach((key) => {
//       if (typeof historyObject[key] === "object") {
//         if (Array.isArray(historyObject[key])) {
//           if (historyObject[key]?.length || obj[key]?.length) {
//             historyObject[key].forEach((x: unknown) => {
//               if (!obj[key].includes(x)) {
//                 differenceFound = true;
//               }
//             });
//           }
//         }
//         else {
//           if(key === "shadow") {

//           }
//         }
//       }
//       if (historyObject[key] != obj[key]) {
//         differenceFound = true;
//       }
//     });
//     if (differenceFound) {
//       objects.update.push(obj);
//     }
//   });
//   historyObjects.forEach((obj) => {
//     if (!currentCanvas.find((x) => x.name === obj.name)) {
//       objects.add.push(obj);
//     }
//   });

//   return objects;
// }

export default useHistory;
