import React, { createContext, useReducer, useState } from "react";
import IHistory from "../../features/Canvas/models/IHistory";

export const HistoryContext = createContext<IHistory[]>([]);

export const HistoryDispatchContext = createContext<
  (history: IHistory[]) => void
>((history: IHistory[]) => {});

const initialState: IHistory[] = [];

function historyReducer(
  state: IHistory[],
  action: { type: string; payload: IHistory[] }
) {
  return action.payload;
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const HistoryProvider = ({ children }: IProviderProps) => {
  const [history, dispatch] = useReducer(historyReducer, initialState);
  function updateHistory(hsty: IHistory[]) {
    dispatch({ type: "update", payload: hsty });
  }

  return (
    <HistoryContext.Provider value={history}>
      <HistoryDispatchContext.Provider value={updateHistory}>
        {children}
      </HistoryDispatchContext.Provider>
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
