import React, { useContext, useEffect, useRef, useState } from "react";
import useLayers from "../../../hooks/useLayers";
import { PagesContext } from "../../../state/contexts/PagesContext";
import PagePreview from "./PagePreview";
import { useDesignerSelector } from "../../../state/store";
import { selectDesignInformation } from "../../../state/slices/designInformation";
import tw, { css } from "twin.macro";
import useSaveData from "../../../hooks/useSaveData";
import useClickOutside from "../../../../hooks/useClickOutside";
import pageSelectorStyles from "./pageSelectorStyles";
import { PlusIcon } from "../shared/SvgComponents";
import Modal, { ModalActions, ModalBody, ModalTitle } from "../shared/Modal";
import DesignerButton from "../shared/DesignerButton";
import generateGuid from "../../../../helpers/generateGuid";

const styles = {
  container: (isOpen: boolean) => [
    css({
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      width: "100%",
      height: "198px",
    }),

    tw`bg-glacier p-4 border-b border-b-border border-solid`,
    !isOpen &&
      css`
        z-index: -1;
      `,
  ],
};

const PageSelector = () => {
  const { addPage, deletePage, selectedPage, changePage, layers, movePage } =
    useLayers();
  const pages = useContext(PagesContext);
  const designInformation = useDesignerSelector(selectDesignInformation);
  const sizeMulti =
    designInformation.canvasSettings?.width === 8.5 &&
    designInformation.canvasSettings?.height === 11
      ? 15
      : 128 / (designInformation.canvasSettings?.height ?? 20);
  const [isOpen, setIsOpen] = useState(false);
  const [renderPages, setRenderPages] = useState(false);
  const [pageToDelete, setPageToDelete] = useState<string>();
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  const isLetter =
    designInformation.canvasSettings?.width === 8.5 &&
    designInformation.canvasSettings?.height === 11;

  function handleOpen() {
    if (!isOpen) {
      setRenderPages(true);
    } else {
      setIsOpen(false);
    }
  }

  function handleDelete() {
    if (pageToDelete) {
      deletePage(pageToDelete);
      setPageToDelete(undefined);
    }
  }

  function handleDrop(page: string, destination: number) {
    movePage(page, destination);
  }

  useEffect(() => {
    if (renderPages) {
      setIsOpen(true);
    }
  }, [renderPages]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setRenderPages(false);
      }, 200);
    }
  }, [isOpen]);

  if (!pages) return null;
  return (
    <React.Fragment>
      <div
        css={[
          css`
            position: absolute;
            z-index: 55;
            top: 47px;
            display: flex;
            justify-content: center;
            transition: transform 0.2s ease-in-out;
            width: 100%;
          `,

          isOpen &&
            css`
              width: 100%;
            `,
          !isOpen &&
            css`
              transform: translateY(-198px);
            `,
          !renderPages &&
            css`
              &:hover {
                transform: translateY(-180px);
              }
            `,
        ]}
      >
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <div css={styles.container(isOpen)} ref={containerRef}>
            <React.Fragment>
              {renderPages &&
                pages.map((page, i) => (
                  <PagePreview
                    key={page.name}
                    page={page}
                    canDrag={pages.length > 1 && pages[0].name !== "Front"}
                    index={i}
                    isLastItem={i === pages.length - 1}
                    onDrop={handleDrop}
                    canDelete={isLetter && pages.length !== 1}
                    onDelete={(page) => {
                      setPageToDelete(page);
                    }}
                    isActive={page.name === selectedPage}
                    width={
                      (designInformation.canvasSettings?.width ?? 6.75) *
                      sizeMulti
                    }
                    height={
                      (designInformation.canvasSettings?.height ?? 4.5) *
                      sizeMulti
                    }
                    sizeMulti={sizeMulti}
                    onClick={changePage}
                    originalWidth={designInformation.canvasSettings?.width ?? 0}
                    originalHeight={
                      designInformation.canvasSettings?.height ?? 0
                    }
                  />
                ))}
              {isLetter && pages.length < 5 && (
                <div
                  onClick={addPage}
                  className="group"
                  css={[
                    pageSelectorStyles.pagePreviewContainer(
                      false,
                      8.5 * sizeMulti,
                      11 * sizeMulti
                    ),
                    pageSelectorStyles.pagePreviewNew,
                  ]}
                >
                  <div css={pageSelectorStyles.pagePreviewNewIconContainer}>
                    <PlusIcon styles={pageSelectorStyles.pagePreviewNewIcon} />
                  </div>
                </div>
              )}
            </React.Fragment>
          </div>
          <button
            onClick={handleOpen}
            css={[
              css({
                position: "relative",
                top: "-1px",
                fontWeight: "600",
              }),
              tw`bg-glacier border border-solid border-t-glacier border-border px-4 py-2 rounded-b`,
            ]}
          >
            Pages
          </button>
        </div>
      </div>
      <Modal isOpen={Boolean(pageToDelete)}>
        <ModalTitle>Are you sure you want to delete this page?</ModalTitle>
        <ModalBody>This action cannot be undone</ModalBody>
        <ModalActions>
          <DesignerButton onClick={handleDelete}>Delete</DesignerButton>
          <DesignerButton onClick={() => setPageToDelete(undefined)}>
            Cancel
          </DesignerButton>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default PageSelector;
