import { ReactComponent as XIcon } from "../../../../assets/images/designer-svg/XIcon.svg";
import ButtonReset from "../shared/ButtonReset";
import tw from "twin.macro";
import propertiesStyles from "../Properties/propertiesStyles";

interface ISidebarTitleProps {
  children?: React.ReactFragment;
  onClose: () => void;
}

const styles = {
  container: [
    tw`bg-glacier border-0 border-b border-solid border-border  flex items-center `,
  ],
  title: [tw`uppercase font-semibold text-lg`],
  button: [
    tw`hover:bg-hover border-0 border-solid border-l border-border ml-auto px-4 h-11`,
  ],
  icon: [tw`fill-labels w-5 h-5`],
};

const SidebarTitle = ({ children, onClose }: ISidebarTitleProps) => {
  return (
    <div css={styles.container}>
      <div css={propertiesStyles.sidebarHeading}>{children}</div>

      <ButtonReset onClick={onClose} css={styles.button}>
        <XIcon css={styles.icon} />
      </ButtonReset>
    </div>
  );
};

export default SidebarTitle;
