import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchClient } from "../../../helpers/fetchClient";

import Img from "../../../shared/BasicHTML/Img";
import DesignOrderExample from "../../../shared/JSONExamples/DesignOrderExample";
import DesignProofExample from "../../../shared/JSONExamples/DesignProofExample";
import TabPanel from "../../../shared/TabPanel";
import DesignModel from "../models/Design";
import DesignPricing from "../models/DesignPricing";
import DesignPricingTable from "./components/DesignPricingTable";
import DesignVariablesTable from "./components/DesignVariablesTable";
import { Link } from "react-router-dom";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import LoadingWrapper from "../../../shared/LoadingWrapper";

import OrdersTable from "../../Overview/components/OrdersTable";
import PaginatedOrdersTable from "../../Overview/Orders/PaginatedOrdersTable";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import DesignFriendlyName from "../components/DesignFriendlyName";
import { putAsync } from "../../../helpers/asyncFetch";
import designInformation, {
  setDesignInformation,
} from "../../../postcard-designer/state/slices/designInformation";

type DesignParams = { designId: string };

const tabs = {
  vars: "vars",
  pricing: "pricing",
  order: "order",
  orderHistory: "orderHistory",
  proof: "proof",
};

const Design: React.FC = (props) => {
  /**
   * State Objects and Refs
   */

  const { designId } = useParams<DesignParams>();
  const isValid = !Number.isNaN(parseInt(designId));
  const {
    data: design,
    isLoading: loading,
    error,
    updateData,
  } = useGetData<DesignModel>(isValid ? `/api/designs/${designId}` : undefined);
  const {
    data: designPricing,
    isLoading: pricingLoading,
    error: pricingError,
  } = useGetData<DesignPricing[]>(
    isValid ? `/api/pricing/${designId}` : undefined
  );

  const [activeTab, setActiveTab] = useState(tabs.vars);
  const [apiVersion, setApiVersion] = useState("v3");

  /**
   * Component Methods
   */

  async function updateFriendlyName(newName: string) {
    if (!design) return;
    await putAsync(`/designs/${design.designID}/name`, {
      name: newName,
    });
    updateData({ ...design, friendlyName: newName });
  }

  function handleTabChange(
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) {
    setActiveTab(value);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  function versionSelector() {
    return (
      <Select
        size="small"
        value={apiVersion}
        onChange={(e) => setApiVersion(e.target.value)}
      >
        <MenuItem value="v3">Direct Mail API v3</MenuItem>
        <MenuItem value="v2">Direct Mail API v2</MenuItem>
      </Select>
    );
  }
  if (!isValid) return <div />;
  return (
    <Box sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper
        height={600}
        loading={loading || pricingLoading}
        hasError={error.hasError}
        errorMessage={error.errorMessage}
      >
        <Box className="designHeader" sx={{ mb: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h4" component="h1">
                Design #{design?.designID}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/place-an-order/${design?.designID}`}
              >
                Place An Order
              </Button>

              {/* <Button variant="contained" color="primary">
                Generate Proof
              </Button> */}
            </Grid>
            {design?.printPDFSource?.toLowerCase().includes("designer") && (
              <Grid item xs="auto">
                <Button
                  color="primary"
                  variant="outlined"
                  component={Link}
                  to={`/designs/designer/${design?.designID}/edit`}
                >
                  Edit Design
                </Button>
              </Grid>
            )}
            {design?.printPDFSource !== "LAS" && (
              <GridItem xs="auto">
                <Button
                  color="primary"
                  component={Link}
                  to={`/designs/${design?.designID}/proof`}
                >
                  Generate A Proof
                </Button>
              </GridItem>
            )}
          </Grid>
        </Box>

        <Box className="designStepper" sx={{ mb: 4 }}>
          <Paper>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab value={tabs.vars} label="Design Details" />
              <Tab value={tabs.orderHistory} label="Order History" />
              <Tab value={tabs.order} label="Order JSON Example" />
              <Tab value={tabs.proof} label="Proof JSON Example" />
            </Tabs>
            <TabPanel value={tabs.vars} activeTab={activeTab}>
              <Box>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ mb: 2 }}>
                      <Img
                        src={
                          design?.proofFront
                            ? design?.proofFront
                            : design?.proofPDF
                        }
                      />
                    </Box>
                    {Boolean(design?.proofBack) && (
                      <Box>
                        <Img src={design?.proofBack} />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5">
                          {design?.friendlyName}
                        </Typography>
                        <DesignFriendlyName
                          designID={design?.designID ?? 0}
                          friendlyName={design?.friendlyName ?? ""}
                          callback={(designID, newName) =>
                            updateFriendlyName(newName)
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Variables{" "}
                        </Typography>
                      </Box>
                      <DesignVariablesTable
                        designFields={design?.designFields ?? []}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Pricing{" "}
                        </Typography>
                      </Box>
                      {designPricing &&
                        designPricing.length > 0 &&
                        design &&
                        design.mailClasses &&
                        designPricing
                          .reduce((prev, curr) => {
                            const index = prev.findIndex(
                              (x) => x.mailClass === curr.mailClass
                            );
                            if (index === -1) {
                              prev.push({
                                mailClass: curr.mailClass,
                                pricing: [curr],
                              });
                            } else {
                              prev[index].pricing.push(curr);
                            }
                            return prev;
                          }, [] as { mailClass: string; pricing: DesignPricing[] }[])
                          .map((mailClass) => (
                            <Box key={mailClass.mailClass} sx={{ mb: 3 }}>
                              <DesignPricingTable
                                mailClass={mailClass.mailClass}
                                pricing={mailClass.pricing}
                              />
                            </Box>
                          ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={tabs.orderHistory} activeTab={activeTab}>
              {/* <OrdersTable designID={design?.designID} />; */}

              <PaginatedOrdersTable designID={design?.designID} />
            </TabPanel>
            <TabPanel value={tabs.order} activeTab={activeTab}>
              <Box>
                {versionSelector()}
                {design && (
                  <DesignOrderExample
                    design={design}
                    designType={design?.printPDFSource}
                    version={apiVersion}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabs.proof} activeTab={activeTab}>
              <Box>
                {versionSelector()}
                {design && (
                  <DesignProofExample design={design} version={apiVersion} />
                )}
              </Box>
            </TabPanel>
          </Paper>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default Design;
