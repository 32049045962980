import { Ellipse, Group, Object as IObject, Image } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { IImageGroup } from "../useImageMasking";
import asyncClone from "../../../features/Canvas/functions/asyncClone";

export function changeToRect(obj: IImageGroup, isSquare = false) {
  const image = obj.__getImage();
  const frame = obj.__getFrame();
  const iWidth = image.getScaledWidth();
  const iHeight = image.getScaledHeight();
  const shape = new fabric.Rect({
    width: iWidth,
    height: iHeight,
    originX: "center",
    originY: "center",
    stroke: frame.stroke,
    strokeWidth: frame.strokeWidth,
    left: 0,
    top: 0,
    fill: "",
    rx: 0,
    ry: 0,
    scaleX: 1 / (image.scaleX ?? 1),
    scaleY: 1 / (image.scaleY ?? 1),
  });
  if (isSquare) {
    const width = iWidth > iHeight ? iHeight : iWidth;
    shape.width = width;
    shape.height = width;
    shape.lockUniScaling = true;
  }
  groupCleanup(obj, image, shape);
}

export function changeToCircle(obj: IImageGroup) {
  const image = obj.__getImage();
  const frame = obj.__getFrame();
  const iWidth = image.getScaledWidth();
  const iHeight = image.getScaledHeight();
  const radius = iWidth > iHeight ? iHeight / 2 : iWidth / 2;
  const shape = new fabric.Ellipse({
    rx: radius,
    ry: radius,
    originX: "center",
    originY: "center",
    stroke: frame.stroke,
    strokeWidth: frame.strokeWidth,
    width: radius * 2,
    height: radius * 2,
    left: 0,
    top: 0,
    fill: "",
    scaleX: 1 / (image.scaleX ?? 1),
    scaleY: 1 / (image.scaleY ?? 1),
  });
  groupCleanup(obj, image, shape);
}

export function changeToEllipse(obj: IImageGroup) {
  const image = obj.__getImage();
  const frame = obj.__getFrame();
  const iWidth = image.width ?? 0;
  const iHeight = image.height ?? 0;
  const rx = iWidth / 2;
  const ry = iHeight / 2;
  const shape = new fabric.Ellipse({
    rx,
    ry,
    originX: "center",
    originY: "center",
    stroke: frame.stroke,
    strokeWidth: frame.strokeWidth,
    width: iWidth,
    height: iHeight,
    left: 0,
    top: 0,
    fill: "",
  });
  groupCleanup(obj, image, shape);
}

export function changeToExistingShape(obj: IImageGroup, shape: IObject) {
  const image = obj.__getImage();
  const frame = obj.__getFrame();
}

export async function groupCleanup(obj: Group, image: Image, frame: IObject) {
  obj.objectCaching = false;
  obj.canvas?.renderAll();

  obj.remove(...obj.getObjects());

  obj.add(frame);
  image.clipPath = frame;
  image.dirty = true;
  obj.width = frame.width;
  obj.height = frame.height;
  obj.add(image);
  obj.setCoords();
  obj.dirty = true;
  obj.canvas?.renderAll();
}

export function groupCleanup2(obj: IImageGroup, image: Image, frame: IObject) {
  const left = obj.left;
  const top = obj.top;
  const scaleX = obj.scaleX ?? 1;
  const scaleY = obj.scaleY ?? 1;
  const angle = frame.angle ?? obj.angle;
  const skewX = frame.skewX ?? obj.skewX;
  const skewY = frame.skewY ?? obj.skewY;

  obj.remove(...obj.getObjects());
  //obj.remove(frame);

  // obj.width = frame.width;
  // obj.height = frame.height;
  obj.addWithUpdate(frame);
  obj.set({ objectCaching: false });
  image.set({ objectCaching: false });
  frame.set({ objectCaching: false });
  obj.canvas?.renderAll();
  frame.clone((clone: IObject) => {
    frame.setCoords();
    clone.set({
      top: 0 - (image.top ?? 0) / (image.scaleX ?? 1),
      left: 0 - (image.left ?? 0) / (image.scaleY ?? 1),
      stroke: "",
      width: (clone.width ?? 0) / (image.scaleX ?? 1),
      height: (clone.height ?? 0) / (image.scaleY ?? 1),
      dirty: true,
    });
    image.set({
      originX: "center",
      originY: "center",
      dirty: true,
      clipPath: clone,
    });
    image.clipPath?.setCoords();

    obj.add(image);

    obj.set({ left, top, scaleX, scaleY, dirty: true });

    obj.setCoords();

    obj.canvas?.renderAll();
  });

  // obj.angle = angle;
  // obj.skewX = skewX;
  // obj.skewY = skewY;
}
