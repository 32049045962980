import React from "react";
import FabricQrCode from "../../../models/types/FabricQrCode";
import ToolButton from "../shared/ToolButton";
import DeleteButton from "./DeleteButton";
import toolbarStyles from "./toolbarStyles";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import { toggleQrCodeSelector } from "../../../state/slices/qrCode";
import isAdminUser from "../../../../helpers/isAdminUser";
import { selectDesignInformation } from "../../../state/slices/designInformation";

interface QrCodePropertiesProps {
  selectedObject: FabricQrCode;
}

const QrCodeProperties = ({ selectedObject }: QrCodePropertiesProps) => {
  const dispatch = useDesignerDispatch();
  const designInformation = useDesignerSelector(selectDesignInformation);
  function handleOpen(isEdit = false) {
    dispatch(toggleQrCodeSelector(isEdit ? "edit" : undefined));
  }
  return (
    <React.Fragment>
      <div>
        <div css={toolbarStyles.section}>
          {(!isAdminUser() || designInformation?.bAccountID) && (
            <div css={toolbarStyles.property}>
              <ToolButton
                css={toolbarStyles.button}
                onClick={() => handleOpen(true)}
              >
                Edit QR Code Settings
              </ToolButton>
            </div>
          )}

          {(!isAdminUser() || designInformation?.bAccountID) && (
            <div css={toolbarStyles.property}>
              <ToolButton
                css={toolbarStyles.button}
                onClick={() => handleOpen()}
              >
                Swap QR Code
              </ToolButton>
            </div>
          )}

          <div css={toolbarStyles.property}>
            <DeleteButton selectedObject={selectedObject} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QrCodeProperties;
