import { Box, Button, Paper, Typography } from "@mui/material";
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import exportCsv from "../../helpers/exportCsv";
import formattingHelpers from "../../helpers/formattingHelpers";
import useLoadData from "../../hooks/useLoadData";
import LoadingWrapper from "../LoadingWrapper";
import GridItem from "../MaterialWrappers/GridItem";
import GridRow from "../MaterialWrappers/GridRow";
import SearchableDataTable from "../SearchableDataTable";
import MailTrackingObject from "./models/MailTrackingObject";
import MailTrackingResult from "./models/MailTrackingResult";

interface MailTrackingProps {
  batchId: number;
  removeElevation?: boolean;
}

const MailTracking: React.FC<MailTrackingProps> = ({
  batchId,
  removeElevation = false,
}) => {
  const { data, isLoading } = useLoadData<MailTrackingObject>(
    `/api/mail-tracking/${batchId}`,
    {
      totalMailed: 0,
      totalDelivered: 0,
      deliveryPercent: 0,
      mailDate: "",
      results: [],
    }
  );

  function exportDailyResults() {
    const today = new Date();
    const todayFormatted = moment(today).format("MM-DD-YYYY");
    exportCsv(data.results, `mail-tracking-batch-${batchId}-${todayFormatted}`);
  }

  const columns: TableColumn<MailTrackingResult>[] = [
    {
      name: "Result Date",
      selector: (res) => res.resultDate,
      format: (res) =>
        moment(res.resultDate).format("MM/DD/YYYY").toLocaleString(),
      sortable: true,
    },
    {
      name: "Projected",
      selector: (res) => res.qtyMailed,
      format: (res) => formattingHelpers.formatThousands(res.qtyMailed),
      sortable: true,
    },
    {
      name: "Delivered",
      selector: (res) => res.qtyDelivered,
      format: (res) => formattingHelpers.formatThousands(res.qtyDelivered),
      sortable: true,
    },
  ];

  return (
    <Box>
      <LoadingWrapper
        loading={isLoading}
        height={600}
        container={removeElevation ? undefined : Paper}
      >
        <GridRow sx={{ mb: 3 }}>
          <GridItem xs={12} md={4}>
            <Paper
              sx={{
                p: 4,
                textAlign: "center",
                border: removeElevation
                  ? "solid 1px rgba(0,0,0,.125)"
                  : undefined,
              }}
              elevation={removeElevation ? 0 : undefined}
            >
              <Typography variant="h6" gutterBottom>
                Total Mailed
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {formattingHelpers.formatThousands(data.totalMailed)}
              </Typography>
            </Paper>
          </GridItem>
          <GridItem xs={12} md={4}>
            <Paper
              sx={{
                p: 4,
                textAlign: "center",
                border: removeElevation
                  ? "solid 1px rgba(0,0,0,.125)"
                  : undefined,
              }}
              elevation={removeElevation ? 0 : undefined}
            >
              <Typography variant="h6" gutterBottom>
                Total Delivered
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {formattingHelpers.formatThousands(data.totalDelivered)}
              </Typography>
            </Paper>
          </GridItem>
          <GridItem xs={12} md={4}>
            <Paper
              sx={{
                p: 4,
                textAlign: "center",
                border: removeElevation
                  ? "solid 1px rgba(0,0,0,.125)"
                  : undefined,
              }}
              elevation={removeElevation ? 0 : undefined}
            >
              <Typography variant="h6" gutterBottom>
                Delivery Percentage
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {data.deliveryPercent.toFixed(2)}%
              </Typography>
            </Paper>
          </GridItem>
        </GridRow>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            border: removeElevation ? "solid 1px rgba(0,0,0,.125)" : undefined,
          }}
        >
          <SearchableDataTable
            hideSearchBar
            data={data.results}
            columns={columns}
            elevation={removeElevation ? 0 : undefined}
          />
        </Paper>
        <Box sx={{ textAlign: "right", mt: 2 }}>
          <Button
            color="primary"
            onClick={exportDailyResults}
            variant="outlined"
          >
            Export Mail Tracking
          </Button>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default MailTracking;
