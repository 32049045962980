import { IPathPoint, fabric } from "fabric";
import { Object as IObject } from "fabric/fabric-impl";
import { controlCircleOptions, controlLineOptions } from "../constants";

export default /**
 * @description Create the fabric objects that will represent our point controls.
 * @param {IPathPoint} point - The collection of coordinates from a point.
 * @returns
 */
function getControls(
  point: IPathPoint,
  transformMatrix?: number[],
  disableEvents = false
) {
  const controls: IObject[] = [];

  if (point.quadraticForward) {
    controls.push(
      new fabric.Path(
        `M ${point.x} ${point.y} L ${point.quadraticForward.x} ${point.quadraticForward.y} Z`,
        {
          ...controlLineOptions,
          selectable: false,
          evented: false,
        }
      )
    );
    controls.push(
      new fabric.Circle({
        ...controlCircleOptions,
        selectable: false,
        top: point.quadraticForward.y,
        left: point.quadraticForward.x,
        name: `PathControl-point-${point.quadraticForward.name}`,
        evented: !disableEvents,
      })
    );
  }
  if (point.quadraticBackward) {
    controls.push(
      new fabric.Path(
        `M ${point.x} ${point.y} L ${point.quadraticBackward.x} ${point.quadraticBackward.y} Z`,
        {
          ...controlLineOptions,
          selectable: false,
          evented: false,
        }
      )
    );
    controls.push(
      new fabric.Circle({
        ...controlCircleOptions,
        selectable: false,
        top: point.quadraticBackward.y,
        left: point.quadraticBackward.x,
        name: `PathControl-point-${point.quadraticBackward.name}`,
        evented: !disableEvents,
      })
    );
  }

  const anchorPoint = new fabric.Circle({
    ...controlCircleOptions,
    selectable: false,
    top: point.y,
    left: point.x,
    name: `PathControl-point-${point.name}`,
    evented: !disableEvents,
  });
  controls.push(anchorPoint);

  return controls;
}
