/**
 * @TODO Create page level error handling: "Oops"
 */

import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import "react-app-polyfill/ie11";

import { ThemeProvider } from "@mui/material/styles";

import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import registerServiceWorker from "./registerServiceWorker";

import theme from "./helpers/theme";
import Admin from "./routes/Admin/Admin";
import AdminDesigns from "./routes/Admin/Designs/AdminDesigns";
import AdminDesign from "./routes/Admin/Designs/Design/AdminDesign";
import AdminDesignProof from "./routes/Admin/Designs/DesignProof/AdminDesignProof";

import AdminDesignSizes from "./routes/Admin/DesignSizes/AdminDesignSizes";

import AuthLayout from "./shared/AuthLayout/AuthLayout";

import Webhooks from "./routes/Webhooks/Webhooks";

import "./index.scss";
import Login from "./routes/Login/Login";

import AutoLogin from "./routes/Login/AutoLogin";

import SignUp from "./routes/SignUp/SignUp";

import Logout from "./routes/Login/Logout";
import OrderSuccess from "./routes/PlaceAnOrder/Confirmations/OrderSuccess";
import PlaceAnOrder from "./routes/PlaceAnOrder/PlaceAnOrder/PlaceAnOrder";

import AccountPage from "./routes/Account/AccountInformation/Account";

import { Box, useMediaQuery } from "@mui/material";
import ApiKeys from "./routes/Account/ApiKeys/ApiKeys";
import IntegrationSettings from "./routes/Account/IntegrationSettings/IntegrationSettings";
import PaymentAndBilling from "./routes/Account/PaymentAndBilling/PaymentAndBilling";
import Batch from "./routes/Batch/Batch";
import CompleteRegistration from "./routes/CompleteRegistration";
import Design from "./routes/Designs/Design/Design";
import Designs from "./routes/Designs/Designs";
import Proof from "./routes/Designs/Proof";
import RequestADesign from "./routes/Designs/RequestDesign/RequestDesign";
import UploadADesign from "./routes/Designs/UploadADesign/UploadADesign";
import Home from "./routes/Home";
import Html from "./routes/Html";
import CustomerLogin from "./routes/Login/CustomerLogin";
import Batches from "./routes/Overview/Batches/Batches";
import Orders from "./routes/Overview/Orders/Orders";
import PricingPlans from "./routes/PricingPlans/PricingPlans";
import Pricings from "./routes/PricingPlans/Pricings";

import ContactUs from "./routes/ContactUs";
import TemplateGallery from "./routes/Designs/TemplateGallery/TemplateGallery";
import EnvelopeDetails from "./routes/Envelopes/EnvelopeDetails";
import EnvelopeOverview from "./routes/Envelopes/EnvelopeOverview";
import NewEnvelope from "./routes/Envelopes/NewEnvelope";
import ErrorTestRoute from "./routes/ErrorTestRoute";
import EditHtml from "./routes/Html/EditHtml";
import HtmlDesign from "./routes/Html/HtmlDesign";
import HtmlProof from "./routes/Html/HtmlProof";
import PlaceAnOrderPage from "./routes/PlaceAnOrder";

import DesignerWrapper from "./routes/Designer/Designer";

import AdminDesignerDesigns from "./routes/Admin/DesignerDesigns/AdminDesignerDesigns";
import HtmlTemplates from "./routes/Admin/HtmlTemplates/HtmlTemplates";
import EditDesignerDesign from "./routes/Designer/EditDesignerDesign";

import { ErrorBoundary } from "react-error-boundary";
import { ReAssignProvider } from "./routes/Admin/components/ReassignPanel";
import GenerateDesignerProof from "./routes/Designer/GenerateDesignerProof";
import PortalDesignGallery from "./routes/DesignGallery";
import NewDesignerDesign from "./routes/Designs/NewDesignerDesign/NewDesignerDesign";
import DesignForm from "./routes/Integrated/DesignForm/DesignForm";
import DesignGallery from "./routes/Integrated/DesignGallery/DesignGallery";
import IntegratedEditor from "./routes/Integrated/IntegratedEditor/IntegratedEditor";
import IntegratedQuotePay from "./routes/Integrated/IntegratedQuotePay/IntegratedQuotePay";
import IntegratedSettings from "./routes/Integrated/IntegrationSettings/IntegratedSettings";
import IntegratedLayout from "./routes/Integrated/layout/IntegratedLayout";
import Terms from "./routes/Integrated/Terms/Terms";
import EditQrCode from "./routes/QrCodes/EditQrCode";
import QrCodes from "./routes/QrCodes/QrCodes";
import DesignerLayout from "./shared/AuthLayout/DesignerLayout";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

const App = () => {
  const isXLScreenSize = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <ReAssignProvider>
      <Router basename={baseUrl !== null ? baseUrl : ""}>
        <Switch>
          <Route path="/integrated">
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
              <Route path="/integrated/integration-settings">
                <IntegratedLayout>
                  <IntegratedSettings />
                </IntegratedLayout>
              </Route>
              <Route path="/integrated/design-form">
                <IntegratedLayout>
                  <DesignForm />
                </IntegratedLayout>
              </Route>
              <Route path="/integrated/gallery">
                <IntegratedLayout>
                  <DesignGallery />
                </IntegratedLayout>
              </Route>
              <Route path="/integrated/embed/editor/:designId">
                <IntegratedLayout>
                  <IntegratedEditor mode="embed" />
                </IntegratedLayout>
              </Route>
              <Route path="/integrated/editor/:designId">
                <IntegratedLayout>
                  <IntegratedEditor />
                </IntegratedLayout>
              </Route>

              <Route path="/integrated/quotePay/:quoteId">
                <IntegratedLayout>
                  <IntegratedQuotePay />
                </IntegratedLayout>
              </Route>

              <Route path="/integrated/tos">
                <Terms />
              </Route>
            </ErrorBoundary>
          </Route>

          {/* Auth */}
          <Route exact path="/login">
            <ThemeProvider theme={theme}>
              <Login />
            </ThemeProvider>
          </Route>
          <Route exact path="/auto-login">
            <ThemeProvider theme={theme}>
              <AutoLogin />
            </ThemeProvider>
          </Route>
          <Route exact path="/sign-up">
            <ThemeProvider theme={theme}>
              <SignUp />
            </ThemeProvider>
          </Route>
          <Route exact path="/logout">
            <ThemeProvider theme={theme}>
              <Logout />
            </ThemeProvider>
          </Route>

          <Route exact path="/editor/designer/:designId">
            <DesignerLayout>
              <EditDesignerDesign />
            </DesignerLayout>
          </Route>
          <Route exact path="/designs/designer/:designId/edit">
            <DesignerLayout>
              <EditDesignerDesign mode="portalCustomer" />
            </DesignerLayout>
          </Route>
          <Route exact path="/editor">
            <DesignerLayout>
              <Box
                sx={{
                  pl: isXLScreenSize ? 10 : 3,
                  pr: isXLScreenSize ? 10 : 3,
                  pt: 6,
                  pb: 6,
                }}
              >
                <AdminDesignerDesigns />
              </Box>
            </DesignerLayout>
          </Route>

          <Route exact path="/customer-login">
            <ThemeProvider theme={theme}>
              <CustomerLogin />
            </ThemeProvider>
          </Route>
          <Route exact path="/admin/designer/:designId">
            <DesignerLayout>
              <EditDesignerDesign />
            </DesignerLayout>
          </Route>

          <Switch>
            <AuthLayout>
              <Route exact path="/gallery">
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                  <IntegratedLayout>
                    <PortalDesignGallery />
                  </IntegratedLayout>
                </ErrorBoundary>
              </Route>
              <Route exact path="/qr-codes/:id">
                <EditQrCode />
              </Route>
              <Route exact path="/qr-codes">
                <QrCodes />
              </Route>

              <Route exact path="/designer-test">
                <DesignerWrapper />
              </Route>
              <Route exact path="/admin/error-test-route">
                <ErrorTestRoute />
              </Route>
              <Route exact path="/contact-us">
                <ContactUs />
              </Route>
              <Route exact path="/complete-registration">
                <CompleteRegistration />
              </Route>

              <Route exact path="/designs/new-design">
                <NewDesignerDesign />
              </Route>

              <Route exact path="/designs/create">
                <DesignForm isPortal />
              </Route>

              <Route exact path="/designs/html/:designId/edit">
                <EditHtml />
              </Route>
              <Route exact path="/designs/html/:designId/:htmlVersionId/proof">
                <HtmlProof />
              </Route>

              {/* Envelope Routes */}
              <Route exact path="/envelopes/new">
                <NewEnvelope />
              </Route>
              <Route exact path="/envelopes/:envelopeId">
                <EnvelopeDetails />
              </Route>
              <Route exact path="/envelopes">
                <EnvelopeOverview />
              </Route>

              <Route exact path="/designs/html/:designId">
                {/* Version Manager / Details */}

                <HtmlDesign />
              </Route>
              <Route exact path="/designs/html/">
                {/* Show All */}
                <Html />
              </Route>
              <Route exact path="/designs/request-design">
                <RequestADesign />
              </Route>

              <Switch>
                <Route exact path="/designs/add">
                  <UploadADesign />
                </Route>
                <Route exact path="/designs/postcards">
                  <Designs type="postcard" />
                </Route>
                <Route exact path="/designs/letters">
                  <Designs type="letter" />
                </Route>
                <Route exact path="/designs/brochures">
                  <Designs type="brochure" />
                </Route>
                <Route exact path="/designs/template-gallery">
                  <TemplateGallery />
                </Route>
                <Route exact path="/designs/place-an-order">
                  <PlaceAnOrderPage />
                </Route>

                <Route path="/designs/:designId/proof">
                  <Proof />
                </Route>

                <Route exact path="/designs/:designId">
                  <Design />
                </Route>
              </Switch>

              {/* Batch */}
              <Route exact path="/batches/:batchId">
                <Batch />
              </Route>

              {/* Place An Order */}

              <Route exact path="/place-an-order/:orderId/success">
                <OrderSuccess />
              </Route>

              <Route exact path="/place-an-order/:designId">
                <PlaceAnOrder />
              </Route>

              {/* Admin */}
              <Route exact path="/admin/pricing/:priceClass">
                <Pricings />
              </Route>

              <Route exact path="/admin/html/:designId/edit">
                <EditHtml isAdmin={true} />
              </Route>

              <Route exact path="/admin/html/:designId">
                <HtmlDesign />
              </Route>
              <Route exact path="/admin/html">
                <HtmlTemplates />
              </Route>
              <Route exact path="/admin/pricing">
                <PricingPlans />
              </Route>
              <Route exact path="/editor/designer/:designId/proof">
                <GenerateDesignerProof />
              </Route>
              <Route exact path="/admin/designer/:designId/proof">
                <GenerateDesignerProof isAdmin />
              </Route>
              <Route exact path="/admin/designer">
                <AdminDesignerDesigns />
              </Route>
              <Route
                exact
                path="/admin/integrations/:customerId/designs/:designId/proof"
              >
                <AdminDesignProof />
              </Route>
              <Route
                exact
                path="/admin/integrations/:customerId/designs/:designId"
              >
                <AdminDesign />
              </Route>
              <Route exact path="/admin/integrations/:customerId/designs">
                <AdminDesigns />
              </Route>
              <Route exact path="/admin/design-sizes">
                <AdminDesignSizes />
              </Route>
              <Route exact path="/admin">
                <Admin />
              </Route>

              {/* Account */}
              <Route exact path="/settings/account-and-billing">
                <Box>
                  <AccountPage />
                  <hr
                    style={{
                      marginTop: "60px",
                      marginBottom: "60px",
                      borderColor: "rgba(0,0,0,.075)",
                    }}
                  />
                  <PaymentAndBilling />
                </Box>
              </Route>

              <Route exact path="/settings/mailing-settings">
                <IntegrationSettings />
              </Route>
              <Route exact path="/settings/api-keys">
                <ApiKeys />
              </Route>

              {/* Webhooks */}
              <Route exact path="/webhooks">
                <Webhooks />
              </Route>
              <Route exact path="/batches">
                <Batches />
              </Route>
              <Route exact path="/orders">
                <Orders />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
            </AuthLayout>
          </Switch>
        </Switch>
      </Router>
    </ReAssignProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
