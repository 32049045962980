export default function checkTarget(pointer, obj, globalPointer) {
  
  if(obj && (obj.name === "background" || obj.name === "overlay" || obj.name === "bleed")) return false;
  
  if (obj &&
      obj.visible &&
      obj.evented &&
      // http://www.geog.ubc.ca/courses/klink/gis.notes/ncgia/u32.html
      // http://idav.ucdavis.edu/~okreylos/TAship/Spring2000/PointInPolygon.html
      obj.containsPoint(pointer)
  ) {
    if ((this.perPixelTargetFind || obj.perPixelTargetFind) && !obj.isEditing) {
      var isTransparent = this.isTargetTransparent(obj, globalPointer.x, globalPointer.y);
      if (!isTransparent) {
        return true;
      }
    }
    else {
      return true;
    }
  }
}