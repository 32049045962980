export function setCookie(name: string, value: string, days: number) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; SameSite=None; Secure; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; SameSite=None; Secure; path=/";
}

export function getCookie(name: string) {
  const element = document.querySelector("#tokenRoot");

  if (element) {
    if (name === "token") {
      const token = element.attributes.getNamedItem("data-token");

      if (token) return token.value;
    }
    if (name === "apiBaseUrl") {
      const baseUrl = element.attributes.getNamedItem("data-baseurl");
      if (baseUrl) return baseUrl.value;
    }
    return null;
  }
  // const nameEQ = name + "=";
  // const ca = document.cookie.split(";");
  // for (let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   while (c.charAt(0) == " ") c = c.substring(1, c.length);
  //   if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  // }
  // return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
