import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IPosition from "../../features/Canvas/models/IPosition";
import { DesignerState } from "../store";

const initialState: {
  mouseDown: IPosition;
  mouseUp: IPosition;
  mouseMove: IPosition;
} = {
  mouseDown: { x: 0, y: 0 },
  mouseUp: { x: 0, y: 0 },
  mouseMove: { x: 0, y: 0 },
};

export const canvasPointerPosition = createSlice({
  name: "canvasPointerPosition",
  initialState: initialState,
  reducers: {
    updateMouseDown: (state, action: PayloadAction<IPosition>) => {
      state.mouseDown = { ...action.payload };
    },
    updateMouseUp: (state, action: PayloadAction<IPosition>) => {
      state.mouseUp = { ...action.payload };
    },
    updateMouseMove: (state, action: PayloadAction<IPosition>) => {
      state.mouseMove = { ...action.payload };
    },
  },
});

export const { updateMouseDown, updateMouseUp, updateMouseMove } =
  canvasPointerPosition.actions;
export const selectCanvasPointerPosition = (state: DesignerState) => ({
  mouseUp: state.canvasPointerPosition.mouseUp,
  mouseDown: state.canvasPointerPosition.mouseDown,
  mouseMove: state.canvasPointerPosition.mouseMove,
});
export default canvasPointerPosition.reducer;
