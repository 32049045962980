
export interface SizeObject {
  size: string,
  label: string
}

interface SizeDictionaryEntry {
  label: string,
  sortOrder: number
}

const sizeDictionary: Record<string, SizeDictionaryEntry> = {
  '46': {label: '4.25" x 6"', sortOrder: 0},
  '68': {label: '6" x 8.5"', sortOrder: 1},
  '611': {label: '6" x 11"', sortOrder: 2},
  '811': {label: '8.5" x 11" Letter', sortOrder: 3}
}

export function getSizeLabel(size: string): string {
  if(sizeDictionary[size]) return sizeDictionary[size].label;
  return size;
}


function lookUpSizeFriendlyNames(sizes: string[]): SizeObject[] {
  
  return sizes.sort((sizeA, sizeB) => {    
    let sizeASortOrder = 1000;
    let sizeBSortOrder = 1000;

    if(sizeDictionary[sizeA]) {
      sizeASortOrder = sizeDictionary[sizeA].sortOrder
    }
    if(sizeDictionary[sizeB]) {
      sizeBSortOrder = sizeDictionary[sizeB].sortOrder
    }
    if(sizeASortOrder < sizeBSortOrder) return -1
    if(sizeBSortOrder < sizeASortOrder) return 1;
    return 0;
  }).map(size => {
    if(sizeDictionary[size]) {
      return {
        label: sizeDictionary[size].label,
        size: size
      } 
    } 
    return {
      label: size,
      size: size
    };
  });  
}

export default lookUpSizeFriendlyNames;