import { IBulletedList, IListItem, Textbox } from "fabric";
import { useDesignerSelector } from "../../../state/store";
import { selectFontManager } from "../../../state/slices/fontManager";
import { useEffect, useRef, useState } from "react";
import { Category, IFont, Variant } from "../../Fonts/googleFontsService/types";
import tw, { css } from "twin.macro";
import Fuse from "fuse.js";
import LazyLoad from "react-lazy-load";
import Font from "../../Fonts/components/Font";
import propertiesStyles from "../Properties/propertiesStyles";
import WebFont from "webfontloader";

import InputReset from "../shared/InputReset";
import DesignerInput from "../shared/DesignerInput/DesignerInput";
import {
  FilterIcon,
  SearchIcon,
  SortDownIcon,
  SortUpIcon,
} from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import FontFilters from "./FontFilters";
import DesignerButton from "../shared/DesignerButton";
import { updateListAndListItems } from "../../Canvas/functions/createBulletedList";
import { cleanStyles } from "../../Canvas/functions/textStyleHelpers";
interface IFontSidebarProps {
  textObject: Textbox;
  onClose: () => void;
}

export interface IFilter {
  property: "category" | "variant";
  value: Category | Variant;
}

const styles = {
  fontFamily: (isActive: boolean) => [
    tw`p-4 hover:bg-hover cursor-pointer`,
    css`
      font-size: 16px;
    `,
    isActive && tw`bg-hover`,
  ],
  fontContainer: css`
    max-height: calc(100vh - 37px - 76px - 175px);
    height: calc(100vh - 37px - 76px - 175px);
    overflow-y: auto;
  `,
  fonts: css`
    height: calc(100vh - 37px - 76px);
    max-height: calc(100vh - 37px - 76px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  top: [
    css`
      height: 100px;
    `,
    tw`border-b border-solid border-border`,
  ],
  bottom: [
    css`
      height: 75px;
    `,
    tw`border-t border-solid border-border text-right`,
  ],
  sortContainer: tw`flex items-center mt-3`,
  sortTitle: tw`font-bold mr-2`,
  sortItem: tw`cursor-pointer text-blue fill-blue mr-2 last:mr-0 flex items-center`,
  sortName: (isActive: boolean) => [
    tw`hover:underline font-semibold `,
    isActive && tw`underline mr-0.5`,
    !isActive && tw`mr-3`,
  ],
  searchBarContainer: tw`flex items-center`,
  searchBar: tw`w-full mr-1`,
  filter: (isActive: boolean) => [
    tw`ml-auto mr-auto`,
    isActive && tw`fill-blue`,
  ],
  filterButton: [tw`h-9 w-9 flex`],
  filterContainer: [tw`w-9 relative`],
};

const FontSidebar = ({ textObject, onClose }: IFontSidebarProps) => {
  let list: IBulletedList | undefined = undefined;
  let isBulletedList = false;
  if (textObject.name?.includes("bulletedList")) {
    isBulletedList = true;
    // @ts-ignore
    list = textObject as IBulletedList;
    // @ts-ignore
    const listItem = (textObject as IBulletedList)._objects[0] as IListItem;
    const listText = listItem._objects.find((x) => x.name === "text");
    textObject = listText as Textbox;
  }
  const fontManager = useDesignerSelector(selectFontManager);
  const fonts = fontManager.fonts;
  const [selectedFont, setSelectedFont] = useState(getFont());
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFonts, setFilteredFonts] = useState([...fonts]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sort, setSort] = useState({
    property: "popularity",
    direction: "desc",
  });
  const textRef = useRef(textObject);

  function getFont() {
    const font = fonts.find((x) => x.family === textObject.fontFamily);
    if (!font) return fonts[0];
    return font;
  }

  function filterFonts() {
    const sorted = sortFonts();
    let filtered: IFont[] = [];
    if (filters.length === 0) {
      filtered = [...sorted];
    }
    filters.forEach((filter) => {
      if (filter.property === "category") {
        sorted
          .filter((x) => x.category === filter.value)
          .forEach((font) => {
            if (!filtered.find((x) => x.family === font.family)) {
              filtered.push(font);
            }
          });
      }
      if (filter.property === "variant") {
        sorted
          .filter((x) => x.variants.includes(filter.value as Variant))
          .forEach((font) => {
            if (!filtered.find((x) => x.family === font.family)) {
              filtered.push(font);
            }
          });
      }
    });

    setFilteredFonts(searchFonts(filtered));
  }

  function searchFonts(sortedAndFiltered: IFont[]) {
    if (!searchTerm) return sortedAndFiltered;
    const fuseOptions: Fuse.IFuseOptions<IFont> = {
      shouldSort: false,
      minMatchCharLength: 1,
      threshold: 0.1,
      keys: ["family"],
    };
    const fuse = new Fuse(sortedAndFiltered, fuseOptions);
    const results = fuse.search(searchTerm.replace(/[^a-zA-Z0-9. ]/g, ""));

    return results.reduce((previous, current) => {
      previous.push(sortedAndFiltered[current.refIndex]);
      return previous;
    }, [] as IFont[]);
  }

  function handleClick(font: IFont) {
    setSelectedFont(font);
    let fontString = `${font.family}:`;
    font.variants.forEach((v, i) => {
      fontString += v;
      if (i !== font.variants.length) fontString += ",";
    });
    if (!!font.isLocal) {
      WebFont.load({
        google: { families: [fontString] },
      });
    }
    if (isBulletedList && list) {
      updateListAndListItems(list, "text", {
        fontFamily: font.family,
        fontWeight: "400",
      });
    }

    if (textObject.isEditing) {
      if (textObject.getSelectedText()) {
        textObject.setSelectionStyles({
          fontFamily: font.family,
          fontWeight: "400",
        });
        textObject.canvas?.renderAll();
        textObject.canvas?.fire("object:modified");
        textObject.canvas?.renderAll();
      }
      return;
    }

    textObject.fontFamily = font.family;
    textObject.fontWeight = "400";
    cleanStyles(textObject, "fontFamily", font.family);
    cleanStyles(textObject, "fontWeight", "400");
    textObject.canvas?.fire("object:modified");
    textObject.canvas?.renderAll();
  }

  function sortFonts() {
    if (sort.property === "popularity") {
      if (sort.direction === "desc") {
        return [...fonts];
      }
      if (sort.direction === "asc") {
        return [...fonts].reverse();
      }
    } else if (sort.property === "fontFamily") {
      return [...fonts].sort((a, b) => {
        if (sort.direction === "asc") {
          return a.family.localeCompare(b.family);
        } else {
          return b.family.localeCompare(a.family);
        }
      });
    }
    return fonts;
  }

  function handleSort(property: string) {
    if (sort.property === property) {
      if (sort.direction === "asc") {
        setSort({ property, direction: "desc" });
      } else {
        setSort({ property, direction: "asc" });
      }
    } else {
      setSort({
        property,
        direction: property === "fontFamily" ? "asc" : "desc",
      });
    }
  }

  function handleFilterChange(filter: IFilter) {
    const index = filters.findIndex(
      (x) => x.property === filter.property && x.value === filter.value
    );
    if (index === -1) {
      setFilters([...filters, filter]);
    } else {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      setFilters(newFilters);
    }
  }

  function handleSync() {
    if (textRef.current.isEditing && textRef.current.getSelectedText()) {
      const styles = textRef.current.getSelectionStyles();
      const style = styles.find((x) => x.fontFamily);
      if (style) {
        const font = fonts.find((x) => x.family === style.fontFamily);
        if (font) setSelectedFont(font);
      } else {
        const font = fonts.find((x) => x.family === textRef.current.fontFamily);
        if (font) setSelectedFont(font);
      }
    }
  }

  useEffect(filterFonts, [sort, searchTerm, filters]);

  useEffect(() => {
    if (textObject.name !== textRef.current.name) {
      textRef.current.off("selection:changed", handleSync);
      textRef.current = textObject;
    }

    textRef.current.on("selection:changed", handleSync);
    return () => {
      textRef.current.off("selection:changed", handleSync);
    };
  }, [textObject]);

  return (
    <div css={styles.fonts}>
      <div css={[propertiesStyles.sidebarContainer, styles.top]}>
        <div css={styles.searchBarContainer}>
          <div css={styles.searchBar}>
            <DesignerInput
              name="searchTerm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="text"
              preIcon={SearchIcon}
              placeholder="Search fonts"
            />
          </div>
          <div css={styles.filterContainer}>
            <ToolButton
              css={styles.filterButton}
              onClick={(e) => {
                e.stopPropagation();
                setFilterOpen(!filterOpen);
              }}
            >
              <FilterIcon styles={styles.filter(filters.length > 0)} />
            </ToolButton>
            <FontFilters
              filters={filters}
              onChange={handleFilterChange}
              onClose={() => setFilterOpen(false)}
              isOpen={filterOpen}
            />
          </div>
        </div>
        <div css={styles.sortContainer}>
          <div css={styles.sortTitle}>Sort:</div>{" "}
          <div css={styles.sortItem} onClick={() => handleSort("popularity")}>
            <span css={styles.sortName(sort.property === "popularity")}>
              Popularity
            </span>
            {sort.property === "popularity" &&
              (sort.direction === "asc" ? (
                <SortUpIcon styles={tw`fill-blue`} />
              ) : (
                <SortDownIcon styles={tw`fill-blue`} />
              ))}
          </div>{" "}
          <div onClick={() => handleSort("fontFamily")} css={styles.sortItem}>
            <span css={styles.sortName(sort.property === "fontFamily")}>
              Name
            </span>
            {sort.property === "fontFamily" &&
              (sort.direction === "asc" ? (
                <SortUpIcon styles={tw`fill-blue`} />
              ) : (
                <SortDownIcon styles={tw`fill-blue`} />
              ))}
          </div>
        </div>
      </div>

      <div css={[styles.fontContainer, propertiesStyles.scrollbar]}>
        {filteredFonts.map((font) => (
          <div
            key={font.family}
            css={styles.fontFamily(font.family === selectedFont.family)}
            onClick={() => handleClick(font)}
          >
            <LazyLoad offset={500}>
              <Font font={font} />
            </LazyLoad>
          </div>
        ))}
      </div>
      <div css={[propertiesStyles.sidebarContainer, styles.bottom]}>
        <DesignerButton onClick={onClose}>Close Window</DesignerButton>
      </div>
    </div>
  );
};

export default FontSidebar;
