import { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import {
  Group,
  IEvent,
  Image,
  IText,
  Object as IObject,
  Path,
  Polygon,
  Rect,
} from "fabric/fabric-impl";
import { Tool } from "../state/models/ICanvasTool";

import { Textbox, fabric } from "fabric";
import drawShape from "../features/Canvas/functions/drawShape";
import IPosition from "../features/Canvas/models/IPosition";
import selectTool from "../features/Canvas/functions/selectTool";
import selectLayersFromObjects from "../features/Canvas/functions/selectLayersFromObjects";
import generateGuid from "../../helpers/generateGuid";
import drawText from "../features/Canvas/functions/drawText";

import { updateSelectedLayers } from "../state/slices/layers";
import { SelectedPageContext } from "../state/contexts/SelectedPageContext";

import { PagesContext } from "../state/contexts/PagesContext";
import reArrangeObjects from "../features/Canvas/functions/reArrangeObjects";

import useHistory from "./useHistory";

import { GuidesContext } from "../state/contexts/GuideContext";
import { setCurrentTool } from "../state/slices/toolSettings";
import { ribbonScaling } from "../features/Canvas/functions/shapeFunctions/ribbonShape";

import isShape from "../features/Canvas/functions/isShape";
// import usePenTool from "./usePenTool";
// import PenTool from "../features/PenTool/PenTool";
import useLayers from "./useLayers";
import { createBleedClipPath } from "../features/Canvas/functions/createBackgroundClipPath";
import textResizer, {
  resetActionHandlers,
} from "../features/Canvas/functions/textResizer";
import { sanitizeTextObjectVariables } from "../features/Canvas/functions/variableHelpers";

export enum CanvasEvents {
  newObject = "newObject",
  selectObjects = "selectObjects",
  newDoodle = "newDoodle",
  newText = "newText",
  textEdit = "textEdit",
  updateCurrentSelection = "updateCurrentSelection",
}

const useCanvas = () => {
  const initialized = useRef(false);
  const isEditingText = useRef(false);
  const isEditingImageFrame = useRef(false);

  const imageFrameGroup = useRef<Group>();
  const { updateHistory, destroyPenToolHistory } = useHistory();

  const selectedPage = useContext(SelectedPageContext);
  const pages = useContext(PagesContext);
  const currentRenderedPage = useRef(selectedPage);
  const canvas = useContext(CanvasContext);
  const guides = useContext(GuidesContext);
  // const penTool = useRef<PenTool>();

  const { createLayer } = useLayers();
  const {
    toolSettings,
    currentTool: toolState,
    layers,
  } = useDesignerSelector((state) => ({
    designInformation: state.designInformation,
    currentTool: state.toolSettings.currentTool,
    toolSettings: state.toolSettings,
    layers: state.layers,
  }));
  const currentToolRef = useRef(toolState);

  const currentTool = currentToolRef.current;

  currentToolRef.current = currentTool;
  const dispatch = useDesignerDispatch();
  //usePenTool(toolState, penTool.current);
  function initializeCanvasHook() {
    if (canvas) {
      /**
       * We need to re-initialize all of the event listeners when the state of the app changes, otherwise we are working with old Scoped data. So we turn all of the even listeners off to prevent duplicating listeners.
       */

      // canvas.off("mouse:down");
      // canvas.off("mouse:up");
      // canvas.off("mouse:move");

      /**
       * Then we turn them back on.
       */

      // canvas.on("mouse:down", handleMouseDown);
      // canvas.on("mouse:up", handleMouseUp);
      // canvas.on("mouse:move", handleMouseMove);

      // need to ts-ignore the following because they aren't typed in the library, but they do exist

      // // @ts-ignore
      // canvas.off("text:editing:entered", handleTextEditStart);

      // // @ts-ignore
      // canvas.off("text:editing:exited", handleTextEditEnd);

      // // @ts-ignore
      // canvas.off("object:modified", handleObjectModified);

      // // @ts-ignore
      // canvas.off("object:removed", handleObjectRemoved);

      // // @ts-ignore
      // canvas.off("object:scaling", handleObjectScaling);

      // // @ts-ignore
      // canvas.off("selection:created", handleSelectLayers);
      // // @ts-ignore
      // canvas.off("selection:updated", handleSelectLayers);
      // // @ts-ignore
      // canvas.off("selection:cleared", handleSelectLayers);

      if (!initialized.current) {
        // @ts-ignore
        canvas.on("text:editing:entered", handleTextEditStart);
        // @ts-ignore
        canvas.on("text:editing:exited", handleTextEditEnd);
        canvas.on("object:modified", handleObjectModified);
        //canvas.on("object:removed", handleObjectRemoved);
        canvas.on("object:scaling", handleObjectScaling);
        canvas.on("selection:created", handleSelectLayers);
        canvas.on("selection:updated", handleSelectLayers);
        canvas.on("selection:cleared", handleSelectLayers);
        canvas.altSelectionKey = "altKey";
        //canvas.on("mouse:dblclick", handleImageFrameSelect);
        //canvas.on("object:moving", handleImageFrameMove);
        // lock down the background layers
        canvas._objects.forEach((x) => {
          if (x.name === "background") x.set("selectable", false);
          if (x.name === "overlay") x.set("selectable", false);
          if (x.name === "bleed") x.set("selectable", false);
        });
        canvas.renderAll();
        // penTool.current = new PenTool(canvas, onPathCompleted);
        initialized.current = true;
      }
    }
  }

  function handleImageFrameSelect(e: IEvent<MouseEvent>) {
    if (e.target && e.target.canvas && e.target.name?.includes("image")) {
      const canvas = e.target.canvas;
      const group = e.target as Group;
      const mask = group._objects.find((x) => x.type !== "image");
      const image = group._objects.find((x) => x.type === "image");
      if (!mask || !image) return;
      isEditingImageFrame.current = true;
      group.on("moving", handleImageFrameMove);
    }
  }

  function handleImageFrameMove(e: IEvent) {
    const target = e.transform?.target as Group;
    if (!e.transform || !e.pointer || !target || target.type !== "group")
      return;
    const mask = target._objects.find((x) => x.type !== "image");
    const image = target._objects.find((x) => x.type === "image") as
      | IGroupedImage
      | undefined;
    if (
      !mask ||
      !image ||
      target.left === undefined ||
      image.left === undefined ||
      image.top === undefined ||
      target.top === undefined ||
      !image.width ||
      !image.height
    )
      return;
    if (isEditingImageFrame.current) {
      if (!image._absoluteLeft) image._absoluteLeft = e.transform.original.left;
      if (!image._absoluteTop) image._absoluteTop = e.transform.original.top;
      if (!image._left) image._left = image.left;
      if (!image._top) image._top = image.top;
      const moveX =
        -(target.left - (image._absoluteLeft - image._left)) - image.width / 2;
      const moveY =
        -(target.top - (image._absoluteTop - image._top)) - image.height / 2;

      image.left += moveX;
      image.top += moveY;
      image._left = image.left;
      image._top = image.top;
    } else {
      image._absoluteLeft = target.left + (image._left ?? 0) + image.width / 2;
      image._absoluteTop = target.top + (image._top ?? 0) + image.height / 2;
    }
    if (!image.clipPath) {
      image.clipPath = new fabric.Rect({
        width: target.width,
        height: target.height,
        absolutePositioned: true,
      });
    }
    image.clipPath.left = target.left;
    image.clipPath.top = target.top;

    image.canvas?.renderAll();
  }

  function onPathCompleted(obj: Path, length: number) {
    if (!canvas) return;
    obj.clipPath = createBleedClipPath(canvas);
    destroyPenToolHistory(length);
    createLayer(obj.name ?? "");
    dispatch(setCurrentTool(Tool.select));
    canvas.renderAll();
  }

  // function handleObjectRemoved() {
  //   if (!canvas) return;

  //   if (penTool.current?.isOpen) return;
  //   updateHistory(canvas.getObjects());
  // }
  function handleObjectScaling(e: IEvent<MouseEvent>) {
    if (e.target && e.target.type === "polygon") {
      const shape = (e.target.name ?? "0-0").split("-")[0];
      switch (shape) {
        case "ribbon": {
          ribbonScaling(e.target as Polygon);
          break;
        }
        default: {
        }
      }
    }
    if (isShape(e.target) && e.transform && e.target?.type === "rect") {
      const scaleX = e.transform.target.scaleX;
      const scaleY = e.transform.target.scaleY;
      const shape = e.target as Rect;

      if (shape.rx || shape.ry) {
        // @ts-ignore
        if (!shape.cornerRadius) {
          // @ts-ignore
          shape.set("cornerRadius", shape.rx);
        }
        //@ts-ignore
        const cornerRadius: number = shape.cornerRadius;
        const rx = shape.rx ?? 0;
        const ry = shape.ry ?? 0;

        if (scaleX) {
          shape.rx = cornerRadius * (1 / scaleX);
        }
        if (scaleY) {
          shape.ry = cornerRadius * (1 / scaleY);
        }
        canvas?.renderAll();
      }
    }
  }

  function handleObjectModified(e: IEvent<MouseEvent>) {
    if (!canvas) return;

    // if (e.target && e.target.type === "textbox") {
    //   const text = e.target as Textbox;
    //   const scaleX = text.scaleX ?? 1;
    //   const scaleY = text.scaleY ?? 1;
    //   const fontSize = text.fontSize ?? 1;
    //   text.fontSize = fontSize * scaleY;
    //   text.scaleY = 1;
    //   text.scaleX = 1;
    // }

    updateHistory(canvas.getObjects(), "object modified");
  }

  function handleBrushChanges() {
    if (canvas) {
      const brush = canvas.freeDrawingBrush;
      brush.color = toolSettings.stroke ?? "#000000";
      brush.width = toolSettings.strokeWidth;
    }
  }

  function handleTextEditStart(e: IEvent<MouseEvent>) {
    const text = e.target as IText;
    isEditingText.current = true;

    dispatch(setCurrentTool(Tool.select));
  }

  function handleTextEditEnd(e: IEvent<MouseEvent>) {
    if (!canvas) return;
    const text = e.target as Textbox;

    sanitizeTextObjectVariables(text);
    isEditingText.current = false;

    updateHistory(canvas.getObjects(), "finished editing text");
  }

  function handleSelectLayers(e: IEvent<Event>) {
    if (canvas && !canvas.selection) return;
    isEditingImageFrame.current = false;
    if (currentToolRef.current !== Tool.select)
      dispatch(setCurrentTool(Tool.select));

    const selectedLayers = [...layers.selectedLayers];
    const layerIds = selectedLayers.map((x) => x.index);
    if (e.selected) {
      e.selected.forEach((selected) => {
        if (selected.name && !layerIds.includes(selected.name)) {
          selectedLayers.push({ page: selectedPage, index: selected.name });
        }
      });
    }
    if (e.deselected) {
      e.deselected.forEach((deselected) => {
        const index = selectedLayers.findIndex(
          (x) => x.index === deselected.name
        );
        if (index !== -1) selectedLayers.splice(index, 1);
      });
    }
    const activeObject = canvas?._activeObject as Group | IObject | undefined;

    if (activeObject) {
      const objects = (activeObject as Group)._objects;
      if (
        !activeObject.name &&
        objects &&
        objects.length !== selectedLayers.length
      ) {
        let ids = selectedLayers.map((x) => x.index);
        objects.forEach((obj) => {
          if (obj.name && !ids.includes(obj.name)) {
            selectedLayers.push({ page: selectedPage, index: obj.name });
          }
        });
        const names = objects.map((obj) => obj.name);
        ids = selectedLayers.map((x) => x.index);
        ids.forEach((id, index) => {
          if (!names.includes(id)) {
            selectedLayers.splice(index, 1);
          }
        });
      }

      dispatch(updateSelectedLayers(selectedLayers));
    } else {
      dispatch(updateSelectedLayers([]));
    }
  }

  // function handleChangePages() {
  //   if (!canvas || !initialized.current) return;

  //   if (selectedPage !== currentRenderedPage.current) {
  //     canvas.remove(...canvas.getObjects());
  //     currentRenderedPage.current = selectedPage;
  //     const page = pages.find((x) => x.name === selectedPage);
  //     if (page) {
  //       canvas.add(...page.objects);
  //       const pageLayers = layers.pageLayers.find(
  //         (x) => x.name === selectedPage
  //       );
  //       if (pageLayers) {
  //         reArrangeObjects(pageLayers.layers, canvas);
  //       }

  //       canvas.renderAll();
  //     }
  //   }
  // }

  useEffect(initializeCanvasHook, [
    canvas,
    currentTool,
    toolSettings,
    layers,
    selectedPage,
  ]);

  useEffect(handleBrushChanges, [toolSettings]);
  //useEffect(handleTextToolChange, [toolSettings]);
  //useEffect(handleChangePages, [selectedPage]);
};

interface IGroupedImage extends Image {
  _left?: number;
  _top?: number;
  _absoluteLeft?: number;
  _absoluteTop?: number;
}

export default useCanvas;
