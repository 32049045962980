import tw from "twin.macro";
interface TailwindLoadingSpinnerProps {
  height: number;
}

const TailwindLoadingSpinner = () => {
  return (
    <div
      tw="inline-block h-16 w-16 animate-spin rounded-full border-solid border-blue border-r-transparent align-[-0.125em]  motion-reduce:animate-[spin_1.5s_linear_infinite]"
      style={{ borderWidth: "6px" }}
    >
      <span tw="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
};

export default TailwindLoadingSpinner;
