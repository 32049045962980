import { useEffect, useState } from "react";
import ColorPicker from "../shared/ColorPicker/ColorPicker";
import IPropertiesProps from "../Properties/IPropertiesProps";
import toolbarStyles from "./toolbarStyles";

import { ReactComponent as StrokeWidthIcon } from "../../../../assets/images/designer-svg/StrokeWidthIcon.svg";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import DuplicateButton from "./DuplicateButton";
import DeleteButton from "./DeleteButton";
import { Group, Image, Rect } from "fabric/fabric-impl";
import { createMask } from "../Properties/ImageProperties";
import { IImageGroup } from "../../../hooks/useImageMasking/useImageMasking";
import { Button } from "@mui/material";
import ToolButton from "../shared/ToolButton";
import Modal, { ModalBody, ModalTitle } from "../shared/Modal";
import React from "react";
import Images from "../Images/Images";
import SwapImage from "../Images/SwapImage";

const ImageProperties = ({ selectedObject }: IPropertiesProps) => {
  const imageGroup = selectedObject as IImageGroup;
  const [imageSwapOpen, setImageSwapOpen] = useState(false);
  const strokeObject =
    selectedObject.type === "group"
      ? (selectedObject as Group)._objects.find((x) => x.type !== "image")
      : undefined;

  const [strokeWidth, setStrokeWidth] = useState(
    strokeObject?.strokeWidth ?? 0
  );

  function handleStrokeWidthChange(property: string, value: number) {
    if (selectedObject.type !== "group") return;
    const image = (selectedObject as Group)._objects.find(
      (x) => x.type === "image"
    );

    if (!strokeObject || !image) return;

    setStrokeWidth(value);
    imageGroup.__updateFrameDimensions({ strokeWidth: value });
    selectedObject.canvas?.renderAll();
    //updateImage(selectedObject as Group, strokeObject as Rect, image as Image);
    selectedObject.canvas?.renderAll();
    selectedObject.canvas?.fire("object:modified");
  }

  function updateImage(group: Group, frame: Rect, img: Image) {
    img.clipPath = createMask(frame);
    const newWidth = (frame.width ?? 0) + (frame.strokeWidth ?? 0);
    const newHeight = (frame.height ?? 0) + (frame.strokeWidth ?? 0);
    group.set("width", newWidth);
    group.set("height", newHeight);
    frame.set("top", -newHeight / 2);
    frame.set("left", -newWidth / 2);
    img.set("top", -newHeight / 2 + (frame.strokeWidth ?? 0) / 2);
    img.set("left", -newWidth / 2 + (frame.strokeWidth ?? 0) / 2);
  }
  useEffect(() => {
    setStrokeWidth(strokeObject?.strokeWidth ?? 0);
  }, [selectedObject, strokeObject?.strokeWidth]);
  if (!strokeObject) return null;
  return (
    <React.Fragment>
      <div>
        <div css={toolbarStyles.section}>
          <div css={toolbarStyles.property}>
            <ColorPicker
              selectedObject={strokeObject}
              type="stroke"
              swatchOnly
              swatchIcon={StrokeWidthIcon}
            />
          </div>
          <div css={toolbarStyles.property}>
            <IncrementInput
              property="strokeWidth"
              value={strokeWidth}
              onChange={handleStrokeWidthChange}
              height={7}
              width={48}
            />
          </div>
          <div css={toolbarStyles.seperator}></div>
          <div css={toolbarStyles.property}>
            <ToolButton
              css={toolbarStyles.button}
              onClick={() => {
                setImageSwapOpen(true);
              }}
            >
              Change Image
            </ToolButton>
          </div>
          <div css={toolbarStyles.property}>
            <DuplicateButton selectedObject={selectedObject} />
          </div>
          <div css={toolbarStyles.property}>
            <DeleteButton selectedObject={selectedObject} />
          </div>
        </div>
      </div>
      <SwapImage open={imageSwapOpen} onClose={() => setImageSwapOpen(false)} />
    </React.Fragment>
  );
};

export default ImageProperties;
