import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  MenuItem,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useState } from "react";
import routes from "./routes";
import BusinessSelector from "../BusinessSelector";
import getDocsUrl from "../../../../helpers/getDocsUrl";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box component="nav">
      <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
        <IconButton
          size="large"
          aria-label="primary-navigation"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => setIsOpen(true)}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Drawer onClose={() => setIsOpen(false)} open={isOpen} anchor="left">
          <List>
            <MenuItem>
              <BusinessSelector />
            </MenuItem>
            <MenuItem component="a" href={getDocsUrl()} target="_blank">
              Documentation
            </MenuItem>
            {routes.map((route) => {
              if (route.children) {
                return route.children.map((child) => (
                  <MenuItem
                    component={Link}
                    to={child.path}
                    key={child.path}
                    onClick={() => setIsOpen(false)}
                    color="#212121"
                  >
                    {child.label}
                  </MenuItem>
                ));
              }
              if (route.path.startsWith("http")) {
                return (
                  <MenuItem
                    href={route.path}
                    target="_blank"
                    component="a"
                    key={route.path}
                    onClick={() => setIsOpen(false)}
                    color="#212121"
                  >
                    {route.label}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  component={Link}
                  to={route.path}
                  key={route.path}
                  onClick={() => setIsOpen(false)}
                  color="#212121"
                >
                  {route.label}
                </MenuItem>
              );
            })}
            <MenuItem component={Link} to="/contact-us">
              Contact Us
            </MenuItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
