import { Image } from "fabric/fabric-impl";
import { fabric } from "fabric";

export default async function asyncImageFromUrl(url: string) {
  return new Promise<Image>((resolve) => {
    fabric.Image.fromURL(url, (image) => {
      resolve(image);
    });
  });
}
