import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { PricingWithDescription } from "../../../Integrated/DesignGallery/models/pricing";

interface PricingPlanProps {
  pricingPlan: PricingWithDescription[];
}

const PricingPlan: FC<PricingPlanProps> = ({ pricingPlan }) => {
  /**
   * State Objects and Refs
   */

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <React.Fragment>
      <Paper>
        <TableContainer sx={{ maxHeight: "225px" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>Mail Class</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Envelope Type</TableCell>
                <TableCell>Price Per Piece</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricingPlan
                .filter((x) => x.sizeInfo != null)
                .map((price) => (
                  <TableRow
                    key={price.inventoryCD + price.breakQty + price.price}
                  >
                    <TableCell>{price.sizeInfo?.sizeLabel}</TableCell>
                    <TableCell>
                      {price.mailClass}
                      {price.mailClass === "ResOcc" ? "*" : ""}
                    </TableCell>
                    <TableCell>{price.description}</TableCell>
                    <TableCell>{price.envelope || "-"}</TableCell>
                    <TableCell>${price.price.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ mt: 2, fontSize: "14px" }}>
        <em>
          * ResOcc mail class only applies when ordering with a list count with
          the type &quot;IRL&quot;.{" "}
          {/* <a
            href="https://docs.pcmintegrations.com/docs/directmail-api/65o0jh0i3n7in-generating-a-list-count-by-carrier-route"
            target="_blank"
            rel="noreferrer"
          >
            Click here to learn more
          </a>. */}
        </em>
      </Box>
    </React.Fragment>
  );
};

export default PricingPlan;
