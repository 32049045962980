import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { DesignField } from "../../models/Design";

interface DesignVariablesTableProps {
  designFields: DesignField[];
}

const DesignVariablesTable: React.FC<DesignVariablesTableProps> = ({
  designFields,
}) => {
  /**
   * State Objects and Refs
   */

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  const hasFieldOptions = Boolean(
    designFields.find((x) => x.designFieldOptions.length > 0)
  );

  return (
    <Box sx={{ border: "solid 1px rgba(0,0,0,.125)", borderRadius: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Field Type</TableCell>
            {hasFieldOptions && (
              <TableCell style={{ maxWidth: "150px" }}>Field Options</TableCell>
            )}
            <TableCell>Required</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {designFields.map((field) => (
            <TableRow key={field.listFieldKey}>
              <TableCell>{field.listFieldKey}</TableCell>
              <TableCell>{field.fieldType}</TableCell>
              {hasFieldOptions && (
                <TableCell style={{ maxWidth: "150px" }}>
                  {field.designFieldOptions?.length > 0 &&
                    field.designFieldOptions.map((option, index) => (
                      <span key={index}>
                        {index === field.designFieldOptions.length - 1
                          ? option.value
                          : option.value + ", "}
                      </span>
                    ))}
                </TableCell>
              )}
              <TableCell>{field.mandatory.toString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DesignVariablesTable;
