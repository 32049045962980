/* eslint-disable */
import { useEffect } from "react";

export default function LiveChatAndTagManager() {
  useEffect(() => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // @ts-ignore
      dataLayer.push(arguments);
    }
    // @ts-ignore
    gtag("js", new Date());
    // @ts-ignore
    gtag("config", "G-0ZJPKHX2P1");

    (function (d, src, c) {
      var t = d.scripts[d.scripts.length - 1],
        s = d.createElement("script");
      s.id = "la_x2s6df8d";
      s.async = true;
      s.src = src;
      // @ts-ignore
      s.onload = s.onreadystatechange = function () {
        // @ts-ignore
        var rs = this.readyState;
        if (rs && rs != "complete" && rs != "loaded") {
          return;
        }
        c(this);
      };
      // @ts-ignore
      t.parentElement.insertBefore(s, t.nextSibling);
    })(
      document,
      "https://mydirectmailportal.ladesk.com/scripts/track.js",
      // @ts-ignore
      function (e) {
        // @ts-ignore
        LiveAgent.createButton("7hxfix5l", e);
      },
    );
  }, []);

  return (
    <div>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-0ZJPKHX2P1"
      ></script>
    </div>
  );
}
