import { Box, SxProps } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Document, Page, pdfjs } from "react-pdf";

import { useSize } from "../../hooks/useSize";
import LoadingWrapper from "../LoadingWrapper";

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallbackImageSrc?: string;
  _makeThisImageNonResponsive?: boolean;
  showLoadingScreen?: boolean;
  width?: number;
  maxWidth?: number;
  canZoom?: boolean;
  sx?: SxProps;
  log?: boolean;
}

/**
 * Img will display a fallback automatically if the image can't be loaded. This component can also render a PDF as an image.
 * @param props
 * @returns
 */

const Img: React.FC<ImgProps> = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    src,
    _makeThisImageNonResponsive,
    showLoadingScreen,
    canZoom,
    sx,
    maxWidth,
    width: widthProp,
    log,
    ...rest
  } = props;

  const boxRef = useRef<HTMLDivElement>(null);
  const size = useSize(boxRef);
  useEffect(() => {
    setBoxWidth(getWidth());
    setBoxHeight(getHeight());
  }, [size]);

  let fallBackImageSrc = props.fallbackImageSrc;
  if (!fallBackImageSrc) fallBackImageSrc = "/assets/img/no-preview.png";

  let style: React.CSSProperties = _makeThisImageNonResponsive
    ? {}
    : {
        width: props.width ? `${props.width}px` : "100%",
        height: "100%",
        maxWidth: props.maxWidth ? `${props.maxWidth}px` : "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      };
  if (props.style) {
    style = { ...style, ...props.style };
  }

  const [urlSrc, setUrlSrc] = useState(src ? src : fallBackImageSrc);
  const [hasError, setHasError] = useState(false);

  const [boxWidth, setBoxWidth] = useState(props.width ?? 0);
  const [boxHeight, setBoxHeight] = useState(0);

  const [loaded, setLoaded] = useState(false);
  const isPDF = urlSrc?.endsWith(".pdf");

  function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (!hasError) {
      if (!urlSrc.endsWith(".pdf")) {
        setUrlSrc(fallBackImageSrc as string);
      }
      setHasError(true);
    }
    if (props.onError) {
      props.onError(e);
    }
  }

  function handleLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (!loaded) {
      setLoaded(true);
    }
    if (props.onLoad) {
      props.onLoad(e);
    }
  }

  function handlePDFLoad() {
    if (!loaded) {
      setLoaded(true);
    }
  }

  function handlePDFError() {
    if (!hasError) {
      setUrlSrc(fallBackImageSrc as string);
      setHasError(true);
    }
  }

  function getHeight() {
    if (boxRef.current) {
      console.log("boxRef.current.offsetHeight", boxRef.current.offsetWidth);
      return boxRef.current.offsetWidth * (9 / 16);
    }
    return 100;
  }

  function getWidth() {
    if (boxRef.current) {
      let width = 0;
      width =
        boxRef.current.clientWidth ??
        boxRef.current.parentElement?.clientWidth ??
        0;
      if (maxWidth && width > maxWidth) {
        width = maxWidth;
      }
      return width;
    }
    return 0;
  }

  function checkForImageChange() {
    if ((loaded && src !== urlSrc) || (hasError && src !== urlSrc)) {
      setLoaded(false);
      setHasError(false);
      setUrlSrc(src ? src : "");
    }
  }

  function render() {
    return (
      <React.Fragment>
        {!loaded && showLoadingScreen && (
          <LoadingWrapper height={getHeight()} loading={!loaded}>
            <div />
          </LoadingWrapper>
        )}
        {isPDF && boxWidth !== 0 ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              margin: "auto",
              border: "1px solid #e0e0e0",
              width: Math.max(104 * 0.77, boxHeight * 0.77) + 2,
            }}
          >
            <Document
              file={urlSrc}
              {...rest}
              onLoadSuccess={handlePDFLoad}
              onLoadError={handlePDFError}
              loading={<span />}
              renderMode="canvas"
            >
              <Page
                pageNumber={1}
                renderMode="canvas"
                // height={boxHeight}
                width={Math.max(104 * 0.77, boxHeight * 0.77)}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
        ) : (
          <img
            {...rest}
            onLoad={handleLoad}
            onError={handleError}
            src={urlSrc}
            style={{ width: "100%", maxWidth: "100%" }}
          />
        )}
      </React.Fragment>
    );
  }

  useEffect(checkForImageChange, [src]);

  useEffect(() => {
    setBoxWidth(getWidth());
    setBoxHeight(getHeight());
  }, [boxRef.current]);

  if (props.canZoom) {
    return (
      <Box className="aslkdjasldk" ref={boxRef} sx={isPDF ? style : undefined}>
        <Zoom>{render()}</Zoom>
      </Box>
    );
  }

  return (
    <Box
      className="aslkdjasldk"
      ref={boxRef}
      sx={sx ? sx : isPDF ? style : style}
    >
      {render()}
    </Box>
  );
};

export default Img;
