import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { FC } from "react";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import AccountLinks from "./AccountLinks";

interface AccountWrapperProps {
  pageTitle?: string;
  loading: boolean;
  loadError?: boolean;
}

const AccountWrapper: FC<AccountWrapperProps> = ({
  pageTitle,
  children,
  loading,
  loadError,
}) => {
  /**
   * State Objects and Refs
   */

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Box sx={Boolean(pageTitle) ? {} : { mt: 3 }}>
      {Boolean(pageTitle) && (
        <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
          {pageTitle}
        </Typography>
      )}

        <LoadingWrapper loading={loading} message="Loading" height={400}>
            {children}
        </LoadingWrapper>
    </Box>
  );
};

export default AccountWrapper;
