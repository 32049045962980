import json2csv from "json2csv";

export default function exportCsv(data: Readonly<unknown>, filename: string) {
  const parser = new json2csv.Parser();
  const csv = parser.parse(data);
  if (!Boolean(csv)) {
    return;
  }
  const blob = new Blob([csv], { type: "text/csv" });
  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute("download", filename + ".csv");
  link.click();
}
