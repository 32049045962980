import { Canvas, Image, Object as IObject } from "fabric/fabric-impl";
import { fabric } from "fabric";

export default async function asyncLoadSvgFromString(svg: string) {
  return new Promise<IObject>((resolve) => {
    fabric.loadSVGFromString(svg, (objs) => {
      const obj = fabric.util.groupSVGElements(objs);
      resolve(obj);
    });
  });
}
