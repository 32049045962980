import { fabric } from "fabric";

/**
 * Method that determines what object we are clicking on
 * the skipGroup parameter is for internal use, is needed for shift+click action
 * 11/09/2018 TODO: would be cool if findTarget could discern between being a full target
 * or the outside part of the corner.
 * @param {Event} e mouse event
 * @param {Boolean} skipGroup when true, activeGroup is skipped and only objects are traversed through
 * @return {fabric.Object} the target found
 */

var getPointer = fabric.util.getPointer,
  degreesToRadians = fabric.util.degreesToRadians,
  isTouchEvent = fabric.util.isTouchEvent;

export default function findTarget(e, skipGroup) {
  if (this.skipTargetFind) {
    return;
  }

  var ignoreZoom = true,
      pointer = this.getPointer(e, ignoreZoom),
      activeObject = this._activeObject,
      aObjects = this.getActiveObjects(),
      activeTarget, activeTargetSubs,
      isTouch = isTouchEvent(e),
      shouldLookForActive = (aObjects.length > 1 && !skipGroup) || aObjects.length === 1;

  // first check current group (if one exists)
  // active group does not check sub targets like normal groups.
  // if active group just exits.
  this.targets = [];

  // if we hit the corner of an activeObject, let's return that.
  if (shouldLookForActive && activeObject._findTargetCorner(pointer, isTouch)) {
    return activeObject;
  }
  if (aObjects.length > 1 && !skipGroup && activeObject === this._searchPossibleTargets([activeObject], pointer)) {
    return activeObject;
  }
  if (aObjects.length === 1 &&
    activeObject === this._searchPossibleTargets([activeObject], pointer)) {
    if (!this.preserveObjectStacking) {
      return activeObject;
    }
    else {
      activeTarget = activeObject;
      activeTargetSubs = this.targets;
      this.targets = [];
    }
  }
  var target = this._searchPossibleTargets(this._objects, pointer);
  if (e[this.altSelectionKey] && target && activeTarget && target !== activeTarget && (target.name && !target.name.includes("PathControl-point"))) {
    target = activeTarget;
    this.targets = activeTargetSubs;
  }
  return target;
}



