import { GridFilterModel, GridSortItem, GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import IOrder, { IOrderDTO } from "../../data/models/IOrder";
import { getAsync } from "../../helpers/asyncFetch";
import { fetchClient } from "../../helpers/fetchClient";
import BatchOrder from "../../routes/Batch/models/BatchOrder";

interface IOrderResponse {
  data: IOrder[];
  totalPages: number;
  totalOrders: number;
}

export type Filter =
  | "all"
  | "active"
  | "canceled"
  | "delivered"
  | "undeliverable"
  | "pending"
  | "processed"
  | "processing"
  | "mailing";

export default function usePaginatedOrders(
  designId?: number,
  isSandbox = false
) {
  const [orders, setOrders] = useState<IOrder[]>([]);

  const [page, setPage] = useState(0);
  const skipCount = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchDebounceRef = useRef<NodeJS.Timeout>();
  const [totalPages, setTotalPages] = useState(-1);
  const [totalItems, setTotalItems] = useState(0);
  const [numItems, setNumItems] = useState(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      sort: "desc",
      field: "orderID",
    },
  ]);
  const [status, setStatus] = useState<Filter>("all");
  const [filter, setFilter] = useState<GridFilterModel>({
    items: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  async function getData() {
    let filters = "";
    if (
      filter.items.length &&
      filter.items.filter(
        (x) =>
          x.operatorValue?.toLowerCase().includes("empty") || Boolean(x.value)
      ).length
    ) {
      filters = filter.items
        .filter(
          (x) =>
            x.operatorValue?.toLowerCase().includes("empty") || Boolean(x.value)
        )
        .map((f) =>
          `${f.columnField}|${f.value}|${
            f.operatorValue !== undefined
              ? encodeURIComponent(f.operatorValue)
              : ""
          }`.trim()
        )
        .join(",");
    }

    setIsLoading(true);
    const url = `/orders?position=${
      page * numItems
    }&items=${numItems}&skipTotal=${skipCount.current}&field=${
      sortModel[0].field
    }&sort=${
      sortModel[0].sort
    }&filters=${filters}&search=${searchTerm}&status=${status}${
      designId !== undefined ? `&designID=${designId}` : ""
    }&isSandbox=${isSandbox}`;

    const data = await getAsync<IOrderResponse>(url);
    if (data) {
      if (!skipCount.current) {
        skipCount.current = true;
        setTotalPages(data.totalPages);
        setTotalItems(data.totalOrders);
      }

      setOrders([...data.data]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    if (totalPages !== -1) {
      if (page === 0) getData();
      else setPage(0);
    }
  }, [numItems, sortModel]);

  useEffect(() => {
    if (totalPages !== -1) {
      skipCount.current = false;
      if (page === 0) getData();
      else setPage(0);
    }
  }, [status]);

  useEffect(() => {
    if (totalPages !== -1) {
      if (
        filter.items &&
        (filter.items.length === 0 ||
          filter.items.filter(
            (x) =>
              x.operatorValue?.toLowerCase().includes("empty") ||
              Boolean(x.value)
          ).length)
      ) {
        skipCount.current = false;

        if (page === 0) getData();
        else setPage(0);
      }
    }
  }, [filter]);

  function nextPage(newPage: number) {
    setPage(newPage);
  }

  function changeRowsPerPage(newRows: number) {
    setNumItems(newRows);
  }

  function sort(sortChange: GridSortModel, b: unknown) {
    if (sortChange.length) {
      setSortModel(sortChange);
    }
  }

  function changeFilter(newFilter: GridFilterModel) {
    if (newFilter.items && newFilter.items.length) {
      setFilter(newFilter);
      return;
    }
    if (filter.items.length) {
      setFilter({ items: [] });
    }
  }

  function changeStatus(newStatus: Filter) {
    setStatus(newStatus);
  }

  return {
    orders,
    isLoading,
    page,
    itemsPerPage: numItems,
    totalItems,
    totalPages,
    sortModel,
    filter,
    status,
    handleChangeStatus: changeStatus,
    handleNewPage: nextPage,
    handleChangeRowsPerPage: changeRowsPerPage,
    handleSort: sort,
    handleFilter: changeFilter,
  };

  // return [orders, isLoading, getData, updateOrders];
}
