import { createPortal } from "react-dom";
import tw, { css } from "twin.macro";
import useClickOutside from "../../../../hooks/useClickOutside";
import useLockBodyScroll from "../../../../hooks/useLockBodyScroll";

interface IModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactFragment;
  width?: number;
}

const styles = {
  modalWrap: [
    css`
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 1300;
    `,
    tw`flex flex-col items-center justify-center`,
  ],
  modal: (width?: number) => [
    tw` bg-white rounded`,
    !width &&
      css`
        max-width: 100%;
      `,
    width &&
      css({
        maxWidth: `${width}px`,
        width: `100%`,
      }),
  ],
  modalTitle: [tw`p-4`],
  modalBody: [tw`p-4`],
  modalActions: [tw`p-4 flex items-center `],
};

const Modal = ({ isOpen, onClose, children, width }: IModalProps) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;
  return createPortal(
    <div css={styles.modalWrap} onClick={onClose ? onClose : () => null}>
      <div
        css={styles.modal(width)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

interface IModalElementProps {
  children: React.ReactFragment;
}
export const ModalTitle = ({ children }: IModalElementProps) => {
  return <div css={styles.modalTitle}>{children}</div>;
};

export const ModalBody = ({ children }: IModalElementProps) => {
  return <div css={styles.modalBody}>{children}</div>;
};

export const ModalActions = ({ children }: IModalElementProps) => {
  return <div css={styles.modalActions}>{children}</div>;
};

export default Modal;

/**
 * <ButtonReset
          onClick={() => onAddText(text, hasWidth ? width : undefined)}
        >
          Insert Text
        </ButtonReset>{" "}
        <ButtonReset onClick={onClose}>Cancel</ButtonReset>
 */
