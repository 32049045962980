import { Group, IText, Object as IObject, Path } from "fabric/fabric-impl";
import React, { createContext, useReducer } from "react";

export type CanvasObject = IObject | IText | Path;

export interface IPage {
  name: string;
  objects: CanvasObject[];
}

export const PagesContext = createContext<IPage[]>([]);
export enum PageActions {
  updatePage = "updatePage",
  setPages = "setPages",
}
export const PagesDispatchContext = createContext<
  React.Dispatch<{
    type: string;
    payload: IPage[] | IPage;
  }>
>(
  {} as React.Dispatch<{
    type: string;
    payload: IPage[] | IPage;
  }>
);
const intialState: IPage[] = [];

function pagesReducer(
  state: IPage[],
  action: { type: string; payload: IPage | IPage[] }
) {
  switch (action.type) {
    case PageActions.updatePage: {
      const payload = action.payload as IPage;
      const pageIndex = state.findIndex((x) => x.name === payload.name);
      if (pageIndex === -1) return state;
      state[pageIndex] = { ...payload };

      return [...state];
    }
    default:
      return action.payload as IPage[];
  }
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const PagesProvider = ({ children }: IProviderProps) => {
  const [pages, dispatch] = useReducer(pagesReducer, intialState);

  return (
    <PagesContext.Provider value={pages}>
      <PagesDispatchContext.Provider value={dispatch}>
        {children}
      </PagesDispatchContext.Provider>
    </PagesContext.Provider>
  );
};

export default PagesProvider;
