import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import toolbarStyles from "./toolbarStyles";
import {
  LetterFoldTypes,
  selectLetterSettings,
  setFoldType,
} from "../../../state/slices/letterSettings";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import { SelectOption } from "../shared/DesignerInput/SelectOption";
import { DesignerSelect } from "../shared/NuDesignerInputs/DesignerSelect";
import {
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../../../state/contexts/PagesContext";
import { SelectedPageContext } from "../../../state/contexts/SelectedPageContext";
import { CanvasContext } from "../../../state/contexts/CanvasContext";

import getBrochureOverlay from "../../Canvas/functions/getBrochureOverlay";

const BrochureProperties = () => {
  const letterProperties = useDesignerSelector(selectLetterSettings);

  const dispatch = useDesignerDispatch();
  const pages = useContext(PagesContext);
  const pagesDispatch = useContext(PagesDispatchContext);
  const selectedPage = useContext(SelectedPageContext);
  const canvas = useContext(CanvasContext);
  async function handleFoldType(name: string, value: string) {
    if (!canvas) return;
    if (value === letterProperties.foldType) return;
    const newOverlay = await getBrochureOverlay(
      value as LetterFoldTypes,
      canvas,
      true
    );

    const newPages = pages.map((x) => ({
      ...x,
      objects:
        x.name === "Front"
          ? [
              // @ts-ignore
              ...x.objects.filter((x) => !x.__addressBlock && !x.__foldLine),
              ...newOverlay,
            ]
          : [
              //@ts-ignore
              ...x.objects.filter((x) => !x.__foldLine),
              //@ts-ignore
              ...newOverlay.filter((x) => !x.__addressBlock),
            ],
    }));
    pagesDispatch({ type: PageActions.setPages, payload: newPages });
    const currentRemovals = canvas.getObjects().filter(
      (x) =>
        x.name === "overlay" &&
        //@ts-ignore
        (x.__addressBlock || x.__foldLine)
    );
    if (currentRemovals.length) {
      canvas.remove(...currentRemovals);
      if (selectedPage === "Front") {
        canvas.add(...newOverlay);
      } else {
        // @ts-ignore
        canvas.add(...newOverlay.filter((x) => !x.__addressBlock));
      }
    }
    dispatch(setFoldType(value as LetterFoldTypes));
  }

  return (
    <div css={toolbarStyles.section}>
      <span css={toolbarStyles.propertyLabel}>Fold Type: </span>
      <div>
        <DesignerSelect
          value={letterProperties.foldType ?? LetterFoldTypes.TRIFOLD}
          onChange={handleFoldType}
          name={"foldType"}
          width={200}
        >
          <SelectOption value={LetterFoldTypes.TRIFOLD}>Tri-Fold</SelectOption>
          <SelectOption value={LetterFoldTypes.BIFOLD}>Bi-Fold</SelectOption>
        </DesignerSelect>
      </div>
    </div>
  );
};

export default BrochureProperties;
