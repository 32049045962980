import { Path } from "fabric";
import { IPathPoint } from "fabric";

export default function findNewPointIndex(points: IPathPoint[], path: Path) {
  const coords = getIntersectionCoordsFromPath(path.path);
  const path1Index = points.findIndex(
    (x) => x.x === coords.point1.x && x.y === coords.point1.y
  );
  if (path1Index === -1) return -1;
  const path2Index = points.findIndex(
    (x) => x.x === coords.point2.x && x.y === coords.point2.y
  );
  if (path2Index === -1) return -1;
  return Math.max(path1Index, path2Index);
}

function getIntersectionCoordsFromPath(path: (string | number)[][]) {
  const command1 = path[0];
  const point1 = { x: command1[1] as number, y: command1[2] as number };

  const command2 = path[1];
  if (command2[0] === "L") {
    const point2 = { x: command2[1] as number, y: command2[2] as number };
    return {
      point1,
      point2,
    };
  }
  if (command2[0] === "Q") {
    const point2 = { x: command2[3] as number, y: command2[4] as number };
    return {
      point1,
      point2,
    };
  }
  if (command2[0] === "C") {
    const point2 = { x: command2[5] as number, y: command2[6] as number };
    return {
      point1,
      point2,
    };
  }
  return {
    point1,
    point2: { x: 0, y: 0 },
  };
}
