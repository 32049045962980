import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFont } from "../../features/Fonts/googleFontsService/types";

import { DesignerState } from "../store";

interface IFontState {
  loaded: boolean;
  fonts: IFont[];
}

const initialState: IFontState = {
  loaded: false,
  fonts: [],
};

const fontManagerSlice = createSlice({
  name: "fontManager",
  initialState,
  reducers: {
    initializeFonts: (state, action: PayloadAction<IFont[]>) => {
      state.loaded = true;
      state.fonts = action.payload;
    },
    loadFont: (state, action: PayloadAction<IFont>) => {
      const index = state.fonts.findIndex(
        (x) => x.family === action.payload.family
      );
      if (index !== -1) {
        const update = [...state.fonts];
        update[index] = { ...action.payload };
        state.fonts = update;
      }
    },
  },
});

export const { initializeFonts, loadFont } = fontManagerSlice.actions;
export const selectFontManager = (state: DesignerState) => state.fontManager;
export default fontManagerSlice.reducer;
