import { IPage } from "../../../state/contexts/PagesContext";

export default function getQrCodeId(pages: IPage[]) {
  let qrCodeId: number | null = null;
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    for (let j = 0; j < page.objects.length; j++) {
      const obj = page.objects[j];
      if (obj.name?.includes("qrcode")) {
        // @ts-ignore
        qrCodeId = obj.__qrCodeId;
        break;
      }
    }
    if (qrCodeId) break;
  }
  return qrCodeId;
}
