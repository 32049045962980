import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";

import theme from "../helpers/theme";

const useStyles = makeStyles((th) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: theme.palette.grey[500],
    opacity: ".5",
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export interface LoadingButtonProps extends ButtonProps {
  success?: boolean;
  loading: boolean;
}

export default (props: LoadingButtonProps) => {
  const { loading, disabled, success, ...rest } = props;

  const classes = useStyles();

  const buttonClassName = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <span className={classes.wrapper}>
      <Button
        variant="contained"
        color="primary"
        className={buttonClassName}
        disabled={loading || disabled || success}
        {...rest}
      >
        {rest.children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {success && (
        <CheckCircleIcon
          sx={{ fontSize: 28 }}
          className={classes.buttonProgress}
          color="primary"
        />
      )}
    </span>
  );
};
