import { GridTypeMap, Grid, GridProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";

const GridRow: FC<GridProps> = (props) => {
  /**
   * State Objects and Refs
   */

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Grid container spacing={props.spacing ? props.spacing : 3} {...props}>
      {props.children}
    </Grid>
  );
};

export default GridRow;
