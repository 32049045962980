import { IText } from "fabric/fabric-impl";
import { Textbox, fabric } from "fabric";
import useTools from "../../../hooks/useTools";
import DesignerButton from "../shared/DesignerButton";
import { useContext } from "react";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import RESOLUTION from "../../../constants/RESOLUTION";
import generateGuid from "../../../../helpers/generateGuid";
import { createBleedClipPath } from "../../Canvas/functions/createBackgroundClipPath";

import tw, { css } from "twin.macro";
import createBulletedList from "../../Canvas/functions/createBulletedList";
import DraggableText from "./DraggableText";

const styles = {
  container: [tw`p-4`],
  button: [tw`w-full rounded font-semibold mb-2 text-base`],
  buttonSecondary: [tw`bg-labels`],
  buttonHeading: [tw`text-xl font-bold capitalize`],
  buttonSubheading: [tw`text-lg capitalize`],
  buttonBody: [tw`font-normal normal-case`],
};

const TextSidebar = () => {
  const canvas = useContext(CanvasContext);
  const { addTextToCanvas, addBulletedListToLayers } = useTools();

  function addText(type: "body" | "heading" | "subheading") {
    if (!canvas) return;
    const background = canvas._objects.find((x) => x.name === "background");

    if (
      !background ||
      background.left === undefined ||
      background.left === null ||
      background.top === undefined ||
      background.top === null
    )
      return;

    const textObj = new fabric.Textbox("Enter your text", {
      left: background.left + background.getScaledWidth() / 3,
      top: background.top + background.getScaledHeight() / 3,
      fontFamily: "Roboto",
      fontSize: 36,
      fontStyle: "normal",
      fontWeight: 400,
      stroke: "#000000",
      strokeWidth: 0,
      fill: "#000000",

      editable: true,
      name: generateGuid(),
      shadow: new fabric.Shadow({}),
      clipPath: createBleedClipPath(canvas),
      strokeUniform: true,
    });
    if (type === "heading") {
      textObj.fontWeight = "bold";
      textObj.fontSize = 66;
    }
    if (type === "subheading") {
      textObj.fontWeight = 500;
      textObj.fontSize = 54;
    }

    addTextToCanvas(textObj as Textbox);
    if (textObj.left && textObj.top) {
      textObj.left -= textObj.width ?? 0;
      textObj.top -= (textObj.height ?? 0) * 2;
    }
    canvas.renderAll();
  }

  async function addBulletedList() {
    if (!canvas) return;
    const list = await createBulletedList(canvas);
    if (!list) return;
    addBulletedListToLayers(list);
  }

  return (
    <div css={styles.container}>
      <DraggableText variant="body" styles={styles.button}>
        Add Text
      </DraggableText>

      <DraggableText
        variant="heading"
        styles={[styles.button, styles.buttonSecondary, styles.buttonHeading]}
      >
        Add A Heading
      </DraggableText>
      <DraggableText
        variant="subheading"
        styles={[
          styles.button,
          styles.buttonSecondary,
          styles.buttonSubheading,
        ]}
      >
        Add A Subheading
      </DraggableText>
      <DraggableText
        variant="body"
        styles={[styles.button, styles.buttonSecondary, styles.buttonBody]}
      >
        Add body text
      </DraggableText>
      <DraggableText
        variant="bulletedList"
        styles={[styles.button, styles.buttonSecondary, styles.buttonBody]}
      >
        <span
          css={css(`
        :after {
          display: inline-block;
          content: '\\f111';
          font-family: "FontAwesome";
          font-size: 8px;
          margin-right: 5px;
          font-weight: 900;
          position: relative;
          bottom: 2px;
        }
       `)}
        ></span>{" "}
        Add bulleted list
      </DraggableText>
    </div>
  );
};

export default TextSidebar;
