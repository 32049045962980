import { useCallback, useContext, useState } from "react";
import { useDrop } from "react-dnd";
import { useDropzone } from "react-dropzone";
import IAsset from "../../../data/models/IAsset";
import useAssetLibrary from "../../hooks/useAssetLibrary";

import useShapeDrop from "../../hooks/useShapeDrop";
import { CanvasContext } from "../../state/contexts/CanvasContext";
import { ISvgDrop } from "../DesignerInterface/Shapes/Shape";
import QrCode from "../../../shared/QrCodeEditor/models/QrCode";
import { DraggableTextDrop } from "../DesignerInterface/Text/DraggableText";

interface IImageDropzoneProps {
  children?: React.ReactFragment;
}

const ImageDropzone = ({ children }: IImageDropzoneProps) => {
  const canvas = useContext(CanvasContext);
  const { onDropFile, onDropLibraryAsset, addVariableImageToCanvas } =
    useAssetLibrary();
  const { addShapeToCanvas, addQrCodeToCanvas, mousePos } = useShapeDrop();

  const [{ canDrop, isOver, dropType }, drop] = useDrop({
    accept: ["asset", "variableImage", "shape", "qrCode", "text"],
    drop: (
      item: IAsset | undefined | ISvgDrop | QrCode | DraggableTextDrop,
      monitor
    ) => {
      if (monitor.getItemType() === "shape") {
        addShapeToCanvas(item as ISvgDrop);
      } else if (monitor.getItemType() === "variableImage") {
        addVariableImageToCanvas();
      } else if (monitor.getItemType() === "qrCode") {
        addQrCodeToCanvas(item as QrCode);
      } else if (monitor.getItemType() === "text") {
        const i = item as DraggableTextDrop;
        i.callback(mousePos.x, mousePos.y);
      } else onDropLibraryAsset(item as IAsset);
    },
    collect: (monitor) => ({
      type: monitor.getItemType(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropType: monitor.getItemType(),
    }),
  });

  const onDrop = (acceptedFiles: File[]) => {
    if (!canDrop) {
      if (acceptedFiles.length) {
        onDropFile(acceptedFiles[0]);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/svg": [],
      "image/bmp": [],
    },
  });

  return (
    <div
      {...getRootProps()}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <div ref={drop}>
        {children}
        <input
          {...getInputProps()}
          onClick={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => e.preventDefault()}
          style={{ display: "none" }}
          accept="image/png, image/jpeg, image/jpg, image/svg, image/bmp"
        />
      </div>
    </div>
  );
};

export default ImageDropzone;
