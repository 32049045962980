import { createTheme } from "@mui/material/styles";
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

const colors = {
  primary: {
    main: "#3f78e0",
    light: "#4299ff",
    dark: "#2667dc",
    darkRGB: "38, 103, 220",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#3f78e0",
    light: "#4299ff",
    dark: "#2667dc",
    darkRGB: "240, 94, 45",
    contrastText: "#FFFFFF",
  },
};

const defaultTheme = createTheme({});

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
      contrastText: colors.primary.contrastText,
    },
    secondary: {
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      contrastText: colors.secondary.contrastText,
    },
    warning: {
      main: "#B42318",
      dark: "#901C14",
      light: "#D72A1D",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "rgba(0,0,0,.65)",
      dark: "rgba(0,0,0,.85)",
      light: "rgba(0,0,0,.45)",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {},
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            color: defaultTheme.palette.error.main,
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "solid 1px #D0D0D0",
        },
      },
    },
  },
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    subtitle1: {
      fontSize: "12px",
    },
    caption: {
      fontSize: "12px",
    },
  },
});

export default theme;
