import { Grid } from "@mui/material";
import React, { useEffect, useContext } from "react";
import Design from "../../../../Designs/models/Design";
import { OrderRequestContext } from "../../PlaceAnOrder";
import CustomFields from "./components/CustomFields";
import MapListToFieldsField from "./components/MapListToFieldsField";
import "./MapListToFields.scss";

interface MapListToFieldsProps {
  errors: Record<string, any>;
  design: Design;
}

const MapListToFields: React.FC<MapListToFieldsProps> = ({
  errors,
  design,
}) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  /**
   * Component Methods
   */

  function preFillMappings() {
    if (orderRequestStore.orderRequest.listMapping.length === 0) {
      orderRequestStore.createBaseListMapping(design.designFields);
    }
  }

  /**
   * Component Effects
   */
  useEffect(preFillMappings, []);
  /**
   * Render
   */

  return (
    <div>
      <h3>Map Your List</h3>
      <p>
        Use the dropdowns below to map your list fields to the required address
        fields. External Reference Number is optional. If you would like to map
        additional fields to your list, use the &quot;Add A New Field&quot;
        button.
      </p>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span className="mapListToFields__label">First Name</span>
          <MapListToFieldsField name="firstName" error={errors.firstName} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">Last Name</span>
          <MapListToFieldsField name="lastName" error={errors.lastName} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">Company</span>
          <MapListToFieldsField name="company" error={errors.company} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">Address</span>
          <MapListToFieldsField name="address" error={errors.address} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">Address 2</span>
          <MapListToFieldsField name="address2" />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">City</span>
          <MapListToFieldsField name="city" error={errors.city} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">State</span>
          <MapListToFieldsField name="state" error={errors.state} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">Zip Code</span>
          <MapListToFieldsField name="zipCode" error={errors.zipCode} />
        </Grid>
        <Grid item xs={12}>
          <span className="mapListToFields__label">
            External Reference Number (optional)
          </span>
          <MapListToFieldsField name="extRefNbr" />
        </Grid>
        {design.designFields && design.designFields.length > 0 && (
          <Grid item xs={12}>
            <CustomFields design={design} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MapListToFields;
