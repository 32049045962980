import { IText, Textbox } from "fabric/fabric-impl";
import { useContext, useEffect, useState } from "react";

import useLayers from "../../../hooks/useLayers";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { CheckIcon, EditIcon } from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import IPropertiesProps from "../Properties/IPropertiesProps";
import toolbarStyles from "./toolbarStyles";
import { varCleanup } from "../../Canvas/functions/variableHelpers";

const EditTextButton = ({ selectedObject }: IPropertiesProps) => {
  const { removeSelectedObjectLayers } = useLayers();
  const canvas = useContext(CanvasContext);
  const textObj = selectedObject as IText;
  const [isEditing, setIsEditing] = useState(textObj.isEditing ?? false);
  textObj.off("editing:entered");
  textObj.on("editing:entered", function () {
    setIsEditing(true);
  });

  textObj.off("editing:exited");
  textObj.on("editing:exited", function () {
    setIsEditing(false);
    varCleanup(textObj);
  });

  function onClick() {
    if (
      !canvas ||
      !selectedObject ||
      !selectedObject.name ||
      !selectedObject.type?.includes("text")
    )
      return;

    const text = selectedObject as Textbox;
    if (text.isEditing) {
      text.exitEditing();
      canvas.renderAll();
      setIsEditing(false);
    } else {
      text.enterEditing();
      text.selectAll();
      canvas.renderAll();
      setIsEditing(true);
    }
  }

  useEffect(() => {
    setIsEditing(textObj.isEditing ?? false);
  }, [selectedObject]);

  return (
    <ToolButton
      css={toolbarStyles.utilityButton}
      title={!isEditing ? "Edit Text [double-click]" : "Done Editing"}
      onClick={onClick}
    >
      {!isEditing && <EditIcon size={5} />}
      {isEditing && <CheckIcon size={5} />}
    </ToolButton>
  );
};

export default EditTextButton;
