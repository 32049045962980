import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import generateGuid from "../../../helpers/generateGuid";
import ILayers, { ILayersCollection, ILayerSelection } from "../models/ILayers";

import ILayersObject from "../models/layers/ILayersObject";

import { DesignerState } from "../store";

const initialState: ILayers = {
  pageLayers: [],
  selectedLayers: [],
  pageOrder: [],
};

function addNewLayerReducer(
  state: ILayers,
  action: PayloadAction<{
    page: string;
    name: string;
    layerName?: string;
    layerType?: string;
  }>
) {
  const { page, name, layerName, layerType } = action.payload;

  const pageIndex = state.pageLayers.findIndex(
    (x) => x.name === action.payload.page
  );
  if (pageIndex !== -1) {
    const page = state.pageLayers[pageIndex];

    const layerCount = Object.keys(page.layers);

    if (layerCount) {
      const key = layerCount.length.toString();
      page.layers = {
        ...page.layers,
        [key]: {
          name: layerName
            ? layerName
            : layerType
            ? layerType === "QR Code"
              ? "QR Code"
              : `New ${layerType}`
            : `New Layer ${layerCount.length + 1}`,
          id: action.payload.name,
          canvasObjectName: action.payload.name,
        },
      };
      const changes = state.pageLayers;
      changes[pageIndex] = page;
      state.pageLayers = [...changes];
      state.selectedLayers = [{ index: name, page: page.name }];
    }
  }
}

function addNewPageReducer(
  state: ILayers,
  action: PayloadAction<{ name: string }>
) {
  state.pageLayers = [
    ...state.pageLayers,
    { name: action.payload.name, layers: {} },
  ];
}

function removePageReducer(
  state: ILayers,
  action: PayloadAction<{ name: string }>
) {
  state.pageLayers = state.pageLayers
    .filter((x) => x.name !== action.payload.name)
    .map((x, i) => ({ ...x, name: `Page ${i + 1}` }));
}

function movePageReducer(
  state: ILayers,
  action: PayloadAction<{ from: number; to: number }>
) {
  const { from, to } = action.payload;
  const newPages = [...state.pageLayers.filter((x, i) => i !== from)];
  if (to > state.pageLayers.length) {
    newPages.push(state.pageLayers[from]);
  } else {
    newPages.splice(to, 0, state.pageLayers[from]);
  }
  const finalNewPages = newPages.map((x, i) => ({
    ...x,
    name: `Page ${i + 1}`,
  }));

  state.pageLayers = finalNewPages;
}

function updateLayerStateReducer(
  state: ILayers,
  action: PayloadAction<{
    selectedLayers: ILayerSelection[];
    pageLayers: ILayersCollection[];
    pageOrder: string[];
  }>
) {
  return action.payload;
}

function updateLayersReducer(
  state: ILayers,
  action: PayloadAction<ILayersCollection>
) {
  const page = state.pageLayers.findIndex(
    (x) => x.name === action.payload.name
  );
  if (page !== -1) {
    state.pageLayers[page] = { ...action.payload };
  }
}

function updateLayerNameReducer(
  state: ILayers,
  action: PayloadAction<{
    page: number;
    layer: keyof ILayersObject;
    name: string;
  }>
) {
  const { page, layer, name } = action.payload;
  if (state.pageLayers[page] && state.pageLayers[page].layers[layer]) {
    state.pageLayers = state.pageLayers.map((l, index) => {
      if (index === page) {
        l.layers = { ...l.layers, [layer]: { ...l.layers[layer], name } };
      }
      return { ...l };
    });
  }
}

function createPagesReducer(state: ILayers, action: PayloadAction<string[]>) {
  state.pageLayers = action.payload.map((name) => ({ name, layers: {} }));
}

function loadLayersReducer(
  state: ILayers,
  action: PayloadAction<ILayersCollection[]>
) {
  state.pageLayers = [...action.payload];
  state.pageOrder = action.payload.map((x) => x.name);
}

function updateSelectedLayersReducer(
  state: ILayers,
  action: PayloadAction<ILayerSelection[]>
) {
  if (action.payload.length === 0) {
    state.selectedLayers = [];
  } else {
    state.selectedLayers = [...action.payload];
  }
}

export const layersSlice = createSlice({
  name: "layers",
  initialState: initialState,
  reducers: {
    addNewLayer: addNewLayerReducer,
    updateSelectedLayers: updateSelectedLayersReducer,
    updateLayers: updateLayersReducer,
    updateLayerName: updateLayerNameReducer,
    createPages: createPagesReducer,
    loadLayers: loadLayersReducer,
    updateLayerState: updateLayerStateReducer,
    resetLayers: () => {
      return initialState;
    },
    addNewPage: addNewPageReducer,
    removePage: removePageReducer,
    movePage: movePageReducer,
  },
});

export const {
  addNewLayer,
  updateSelectedLayers,
  updateLayers,
  createPages,
  loadLayers,
  updateLayerName,
  updateLayerState,
  resetLayers,
  addNewPage,
  removePage,
  movePage,
} = layersSlice.actions;
export const selectLayers = (state: DesignerState) => state.layers;
export default layersSlice.reducer;
