export default function getVars(html?: string): string[] {
  if (!html) return [];
  const vars = varRetrievalFunc(html);
  return vars;
}

export const EmptyVarError = "_EMPTYHANDLEBARS_";

export function getVarsWithErrors(html?: string): {
  vars: string[];
  errors: string[];
} {
  if (!html) return { vars: [], errors: [] };
  const vars = varRetrievalFunc(html).reduce(
    (x: { vars: string[]; errors: string[] }, currentVal) => {
      if (currentVal.match("^[^a-zA-Z_$]|[^0-9a-zA-Z_$]")) {
        x.errors = [...x.errors, currentVal];
      } else if (currentVal.length === 0) {
        x.errors = [...x.errors, EmptyVarError];
      } else {
        x.vars = [...x.vars, currentVal];
      }

      return x;
    },
    { vars: [], errors: [] }
  );

  return vars;
}

function varRetrievalFunc(html: string) {
  const vars: string[] = [];
  for (let i = 0; i < html.length; i++) {
    if (html.charAt(i) === "{" && html.charAt(i + 1) === "{") {
      const stopIndex = html.indexOf("}", i);
      if (
        stopIndex !== -1 &&
        vars.indexOf(html.substring(i + 2, stopIndex)) === -1
      )
        vars.push(html.substring(i + 2, stopIndex));
    }
  }
  return vars;
}
