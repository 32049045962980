import React, { useRef } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { IAvailableShape } from "./svgShapes";
import tw, { css } from "twin.macro";

interface IShapeProps {
  shape: IAvailableShape;
}

const styles = {
  container: [
    css`
      width: 85px;
      height: 85px;
      background: transparent;
      transform: translate(0, 0);
      cursor: pointer;
    `,
    tw`m-2 flex flex-col items-center justify-center`,
  ],
};

export interface ISvgDrop {
  svg: SVGSVGElement | null;
  shape: IAvailableShape;
}

const Shape = ({ shape }: IShapeProps) => {
  const { Svg, name } = shape;
  const ref = useRef<SVGSVGElement>(null);
  const [collected, drag, dragPreview] = useDrag({
    type: "shape",
    item: { svg: ref.current, shape },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <React.Fragment>
      {collected.isDragging && (
        <div
          ref={dragPreview}
          css={[
            css`
              background: transparent;
            `,
          ]}
        />
      )}
      <div ref={drag} css={styles.container}>
        <Svg ref={ref} />
      </div>
    </React.Fragment>
  );
};

export default Shape;
