import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { Reducer, useEffect, useReducer, useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import { getAsync, putAsync } from "../../../helpers/asyncFetch";
import { getNumberFromString } from "../../../helpers/getNumberFromString";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import LoadingButton from "../../../shared/LoadingButton";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import { IPaymentMethod } from "../../Account/models/AccountModel";
import AddNewCreditCard from "../../Account/PaymentAndBilling/components/AddNewCreditCard";
import { Pricing } from "../../PricingPlans/models/PricingPlanModel";
import envelopeSettingsValidation from "../helpers/envelopeSettingsValidation";
import IEnvelope, { ReplenishmentMethod } from "../models/IEnvelope";
import { EnvelopeDetailsTabStates } from "../models/IEnvelopeTabProps";
import EnvelopeInfo from "./components/EnvelopeInfo";
import EnvelopeOrderHistory from "./components/EnvelopeOrderHistory";
import {
  EnvelopeSettingsActions,
  envelopeSettingsReducer,
  EnvelopeStatus,
  IEnvelopeAction,
  IEnvelopeSettings,
  IEnvelopeSettingsState,
  initialEnvelopeSettingsState,
} from "./EnvelopeSettingsReducer";

interface IParams {
  envelopeId: string;
}

const EnvelopeDetails = () => {
  const [tab, setTab] = useState<EnvelopeDetailsTabStates>(
    EnvelopeDetailsTabStates.info
  );
  const { envelopeId } = useParams<IParams>();
  const [editWindowOpen, setEditWindowOpen] = useState(false);

  const [creditCardOpen, setCreditCardOpen] = useState(false);

  const { data: paymentMethods, updateData: setPaymentMethods } = useGetData<
    IPaymentMethod[]
  >(`/api/payments/payment-methods-expanded`, []);
  const {
    data: envelopePricing,
    isLoading: pricingLoading,
    error: pricingError,
  } = useGetData<Pricing[]>(
    `/api/envelopes/pricing?envelopeId=${envelopeId}`,
    []
  );

  const {
    data,
    isLoading,
    updateData: setEnvelope,
    error: envelopeError,
  } = useGetData<IEnvelope>(`/api/envelopes/${envelopeId}`);

  const [settings, dispatch] = useReducer(
    envelopeSettingsReducer,
    initialEnvelopeSettingsState
  );

  // async function getEnvelope() {
  //   const envelope = await getAsync<IEnvelope>();

  //   if (envelope) {
  //     updateSettingValues(
  //       envelope
  //         ? {
  //             replenishmentMethod: envelope.replenishmentMethod,
  //             replenishmentValue: envelope.replenishmentValue.toString(),
  //             orderQty: envelope.orderQty.toString(),
  //             paymentMethod: envelope.pmid.toString(),
  //           }
  //         : initialEnvelopeSettingsState.values
  //     );
  //     return envelope;
  //   }
  // }

  function updateSettingValues(changes: IEnvelopeSettings) {
    dispatch({ type: EnvelopeSettingsActions.UPDATE_VALUES, payload: changes });
  }

  function handleCancel() {
    dispatch({
      type: EnvelopeSettingsActions.UPDATE_VALUES,
      payload: data
        ? {
            replenishmentMethod: data.replenishmentMethod,
            replenishmentValue: data.replenishmentValue.toString(),
            orderQty: data.orderQty.toString(),
            paymentMethod: data.pmid.toString(),
          }
        : initialEnvelopeSettingsState.values,
    });
    dispatch({
      type: EnvelopeSettingsActions.UPDATE_STATUS,
      payload: EnvelopeStatus.Idle,
    });
    setEditWindowOpen(false);
  }

  function updateField(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    updateSettingValues({
      ...settings.values,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmitSettingsChanges() {
    const errors = envelopeSettingsValidation(settings.values);
    if (errors.length) {
      dispatch({
        type: EnvelopeSettingsActions.UPDATE_ERRORS,
        payload: errors,
      });
      return;
    }
    dispatch({
      type: EnvelopeSettingsActions.UPDATE_STATUS,
      payload: EnvelopeStatus.Submitting,
    });
    const body = { ...data };
    body.replenishmentMethod = settings.values.replenishmentMethod;
    body.replenishmentValue = getNumberFromString(
      settings.values.replenishmentValue
    );
    body.pmid = getNumberFromString(settings.values.paymentMethod);
    body.orderQty = getNumberFromString(settings.values.orderQty);
    const res = await putAsync<IEnvelope>(`/envelopes/${envelopeId}`, body);
    if (res) {
      setEnvelope({ ...data, ...res });
      dispatch({
        type: EnvelopeSettingsActions.UPDATE_STATUS,
        payload: EnvelopeStatus.Success,
      });
    } else {
      dispatch({
        type: EnvelopeSettingsActions.UPDATE_STATUS,
        payload: EnvelopeStatus.Error,
      });
    }
  }

  function handleCreditCardAdd(pm: IPaymentMethod) {
    if (pm) {
      dispatch({
        type: EnvelopeSettingsActions.UPDATE_VALUES,
        payload: {
          ...settings.values,
          paymentMethod: pm.id ? pm.id.toString() : "",
        },
      });
      if (paymentMethods) {
        setPaymentMethods([...paymentMethods, pm]);
      } else {
        setPaymentMethods([pm]);
      }
    }
  }

  function validateAfterSubmit() {
    if (settings.errors.length) {
      const errors = envelopeSettingsValidation(settings.values);
      dispatch({
        type: EnvelopeSettingsActions.UPDATE_ERRORS,
        payload: errors,
      });
    }
  }

  useEffect(validateAfterSubmit, [settings.values]);

  return (
    <Box sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={envelopeError.hasError}
      >
        {data && (
          <React.Fragment>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" component="h1">
                Envelope #{data?.envelopeID}
              </Typography>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Paper>
                <Tabs
                  value={tab}
                  onChange={(t, tab: EnvelopeDetailsTabStates) => setTab(tab)}
                >
                  <Tab
                    value={EnvelopeDetailsTabStates.info}
                    label="Envelope Details"
                  />
                  <Tab
                    value={EnvelopeDetailsTabStates.orderHistory}
                    label="Reorder History"
                  />

                  {/* <Tab value={Tab.} label="Proof JSON Example" /> */}
                </Tabs>
                <EnvelopeInfo
                  tabValue={EnvelopeDetailsTabStates.info}
                  activeTab={tab}
                  envelope={data}
                  onEditClick={() => {
                    setEditWindowOpen(true);
                  }}
                  paymentMethod={paymentMethods?.find(
                    (x) => x.id === data.pmid
                  )}
                  pricing={
                    envelopePricing && !pricingError.hasError
                      ? envelopePricing
                      : []
                  }
                />
                <EnvelopeOrderHistory
                  tabValue={EnvelopeDetailsTabStates.orderHistory}
                  activeTab={tab}
                  envelope={data}
                />
              </Paper>
            </Box>

            <Dialog fullWidth maxWidth={"sm"} open={editWindowOpen}>
              <DialogTitle>Modify Automatic Reorder Settings</DialogTitle>
              <DialogContent>
                <Box sx={{ pt: 2 }}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Automatic Reorder Method</InputLabel>
                    <Select
                      label="Automatic Reorder Method"
                      name="replenishmentMethod"
                      value={settings.values.replenishmentMethod}
                      onChange={updateField}
                      disabled={settings.status !== "idle"}
                    >
                      <MenuItem value={ReplenishmentMethod.None}>
                        Disable Automatic Reorder
                      </MenuItem>
                      <MenuItem value={ReplenishmentMethod.Percentage}>
                        Reorder When Inventory Is Below A Percentage
                      </MenuItem>
                      <MenuItem value={ReplenishmentMethod.Static}>
                        Reorder When Inventory Is Below A Fixed Number
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {settings.values.replenishmentMethod !==
                    ReplenishmentMethod.None && (
                    <Box>
                      <Box sx={{ mb: 2 }}>
                        <NumberFormat
                          customInput={TextField}
                          value={settings.values.replenishmentValue}
                          onChange={updateField}
                          label="Minium Threshold Before Reorder"
                          disabled={settings.status !== "idle"}
                          suffix={
                            settings.values.replenishmentMethod ===
                            ReplenishmentMethod.Percentage
                              ? "%"
                              : undefined
                          }
                          fullWidth
                          name="replenishmentValue"
                        />
                        {Boolean(
                          settings.errors.includes("replenishmentValue")
                        ) &&
                          !Boolean(
                            settings.errors.includes("greaterThanQty")
                          ) && (
                            <FormHelperText error>
                              {settings.values.replenishmentMethod ===
                              ReplenishmentMethod.Percentage
                                ? "Please enter a number between 1 and 100"
                                : "Please enter a number greater than 0"}
                            </FormHelperText>
                          )}
                        {settings.errors.includes("greaterThanQty") && (
                          <FormHelperText error>
                            Your minimum threshold must be less than your
                            reorder amount.
                          </FormHelperText>
                        )}
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <NumberFormat
                          customInput={TextField}
                          value={settings.values.orderQty}
                          onChange={updateField}
                          label="Reorder Amount"
                          thousandSeparator=","
                          disabled={settings.status !== "idle"}
                          fullWidth
                          name="orderQty"
                        />
                        {Boolean(settings.errors.includes("orderQty")) && (
                          <FormHelperText error>
                            The minimum reorder amount is 5,000
                          </FormHelperText>
                        )}
                      </Box>
                      <Box>
                        <FormControl
                          fullWidth
                          disabled={settings.status !== "idle"}
                          sx={{ mb: 2 }}
                        >
                          <InputLabel>Payment Method</InputLabel>
                          <Select
                            name="paymentMethod"
                            value={settings.values.paymentMethod}
                            onChange={updateField}
                            label="Payment Method"
                            disabled={settings.status !== "idle"}
                            fullWidth
                          >
                            <MenuItem value="" disabled>
                              Choose an option
                            </MenuItem>
                            {paymentMethods?.map((method) => (
                              <MenuItem key={method.id} value={method.id}>
                                Card ending in {method.lastFour} | Expires:{" "}
                                {method.expiration}
                              </MenuItem>
                            ))}
                          </Select>
                          {settings.errors.includes("paymentMethod") && (
                            <FormHelperText error>
                              Please select a payment method
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Button
                          color="info"
                          variant="outlined"
                          onClick={() => setCreditCardOpen(true)}
                        >
                          Add a new credit card
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {settings.status === "error" && (
                    <FormHelperText error>
                      Something went wrong! We were unable to make the requested
                      changes. Please try again later.
                    </FormHelperText>
                  )}
                </Box>
                <AddNewCreditCard
                  isOpen={creditCardOpen}
                  onClose={() => setCreditCardOpen(false)}
                  callback={handleCreditCardAdd}
                />
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={settings.status === "submitting"}
                  disabled={settings.status === "error"}
                  success={settings.status === "success"}
                  onClick={handleSubmitSettingsChanges}
                >
                  Save Changes
                </LoadingButton>{" "}
                <Button color="info" onClick={() => handleCancel()}>
                  Close Window
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </LoadingWrapper>
    </Box>
  );
};

export default EnvelopeDetails;
