import React, { useState, useEffect, useRef } from "react";
import { Order } from "../models/Order";
import { fetchClient } from "../../../helpers/fetchClient";
import moment from "moment";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import RecipientListTable from "./RecipientListTable";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { TableColumn } from "react-data-table-component";
import BatchOrder from "../models/BatchOrder";
import LocalStorage from "../../../helpers/LocalStorage";

interface OrderListProps {
  data: BatchOrder[];
}

const OrderList: React.FC<OrderListProps> = (order) => {
  const [activeOrder, setActiveOrder] = useState<BatchOrder>();
  const [open, setOpen] = useState(false);
  const handleOpen = (order: BatchOrder) => {
    setActiveOrder(order);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const session = LocalStorage.getSession();
  const enableRecipientPrivacy = session?.enableRecipientPrivacy;

  const orderColumns: TableColumn<BatchOrder>[] = [
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
    },
    {
      name: "Ext. Ref Number",
      selector: (row) => row.extRefNbr,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => row.orderDate,
      sortable: true,
      format: (row) =>
        moment.utc(row.orderDate).local().format("MM/DD/YYYY hh:mma"),
    },
    {
      name: "Design ID",
      selector: (row) => (row.designID ? row.designID : 0),
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) => row.sizeLabel,
      sortable: true,
    },
    {
      name: "Mail Class",
      selector: (row) => row.mailClass,
      sortable: true,
    },
    {
      name: "# of Recipients",
      cell: (order) => (
        <React.Fragment>
          {order.recipients ? order.recipients.length + " " : ""}
          {!enableRecipientPrivacy && order.recipients && (
            <Button
              size="small"
              color="primary"
              onClick={() => handleOpen(order)}
              sx={{ ml: 2 }}
            >
              View Recipients
            </Button>
          )}
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <SearchableDataTable
        columns={orderColumns}
        tableTitle="Orders"
        searchableColumns={[
          "orderID",
          "orderDate",
          "designID",
          "design.sizeInfo.sizeLabel",
          "mailClass",
        ]}
        data={order.data}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Recipients</DialogTitle>
        <DialogContent>
          {activeOrder && <RecipientListTable data={activeOrder?.recipients} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderList;
