import { Group } from "fabric/fabric-impl";
import React, { createContext, useReducer } from "react";

export const PropertyDropdownContext = createContext<string>("");

export const PropertyDropdownDispatchContext = createContext<
  React.Dispatch<{
    type: string;
    payload: string;
  }>
>(
  {} as React.Dispatch<{
    type: string;
    payload: string;
  }>
);
const intialState = "";

function propertyDropdownReducer(
  page: string,
  action: { type: string; payload: string }
) {
  if (action.payload) return action.payload;
  return page;
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const PropertyDropdownProvider = ({ children }: IProviderProps) => {
  const [propertyDropdown, dispatch] = useReducer(
    propertyDropdownReducer,
    intialState
  );

  return (
    <PropertyDropdownContext.Provider value={propertyDropdown}>
      <PropertyDropdownDispatchContext.Provider value={dispatch}>
        {children}
      </PropertyDropdownDispatchContext.Provider>
    </PropertyDropdownContext.Provider>
  );
};

export default PropertyDropdownProvider;
