import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gradient } from "fabric/fabric-impl";

import { DesignerState } from "../store";

export interface IColorSwatch {
  hex: string;
  r: number;
  g: number;
  b: number;
  opacity: number;
}

export type ColorProperties = IColorSwatch | Gradient;

interface IColorPalette {
  designColors: ColorProperties[];
}

const initialState: IColorPalette = {
  designColors: [],
};

const colorPalette = createSlice({
  name: "colorPalette",
  initialState,
  reducers: {
    addColor: (state, action: PayloadAction<ColorProperties>) => {
      state.designColors = [action.payload, ...state.designColors];
    },
  },
});

export const { addColor } = colorPalette.actions;
export const selectColorPalette = (state: DesignerState) => state.colorPalette;
export default colorPalette.reducer;
