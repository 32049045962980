import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchClient } from "../../../helpers/fetchClient";
import { trimPhoneNumber } from "../../../helpers/formattingHelpers";
import useGetData from "../../../hooks/dataFetchers/useGetData";

import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";

import AccountWrapper from "../components/AccountWrapper";
import {
  AccountInformationResponse,
  AddressModel,
  ContactInformationModel,
} from "../models/AccountModel";
import PasswordReset from "./components/PasswordReset";

const Account: React.FC = () => {
  const { data, isLoading, error } =
    useGetData<AccountInformationResponse>("/api/my-account");
  const [contactInformation, setContactInformation] =
    useState<ContactInformationModel>();
  const [address, setAddress] = useState<AddressModel>();

  function getAccountData() {
    if (data) {
      console.log(data);
      setAddress(data.address);
      setContactInformation(data.contactInformation);
    }
  }

  useEffect(getAccountData, [data]);
  return (
    <AccountWrapper
      pageTitle="Account Information"
      loading={isLoading}
      loadError={error.hasError}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Contact Information
        </Typography>
        <GridRow>
          <GridItem>
            <Typography variant="subtitle1">Account email</Typography>
            <Typography variant="body1">
              {contactInformation?.accountEmail}
            </Typography>
          </GridItem>
          <GridItem>
            <Typography variant="subtitle1">Name</Typography>
            <Typography variant="body1">{contactInformation?.name}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="subtitle1">Phone number</Typography>
            <Typography variant="body1">
              {trimPhoneNumber(contactInformation?.phone ?? "")}
            </Typography>
          </GridItem>
        </GridRow>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Address
        </Typography>
        <GridRow>
          <GridItem>
            <Typography variant="subtitle1">Street address</Typography>
            <Typography variant="body1">{address?.streetAddress}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="subtitle1">City</Typography>
            <Typography variant="body1">{address?.city}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="subtitle1">State</Typography>
            <Typography variant="body1">{address?.state}</Typography>
          </GridItem>
          <GridItem>
            <Typography variant="subtitle1">Zip</Typography>
            <Typography variant="body1">{address?.zip}</Typography>
          </GridItem>
        </GridRow>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Change My Password
        </Typography>
        <PasswordReset />
      </Box>
    </AccountWrapper>
  );
};

export default Account;
