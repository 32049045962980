import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useState } from "react";
import formattingHelpers from "../../../helpers/formattingHelpers";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import BatchResponse from "../models/BatchResponse";

interface QuickStatsProps {
  isLoading: boolean;
  batches: BatchResponse | undefined;
}

// styled components
const StatHeading = styled(Box)`
  background: #f4f6f8;
  padding: 16px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  border-bottom: solid 1px rgba(0, 0, 0, 0.125);
`;

const StatBody = styled(Box)`
  padding: 40px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
`;

const QuickStats: React.FC<QuickStatsProps> = ({ isLoading, batches }) => {
  const [statDays, setStatDays] = useState("0");
  const { moneySpent, recipients } = getStats();

  function getStats() {
    if (statDays && batches) {
      // combine our orders from active and completed, then filter them
      const orders = [
        ...batches.activeBatches,
        ...batches.completedBatches,
      ].filter((batch) => {
        if (batch.status) {
          const status = batch.status.toLocaleLowerCase();
          // check if the status is one of the below
          if (
            [
              "canceled",
              "cancelled",
              "undeliverable",
              "failed",
              "failed payment",
              "pending payment",
            ].indexOf(status) === -1
          ) {
            // if we are not filtering by all time, then we need to do some date math
            if (statDays !== "0") {
              if (
                -moment(batch.orderDate).diff(moment(), "days") <=
                parseInt(statDays)
              ) {
                // this one matches our criteria, return true
                return true;
              }
              return false;
            }
            // if we are filtering all time, then just return true
            return true;
          }
        }
        return false;
      });

      // Combine all the quoteTotals and recipient totals from the valid orders into a new object via reduce.
      // reduce takes a function (previousValue, currentValue) => newValue and an "initialValue". In this case, previousValue is the totalsObject which is passed in as the second argument of reduce, initialValue. The only thing this loop does is feed whatever value is returned each iteration and passed into the next. At the end, the return value is whatever has been built up via the loop. So just an object of totals here.
      const { moneySpent, recipients } = orders.reduce(
        (totalsObj, currentBatchInLoop) => {
          return {
            moneySpent: totalsObj.moneySpent + currentBatchInLoop.quoteTotal,
            recipients:
              totalsObj.recipients + (currentBatchInLoop.recipients ?? 0),
          };
        },
        { moneySpent: 0, recipients: 0 }
      );

      // return the totals object we just created with reduce.
      return { moneySpent, recipients };
    }
    return { moneySpent: 0, recipients: 0 };
  }

  return (
    <Box>
      <GridRow alignItems="center">
        <GridItem breakPoints={[6]}>
          <Typography variant="h6" component="h4">
            Your Recent Activity
          </Typography>
        </GridItem>
        <GridItem breakPoints={[6]}>
          <GridRow
            justifyContent="end"
            alignItems="center"
            height="100%"
            spacing={0}
          >
            <Typography variant="body1" component="span" sx={{ mr: 3 }}>
              Showing Results From
            </Typography>
            <FormControl size="small">
              <Select
                value={statDays}
                onChange={(e) => setStatDays(e.target.value)}
                className="date-range-picker-mui"
                sx={{ backgroundColor: "#FFFFFF" }}
              >
                <MenuItem value="0">All Time</MenuItem>
                <MenuItem value="7">Last 7 Days</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
                <MenuItem value="90">Last 90 Days</MenuItem>
              </Select>
            </FormControl>
          </GridRow>
        </GridItem>
      </GridRow>
      <GridRow sx={{ py: 3 }} height="100%">
        <GridItem breakPoints={[12, 6]} height="100%">
          <Paper elevation={3}>
            <LoadingWrapper loading={isLoading} height={165}>
              <StatHeading>Amount Spent</StatHeading>
              <StatBody>
                $
                {moneySpent
                  ? formattingHelpers.formatThousands(moneySpent.toFixed(2))
                  : "0.00"}
              </StatBody>
            </LoadingWrapper>
          </Paper>
        </GridItem>
        <GridItem breakPoints={[12, 6]} height="100%">
          <Paper elevation={3}>
            <LoadingWrapper loading={isLoading} height={165}>
              <StatHeading>Total Recipients</StatHeading>
              <StatBody>
                {formattingHelpers.formatThousands(recipients)}
              </StatBody>
            </LoadingWrapper>
          </Paper>
        </GridItem>
      </GridRow>
    </Box>
  );
};

export default QuickStats;
