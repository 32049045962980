import {
  Box,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchClient } from "../../helpers/fetchClient";
import LoadingButton from "../../shared/LoadingButton";
import { getSessionInfo } from "../Login/helpers/handleLogin";

const ContactUs = () => {
  const [form, setForm] = useState({ subject: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contactEmail, setContactEmail] = useState("");

  async function getContactEmail() {
    const session = await getSessionInfo();
    setContactEmail(
      session ? session.salesRepEmail : "amber@pcmintegrations.com"
    );
  }

  function handleSubmit() {
    setIsLoading(true);
    fetchClient.post({
      path: "/email/send",
      data: {
        emailTo: contactEmail,
        emailBody: form.message,
        subject: form.subject,
      },
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess(true);
      },
      onError: () => {
        setIsLoading(false);
        setHasError(true);
      },
    });
  }

  useEffect(() => {
    getContactEmail();
  }, []);

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Contact Us
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Use the form below to get in touch. Alternatively you may use the live
        chat during business hours:
        <strong style={{ display: "block", marginTop: "8px" }}>
          Mon-Fri 9AM - 6PM Eastern
        </strong>
      </Typography>
      <Box>
        <FormGroup sx={{ mb: 3 }}>
          <FormLabel>Subject</FormLabel>
          <TextField
            size="small"
            value={form.subject}
            onChange={(e) => setForm({ ...form, subject: e.target.value })}
            fullWidth
            disabled={isLoading}
          />
        </FormGroup>
        <FormGroup sx={{ mb: 3 }}>
          <FormLabel>Message</FormLabel>
          <TextField
            size="small"
            value={form.message}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
            fullWidth
            multiline
            rows={10}
            disabled={isLoading}
          />
        </FormGroup>
        <FormGroup>
          {isSuccess && (
            <Box sx={{ mb: 2 }}>
              <FormHelperText
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Success! We&apos;ll reach out as soon as possible.
              </FormHelperText>
            </Box>
          )}
          {hasError && (
            <Box sx={{ mb: 2 }}>
              <FormHelperText error>
                Something went wrong, our team has been alerted
              </FormHelperText>
            </Box>
          )}
          <LoadingButton
            loading={isLoading}
            success={isSuccess}
            fullWidth
            onClick={handleSubmit}
            disabled={Boolean(!form.subject) || Boolean(!form.message)}
          >
            Send
          </LoadingButton>
        </FormGroup>
      </Box>
    </Box>
  );
};

export default ContactUs;
