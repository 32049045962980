import {
  Box,
  Button,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchClient } from "../../../helpers/fetchClient";
import Img from "../../../shared/BasicHTML/Img";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import { DesignSize } from "../../Admin/models/DesignCreationModel";
import Design from "../models/Design";
import ImageUpload from "./components/ImageUpload";
import SizeSelector from "./components/SizeSelector";

const proofOrderRecipient = {
  firstName: "First",
  lastName: "Last",
  address: "123 Address St.",
  address2: "Apt. 1",
  city: "Clearwater",
  state: "FL",
  zipCode: "33765",
  extRefNbr: "",
  recipientDesignVariables: [],
};

const UploadADesign = () => {
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [size, setSize] = useState({ sizeInfoSize: "" } as DesignSize);
  const [name, setName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [proofs, setProofs] = useState<string[]>([]);
  const [proofError, setProofError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [design, setDesign] = useState<Design>();

  const history = useHistory();

  function onFrontComplete(path: string) {
    setFront(path);
  }

  function onBackComplete(path: string) {
    setBack(path);
  }

  function overrideDragAndDrop() {
    function dragndrop(e: Event) {
      e.preventDefault();
    }

    window.addEventListener("dragover", dragndrop);
    window.addEventListener("drop", dragndrop);
    function clearEventListeners() {
      window.removeEventListener("dragover", dragndrop);
      window.removeEventListener("drop", dragndrop);
    }
    return clearEventListeners();
  }

  function onSizeChange(size: DesignSize) {
    setSize(size);
  }

  function createNewDesign() {
    // start uploading
    setIsUploading(true);
    fetchClient.post({
      path: "/designs/upload",
      data: {
        name,
        frontOfCard: `${window.location.origin}/uploads/${front}`,
        backOfCard: `${window.location.origin}/uploads/${back}`,
        size: size.sizeInfoSize,
        documentId: size.documentId,
      },
      onSuccess: onDesignCreationResponse,
    });
  }

  function onDesignCreationResponse(design: Design) {
    setIsUploading(true);
    setDesign(design);
    fetchClient.post({
      path: `/designs/${design.designID}/generate-proof`,
      data: {
        recipient: {
          ...proofOrderRecipient,
          variables: proofOrderRecipient.recipientDesignVariables,
        },
      },
      onSuccess: onProofResponse,
      onError: handleProofFailure,
    });
  }

  function handleProofFailure() {
    setProofError(true);
    setIsUploading(false);
  }

  function onProofResponse(proofRes: {
    front?: string;
    back?: string;
    pdf?: string;
  }) {
    const proofImages: string[] = [];
    if (proofRes.front) {
      proofImages.push(proofRes.front);
    }
    if (proofRes.back) {
      proofImages.push(proofRes.back);
    }
    if (proofRes.pdf) {
      proofImages.push(proofRes.pdf);
    }

    // set proof state
    setProofs(proofImages);
    // stop uploading
    setIsUploading(false);
    // display proof approval
  }

  function createDesignOnApproval() {
    setIsCreating(true);
    fetchClient.put({
      path: `/designs/approve?designID=${design?.designID}`,
      data: { proofFront: proofs[0], proofBack: proofs[1] },
      onSuccess: onCreateResponse,
    });
  }

  function onCreateResponse() {
    // redirect to design.
    history.push(`/designs/${design?.designID}`);
  }

  function handleDeleteDesign() {
    // fire off request
    fetchClient.put({
      path: `/designs/upload`,
      data: { designID: design?.designID },
      onSuccess: () => {},
    });
    // clear design state
    setDesign(undefined);
  }

  useEffect(overrideDragAndDrop, []);

  return (
    <Box sx={{ py: 5 }}>
      <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
        Upload A Design
      </Typography>
      <Typography>
        Use the uploader below to create a custom design using a front and back
        image.
      </Typography>
      <Paper sx={{ p: 3, my: 3 }}>
        {(!design || (design && isUploading)) && (
          <LoadingWrapper
            loading={isUploading}
            height={500}
            message="Uploading your design and generating a proof. This could take a few minutes"
          >
            <Box>
              <Box>
                <Typography variant="h6" component="h3">
                  Configure Your Design
                </Typography>
                <Box sx={{ py: 2 }}>
                  <TextField
                    label="Design Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box sx={{ py: 2 }}>
                  <SizeSelector size={size} onChange={onSizeChange} />
                </Box>
              </Box>

              <GridRow>
                <GridItem breakPoints={[12, 6]}>
                  <Typography variant="h6" component="h3">
                    Upload The Front
                  </Typography>
                  <ImageUpload
                    onUploadSuccess={onFrontComplete}
                    helpText="PDF Images must be a single page"
                    defaultImage={front}
                  />
                </GridItem>
                <GridItem breakPoints={[12, 6]}>
                  <Typography variant="h6" component="h3">
                    Upload The Back
                  </Typography>
                  <ImageUpload
                    onUploadSuccess={onBackComplete}
                    helpText="PDF Images must be a single page"
                    defaultImage={back}
                  />
                </GridItem>
              </GridRow>

              <Button
                variant="contained"
                color="primary"
                onClick={createNewDesign}
                disabled={!name || !size.sizeInfoSize || !front || !back}
              >
                Create My Design
              </Button>
            </Box>
          </LoadingWrapper>
        )}
        {design && proofError && (
          <LoadingWrapper loading={isUploading} height={500}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h4" component="h3">
                There was an error generating your proof for approval. Please
                try again
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onDesignCreationResponse(design)}
              >
                Generate Proof
              </Button>
            </Box>
          </LoadingWrapper>
        )}
        {design && !proofError && !isUploading && (
          <LoadingWrapper
            loading={isCreating}
            height={500}
            message="Approving your design"
          >
            <Typography variant="h4" component="h3">
              Please approve your proof in order to finalize your design
            </Typography>
            <GridRow sx={{ py: 3 }}>
              <GridItem breakPoints={[6]}>
                <Img src={proofs[0]} />
              </GridItem>
              <GridItem breakPoints={[6]}>
                <Img src={proofs[1]} />
              </GridItem>
            </GridRow>
            <GridRow>
              <GridItem>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createDesignOnApproval}
                >
                  Approve Design
                </Button>
              </GridItem>
              <GridItem>
                <Button color="primary" onClick={handleDeleteDesign}>
                  I need to make changes
                </Button>
              </GridItem>
            </GridRow>
          </LoadingWrapper>
        )}
      </Paper>
    </Box>
  );
};

export default UploadADesign;
