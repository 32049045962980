import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../helpers/LocalStorage";
import { usePostData } from "../../hooks/useFetch";
import useLoadData from "../../hooks/useLoadData";
import useQuery from "../../hooks/useQuery";
import Form from "../../shared/BasicHTML/Form";
import LoadingButton from "../../shared/LoadingButton";
import LoadingWrapper from "../../shared/LoadingWrapper";
import LoginLayout from "../../shared/LoginLayout/LoginLayout";
import handleLogin, { getSessionInfo } from "./helpers/handleLogin";
import {
  CustomerLoginCode,
  CustomerLoginRequest,
} from "./models/CustomerLogin";
import LoginResponse from "./models/LoginResponse";

const CustomerLogin = () => {
  const history = useHistory();
  const query = useQuery();
  const [termsOpen, setTermsOpen] = useState(false);
  const { data, isLoading, error } = useLoadData<CustomerLoginCode>(
    `/api/auth/customer-login?email=${query.get("email")}&id=${query.get(
      "id"
    )}`,
    {
      code: "",
    },
    (res) => {
      setRequestBody({ ...requestBody, code: res.code });
      return res;
    }
  );
  const [sendLogin, sendLoginStatus] = usePostData<LoginResponse>(
    `/api/auth/customer-login`
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const [requestBody, setRequestBody] = useState<CustomerLoginRequest>({
    email: query.get("email") ?? "",
    password: "",
    confirmPassword: "",
    code: "",
  });

  function validateFields() {
    const { email, password, confirmPassword } = requestBody;
    const errors: Record<string, string> = {};

    if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords must match";
    }

    setValidationErrors({ ...errors });

    if (Object.keys(errors).length) return false;
    return true;
  }

  async function handleSubmit() {
    setHasSubmitted(true);
    if (!validateFields) {
      return false;
    }
    const res = await sendLogin(requestBody);
    if (res) {
      handleLogin(res, query.get("email") ?? "");
      const session = await getSessionInfo();
      if (session) {
        LocalStorage.setSession(session);
        history.push("/");
      }
    }
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (!touched[e.target.name]) {
      setTouched({ ...touched, [e.target.name]: true });
    }
    setRequestBody({ ...requestBody, [e.target.name]: e.target.value });
  }

  function handleTermsClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    setTermsOpen(true);
  }

  useEffect(() => {
    validateFields();
  }, [requestBody]);

  if (error) {
    return (
      <LoginLayout>
        <Typography variant="h5" mb={2} component={"h1"}>
          Something went wrong
        </Typography>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <LoadingWrapper height={300} loading={isLoading}>
        <Typography variant="h5" mb={2} component={"h1"}>
          Complete Your Registration
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Email Address"
              type="email"
              value={query.get("email") ?? ""}
              disabled
              fullWidth
              size="small"
              style={{ backgroundColor: "#ddd", borderRadius: "6px" }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="password"
              type="password"
              onChange={handleInputChange}
              value={requestBody.password}
              required
              disabled={sendLoginStatus.status === "loading"}
              label="Password"
              fullWidth
              size="small"
              error={
                Boolean(validationErrors["password"]) &&
                (Boolean(touched["password"]) || hasSubmitted)
              }
            />
            {Boolean(validationErrors["password"]) &&
              (Boolean(touched["password"]) || hasSubmitted) && (
                <FormHelperText error>
                  {validationErrors["password"]}
                </FormHelperText>
              )}
          </Box>{" "}
          <Box mb={2}>
            <TextField
              name="confirmPassword"
              type="password"
              onChange={handleInputChange}
              value={requestBody.confirmPassword}
              required
              disabled={sendLoginStatus.status === "loading"}
              label="Confirm Password"
              fullWidth
              size="small"
              error={
                Boolean(validationErrors["confirmPassword"]) &&
                (Boolean(touched["confirmPassword"]) || hasSubmitted)
              }
            />
            {Boolean(validationErrors["confirmPassword"]) &&
              (Boolean(touched["confirmPassword"]) || hasSubmitted) && (
                <FormHelperText error>
                  {validationErrors["confirmPassword"]}
                </FormHelperText>
              )}
          </Box>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={sendLoginStatus.status === "loading"}
          >
            Complete Registration
          </LoadingButton>
          {sendLoginStatus.status === "error" && (
            <Box>
              <FormHelperText error>Something went wrong</FormHelperText>
            </Box>
          )}
        </Form>
      </LoadingWrapper>
    </LoginLayout>
  );
};

export default CustomerLogin;
