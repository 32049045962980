import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAsync } from "../../../helpers/asyncFetch";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import Img from "../../../shared/BasicHTML/Img";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import Design from "../models/Design";
import IDesignCategory from "../models/IDesignCategory";
import IDesignSubcategory from "../models/IDesignSubcategory";

interface IActiveCategory {
  category?: IDesignCategory;
  subcategory?: IDesignSubcategory;
}

const TemplateGallery = () => {
  const {
    data,
    isLoading,
    error: designsError,
  } = useGetData<Design[]>(`/api/designs/template-gallery`, []);

  const [activeCategory, setActiveCategory] = useState<IActiveCategory>({});

  const {
    data: designCategories,
    updateData: setDesignCategories,
    error: categoriesError,
  } = useGetData<IDesignCategory[]>(`/api/design-category`, []);

  function handleCategoryChange(
    e: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) {
    if (!designCategories) return;
    if (value && value !== "No Filter") {
      const category = designCategories.find((x) => x.categoryName === value);
      setActiveCategory({ category });
    } else {
      setActiveCategory({});
    }
  }
  function handleSubcategoryChange(
    e: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) {
    if (!designCategories) return;
    if (value && value !== "No Filter" && activeCategory.category) {
      const subcategory = activeCategory.category.subcategories.find(
        (x) => x.subcategoryName === value
      );
      setActiveCategory({ ...activeCategory, subcategory });
    } else {
      setActiveCategory({ category: activeCategory.category });
    }
  }

  const designCols: TableColumn<Design>[] = [
    {
      name: "Proof",
      maxWidth: "200px",
      cell: (design) => {
        return (
          <Box
            sx={{
              maxWidth: "200px",
              maxHeight: "200px",
              overflow: "hidden",
              pt: 1,
              pb: 1,
            }}
          >
            <Img
              src={design.proofFront ? design.proofFront : design.proofPDF}
            />
          </Box>
        );
      },
    },
    {
      name: "ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (design) => design.friendlyName,
      sortable: true,
    },
    {
      name: "Category",
      selector: (design) => design.designCategory?.categoryName ?? "",
      sortable: true,
    },
    {
      name: "Subcategory",
      selector: (design) => design.designSubcategory?.subcategoryName ?? "",
      sortable: true,
    },
    {
      name: "Size",
      selector: (design) => design.size,
      right: true,
      sortable: true,
    },

    {
      name: "",
      right: true,
      maxWidth: "110px",
      width: "110px",
      cell: (design) => {
        return (
          <Button
            component={Link}
            to={
              design.htmlVersionID !== null
                ? `/designs/html/${design.designID}`
                : `/designs/${design.designID}`
            }
            color="primary"
            variant="outlined"
            size="small"
          >
            Details
          </Button>
        );
      },
    },
  ];

  function filterDesigns() {
    if (!data) return [];
    if (!activeCategory.category) return data;
    return data?.filter((x) => {
      if (activeCategory.subcategory) {
        return (
          x.designSubcategory?.subcategoryID ===
            activeCategory.subcategory.subcategoryID &&
          x.designCategory?.categoryID === activeCategory.subcategory.categoryID
        );
      }
      return (
        x.designCategory?.categoryID === activeCategory.category?.categoryID
      );
    });
  }

  const customHeader = (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} md={3}>
          <Autocomplete
            value={activeCategory.category?.categoryName ?? ""}
            options={
              designCategories
                ? ["No Filter", ...designCategories?.map((x) => x.categoryName)]
                : ["No Filter"]
            }
            fullWidth
            onChange={handleCategoryChange}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Category" />
            )}
          />

          {/* <FormControl fullWidth size="small">
            <InputLabel>Filter Categories</InputLabel>
            <Select
              fullWidth
              label="Filter Categories"
              value={activeCategory.category?.categoryID.toString() ?? ""}
              onChange={handleCategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {designCategories?.map((category) => (
                <MenuItem key={category.categoryID} value={category.categoryID}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            value={activeCategory.subcategory?.subcategoryName ?? ""}
            options={
              designCategories && activeCategory.category
                ? [
                    "No Filter",
                    ...activeCategory.category.subcategories.map(
                      (x) => x.subcategoryName
                    ),
                  ]
                : ["No Filter"]
            }
            fullWidth
            onChange={handleSubcategoryChange}
            disabled={!activeCategory.category}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Subcategory" />
            )}
          />

          {/* <FormControl fullWidth size="small">
            <InputLabel>Filter Subcategories</InputLabel>
            <Select
              fullWidth
              label="Filter Subcategories"
              disabled={!activeCategory.category}
              value={activeCategory.subcategory?.subcategoryID.toString() ?? ""}
              onChange={handleSubcategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {activeCategory.category?.subcategories?.map((category) => (
                <MenuItem
                  key={category.subcategoryID}
                  value={category.subcategoryID}
                >
                  {category.subcategoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
        Template Gallery
      </Typography>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={designsError.hasError}
      >
        <SearchableDataTable
          data={data ? filterDesigns() : []}
          customHeaderComponent={customHeader}
          columns={designCols}
          searchableColumns={[
            "friendlyName",
            "designID",
            "size",
            "designCategory.categoryName",
            "designSubcategory.subcategoryName",
          ]}
        />
      </LoadingWrapper>
    </Box>
  );
};

export default TemplateGallery;
