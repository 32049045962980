import React from "react";
import useBatches from "../../../hooks/dataFetchers/useBatches";
import BatchesTable from "../../Home/components/Batches";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import { Box, Typography } from "@mui/material";
import { usePostData } from "../../../hooks/useFetch";
import NimbleBatch from "../../Batches/models/NimbleBatch";
import BatchResponse from "../../Home/models/BatchResponse";
import useGetData from "../../../hooks/dataFetchers/useGetData";

const Batches = () => {
  const {
    data: batches,
    isLoading,
    updateData: setBatches,
    error,
  } = useGetData<BatchResponse>(`/api/batch/homepage`);

  const [changeSandboxStatus, changeSandboxStatusInfo] = usePostData(
    "/api/batch/:id/change-sandbox-status"
  );

  function batchCancelCallback(batchId: number) {
    if (batches && batches.activeBatches.length) {
      const activeBatches = batches.activeBatches.filter(
        (x) => x.batchID !== batchId
      );
      setBatches({ ...batches, activeBatches });
    }
  }

  async function handleChangeSandboxStatus(batchId: number, direction: -1 | 1) {
    if (batches) {
      const newStatus = await changeSandboxStatus(
        undefined,
        { accessor: "id", value: batchId },
        `direction=${direction}`
      );

      const sandboxBatches = batches.sandboxBatches.map((batch) => {
        if (batch.batchID === batchId) return { ...batch, status: newStatus };
        return batch;
      }) as NimbleBatch[];

      setBatches({ ...batches, sandboxBatches });
    }
  }

  return (
    <Box>
      <Typography sx={{ mb: 3 }} variant="h4" component="h2">
        Batches
      </Typography>
      <LoadingWrapper
        height={500}
        loading={isLoading}
        hasError={error.hasError}
        errorMessage={error.errorMessage}
      >
        <Box>
          <Box sx={{ mb: 3 }}>
            <BatchesTable
              batches={batches ? batches.activeBatches : []}
              tableTitle="Active Batches"
              onCancelOrder={batchCancelCallback}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <BatchesTable
              batches={batches ? batches.completedBatches : []}
              tableTitle="Completed Batches"
              isCompleted
            />
          </Box>
          <BatchesTable
            batches={batches ? batches.sandboxBatches : []}
            tableTitle="Sandbox Batches"
            isSandbox
            sandBoxChangeReqInfo={changeSandboxStatusInfo}
            onSandboxStatusChange={handleChangeSandboxStatus}
          />
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default Batches;
