import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IPosition from "../../features/Canvas/models/IPosition";
import { DesignerState } from "../store";
import {
  IObjectProperties,
  propertiesTemplate,
} from "../contexts/SelectedObjectContext";

const initialState: IObjectProperties = propertiesTemplate;

export const objectProperties = createSlice({
  name: "objectProperties",
  initialState: initialState,
  reducers: {
    updateSingleProperty: (
      state,
      action: PayloadAction<{
        property: keyof IObjectProperties;
        value: string;
      }>
    ) => {
      state[action.payload.property] = action.payload.value;
    },
    updateManyProperties: (
      state,
      action: PayloadAction<Partial<IObjectProperties>>
    ) => {
      const keys = Object.keys(action.payload) as (keyof IObjectProperties)[];
      keys.forEach((key) => {
        state[key] = action.payload[key] as string;
      });
    },
    resetProperties: () => initialState,
  },
});

export const { updateSingleProperty, updateManyProperties, resetProperties } =
  objectProperties.actions;
export const selectObjectProperties = (state: DesignerState) =>
  state.objectProperties;
export default objectProperties.reducer;
