import { IColorSwatch } from "../../../../state/slices/colorPalette";

export const colorSwatches: IColorSwatch[] = [
  {
    hex: "#D0021B",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#F5A623",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#F8E71C",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#8B572A",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#7ED321",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#417505",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#BD10E0",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#9013FE",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#4A90E2",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#50E3C2",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#000000",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#4A4A4A",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#9B9B9B",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
  {
    hex: "#FFFFFF",
    r: 208,
    g: 2,
    b: 27,
    opacity: 1,
  },
];

export const noColor: IColorSwatch = {
  hex: "#undefined",
  r: 0,
  g: 0,
  b: 0,
  opacity: 0,
};
