export default function shouldClearSelection(e, target) {
    
    // clean up to ternaries so they are readable...

    // If we are clicking any of our background or overlay objects, we should clear the selection.
    if(target) {
      if(target.name === "background" || target.name === "overlay" || target.name === "bleed" || target.__locked) return true;
    }
    // If we have no target and the canvas selection is on, we should clear the selection.
    if(!target && this.selection) return true;
    // If we have a target and the target is selectable, we should think about clearing the selection.
    // if(target && target.selectable) {
    //   if(activeObject && target !== activeObject) return true;
    // }    
    // in all other cases we do not want to clear the selection. 
    return false;
    
}

/**
 _shouldClearSelection: function (e, target) {
      var activeObjects = this.getActiveObjects(),
          activeObject = this._activeObject;

      return (
        !target
        ||
        (target &&
          activeObject &&
          activeObjects.length > 1 &&
          activeObjects.indexOf(target) === -1 &&
          activeObject !== target &&
          !this._isSelectionKeyPressed(e))
        ||
        (target && !target.evented)
        ||
        (target &&
          !target.selectable &&
          activeObject &&
          activeObject !== target)
      );
    }
 
 */