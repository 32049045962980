import { Box } from "@mui/material";
import { Context, createContext, useContext } from "react";
import { Provider } from "react-redux";

import CanvasDesigner from "../../postcard-designer/CanvasDesigner";
import CanvasProvider, {
  CanvasContext,
} from "../../postcard-designer/state/contexts/CanvasContext";
import ISvgShape from "../../postcard-designer/state/models/ISvgShape";

import StateProvider from "../../postcard-designer/state/StateProvider";

import {
  store,
  useDesignerSelector,
  DesignerState,
} from "../../postcard-designer/state/store";
import testData from "../../postcard-designer/testLoadCanvas";
import { arrowSvg } from "./mockSvgs";

// Get these from the DB
const mockShapes: ISvgShape[] = [{ name: "arrow", svg: arrowSvg }];

const Designer = () => {
  return <div></div>;
};

const DesignerWrapper = () => {
  return (
    <StateProvider>
      <Designer />
    </StateProvider>
  );
  // return (
  //   <Provider store={store}>
  //     <CanvasProvider>
  //       <Designer />
  //     </CanvasProvider>
  //   </Provider>
  // );
};

export default DesignerWrapper;
