import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import useGetData from "../../../hooks/dataFetchers/useGetData";

import { PricingWithDescription } from "../../Integrated/DesignGallery/models/pricing";
import AccountWrapper from "../components/AccountWrapper";
import { IBudgetSettings, IPaymentMethod } from "../models/AccountModel";
import Budget from "./components/Budget";
import PaymentMethods from "./components/PaymentMethods";
import PricingPlan from "./components/PricingPlan";

const PaymentAndBilling: FC = (props) => {
  /**
   * State Objects and Refs
   */
  const {
    data: budget,
    isLoading: budgetLoading,
    error: budgetError,
    updateData: setBudget,
  } = useGetData<IBudgetSettings>(`/api/integration/budget`);
  const {
    data: pricing,
    isLoading: pricingLoading,
    error: pricingError,
    updateData: setPricing,
  } = useGetData<PricingWithDescription[]>("/api/pricing");
  const {
    data: cards,
    isLoading: cardsLoading,
    error: cardsError,
    updateData: setCards,
  } = useGetData<IPaymentMethod[]>(`/api/payments/payment-methods-expanded`);

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Box>
      <AccountWrapper
        loading={pricingLoading || cardsLoading}
        pageTitle="Payment And Billing"
        loadError={cardsError.hasError}
      >
        <Box className="CreditCards">
          <Typography variant="h6" gutterBottom>
            Payment Methods
          </Typography>
          {cards && <PaymentMethods cards={cards} />}
        </Box>
        <Box sx={{ mb: 3 }} className="BudgetInformation">
          <Typography variant="h6" gutterBottom>
            Budget Settings
          </Typography>

          {budget === null ? (
            <Typography variant="body1">
              It looks like you still need to complete your integration setup.
              Please <Link to="/">click here to continue</Link>.
            </Typography>
          ) : (
            <Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                You can adjust your budget to set a maximum amount for a certain
                time period, or you may choose to manually approve orders. You
                may also choose to have no budget by selecting
                &quot;Unlimited&quot; from the dropdown.
              </Typography>
              {budget && <Budget budget={budget} />}
            </Box>
          )}
        </Box>
        <Box sx={{ mb: 3 }} className="PricingInformation">
          <Typography variant="h6" gutterBottom>
            Pricing Information
          </Typography>
          <Box sx={{ mb: 2 }}>
            {pricing && <PricingPlan pricingPlan={pricing} />}
          </Box>
          <Typography variant="body1">
            Contact us for more details about your pricing structure.
          </Typography>
        </Box>
      </AccountWrapper>
    </Box>
  );
};

export default PaymentAndBilling;
