import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import React from "react";

interface IExpandMoreProps extends BoxProps {
  isExpanded: boolean;
}

const ExpandMore = styled((props: IExpandMoreProps) => {
  const { isExpanded, ...other } = props;
  return <Box {...other} />;
})(({ theme, isExpanded }) => ({
  transform: !isExpanded ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMore;
