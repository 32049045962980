import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiApp from "../../../../../data/models/App";
import { fetchClient } from "../../../../../helpers/fetchClient";
import LocalStorage from "../../../../../helpers/LocalStorage";
import Img from "../../../../../shared/BasicHTML/Img";
import LoadingWrapper from "../../../../../shared/LoadingWrapper";
import Design from "../../../../Designs/models/Design";
import OrderCreationResponse, {
  OrderError,
} from "../../models/OrderCreationResponse";
import { OrderRequestContext } from "../../PlaceAnOrder";
import ProofGenerator from "../ProofGenerator/ProofGenerator";

interface ApproveProps {
  design: Design;
  submitCallback: (isDisabled?: boolean) => void;
}

const Approve: React.FC<ApproveProps> = ({ design, submitCallback }) => {
  /**
   * State Objects and Refs
   */
  const isOffsetLetter = design.printPDFSource === "LAS";
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({
    varsMapped: false,
    imagesCorrect: false,
    addressMapped: false,
    noSpellingErrors: false,
    qtyCorrect: false,
  });

  const [apps, setApps] = useState<ApiApp[]>([]);
  const history = useHistory();
  const [proofSuccess, setProofSuccess] = useState(
    isOffsetLetter ? true : false
  );
  const [proofLoading, setProofLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderErrors, setOrderErrors] = useState<OrderError[]>([]);
  //const [submissionError, setSubmissionError] = useState();
  const orderRequestStore = useContext(OrderRequestContext);
  const orderConfig = orderRequestStore.orderRequest;
  const request = orderRequestStore.orderRequest;

  /**
   * Component Methods
   */

  function updateCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: !checkboxes[e.target.name],
    });
  }

  function getApps() {
    fetchClient.get({
      path: "/app",
      onSuccess: onAppResponse,
    });
  }

  function onAppResponse(res: ApiApp[]) {
    setApps(res);
    const app = res.filter((x) => x.appType.toLowerCase() === "production");
    if (app && app.length) {
      orderRequestStore.updateApiKey(app[0].apiKey);
    }
  }

  function handleApiKeySelect(e: SelectChangeEvent) {
    orderRequestStore.updateApiKey(e.target.value);
  }

  function handleProofLoaded(success: boolean) {
    setProofSuccess(success);
    setProofLoading(false);
  }

  function placeOrder() {
    setIsSubmitting(true);
    submitCallback(true);
    const printPDFSource = design.printPDFSource?.toLowerCase() ?? "uproduce";
    fetchClient.post({
      path: "/place-an-order",
      data: {
        ...orderRequestStore.orderRequest,
        recipients: orderRequestStore.orderRequest.recipients.map((x) => {
          const { recipientDesignVariables, ...rest } = x;
          return {
            ...rest,
            variables: recipientDesignVariables,
          };
        }),
        type: printPDFSource?.includes("bro")
          ? "brochure"
          : printPDFSource === "las" ||
            printPDFSource === "html-letter" ||
            printPDFSource === "designer-letter"
          ? "letter"
          : "postcard",
      },
      onSuccess: handlePlaceOrderResponse,
      onError: handlePlaceOrderError,
    });
  }

  function handlePlaceOrderResponse(res: OrderCreationResponse) {
    LocalStorage.setOrderDetails({
      batchID: res.batchID,
      successfulOrders: [
        {
          orderID: res.orderID,
          extRefNbr: res.extRefNbr,
          successfulRecipientCount:
            orderRequestStore.orderRequest.recipients.length,
          failedRecipientCount: 0,
          failedRecipientList: [],
          errors: [],
        },
      ],
      failedOrders: [],
    });
    if (res.orderID) {
      history.push(`${res.orderID}/success`);
    } else {
      setIsSubmitting(false);
      setOrderErrors([
        {
          message:
            "There was an unexpected error placing your order, please try again later",
        },
      ]);
      submitCallback(false);
    }
  }

  function handlePlaceOrderError(res: any) {
    setIsSubmitting(false);
    submitCallback(false);
    setOrderErrors([
      {
        message:
          "There was an unexpected error placing your order, please try again later",
      },
    ]);
  }

  /**
   * Component Effects
   */
  useEffect(() => setProofLoading(true), []);
  useEffect(getApps, []);

  /**
   * Render
   */

  return (
    <LoadingWrapper
      loading={isSubmitting}
      message={<h5>Submitting Your Order</h5>}
      height={550}
    >
      <Grid container spacing={3}>
        {orderErrors.length > 0 && (
          <Grid item md={12}>
            <h4>There were errors with your order: </h4>
            {orderErrors.map((error) => (
              <div key={error.message} className="error pb-3">
                {error.message}
              </div>
            ))}
          </Grid>
        )}
        <Grid item md={6}>
          <h3>Approve Your Order And Design</h3>
          <p>Please inspect your preview thouroughly for the following:</p>
          <Box sx={{ mb: 3 }}>
            <div>
              <FormControlLabel
                label={
                  isOffsetLetter
                    ? "Your print-ready pdf is displaying correctly"
                    : "Any images are correctly displaying"
                }
                control={
                  <Checkbox
                    checked={checkboxes.imagesCorrect}
                    name="imagesCorrect"
                    onChange={updateCheckbox}
                    disabled={!proofSuccess}
                  />
                }
              />
            </div>
            <Box>
              <FormControlLabel
                label={`The quantity of ${request.recipients.length} is correct`}
                control={
                  <Checkbox
                    checked={checkboxes.qtyCorrect}
                    name="qtyCorrect"
                    onChange={updateCheckbox}
                    disabled={!proofSuccess}
                  />
                }
              />
            </Box>
            <div>
              <FormControlLabel
                label="There are no spelling errors"
                control={
                  <Checkbox
                    checked={checkboxes.noSpellingErrors}
                    name="noSpellingErrors"
                    onChange={updateCheckbox}
                    disabled={!proofSuccess}
                  />
                }
              />
            </div>
            {!isOffsetLetter && (
              <React.Fragment>
                <div>
                  <FormControlLabel
                    label="All design variables are correct"
                    control={
                      <Checkbox
                        checked={checkboxes.varsMapped}
                        name="varsMapped"
                        onChange={updateCheckbox}
                        disabled={!proofSuccess}
                      />
                    }
                  />
                </div>

                <div>
                  <FormControlLabel
                    label="The address block has been mapped correctly"
                    control={
                      <Checkbox
                        checked={checkboxes.addressMapped}
                        name="addressMapped"
                        onChange={updateCheckbox}
                        disabled={!proofSuccess}
                      />
                    }
                  />
                </div>

                <Box sx={{ mb: 3 }}>
                  <small>
                    <em>
                      Note: Proof shown is rendered at a lower resolution than
                      your print quality, so some artifacting may occur.
                    </em>
                  </small>
                </Box>
              </React.Fragment>
            )}

            {/* <div>
            <label htmlFor="appSelector" className="approveOrder__label">
              Select Your Api Key
            </label>
            <FormControl fullWidth className=" ">
              <InputLabel id="appSelector-label">Api Key</InputLabel>
              <Select
                fullWidth
                labelId="appSelector-label"
                id="appSelector"
                value={orderRequestStore.orderRequest.apiKey}
                label="Api Key"
                onChange={handleApiKeySelect}
              >
                {apps.map((app) => (
                  <MenuItem value={app.apiKey} key={app.apiKey}>
                    {app.appType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          </Box>
          <Button
            color="primary"
            disabled={
              (!isOffsetLetter &&
                (!checkboxes.varsMapped ||
                  !checkboxes.imagesCorrect ||
                  !checkboxes.addressMapped ||
                  !checkboxes.noSpellingErrors ||
                  !checkboxes.qtyCorrect ||
                  !proofSuccess ||
                  !orderRequestStore.orderRequest.apiKey)) ||
              (isOffsetLetter &&
                (!checkboxes.qtyCorrect ||
                  !checkboxes.imagesCorrect ||
                  !checkboxes.noSpellingErrors))
            }
            fullWidth
            size="large"
            variant="contained"
            onClick={placeOrder}
          >
            Place My Order
          </Button>
          {!proofSuccess && !proofLoading && (
            <div className="error  ">
              <div
                className="error  "
                style={{ color: "red", marginBottom: "6px" }}
              >
                An error occured generating your proof. Please double check your
                list for errors.
              </div>
              <div>
                You must have at least one recipient with a valid first & last
                name, or company, and a valid address, city, state, and zip
                code. If your list looks good and you are still getting this
                error, please contact us using the chat below.
              </div>
            </div>
          )}
          {/* {!proofSuccess && (
            <div className="text-center">
              You must successfully generate a proof to complete your order.
            </div>
          )} */}
        </Grid>
        <Grid item md={6}>
          <Box sx={{ width: "100%" }}>
            {!isOffsetLetter && (
              <ProofGenerator
                design={design}
                generateOnLoad={true}
                onProofLoaded={handleProofLoaded}
              />
            )}
          </Box>
          {isOffsetLetter && (
            <Box sx={{ width: "100%" }}>
              {orderConfig.orderconfig.globalDesignVariables.find(
                (x) => x.key === "pdfurl"
              )?.value ? (
                <Img
                  src={
                    orderConfig.orderconfig.globalDesignVariables.find(
                      (x) => x.key === "pdfurl"
                    )?.value ?? ""
                  }
                />
              ) : (
                <iframe
                  src={`${design.proofPDF}#toolbar=0&navpanes=0`}
                  width="100%"
                  height="700px"
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </LoadingWrapper>
  );
};

export default Approve;
