import React, { useState } from "react";
import moment from "moment";
import Integration from "../models/Integration";
import { Box, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { Link, useHistory } from "react-router-dom";
import { fetchClient } from "../../../helpers/fetchClient";
import LoginResponse from "../../Login/models/LoginResponse";
import BaseErrorResponse from "../../../data/models/BaseErrorResponse";
import LocalStorage from "../../../helpers/LocalStorage";
import { getSessionInfo } from "../../Login/helpers/handleLogin";

interface IntegrationsProps {
  data?: Integration[];
  syncCallback: (integration: Integration) => void;
  getPricingPlans(): void;
}

const Integrations: React.FC<IntegrationsProps> = ({
  data,
  syncCallback,
  getPricingPlans,
}: IntegrationsProps) => {
  /**
   * State Objects and Refs
   */

  const history = useHistory();
  const [toolMenuOpen, setToolMenuOpen] = useState<number | null>(null);
  const [toolMenuAnchor, setToolMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  function afterSync(res: Integration) {
    setSyncingRecord(false);
    syncCallback(res);
    setToolMenuOpen(null);
    setToolMenuAnchor(null);
  }

  const [syncingRecord, setSyncingRecord] = useState(false);

  function handleToolMenuClick(
    e: React.MouseEvent<HTMLButtonElement>,
    bAccountId: number
  ) {
    if (!syncingRecord) {
      setToolMenuAnchor(e.currentTarget);
      setToolMenuOpen(bAccountId);
    }
  }

  const columns: any[] = [
    {
      name: "BAccountID",
      selector: "bAccountID",
      sortable: true,
    },
    {
      name: "Company Name",
      selector: "companyName",
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: "registrationDate",
      sortable: true,
      format: (integration: Integration) =>
        moment(integration.registrationDate).format("MM/DD/YYYY"),
    },
    {
      name: "",
      cell: (integration: Integration) => {
        function handleSync() {
          setSyncingRecord(true);
          fetchClient.post({
            path: `/admin/integrations/sync-integrations/${integration.bAccountID}`,
            onSuccess: (data: Integration) => afterSync(data),
          });
        }

        return (
          <div>
            <Button
              onClick={(e) => handleToolMenuClick(e, integration.bAccountID)}
            >
              Tools{" "}
              <Box
                sx={{ ml: 1 }}
                component="i"
                className="fas fa-chevron-down d-inline-block"
              />
            </Button>
            <Menu
              open={toolMenuOpen === integration.bAccountID}
              onClose={() => (!syncingRecord ? setToolMenuOpen(null) : null)}
              anchorEl={toolMenuAnchor}
            >
              <MenuItem>
                <Link
                  to={`/admin/integrations/${integration.bAccountID}/designs`}
                  style={{ color: "#212121" }}
                >
                  Manage Designs
                </Link>
              </MenuItem>
              <MenuItem onClick={() => handleLogin(integration)}>
                Log In
              </MenuItem>
              <MenuItem onClick={getPricingPlans}>Pricing Plan</MenuItem>
              <MenuItem onClick={handleSync}>
                Sync With Nimble{" "}
                {syncingRecord === true && (
                  <CircularProgress color="primary" size="16px" />
                )}
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  /**
   * Component Methods
   */

  function handleLogin(integration: Integration) {
    fetchClient.post({
      path: "/auth/admin-login",
      onSuccess: onLoginSuccess,
      onError: onLoginFailure,
      data: { companyId: integration.bAccountID },
    });
  }

  async function onLoginSuccess(res: LoginResponse) {
    const token = LocalStorage.getToken();
    LocalStorage.setPrimaryToken(token ?? "");
    LocalStorage.setToken(res.token);
    LocalStorage.setCompanies(res.companies);
    LocalStorage.setCurrentCompany(res.currentCompanyId);
    const session = await getSessionInfo();
    if (session) LocalStorage.setSession(session);
    history.push("/");
  }

  function onLoginFailure(res: BaseErrorResponse) {
    console.log(res);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <SearchableDataTable
      tableTitle={"Integrations"}
      searchableColumns={["bAccountID", "companyName"]}
      columns={columns}
      data={data ?? []}
    />
  );
};

export default Integrations;
