import {
  IText,
  Object as IObject,
  Group,
  ActiveSelection,
  IEvent,
} from "fabric/fabric-impl";
import { useContext, useEffect, useRef, useState } from "react";
import generateGuid from "../../helpers/generateGuid";
import RESOLUTION from "../constants/RESOLUTION";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { Textbox, fabric } from "fabric";
import useHistory from "./useHistory";
import useLayers from "./useLayers";
import textResizer from "../features/Canvas/functions/textResizer";
import DATALESS_PROPERTIES from "../constants/DATALESS_PROPERTIES";
import asyncClone from "../features/Canvas/functions/asyncClone";
import duplicateObjects from "../features/Canvas/functions/duplicateObjects";
import toggleCanvasSelection from "../features/Canvas/functions/toggleCanvasSelection";

const useKeyboardShortcuts = (disabled: boolean | string = false) => {
  const { undo, redo, history } = useHistory();
  const { createLayer } = useLayers();
  const canvas = useContext(CanvasContext);
  const selectedObject = canvas && canvas._activeObject;
  const selectedObjectRef = useRef(selectedObject);
  selectedObjectRef.current = selectedObject;
  const hookFunctionsRef = useRef({ undo, redo, copy, paste });
  const lockedSelectionRef = useRef<IObject>();
  const [clipboard, setClipboard] = useState<IObject[]>([]);
  const clipboardRef = useRef(clipboard);
  clipboardRef.current = clipboard;
  const disabledRef = useRef(disabled);
  disabledRef.current = disabled;
  const xLength = useRef(0);
  const originalX = useRef(0);
  const originalY = useRef(0);
  const yLength = useRef(0);
  const threshold = 20;
  const lockDir = useRef("none");

  function init() {
    if (!canvas) return;
    window.addEventListener("keydown", handleShortcuts);
    window.addEventListener("keyup", handleKeyUpShortcuts);
    canvas.on("selection:created", setupOriginalCoords);
    canvas.on("selection:updated", setupOriginalCoords);
    canvas.on("object:moving", lockDirection);
    canvas.on("object:modified", resetDirectionLock);
    return dispose;
  }

  // function handleDisable() {
  //   if (!canvas) return;
  //   window.removeEventListener("keydown", handleShortcuts);
  //   window.removeEventListener("keyup", handleKeyUpShortcuts);
  //   canvas.off("selection:created", setupOriginalCoords);
  //   canvas.off("selection:updated", setupOriginalCoords);
  //   canvas.off("object:moving", lockDirection);
  //   canvas.off("object:modified", resetDirectionLock);
  //   if (!disabled) {
  //     init();
  //   }
  // }

  function setupOriginalCoords(event: IEvent) {
    if (event && event.selected && canvas) {
      const target = canvas.getActiveObject();
      if (target) {
        lockDir.current = "none";
        target.lockMovementX = false;
        target.lockMovementY = false;
        originalX.current = target.left ?? 0;
        originalY.current = target.top ?? 0;
      }
    }
  }
  function resetDirectionLock(event: IEvent) {
    if (event.target) {
      event.target.lockMovementX = false;
      event.target.lockMovementY = false;
      lockDir.current = "none";
      originalX.current = event.target.left ?? 0;
      originalY.current = event.target.top ?? 0;
    }
  }
  function lockDirection(event: IEvent<MouseEvent>) {
    if (event.target) {
      const left = event.target.left ?? 0;
      const top = event.target.top ?? 0;
      const xMovement = Math.abs(left - originalX.current);
      const yMovement = Math.abs(top - originalY.current);

      if (event.e && event.e.shiftKey) {
        if (lockDir.current === "none") {
          if (xMovement > threshold && yMovement > threshold) {
            if (xMovement > yMovement) {
              lockDir.current = "x";
              event.target.top = originalY.current;
            } else {
              lockDir.current = "y";
              event.target.left = originalX.current;
            }
          } else if (xMovement > threshold) {
            lockDir.current = "x";
            event.target.top = originalY.current;
          } else if (yMovement > threshold) {
            lockDir.current = "y";
            event.target.left = originalX.current;
          }
        }
        if (lockDir.current !== "none") {
          if (lockDir.current === "x") {
            event.target.lockMovementY = true;
          } else if (lockDir.current === "y") {
            event.target.lockMovementX = true;
          }
        }
      } else {
        lockDir.current = "none";
        event.target.lockMovementX = false;
        event.target.lockMovementY = false;
      }
    }
  }

  function copy() {
    if (
      !selectedObjectRef.current ||
      selectedObjectRef.current.name?.includes("qrcode")
    )
      return;
    if (
      selectedObjectRef.current.type !== "textbox" ||
      !(selectedObjectRef.current as IText).isEditing
    ) {
      // if (selectedObjectRef.current.type === "activeSelection") {
      //   const selected = (selectedObjectRef.current as Group)._objects;

      //   setClipboard(selected);
      // } else
      setClipboard([selectedObjectRef.current]);
    } else {
      setClipboard([]);
    }
  }

  async function paste(inPlace: boolean) {
    if (fabric.copiedText && fabric.copiedTextStyle.length) {
      fabric.copiedTextStyle = fabric.copiedTextStyle.map((style) => {
        return {};
      });
    }
    if (!clipboardRef.current.length || !canvas) return;
    if (
      selectedObjectRef.current &&
      ((selectedObjectRef.current.type === "textbox" &&
        (selectedObjectRef.current as IText).isEditing) ||
        selectedObjectRef.current.name?.includes("qrcode"))
    ) {
      return;
    }

    const clones = await duplicateObjects(
      clipboardRef.current,
      createLayer,
      canvas,
      inPlace
    );

    canvas.setActiveObject(new fabric.ActiveSelection(clones, { canvas }));
    canvas.renderAll();
  }

  function handleNudge(e: KeyboardEvent) {
    const selectedObject = canvas?.getActiveObject();
    if (
      !canvas ||
      !selectedObject ||
      !selectedObject.top ||
      !selectedObject.left
    )
      return;
    e.preventDefault();
    const direction = e.key.replace("Arrow", "");
    const amount = e.shiftKey ? (e.altKey ? 50 : 10) : 1;
    switch (direction) {
      case "Up": {
        selectedObject.top -= amount;
        break;
      }
      case "Down": {
        selectedObject.top += amount;
        break;
      }
      case "Left": {
        selectedObject.left -= amount;
        break;
      }
      case "Right": {
        selectedObject.left += amount;
        break;
      }
      default: {
        break;
      }
    }
    selectedObject.fire("modified");
    canvas.renderAll();
  }

  async function handleShortcuts(e: KeyboardEvent) {
    if (e.target && (e.target as HTMLElement).tagName === "INPUT") return;
    if (!disabledRef.current) {
      if (e.key && e.key.includes("Arrow")) {
        handleNudge(e);
      }
      if (
        e.key === "Escape" &&
        canvas &&
        canvas._activeObject &&
        !disabledRef.current
      ) {
        canvas.discardActiveObject();
        canvas.renderAll();
      }
    }
    if (disabledRef.current !== "save") {
      if (e.ctrlKey || e.metaKey) {
        if (e.key.toLowerCase() === "z" && !e.shiftKey) {
          await hookFunctionsRef.current.undo();
        }
        if (e.key.toLowerCase() === "z" && e.shiftKey) {
          await hookFunctionsRef.current.redo();
        }
        if (!disabledRef.current) {
          if (e.key.toLowerCase() === "c") {
            hookFunctionsRef.current.copy();
          }
          if (e.key.toLowerCase() === "v") {
            hookFunctionsRef.current.paste(e.shiftKey);
          }
        }
      }
    }
  }

  function handleKeyUpShortcuts(e: KeyboardEvent) {
    // if ((!e.ctrlKey || !e.altKey) && canvas) {
    //   canvas
    //     .getObjects()
    //     .filter(
    //       (x) =>
    //         x.name !== "background" &&
    //         x.name !== "overlay" &&
    //         x.name !== "bleed"
    //     )
    //     .forEach((x) => {
    //       //@ts-ignore
    //       if (!x.__locked) x.set("selectable", true);
    //     });
    // }
  }

  function dispose() {
    window.removeEventListener("keydown", handleShortcuts);
  }

  useEffect(init, [canvas]);

  useEffect(() => {
    hookFunctionsRef.current = {
      ...hookFunctionsRef.current,
      undo,
      redo,
      copy,
      paste,
    };
  }, [history, selectedObject, clipboard]);
};

export default useKeyboardShortcuts;
