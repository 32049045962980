import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { fetchClient } from "../../helpers/fetchClient";
import LocalStorage, { setTokenExpires } from "../../helpers/LocalStorage";
import useQuery from "../../hooks/useQuery";
import SessionInfo from "../../data/models/SessionInfo";
import LoginRequest from "./models/LoginRequest";
import LoginResponse from "./models/LoginResponse";
import "./Login.scss";
import logger from "../../helpers/logger";
import isAdminUser from "../../helpers/isAdminUser";

import Form from "../../shared/BasicHTML/Form";
import ForgotPasswordDialog from "./components/ForgotPasswordDialog";
import LoginLayout from "../../shared/LoginLayout/LoginLayout";
import handleLogin, { getSessionInfo } from "./helpers/handleLogin";
import { eraseCookie } from "../Integrated/helpers/cookies";

const Login: React.FC = (props) => {
  /**
   * State Objects and Refs
   */
  const history = useHistory();
  const query = useQuery();
  const [loginForm, setLoginForm] = useState<LoginRequest>({
    email: "",
    password: "",
  });
  // the local storage email address if it exists
  const [savedEmail, setSavedEmail] = useState<string | null>(null);
  // the checkbox to remember
  const [rememberEmail, setRememberEmail] = useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  // form state objects
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  /**
   * Component Methods
   */
  function onLoad() {
    eraseCookie("token");
    // check for the saved email address, if it exists update our state objects
    LocalStorage.clearToken();
    LocalStorage.clearSession();
    const _savedEmail: string = LocalStorage.getSavedEmail();
    if (_savedEmail) {
      setSavedEmail(_savedEmail);
      setRememberEmail(true);
      setLoginForm({ ...loginForm, email: _savedEmail });
    }
    logger.log(_savedEmail);
  }

  function onErrorResponse(error: any) {
    setIsSubmitting(false);
    setIsInvalid(true);
    setErrorMessage("Email or password is incorrect");
  }

  async function getSession() {
    const session = await getSessionInfo();
    if (session) onSessionInfoResponse(session);
  }

  function onSessionInfoResponse(data: SessionInfo) {
    setIsSubmitting(false);
    LocalStorage.setSession(data);
    setLoginForm({ email: "", password: "" });
    const referrer = query.get("referrer");
    if (referrer) {
      history.push(referrer);
    } else if (isAdminUser()) {
      history.push("/admin");
    } else history.push("/");
  }

  function onLogin(data: LoginResponse) {
    setIsInvalid(false);

    // if the login is valid, we can save the email if the user has selected the option
    if (rememberEmail) LocalStorage.setSavedEmail(loginForm.email);
    handleLogin(data, loginForm.email);
    if (isAdminUser()) {
      history.push("/admin");
    } else {
      getSession();
    }

    /**
     * @TODO Add Notification and Session Info calls
     */

    /**
     * @TODO Add redirect query string logic
     */

    // redirect to homepage
    //history.push("/");
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    LocalStorage.clearSession();
    // backup validation in case html5 validation doesn't work or is bypassed somehow
    if (!loginForm.email || !loginForm.password) {
      setIsInvalid(true);
      setErrorMessage("Please enter a valid email and password");
    }

    // if the user no longer wants to save their email address we remove it on submit
    if (savedEmail && rememberEmail === false) LocalStorage.clearSavedEmail();

    setIsSubmitting(true);

    fetchClient.post({
      path: "/auth/login",
      onSuccess: onLogin,
      data: loginForm,
      onError: onErrorResponse,
    });
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
    });
  }

  function handleCheckChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    switch (event.target.name) {
      default:
        setRememberEmail(!rememberEmail);
    }
  }

  /**
   * Component Effects
   */
  // on load
  useEffect(onLoad, []);

  /**
   * Render
   */
  return (
    <LoginLayout>
      <Box>
        <Typography variant="h5" mb={2} component={"h1"}>
          Login To Your Account
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Email Address"
              type="email"
              error={isInvalid}
              name="email"
              onChange={handleInputChange}
              value={loginForm.email}
              required
              disabled={isSubmitting}
              size="small"
              fullWidth
            />
          </Box>

          <Box mb={2}>
            <TextField
              name="password"
              type="password"
              onChange={handleInputChange}
              value={loginForm.password}
              required
              disabled={isSubmitting}
              label="Password"
              fullWidth
              size="small"
            />
          </Box>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberEmail}
                  name="rememberEmail"
                  onChange={handleCheckChange}
                  color="primary"
                  size="small"
                />
              }
              label="Remember Email Address"
            />
          </Box>
          {isInvalid && (
            <Box mb={2}>
              <Typography variant="caption" color="red">
                {errorMessage}
              </Typography>
            </Box>
          )}
          <Box>
            <Box component="span" mr={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Login
              </Button>
            </Box>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => setForgotPassword(true)}
            >
              I forgot my password!
            </Button>
          </Box>
        </Form>
        <ForgotPasswordDialog
          open={forgotPassword}
          onClose={() => setForgotPassword(false)}
        />
      </Box>
    </LoginLayout>
  );
};

export default Login;
