type Direction = "asc" | "dsc";

export function sortAlphabetically(
  inputA: string,
  inputB: string,
  direction: Direction = "asc"
) {
  const normalizedA = inputA.toUpperCase();
  const normalizedB = inputB.toUpperCase();
  if (direction.toLowerCase() === "asc") {
    if (normalizedA < normalizedB) return -1;
    if (normalizedA > normalizedB) return 1;
    return 0;
  }

  if (normalizedA < normalizedB) return 1;
  if (normalizedA > normalizedB) return -1;
  return 0;
}
