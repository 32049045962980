import React, { useState, useEffect } from "react";
import PendingTransaction from "../models/PendingTransaction";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { TableColumn } from "react-data-table-component";
import moment from "moment";

import { usePostData, usePutData } from "../../../hooks/useFetch";
import RecipientListTable from "./RecipientListTable";
import exportCsv from "../../../helpers/exportCsv";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import Img from "../../../shared/BasicHTML/Img";
import LocalStorage from "../../../helpers/LocalStorage";

interface PendingTransactionsProps {
  pendingTransactions: PendingTransaction[];
  searchTerm: string;
  cancelCallback: (transaction: PendingTransaction) => void;
}

const PendingTransactions: React.FC<PendingTransactionsProps> = ({
  pendingTransactions,
  searchTerm,
  cancelCallback,
}) => {
  /**
   * State Objects and Refs
   */
  const [recipientsOpen, setRecipientsOpen] = useState(-1);
  const [designOpen, setDesignOpen] = useState(-1);
  const [cancelOpen, setCancelOpen] = useState(-1);

  const activeTransaction = getOrder(recipientsOpen, designOpen, cancelOpen);

  const [toolMenuOpen, setToolMenuOpen] = useState<number | null>(null);
  const [toolMenuAnchor, setToolMenuAnchor] =
    useState<HTMLButtonElement | null>(null);

  const session = LocalStorage.getSession();
  const enableRecipientPrivacy = session?.enableRecipientPrivacy;

  const [cancelOrder, cancelOrderStatus] = usePutData("/api/orders/:id/cancel");

  /**
   * Component Methods
   */
  function getOrder(id1: number, id2: number, id3: number) {
    if (id1 === -1 && id2 === -1 && id3 === -1) return null;
    if (id1 !== -1) return pendingTransactions.find((x) => x.orderID === id1);
    if (id2 !== -1) return pendingTransactions.find((x) => x.orderID === id2);
    if (id3 !== -1) return pendingTransactions.find((x) => x.orderID === id3);
    return null;
  }

  function exportRecipients() {
    if (activeTransaction) {
      exportCsv(
        activeTransaction.recipients,
        `Records for order #${activeTransaction.orderID}`
      );
    }
  }

  async function cancelActiveTransaction() {
    if (activeTransaction) {
      await cancelOrder(undefined, {
        accessor: "id",
        value: activeTransaction?.orderID,
      });
      cancelCallback(activeTransaction);
      setCancelOpen(-1);
    }
  }

  function handleToolMenuOpen(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    orderId: number
  ) {
    setToolMenuOpen(orderId);
    setToolMenuAnchor(e.currentTarget);
  }

  function handleToolMenuClose() {
    setToolMenuOpen(null);
    setToolMenuAnchor(null);
  }

  const columns: TableColumn<PendingTransaction>[] = [
    {
      name: "Batch ID",
      selector: (row) => row.batchID,
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => row.orderDate,
      sortable: true,
      format: (row) =>
        moment.utc(row.orderDate).local().format("MM/DD/YYYY h:mma"),
    },
    {
      name: "Processing Date",
      selector: (row) => row.processDate,
      sortable: true,
      format: (row) => moment(row.processDate).format("MM/DD/YYYY"),
    },
    {
      name: "# of Recipients",
      selector: (row) => row.recipients.length,
      sortable: true,
      format: (row) => (row.recipients ? row.recipients.length : row.listCount),
    },
    {
      name: "Ext. Ref #",
      selector: (row) => (row.extRefNbr ? row.extRefNbr : ""),
      sortable: true,
    },
    {
      name: "",
      right: true,
      cell: (order) => (
        <Box>
          <Button
            onClick={(e) => handleToolMenuOpen(e, order.orderID)}
            color="primary"
          >
            Actions <KeyboardArrowDownIcon />
          </Button>
          <Menu
            anchorEl={toolMenuAnchor}
            open={toolMenuOpen === order.orderID}
            onClose={() => setToolMenuOpen(null)}
          >
            {!enableRecipientPrivacy &&
              order.recipients &&
              order.recipients.length > 0 && (
                <MenuItem onClick={() => setRecipientsOpen(order.orderID)}>
                  View Recipients
                </MenuItem>
              )}
            <MenuItem onClick={() => setDesignOpen(order.orderID)}>
              View Design
            </MenuItem>
            <MenuItem onClick={() => setCancelOpen(order.orderID)}>
              Cancel This Order
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  /**
   * Component Effects
   */

  useEffect(handleToolMenuClose, [recipientsOpen, designOpen, cancelOpen]);

  /**
   * Render
   */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SearchableDataTable
        columns={columns}
        data={pendingTransactions}
        searchableColumns={["orderID", "orderDate", "extRefNbr"]}
        hideSearchBar
        overrideSearchTerm={searchTerm}
        paginationRowsPerPageOptions={[5, 10, 25, 50]}
        paginationPerPage={5}
        pagination
        dense
        fixedHeader={pendingTransactions.length > 0 ? true : false}
        fixedHeaderScrollHeight="215px"
        responsive
        noDataComponent={
          pendingTransactions.length === 0
            ? "You have no pending transactions"
            : undefined
        }
        elevation={0}
        customStyles={{
          table: {
            style: {
              maxHeight: pendingTransactions.length > 0 ? "215px" : "271px",
              height: pendingTransactions.length > 0 ? "215px" : "271px",
            },
          },
        }}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={recipientsOpen !== -1 || designOpen !== -1 || cancelOpen !== -1}
      >
        {" "}
        {activeTransaction !== undefined && activeTransaction !== null && (
          <React.Fragment>
            {recipientsOpen !== -1 && (
              <React.Fragment>
                <DialogContent>
                  <RecipientListTable
                    recipients={activeTransaction.recipients}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={exportRecipients}>Export CSV</Button>
                  <Button color="primary" onClick={() => setRecipientsOpen(-1)}>
                    Close
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
            {designOpen !== -1 && (
              <React.Fragment>
                <DialogContent dividers={false}>
                  <GridRow>
                    <GridItem xs={12} md={6}>
                      <Typography variant="h6" component="h4" sx={{ mb: 3 }}>
                        Design Front
                      </Typography>
                      <Paper elevation={3}>
                        <Img
                          src={activeTransaction.design.proofFront}
                          className="img-fluid"
                        />
                      </Paper>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Typography variant="h6" component="h4" sx={{ mb: 3 }}>
                        Design Back
                      </Typography>
                      <Paper elevation={3}>
                        <Img
                          src={activeTransaction.design.proofBack}
                          alt=""
                          className="img-fluid"
                        />
                      </Paper>
                    </GridItem>
                  </GridRow>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => setDesignOpen(-1)}>
                    Close
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
            {cancelOpen !== -1 && (
              <React.Fragment>
                <DialogTitle>Are you sure you want to cancel?</DialogTitle>
                <DialogContent dividers={false}>
                  <DialogContentText>
                    You are about to cancel an order that has not been processed
                    yet. Are you sure you would like to do this?
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={cancelActiveTransaction}
                    disabled={cancelOrderStatus.status === "loading"}
                  >
                    Confirm
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setCancelOpen(-1)}
                    disabled={cancelOrderStatus.status === "loading"}
                  >
                    Close
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Dialog>
    </Box>
  );
};

export default PendingTransactions;
