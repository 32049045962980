import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";
import LocalStorage from "../../helpers/LocalStorage";
import { FC } from "react";
import { Box } from "@mui/material";

interface AuthExampleProps {
  apiKey: string;
}

const AuthExample: FC<AuthExampleProps> = ({ apiKey }) => {
  /**
   * State Objects and Refs
   */
  const style = { ...nord };
  style["hljs-comment"] = { color: "green" };
  const email = LocalStorage.getEmail();
  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Box>
      <SyntaxHighlighter language="json" style={style}>
        {`// Endpoint: https://v3.pcmintegrations.com/auth/login
// Method: POST
{
    "apiKey" : "${apiKey}",
    "apiSecret" : "{apiSecret}"
}`}
      </SyntaxHighlighter>
    </Box>
  );
};

export default AuthExample;
