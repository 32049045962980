import React, { useContext, useEffect, useRef, useState } from "react";
import QrCode, {
  QrCodeRaw,
} from "../../../../shared/QrCodeEditor/models/QrCode";
import tw, { css } from "twin.macro";
import { getAsync, postAsync, putAsync } from "../../../../helpers/asyncFetch";
import { QrCodeStylingOptions } from "../../../../shared/QrCodeEditor/types/QrCodeStylingTypes";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import qrCode, {
  selectQrCode,
  setQrCodeId,
  toggleQrCodeSelector,
  createNewQrCode,
  updateSelectedQrCode,
  setQrCodes,
} from "../../../state/slices/qrCode";
import Modal, {
  ModalActions,
  ModalBody,
  ModalTitle,
} from "../../DesignerInterface/shared/Modal";
import EditQrCode from "../../../../routes/QrCodes/EditQrCode";
import QrCodeEditor from "../../../../shared/QrCodeEditor/QrCodeEditor";
import DesignerButton from "../../DesignerInterface/shared/DesignerButton";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import useLayers from "../../../hooks/useLayers";

import QRCodeStyling from "qr-code-styling";
import asyncImageFromUrl from "../../Canvas/functions/asyncImageFromUrl";
import blobToBase64 from "../../../../helpers/blobToBase64";
import generateGuid from "../../../../helpers/generateGuid";
import { createBleedClipPath } from "../../Canvas/functions/createBackgroundClipPath";
import FabricQrCode from "../../../models/types/FabricQrCode";
import { fabric } from "fabric";
import SelectableQrCode from "./SelectableQrCode";
import { setCurrentTool } from "../../../state/slices/toolSettings";
import { Tool } from "../../../state/models/ICanvasTool";
import { Image } from "fabric/fabric-impl";
import {
  createCanvasQrCodeObject,
  getQrCodeBase64,
  getQrCodeSvg,
} from "../functions/createCanvasQrCodeObject";
import isAdminUser from "../../../../helpers/isAdminUser";
import { selectDesignInformation } from "../../../state/slices/designInformation";

const QrCodeSelector = () => {
  const qrCodeState = useDesignerSelector(selectQrCode);
  const canvas = useContext(CanvasContext);
  const { createLayer, selectedPage, pages } = useLayers();
  const designInformation = useDesignerSelector(selectDesignInformation);
  const dispatch = useDesignerDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editQrChanges, setEditQrChanges] = useState<QrCode>();
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [confirmBackOpen, setConfirmBackOpen] = useState(false);

  const qrCodeRef = useRef(qrCodeState.selectedQrCode);

  function handleChange(qrCode: QrCode) {
    if (isEditMode) {
      setHasChanges(true);
      setEditQrChanges(qrCode);
    } else dispatch(updateSelectedQrCode(qrCode));
  }

  // User clicked Select & Edit button on saved QR Code
  function handleEditSelect(qrCode: QrCode) {
    handleSelect(qrCode);
    setIsEditMode(true);
  }

  // User selected a saved QR Code
  function handleSelect(qrCode: QrCode) {
    qrCodeRef.current = qrCode;
    dispatch(updateSelectedQrCode(qrCode));
  }

  // User clicked New QR Code button
  function handleNewClicked() {
    if (
      qrCodeState.selectedQrCode &&
      qrCodeState.selectedQrCode.qrCodeID !== -1
    ) {
      qrCodeRef.current = qrCodeState.selectedQrCode;
    }
    dispatch(createNewQrCode());
    setIsEditMode(true);
  }

  // User clicked OK Button at bottom of modal.
  async function handleOkClick() {
    if (!canvas) return;
    const qr = hasChanges
      ? { ...editQrChanges }
      : { ...qrCodeState.selectedQrCode };

    if (qrCodeRef.current?.qrCodeID) {
      qr.qrCodeID = qrCodeRef.current.qrCodeID;
    }
    if (!qr) return;

    if (qr.qrCodeID === -1) {
      const preview = qr.config ? await getQrCodeSvg(qr.config) : "";
      const savedQrCode = await postAsync<QrCodeRaw>(`/qr-codes`, {
        url: qr.config?.data,
        config: JSON.stringify(qr.config),
        preview: preview,
        bAccountID: isAdminUser() ? designInformation.bAccountID : undefined,
      });
      if (savedQrCode) {
        qr.qrCodeID = savedQrCode.qrCodeID;
        const rawQrCodes =
          isAdminUser() && designInformation.bAccountID
            ? await getAsync<QrCodeRaw[]>(
                `/qr-codes?bAccountID=${designInformation.bAccountID}`,
              )
            : await getAsync<QrCodeRaw[]>(`/qr-codes`);
        if (rawQrCodes) {
          const qrCodes = rawQrCodes.map((x) => {
            return {
              ...x,
              config: JSON.parse(x.config as string) as QrCodeStylingOptions,
            };
          });

          dispatch(setQrCodes(qrCodes));
        }
      }
    } else if (hasChanges) {
      const preview = qr.config ? await getQrCodeSvg(qr.config) : "";
      const savedQrCode = await putAsync<QrCodeRaw>(
        `/qr-codes/${qr.qrCodeID}`,
        {
          url: qr.config?.data,
          config: JSON.stringify(qr.config),
          preview,
        },
      );
      if (savedQrCode) {
        qr.qrCodeID = savedQrCode.qrCodeID;
        const rawQrCodes =
          isAdminUser() && designInformation.bAccountID
            ? await getAsync<QrCodeRaw[]>(
                `/qr-codes?bAccountID=${designInformation.bAccountID}`,
              )
            : await getAsync<QrCodeRaw[]>(`/qr-codes`);
        if (rawQrCodes) {
          const qrCodes = rawQrCodes.map((x) => {
            return {
              ...x,
              config: JSON.parse(x.config as string) as QrCodeStylingOptions,
            };
          });

          dispatch(setQrCodes(qrCodes));
        }
      }
    }
    if (hasChanges) {
      dispatch(updateSelectedQrCode(editQrChanges ?? null));
    }
    const existingQrObject = canvas._objects.find((x) =>
      x.name?.includes("qrcode"),
    ) as FabricQrCode | undefined;
    if (!qr) return;
    if (!existingQrObject && qr.config) {
      const obj = await createCanvasQrCodeObject(
        qr.config,
        canvas,
        qr.qrCodeID,
        qr.preview,
      );
      if (obj) {
        canvas.add(obj);
        canvas.renderAll();

        createLayer(obj.name ?? "");
        dispatch(setCurrentTool(Tool.select));
        dispatch(toggleQrCodeSelector());
      }
    } else if (qr.config && existingQrObject) {
      const img = qr.preview ?? (await getQrCodeBase64(qr.config));
      existingQrObject.setSrc(img, () => {
        existingQrObject.__qrCodeId = qr?.qrCodeID;
        canvas.renderAll();
        dispatch(setCurrentTool(Tool.select));
        dispatch(toggleQrCodeSelector());
      });
      updateOtherPages(img);
    }
    dispatch(setQrCodeId(qr?.qrCodeID ?? null));
    function updateOtherPages(img: string) {
      const otherPages = pages.filter((x) => x.name !== selectedPage);
      otherPages.forEach((page) => {
        const objects = page.objects;
        const qrCode = objects.find((x) => x.name?.includes("qrcode")) as
          | FabricQrCode
          | undefined;
        if (qrCode) {
          qrCode.setSrc(img, () => {
            qrCode.__qrCodeId = qr?.qrCodeID;
          });
        }
      });
    }

    // if has changes
    // dispatch to update selected with editQrChanges object
    setHasChanges(false);
  }

  // User clicked Cancel at bottom of modal
  function handleCancelClick() {
    if (isEditMode) {
      setConfirmCancelOpen(true);
      setHasChanges(false);
      return;
    }
    if (
      qrCodeState.selectedQrCode?.qrCodeID !== qrCodeState.qrCodeId ||
      qrCodeState.qrCodeId === -1
    ) {
      dispatch(updateSelectedQrCode(null));
    }
    dispatch(toggleQrCodeSelector());
    setHasChanges(false);
  }

  // Back button clicked on Edit Mode
  function handleBackClick() {
    if (hasChanges) {
      setConfirmBackOpen(true);
      return;
    }
    setIsEditMode(false);
  }

  function handleSaveChanges() {}

  // If they confirm they want to quit or confirm they want to go back to the gallery of qr codes
  function handleSaveCancel() {
    if (confirmBackOpen) {
      setConfirmBackOpen(false);
      setIsEditMode(false);
      setHasChanges(false);
      if (qrCodeRef.current) {
        setEditQrChanges({ ...qrCodeRef.current });
      } else setEditQrChanges(undefined);
      if (
        !qrCodeState.qrCodeId ||
        qrCodeState.qrCodeId === -1 ||
        qrCodeState.selectedQrCode?.qrCodeID === -1
      ) {
        if (qrCodeRef.current) {
          dispatch(updateSelectedQrCode(qrCodeRef.current));
        } else dispatch(updateSelectedQrCode(null));
      }
    } else {
      setConfirmCancelOpen(false);
      dispatch(toggleQrCodeSelector());
      if (
        !qrCodeState.qrCodeId ||
        qrCodeState.qrCodeId === -1 ||
        qrCodeState.selectedQrCode?.qrCodeID === -1
      ) {
        if (qrCodeRef.current) {
          dispatch(updateSelectedQrCode(qrCodeRef.current));
        } else dispatch(updateSelectedQrCode(null));
      }
    }
    setIsEditMode(false);
  }

  useEffect(() => {
    if (qrCodeState.selectedQrCode) {
      if (qrCodeState.selectorOpen === "edit") {
        setIsEditMode(true);
        setEditQrChanges(qrCodeState.selectedQrCode);
      } else {
        setIsEditMode(false);
        setEditQrChanges(undefined);
      }
    } else if (qrCodeState.qrCodeId && qrCodeState.qrCodeId !== -1) {
      const code = qrCodeState.qrCodes.find(
        (x) => x.qrCodeID === qrCodeState.qrCodeId,
      );
      if (code) handleSelect(code);
    }
  }, [qrCodeState.selectorOpen]);

  return (
    <React.Fragment>
      <Modal isOpen={Boolean(qrCodeState.selectorOpen)}>
        <ModalTitle>Add QR Code</ModalTitle>
        <ModalBody>
          {isEditMode && (
            <div>
              {qrCodeState.qrCodes.length > 0 && (
                <div css={tw`mb-2`}>
                  <DesignerButton onClick={handleBackClick}>
                    Back To QR Codes
                  </DesignerButton>
                </div>
              )}
              <QrCodeEditor
                qrCodeID={qrCodeState.qrCodeId ?? undefined}
                qrCodeOverride={qrCodeState.selectedQrCode ?? undefined}
                onChangeOverride={handleChange}
                loading={false}
              />
            </div>
          )}
          {!isEditMode && (
            <div>
              <DesignerButton onClick={handleNewClicked}>
                Create New QR Code
              </DesignerButton>
              <div
                css={[
                  css`
                    max-height: 300px;
                    overflow-y: auto;
                  `,
                ]}
              >
                {qrCodeState.qrCodes.map((code) => (
                  <SelectableQrCode
                    key={code.qrCodeID}
                    qrCode={code}
                    onEdit={handleEditSelect}
                    onSelect={handleSelect}
                    isActive={
                      qrCodeState.selectedQrCode?.qrCodeID === code.qrCodeID
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalActions>
          <DesignerButton onClick={handleOkClick} css={tw`mr-2`}>
            Ok
          </DesignerButton>{" "}
          <DesignerButton
            onClick={handleCancelClick}
            css={tw`bg-white text-blue hover:bg-white`}
          >
            Cancel
          </DesignerButton>
        </ModalActions>
      </Modal>
      <Modal isOpen={confirmCancelOpen || confirmBackOpen}>
        <ModalTitle>Unsaved Changes</ModalTitle>
        <ModalBody>
          Are you sure you want to leave the QR Code editor without saving?
        </ModalBody>
        <ModalActions>
          <DesignerButton onClick={handleSaveCancel} css={tw`mr-2`}>
            Yes
          </DesignerButton>
          <DesignerButton
            onClick={() => {
              if (confirmCancelOpen) {
                setConfirmCancelOpen(false);
              } else {
                setConfirmBackOpen(false);
              }
            }}
            css={tw`bg-white text-blue hover:bg-white`}
          >
            Cancel
          </DesignerButton>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default QrCodeSelector;
