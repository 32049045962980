import { forwardRef, InputHTMLAttributes } from "react";
import tw, { css } from "twin.macro";

const reset = css`
  margin: 0;
  border: 0;
  padding: 0;
  max-width: 100%;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: inherit;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus {
    outline: 0;
    -webkit-appearance: none;
  }
`;

const InputReset = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return <input {...props} css={reset} ref={ref} />;
});

export default InputReset;
