import React, { useMemo, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import useClickOutside from "../../../../../hooks/useClickOutside";
import propertiesStyles from "../../Properties/propertiesStyles";
import { SelectInputIcon } from "../SvgComponents";

interface IDesignerSelectProps {
  children: React.ReactElement<any> | React.ReactElement<any>[];
  value: string;
  onChange: (property: string, value: string) => void;
  name: string;
  width?: number;
  onClick?: () => void;
}

const styles = {
  wrapper: (width?: number) => [
    tw`relative w-full text-xs`,
    width !== undefined && css({ width: width + "px" }),
  ],
  dropdown: (isOpen: boolean) => [
    !isOpen && tw`hidden`,
    isOpen &&
      tw`block absolute top-8 left-0 z-50 border  border-solid border-border bg-glacier w-full`,
    css`
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
    `,
    propertiesStyles.scrollbar,
  ],
  icon: [],
  iconWrapper: [tw`ml-auto`],
  item: [tw`py-2 px-1 w-full hover:bg-hover cursor-pointer`],
};

export const DesignerSelect = ({
  value,
  onChange,
  children,
  name,
  width,
  onClick,
}: IDesignerSelectProps) => {
  function handleClick(value: string) {
    onChange(name, value);
    setIsOpen(false);
  }

  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    if (!children) return undefined;
    if (Array.isArray(children)) {
      return children.map((child) => {
        if (child.props && "value" in child.props) {
          return (
            <div
              onClick={() => handleClick(child.props.value as string)}
              css={styles.item}
              key={child.props.value}
            >
              {child}
            </div>
          );
        }
        return null;
      });
    } else {
      if (
        typeof children === "object" &&
        "props" in children &&
        "value" in children.props
      ) {
        return (
          <div
            onClick={() => handleClick(children.props.value as string)}
            css={styles.item}
          >
            {children}
          </div>
        );
      }
    }
    return undefined;
  }, [children]);

  function getValue() {
    if (children && Array.isArray(children)) {
      const val = children.find((x) => x.props.value === value);
      if (val && val.props.children) {
        return val.props.children;
      }
    }
    return value;
  }

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsOpen(false));
  return (
    <div css={styles.wrapper(width)} ref={ref}>
      <div
        css={propertiesStyles.inputWrapper}
        onClick={(e) => {
          if (!isOpen && !onClick) setIsOpen(true);
          if (isOpen && !onClick) setIsOpen(false);
          if (onClick) {
            onClick();
          }
        }}
      >
        <div>{getValue()}</div>{" "}
        <div css={styles.iconWrapper}>
          <SelectInputIcon />
        </div>
        <div css={styles.dropdown(isOpen)}>{options}</div>
      </div>
    </div>
  );
  return (
    <div>
      <select value={value} onChange={(e) => onChange(name, e.target.value)}>
        {children}
      </select>
    </div>
  );
};

interface IDesignerSelectOptionProps {
  children: React.ReactFragment;
  value: string;
}

export const DesignerSelectOption = ({
  children,
  value,
}: IDesignerSelectOptionProps) => {
  return <div>{children}</div>;
};
