import { useEffect, useState } from "react";
import { getObjectTruthyValues } from "../../../../helpers/getObjectTruthyValues";
import {
  creditCardValidation,
  NewCardValidationErrors,
} from "../../../../shared/validationHelpers/creditCardValidation";
import { INewPaymentMethod, IPaymentMethod } from "../../models/AccountModel";
import valid from "card-validator";
import { fetchClient } from "../../../../helpers/fetchClient";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Form from "../../../../shared/BasicHTML/Form";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";
import NumberFormat from "react-number-format";
import SmallTextField from "../../../../shared/MaterialWrappers/SmallTextField";
import LoadingButton from "../../../../shared/LoadingButton";

interface IAddNewCreditCardProps {
  callback: (paymentMethod: IPaymentMethod) => void;
  isOpen: boolean;
  onClose: () => void;
}

const newPaymentMethodObject: INewPaymentMethod = {
  firstName: "",
  lastName: "",
  cardNumber: "",
  expiration: "",
  cvc: "",
};

const AddNewCreditCard = ({
  callback,
  isOpen,
  onClose,
}: IAddNewCreditCardProps) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState(
    newPaymentMethodObject
  );
  const [cardValidationErrors, setCardValidationErrors] =
    useState<NewCardValidationErrors>({});
  const [showValidation, setShowValidation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newCardError, setNewCardError] = useState(false);

  function validateCard() {
    setCardValidationErrors(creditCardValidation(newPaymentMethod));
  }

  function submitNewCard(e: React.FormEvent) {
    e.preventDefault();
    if (getObjectTruthyValues(cardValidationErrors).length !== 0) {
      setShowValidation(true);
      return;
    }
    const cardNumber = newPaymentMethod.cardNumber.replace(/\D/g, "");
    const expirationMonth = valid.expirationDate(
      newPaymentMethod.expiration
    ).month;
    const expirationYear = valid.expirationDate(
      newPaymentMethod.expiration
    ).year;
    setIsSubmitting(true);
    fetchClient.post({
      path: "/payments",
      onSuccess: onSubmitSuccess,
      onError: onSubmitError,
      data: { cardNumber, expirationMonth, expirationYear },
    });
  }

  function onSubmitSuccess(res: IPaymentMethod) {
    callback(res);
    setIsSubmitting(false);
    setNewCardError(false);
    setShowValidation(false);
    setNewPaymentMethod(newPaymentMethodObject);
    onClose();
  }
  function onSubmitError() {
    setNewCardError(true);
    setIsSubmitting(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewPaymentMethod({
      ...newPaymentMethod,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    validateCard();
  }, [newPaymentMethod]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>New Credit Card</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <Form onSubmit={submitNewCard}>
            <GridRow>
              <GridItem xs={12}>
                <TextField
                  required
                  size="small"
                  value={newPaymentMethod.firstName}
                  name="firstName"
                  onChange={handleChange}
                  fullWidth
                  label="First Name"
                  disabled={isSubmitting}
                />
              </GridItem>
              <GridItem xs={12}>
                <TextField
                  required
                  size="small"
                  value={newPaymentMethod.lastName}
                  name="lastName"
                  onChange={handleChange}
                  fullWidth
                  label="Last Name"
                  disabled={isSubmitting}
                />
              </GridItem>
              <GridItem xs={12}>
                <NumberFormat
                  customInput={SmallTextField}
                  required
                  value={newPaymentMethod.cardNumber}
                  onChange={handleChange}
                  fullWidth
                  label="Card Card Number"
                  type="tel"
                  mask="_"
                  name="cardNumber"
                  format={
                    newPaymentMethod.cardNumber.startsWith("34") ||
                    newPaymentMethod.cardNumber.startsWith("37")
                      ? "#### - ###### - #####"
                      : "#### - #### - #### - ####"
                  }
                  disabled={isSubmitting}
                  error={
                    showValidation && Boolean(cardValidationErrors.cardNumber)
                  }
                />
                {showValidation && Boolean(cardValidationErrors.cardNumber) && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {cardValidationErrors.cardNumber}
                  </Typography>
                )}
              </GridItem>
              <GridItem xs={6}>
                <NumberFormat
                  customInput={SmallTextField}
                  required
                  value={newPaymentMethod.expiration}
                  onChange={handleChange}
                  name="expiration"
                  type="tel"
                  format="##/##"
                  label="Exp. (MM/YY)"
                  fullWidth
                  disabled={isSubmitting}
                  error={
                    showValidation && Boolean(cardValidationErrors.expiration)
                  }
                />
                {showValidation && Boolean(cardValidationErrors.expiration) && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {cardValidationErrors.expiration}
                  </Typography>
                )}
              </GridItem>
              <GridItem xs={6}>
                <NumberFormat
                  customInput={SmallTextField}
                  value={newPaymentMethod.cvc}
                  type="tel"
                  format={
                    newPaymentMethod.cardNumber.startsWith("34") ||
                    newPaymentMethod.cardNumber.startsWith("37")
                      ? "####"
                      : "###"
                  }
                  label="CVC"
                  name="cvc"
                  onChange={handleChange}
                  fullWidth
                  disabled={isSubmitting}
                  error={showValidation && Boolean(cardValidationErrors.cvc)}
                />
                {showValidation && Boolean(cardValidationErrors.cvc) && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {cardValidationErrors.cvc}
                  </Typography>
                )}
              </GridItem>
              <GridItem xs="auto">
                <LoadingButton
                  loading={isSubmitting}
                  success={false}
                  type="submit"
                >
                  Add New Card
                </LoadingButton>
              </GridItem>
              <GridItem xs="auto">
                <Button
                  color="primary"
                  type="button"
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </GridItem>
              <GridItem xs={12}>
                {newCardError && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    There was an error adding your new payment method, please
                    ensure all of the information provided is correct
                  </Typography>
                )}
              </GridItem>
            </GridRow>
          </Form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewCreditCard;
