import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ApiApp from "../../../data/models/App";
import { fetchClient } from "../../../helpers/fetchClient";
import useFormData from "../../../hooks/useFormData";
import useLoadData from "../../../hooks/useLoadData";
import LoadingButton from "../../../shared/LoadingButton";
import DialogProps from "../models/DialogProps";
import NewWebhookForm from "../models/NewWebhookForm";
import { SubscriptionEvent, Webhook } from "../models/Webhook";
import { MuiChangeEvent } from "../../../hooks/useFormData";

interface NewWebhookDialogProps extends DialogProps {
  apps: ApiApp[];
  successCallback: (newHooks: Webhook[]) => void;
}

const NewWebhookDialog: React.FC<NewWebhookDialogProps> = ({
  open,
  onClose,
  apps,
  successCallback,
}) => {
  const [newWebhook, handleChange, handleReset] = useFormData<NewWebhookForm>({
    friendlyName: "",
    url: "",
    event: "",
    selectedApps: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [lengthNotice, setLengthNotice] = useState(false);

  const { data: eventTypes, isLoading } = useLoadData<SubscriptionEvent[]>(
    "/api/webhooks/event-types",
    []
  );

  function handleClose() {
    setIsSubmitting(false);
    setHasError(false);
    handleReset();
    onClose();
  }

  function handleNameChange(e: MuiChangeEvent) {
    const inputVal = e.target.value;
    if (inputVal.length <= 255) {
      setLengthNotice(false);
      handleChange(e);
    } else {
      setLengthNotice(true);
    }
  }

  function subscribe() {
    setIsSubmitting(true);
    fetchClient.post({
      path: "/webhooks",
      data: {
        subType: "webhook",
        event: newWebhook.event,
        friendlyName: newWebhook.friendlyName,
        subConfig: JSON.stringify({
          url: newWebhook.url,
        }),
        apps: newWebhook.selectedApps,
      },
      onSuccess,
      onError,
    });
  }

  function onSuccess(data: Webhook[]) {
    successCallback(data);
    handleClose();
  }

  function onError() {
    setIsSubmitting(false);
    setHasError(true);
  }

  function validateWebhook() {
    const empties = Object.keys(newWebhook).reduce<string[]>((prev, curr) => {
      if (curr === "selectedApps") {
        if (newWebhook.selectedApps.length === 0) {
          return [...prev, curr];
        }
      }
      if (newWebhook[curr as keyof NewWebhookForm] === "") {
        return [...prev, curr];
      }
      return prev;
    }, []);
    return Boolean(empties.length);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>New Webhook Subscription</DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <Typography sx={{ mb: 2 }}>
            All fields are required for submission
          </Typography>
          <TextField
            fullWidth
            value={newWebhook.friendlyName}
            onChange={handleNameChange}
            name="friendlyName"
            label="Friendly name"
            sx={{ mb: 2 }}
            disabled={isSubmitting}
          />
          {lengthNotice && (
            <FormHelperText
              sx={{ color: (theme) => theme.palette.error.main, mb: 2 }}
            >
              Please enter a name that is less than 255 characters.
            </FormHelperText>
          )}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Event</InputLabel>
            <Select
              name="event"
              onChange={handleChange}
              value={newWebhook.event}
              label="Event"
              disabled={isSubmitting}
            >
              {eventTypes.map((eType) => (
                <MenuItem value={eType.event} key={eType.event}>
                  {eType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            value={newWebhook.url}
            onChange={handleChange}
            name="url"
            label="URL"
            sx={{ mb: 2 }}
            disabled={isSubmitting}
          />
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">Select Environment(s)</FormLabel>
            {apps.map((app) => (
              <FormControlLabel
                key={app.apiKey}
                label={app.appType}
                sx={{ textTransform: "capitalize" }}
                control={
                  <Checkbox
                    name="selectedApps"
                    onChange={handleChange}
                    disabled={isSubmitting}
                    checked={
                      newWebhook.selectedApps.indexOf(app.appID.toString()) !==
                      -1
                    }
                    value={app.appID.toString()}
                  />
                }
              />
            ))}
          </FormControl>
          {hasError && (
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.palette.error.main, mb: 2 }}
            >
              Something went wrong, please try again later.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={subscribe}
          variant="contained"
          color="primary"
          loading={isSubmitting}
          success={false}
          disabled={validateWebhook()}
        >
          Subscribe
        </LoadingButton>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewWebhookDialog;
