import { IFont, IGoogleFont, SortOption } from "./types";

const key = "AIzaSyD94PwI_150T-h7dnMidzIMWAF5xFYz1yE";
const baseUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${key}`;

async function getFontsAsync(sort: SortOption = "popularity", limit = 500) {
  const res = await fetch(`${baseUrl}&sort=${sort}&subset=latin`);
  if (res.ok) {
    const json: IGoogleFontsResponse = await res.json();
    return [
      {
        family: "Arial",
        category: "sans-serif",
        files: {},
        variants: ["400", "italic", "700", "700italic"],
        boldWeight: "700",
        previewLoaded: true,
        loaded: true,
        isLocal: true,
        hasItalic: true,
      },
      {
        family: "Times New Roman",
        category: "serif",
        files: {},
        variants: ["400", "italic", "700", "700italic"],
        boldWeight: "700",
        previewLoaded: true,
        loaded: true,
        isLocal: true,
        hasItalic: true,
      },
      {
        family: "proxima-nova",
        category: "sans-serif",
        files: {},
        boldWeight: "700",
        variants: [
          "100",
          "100italic",
          "300",
          "300italic",
          "400",
          "italic",
          "500",
          "500italic",
          "600",
          "600italic",
          "700",
          "700italic",
          "800",
          "800italic",
          "900",
          "900italic",
        ],
        previewLoaded: true,
        loaded: true,
        isLocal: true,
        hasItalic: true,
      },
      {
        family: "p22-mackinac-pro",
        category: "serif",
        boldWeight: "700",
        files: {},
        variants: [
          "400",
          "italic",
          "500",
          "500italic",
          "700",
          "700italic",
          "800",
          "800italic",
        ],
        previewLoaded: true,
        loaded: true,
        isLocal: true,
        hasItalic: true,
      },
      ...json.items.slice(0, limit).map((font) => ({
        ...font,
        previewLoaded: false,
        loaded: false,
        boldWeight: getFontBoldWeight(font),
        isLocal: false,
        hasItalic: Boolean(font.variants.find((x) => x.includes("italic"))),
      })),
    ] as IFont[];
  } else {
    return [];
  }
}

function getFontBoldWeight(font: IGoogleFont) {
  const variantStrings = font.variants.filter((x) => !x.includes("italic"));
  let bold = variantStrings.find((x) => x === "700");
  if (bold === undefined) {
    bold = variantStrings.find((x) => x === "800");
  }
  if (bold === undefined) {
    bold = variantStrings.find((x) => x === "900");
  }
  if (bold !== undefined) {
    return bold;
  }
  return bold;
}

interface IGoogleFontsResponse {
  items: IGoogleFont[];
}

export default getFontsAsync;
