import { Object as IObject } from "fabric/fabric-impl";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import RESOLUTION from "../../../constants/RESOLUTION";
import { reAlignListAndItems } from "../../../features/Canvas/functions/createBulletedList";
import { IBulletedList } from "fabric";

export default function updateSize(
  obj: IObject,
  property: keyof IObjectProperties,
  value: number
) {
  if (value === 0) return;
  const pixels = value * RESOLUTION;

  const constrainDimensions =
    !obj.name?.includes("bulletedList") &&
    (obj.type === "group" || obj.type === "image");
  if (constrainDimensions) {
    if (property === "width") obj.scaleToWidth(pixels, true);
    else obj.scaleToHeight(pixels, true);
  } else if (
    !obj.type?.includes("text") &&
    !obj.name?.includes("bulletedList")
  ) {
    const scaleX = obj.scaleX ?? 1;
    const scaleY = obj.scaleY ?? 1;
    const widthInches = obj.getScaledWidth() / RESOLUTION;
    const heightInches = obj.getScaledHeight() / RESOLUTION;

    if (property === "width") {
      obj.set({ scaleX: (value / widthInches) * scaleX });
    } else {
      obj.set({ scaleY: (value / heightInches) * scaleY });
    }
  } else {
    obj.set({
      [property]: pixels,
    });
    if (obj.name?.includes("bulletedList")) {
      reAlignListAndItems(obj as IBulletedList);
    }
  }
}
