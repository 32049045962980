import { ScopedCssBaseline, ThemeProvider } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import LocalStorage from "../../../helpers/LocalStorage";
import hideLiveChat from "../../../helpers/hideLiveChat";
import useQuery from "../../../hooks/useQuery";
import { defaultSalesforceTheme } from "./createSalesforceTheme";

interface ISalesforceLayoutProps {
  children: React.ReactNode;
  auth?: boolean;
}

export const IntegratedContext = createContext({
  source: null as string | null,
});

const IntegratedLayout = ({
  children,
  auth = false,
}: ISalesforceLayoutProps) => {
  hideLiveChat();
  const query = useQuery();
  const [token, setToken] = useState<string | null>(null);
  const source = query.get("source");
  const [baseUrl, setBaseUrl] = useState<string>();
  const [theme, setTheme] = useState(defaultSalesforceTheme);
  const { showBoundary } = useErrorBoundary();

  async function authenticate() {
    let qToken = query.get("token");
    if (!qToken) {
      qToken = LocalStorage.getToken();
    }
    //setTheme(createSalesforceTheme(primary, secondary));
    if (qToken) {
      const res = await fetch(`/api/auth/integrated-login`, {
        headers: {
          Authorization: `Bearer ${qToken}`,
        },
        method: "POST",
      });
      if (res.ok) {
        const json: { token: string; expires: string; baseApiUrl: string } =
          await res.json();
        if (json) {
          // setCookie("token", json.token);
          // setCookie("apiBaseUrl", json.baseApiUrl);
          setTimeout(() => {
            setToken(json.token);
            setBaseUrl(json.baseApiUrl);
          }, 200);
          return;
        }
      }
    }
    showBoundary({ message: "Could not authenticate" });
  }

  useEffect(() => {
    authenticate();
  }, []);

  /**
   * Error Page
   */
  if (!token || !baseUrl) return <div></div>;

  return (
    <ThemeProvider theme={defaultSalesforceTheme}>
      <IntegratedContext.Provider
        value={{
          source: source || "",
        }}
      >
        <ScopedCssBaseline>
          <div
            data-token={token}
            id="tokenRoot"
            data-baseurl={baseUrl}
            style={{
              height: "100vh",
              overflow: "auto",
            }}
          >
            {children}
          </div>
        </ScopedCssBaseline>
      </IntegratedContext.Provider>
    </ThemeProvider>
  );
};

export default IntegratedLayout;
