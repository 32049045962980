import { Button, Link } from "@mui/material";
import React, { useState, useContext } from "react";
import { fetchClient } from "../../../../../helpers/fetchClient";
import DropzoneUploader from "../../../../../shared/DropzoneUploader";
import GetListFileResponse from "../../models/GetListFileResponse";
import UploadListResponse from "../../models/UploadListResponse";
import { OrderRequestContext } from "../../PlaceAnOrder";
import "./UploadList.scss";

interface UploadListProps {
  errors: Record<string, boolean>;
  generateCsvSample: () => void;
}

const UploadList: React.FC<UploadListProps> = ({
  errors,
  generateCsvSample,
}) => {
  /**
   * State Objects and Refs
   */

  const orderRequestStore = useContext(OrderRequestContext);

  const [hasValidListFile, setHasValidListFile] = useState(
    orderRequestStore.orderRequest.listFileId ? true : false
  );
  const [hasListFileError, setHasListFileError] = useState(false);
  const [hasInvalidFileError, setHasInvalidFileError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  /**
   * Component Methods
   */
  function handleUpload(files: File[]) {
    setHasInvalidFileError(false);
    setIsUploading(true);
    const request = fetchClient.uploadFile({
      onSuccess: onUpload,
      onError: onUploadError,
      files,
      fileType: "LIST",
      isList: true,
    });
  }

  function onUpload(response: UploadListResponse) {
    getListFile(response.data[0]);
    setIsUploading(false);
  }
  function onUploadError() {
    setHasListFileError(true);
    setIsUploading(false);
  }

  function getListFile(filePath: string) {
    fetchClient.get({
      path: `/place-an-order/list-file?filePath=${filePath}`,
      onSuccess: handleListFile,
    });
  }

  function handleListFile(data: GetListFileResponse) {
    orderRequestStore.updateList(
      data.listFile.id,
      data.count,
      data.listFile.originalName,
      data.columns,
      data.records
    );
    setHasValidListFile(true);
  }

  function handleRejection() {
    setHasInvalidFileError(true);
  }

  function handleChangeButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setHasValidListFile(false);
    orderRequestStore.clearListFile();
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <div>
      {hasValidListFile && (
        <div>
          <h3>Your List Has Been Uploaded</h3>
          <p>Confirm the details below and continue to the next step.</p>
          <div>
            <strong>List Name:</strong>{" "}
            {orderRequestStore.orderRequest.listAttributes?.listName}
          </div>
          <div>
            <strong>Records: </strong>{" "}
            {orderRequestStore.orderRequest.listAttributes?.listCount}
          </div>
          <div>
            <Button
              color="primary"
              className="uploadList__changeButton"
              onClick={handleChangeButtonClick}
            >
              Need to Make a change?
            </Button>
          </div>
        </div>
      )}
      {!hasValidListFile && (
        <div>
          <h3>Upload Your List</h3>
          <p>
            Your list must be a .csv containing all the proper fields for
            addressing: first name, last name, address, address 2, city, state,
            and zip code.
          </p>
          <p>After uploading your list, you will map your fields to ours.</p>
          <p>
            <Button color="primary" onClick={generateCsvSample}>
              Download Sample CSV
            </Button>
          </p>
        </div>
      )}

      {!hasValidListFile && (
        <React.Fragment>
          <DropzoneUploader
            onUpload={handleUpload}
            onRejections={handleRejection}
            fileTypes={[".csv"]}
            disabled={isUploading}
          />
          {hasInvalidFileError && (
            <div className="error">File must be a .csv</div>
          )}
          {hasListFileError && (
            <div className="error">Something went wrong, please try again</div>
          )}
          {(errors.listFile || errors.listInvalid) && (
            <div className="error">
              You must upload a valid list to continue
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UploadList;
