import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { numberWithCommas } from "../../../../LegacyReactSite/helpers";
import Img from "../../../../shared/BasicHTML/Img";
import TabPanel from "../../../../shared/TabPanel";
import { IPaymentMethod } from "../../../Account/models/AccountModel";
import { Pricing } from "../../../PricingPlans/models/PricingPlanModel";
import EnvelopePricingTable from "../../components/EnvelopePricingTable";
import { ReplenishmentMethod } from "../../models/IEnvelope";
import IEnvelopeTabProps from "../../models/IEnvelopeTabProps";

interface IEnvelopeInfoProps extends IEnvelopeTabProps {
  onEditClick: () => void;
  pricing: Pricing[];
  paymentMethod?: IPaymentMethod;
}

const EnvelopeInfo = ({
  tabValue,
  envelope,
  activeTab,
  onEditClick,
  pricing,
  paymentMethod,
}: IEnvelopeInfoProps) => {
  return (
    <TabPanel activeTab={activeTab} value={tabValue}>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Preview
              </Typography>
              <Img src={`/uploads/${envelope.front}`} />
              {Boolean(envelope.back) && <Img src={envelope.back} />}
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Details
              </Typography>
              <Box sx={{ mb: 2 }}>
                <strong>Available Inventory: </strong>
                {numberWithCommas(envelope.stockQty)}
              </Box>
              {envelope.lastOrder && (
                <Box sx={{ mb: 2 }}>
                  <strong>Last Order Quantity: </strong>
                  {numberWithCommas(envelope.lastOrder.orderQty)}
                </Box>
              )}
              {envelope.lastOrder && (
                <Box sx={{ mb: 2 }}>
                  <strong>Last Order Date: </strong>
                  {moment(envelope.lastOrderDate).format("MM/DD/YYYY")}
                </Box>
              )}
              <Paper sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1,
                    borderBottom: "solid 1px rgba(0,0,0,.125)",
                  }}
                >
                  <strong>Automatic Reorder Settings</strong>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={onEditClick}
                  >
                    Edit
                  </Button>
                </Box>
                <Box sx={{ p: 1 }}>
                  <Box>
                    <strong>Automatic Reorder: </strong>{" "}
                    {envelope.replenishmentMethod !== ReplenishmentMethod.None
                      ? "On"
                      : "Off"}
                  </Box>
                  {envelope.replenishmentMethod !==
                    ReplenishmentMethod.None && (
                    <React.Fragment>
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <strong>Reorder Threshold: </strong>{" "}
                        {envelope.replenishmentMethod ===
                        ReplenishmentMethod.Percentage
                          ? `${envelope.replenishmentValue}%`
                          : numberWithCommas(envelope.replenishmentValue)}
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <strong>Reorder Quantity: </strong>{" "}
                        {numberWithCommas(envelope.orderQty)}
                      </Box>
                      {paymentMethod && (
                        <Box>
                          <strong>Payment Method: </strong> Card ending in{" "}
                          {paymentMethod.lastFour} | Expires:{" "}
                          {paymentMethod.expiration}
                        </Box>
                      )}
                    </React.Fragment>
                  )}
                </Box>
              </Paper>
              {/* <Typography variant="h6" sx={{ mb: 2 }}>
                Pricing
              </Typography>
              <EnvelopePricingTable pricing={pricing} /> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </TabPanel>
  );
};

export default EnvelopeInfo;
