import { Group, Object as IObject } from "fabric/fabric-impl";
import { useEffect } from "react";
import { fabric } from "fabric";

const useAlignment = (selectedObject?: IObject) => {
  const selection =
    selectedObject &&
    selectedObject.canvas &&
    selectedObject.canvas._activeObject;
  const canvas = selectedObject?.canvas;

  const isGroup = selection?.name === undefined;
  const group = selection as Group | undefined;

  function alignHCenter() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const oDimensions = getObjectDimensions(selection);
      if (!selection.originX || selection.originX === "left") {
        selection.left = cDimensions.centerX - oDimensions.width / 2;
      }
      if (selection.originX === "right") {
        selection.left = cDimensions.centerX + oDimensions.width / 2;
      }
      if (selection.originX === "center") {
        selection.left = cDimensions.centerX;
      }
    } else if (group) {
      const objects = group._objects;
      objects.forEach((obj) => {
        if (!obj.originX || obj.originX === "left") {
          obj.left = 0 - obj.getScaledWidth() / 2;
        }
        if (obj.originX === "right") {
          obj.left = 0 + obj.getScaledWidth() / 2;
        }
        if (obj.originX === "center") {
          obj.left = 0;
        }
      });
      regroupObjects(objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function alignVCenter() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const oDimensions = getObjectDimensions(selection);
      if (!selection.originY || selection.originY === "top") {
        selection.top = cDimensions.centerY - oDimensions.height / 2;
      }
      if (selection.originY === "bottom") {
        selection.top = cDimensions.centerY + oDimensions.height / 2;
      }
      if (selection.originY === "center") {
        selection.top = cDimensions.centerY;
      }
    } else if (group) {
      const objects = group._objects;
      objects.forEach((obj) => {
        if (!obj.originY || obj.originY === "top") {
          obj.top = 0 - obj.getScaledHeight() / 2;
        }
        if (obj.originY === "bottom") {
          obj.top = 0 + obj.getScaledHeight() / 2;
        }
        if (obj.originY === "center") {
          obj.top = 0;
        }
      });
      regroupObjects(objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function getObjectLeft(obj: IObject, direction: "left" | "right") {
    if (obj.left === undefined) return 0;

    if (!obj.originX || obj.originX === "left") {
      if (direction === "right") return obj.left + obj.getScaledWidth();
      return obj.left;
    }
    if (obj.originX === "right") {
      if (direction === "right") return obj.left;
      return obj.left - obj.getScaledWidth();
    }
    if (direction === "right") return obj.left + obj.getScaledWidth() / 2;
    return obj.left - obj.getScaledWidth() / 2;
  }

  function getGroupLeft(group: Group, direction: "left" | "right") {
    let anchorObj = group._objects[0];
    let left = getObjectLeft(anchorObj, direction);
    group._objects.forEach((obj) => {
      const objLeft = getObjectLeft(obj, direction);

      if (direction === "left" && objLeft < left) {
        anchorObj = obj;
        left = objLeft;
      }
      if (direction === "right" && objLeft > left) {
        anchorObj = obj;
        left = objLeft;
      }
    });
    return { anchorObj, left };
  }

  function alignLeft() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const width = selection.getScaledWidth();
      if (!selection.originX || selection.originX === "left") {
        selection.left = cDimensions.left;
      }
      if (selection.originX === "right") {
        selection.left = cDimensions.left + width;
      }
      if (selection.originX === "center") {
        selection.left = cDimensions.left + width / 2;
      }

      canvas.renderAll();
    } else if (group) {
      const { anchorObj, left } = getGroupLeft(group, "left");
      group._objects.forEach((obj) => {
        if (anchorObj.name !== obj.name) {
          if (!obj.originX || obj.originX === "left") {
            obj.left = left;
          }
          if (obj.originX === "right") {
            obj.left = left + obj.getScaledWidth();
          }
          if (obj.originX === "center") {
            obj.left = left + obj.getScaledWidth() / 2;
          }
        }
      });
      regroupObjects(group._objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function alignRight() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const width = selection.getScaledWidth();
      if (!selection.originX || selection.originX === "left") {
        selection.left = cDimensions.left + cDimensions.width - width;
      }
      if (selection.originX === "right") {
        selection.left = cDimensions.left + cDimensions.width;
      }
      if (selection.originX === "center") {
        selection.left = cDimensions.left + cDimensions.width - width / 2;
      }
    } else if (group) {
      const objects = group._objects;
      const { left, anchorObj } = getGroupLeft(group, "right");
      objects.forEach((obj) => {
        if (obj.name !== anchorObj.name) {
          if (!obj.originX || obj.originX === "left") {
            obj.left = left - obj.getScaledWidth();
          }
          if (obj.originX === "right") {
            obj.left = left;
          }
          if (obj.originX === "center") {
            obj.left = left - obj.getScaledWidth() / 2;
          }
        }
      });
      regroupObjects(objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function alignTop() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const height = selection.getScaledHeight();
      if (!selection.originY || selection.originY === "top") {
        selection.top = cDimensions.top;
      }
      if (selection.originY === "bottom") {
        selection.top = cDimensions.top + height;
      }
      if (selection.originY === "center") {
        selection.top = cDimensions.top + height / 2;
      }
    } else if (group) {
      const objects = group._objects;
      const { anchorObj, top } = getGroupTop(group, "top");
      objects.forEach((obj) => {
        if (anchorObj.name !== obj.name) {
          if (obj.originY === "top" || !obj.originY) {
            obj.top = top;
          }
          if (obj.originY === "bottom") {
            obj.top = top + obj.getScaledHeight();
          }

          if (obj.originY === "center") {
            obj.top = top + obj.getScaledHeight() / 2;
          }
        }
      });
      regroupObjects(objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function alignBottom() {
    if (!selection || !canvas) return;
    if (!isGroup) {
      const cDimensions = getCanvasDimensions();
      const height = selection.getScaledHeight();
      if (!selection.originY || selection.originY === "top") {
        selection.top = cDimensions.top + cDimensions.height - height;
      }
      if (selection.originY === "bottom") {
        selection.top = cDimensions.top + cDimensions.height;
      }
      if (selection.originY === "center") {
        selection.top = cDimensions.top + cDimensions.height - height / 2;
      }
    } else if (group) {
      const objects = group._objects;
      const { top, anchorObj } = getGroupTop(group, "bottom");
      objects.forEach((obj) => {
        if (anchorObj.name !== obj.name) {
          if (obj.originY === "top" || !obj.originY) {
            obj.top = top - obj.getScaledHeight();
          }
          if (obj.originY === "bottom") {
            obj.top = top;
          }

          if (obj.originY === "center") {
            obj.top = top - obj.getScaledHeight() / 2;
          }
        }
      });
      regroupObjects(objects);
    }
    canvas.renderAll();
    canvas.fire("object:modified");
  }

  function getObjectTop(obj: IObject, direction: "top" | "bottom") {
    if (obj.top === undefined) return 0;

    if (!obj.originY || obj.originY === "top") {
      if (direction === "bottom") return obj.top + obj.getScaledHeight();
      return obj.top;
    }
    if (obj.originY === "bottom") {
      if (direction === "bottom") return obj.top;
      return obj.top - obj.getScaledHeight();
    }
    if (direction === "bottom") return obj.top + obj.getScaledHeight() / 2;
    return obj.top - obj.getScaledHeight() / 2;
  }

  function getGroupTop(group: Group, direction: "top" | "bottom") {
    let anchorObj = group._objects[0];
    let top = getObjectTop(anchorObj, direction);
    group._objects.forEach((obj) => {
      const objTop = getObjectTop(obj, direction);

      if (direction === "top" && objTop < top) {
        anchorObj = obj;
        top = objTop;
      }
      if (direction === "bottom" && objTop > top) {
        anchorObj = obj;
        top = objTop;
      }
    });
    return { anchorObj, top };
  }

  function regroupObjects(objects: IObject[]) {
    if (!canvas) return;
    canvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(objects, {
      canvas: canvas,
    });
    canvas.setActiveObject(selection);
  }

  function getObjectDimensions(obj: IObject | Group) {
    const center = obj.getCenterPoint();

    return {
      centerX: center.x,
      centerY: center.y,
      left: obj.left,
      top: obj.top,
      width: obj.getScaledWidth(),
      height: obj.getScaledHeight(),
    };
  }

  function getHeight(obj: IObject) {
    const height = obj.getScaledHeight();
    switch (obj.originY) {
      case "center": {
        return 0;
      }
      case "bottom": {
        return -height;
      }
      case "top":
      default: {
        return height;
      }
    }
  }

  function getWidth(obj: IObject) {
    const width = obj.getScaledWidth();

    switch (obj.originX) {
      case "center": {
        return width / 2;
      }
      case "right": {
        return width;
      }

      case "left":
      default: {
        return 0;
      }
    }
  }

  function getCanvasDimensions() {
    if (!canvas)
      return { centerX: 0, centerY: 0, width: 0, height: 0, left: 0, top: 0 };
    const background = canvas._objects.find((x) => x.name === "background");
    if (!background)
      return { centerX: 0, centerY: 0, width: 0, height: 0, left: 0, top: 0 };
    const center = background.getCenterPoint();

    return {
      left: background.left ?? 0,
      top: background.top ?? 0,
      centerX: center.x,
      centerY: center.y,
      width: background.width ?? 0,
      height: background.height ?? 0,
    };
  }

  return {
    alignHCenter,
    alignVCenter,
    alignLeft,
    alignRight,
    alignTop,
    alignBottom,
  };
};

export default useAlignment;
