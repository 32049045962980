import { Canvas, Object as IObject } from "fabric/fabric-impl";
import { Tool } from "../../../state/models/ICanvasTool";

export default function toggleCanvasSelection(
  canvas: Canvas,
  state?: "on" | "off",
  ...ignore: string[]
) {
  let objects = canvas._objects.filter(
    (x) => x.name !== "background" && x.name !== "overlay" && x.name !== "bleed"
  );
  if (ignore && ignore.length) {
    objects = objects.filter((x) => x.name && !ignore.includes(x.name));
  }
  canvas.selection =
    state === "on" ? true : state === "off" ? false : !canvas.selection;
  objects.forEach((obj) => {
    // @ts-ignore
    if (obj.__locked === undefined || obj.__locked === false)
      obj.set(
        "selectable",
        state === "on"
          ? true
          : state === "off"
          ? false
          : obj.selectable !== undefined
          ? !obj.selectable
          : true
      );
  });
}
