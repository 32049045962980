import React, { useState } from 'react';

const ErrorTestRoute = () => {
  const [counter, setCounter] = useState(0);
  if(counter === 5) throw new Error('Oh no, this is bad');
  return <div>
    <h3>Counter: {counter}</h3>
    <button onClick={() => setCounter(counter + 1)}>Increase</button>
  </div>

}

export default ErrorTestRoute;