import { Box, Typography } from "@mui/material";

import PaginatedOrdersTable from "./PaginatedOrdersTable";

const Orders = () => {
  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
          Orders
        </Typography>
        <PaginatedOrdersTable />
      </Box>
      <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
        Sandbox Orders
      </Typography>
      <PaginatedOrdersTable isSandbox />
    </Box>
  );
};

export default Orders;
