import React, { ReactFragment } from "react";
import { Box } from "@mui/material";
import tw, { css } from "twin.macro";
import CanvasErrorBoundary from "./CanvasErrorBoundary";

interface ICanvasWrapperProps {
  children?: ReactFragment;
  mode?: string;
}

const CanvasWrapper = ({ children, mode }: ICanvasWrapperProps) => {
  return (
    <div
      css={[
        css({
          width: "100%",
          minHeight: "300px",
          height: "calc(100% - 4px)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          maxWidth: "100%",
        }),
        (mode === "iframe" || mode === "embed") &&
          css`
            max-width: 100%;
            overflow-x: hidden;
          `,
      ]}
    >
      <CanvasErrorBoundary>{children}</CanvasErrorBoundary>
    </div>
  );
};

export default CanvasWrapper;

/**
 * <Box className="_canvasDesignerRoot">
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          className="_canvasDesignerColumn"
          sx={{
            width: "100%",
            minHeight: "300px",
            height: "calc(100vh - 250px)",
          }}
        >
    
        </Grid>
      </Grid>
    </Box>
 */
