import {
  Gradient,
  Group,
  Object as IObject,
  Pattern,
  Shadow,
} from "fabric/fabric-impl";
import { IBulletedList, IListItem, Textbox, fabric } from "fabric";
import React, { useContext, useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import ColorPickerPopout from "./ColorPickerPopout";
// @ts-ignore
import GradientColorPicker, {
  useColorPicker,
  // @ts-ignore
} from "react-best-gradient-color-picker";
import { getColorFromObject } from "./helpers/getColorFromObject";
import ColorPickerInput from "./ColorPickerInput";
import { IGradientObject } from "./types";
import tinycolor from "tinycolor2";
import getGradientFromCss from "./helpers/getGradientFromCss";
import ColorPalette from "./ColorPalette";
import { FillIcon, StrokeWidthIcon } from "../SvgComponents";
import propertiesStyles from "../../Properties/propertiesStyles";
import useHistory from "../../../../hooks/useHistory";
import { useDesignerDispatch } from "../../../../state/store";
import {
  IObjectProperties,
  SelectedObjectDispatchContext,
} from "../../../../state/contexts/SelectedObjectContext";

interface IColorPickerProps {
  debug?: boolean;
  selectedObject: IObject;
  type: "fill" | "stroke" | "shadow";
  listProperty?: "bullet" | "text";
  prefix?: React.ReactFragment;
  swatchOnly?: boolean;
  swatchIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  positionOverride?: {
    left?: number | string;
    top?: number | string;
    bottom?: number | string;
    right?: number | string;
  };
}

type FillType = "fill" | "gradient" | "image";

const styles = {
  container: (swatchOnly: boolean) => [
    tw`w-full`,
    swatchOnly && tw`mt-auto mb-auto`,
  ],
  inputContainer: [tw`flex items-center w-full`],
  icon: [tw`w-3.5 h-3.5 fill-text mr-1.5`],
};

const ColorPicker = ({
  selectedObject,
  type,
  prefix,
  swatchOnly = false,
  swatchIcon,
  positionOverride,
  listProperty,
  debug = false,
}: IColorPickerProps) => {
  const isImage = selectedObject.name?.includes("image");
  const objRef = useRef(selectedObject);
  const isList = selectedObject.name?.includes("bulletedList");
  const listObj = isList
    ? listProperty === "text"
      ? (
          (selectedObject as IBulletedList)._objects[0] as IListItem
        )._objects.find((x) => x.name === "text")
      : (
          (selectedObject as IBulletedList)._objects[0] as IListItem
        )._objects.find((x) => x.name !== "text")
    : undefined;
  const frame = isImage
    ? (selectedObject as Group)._objects?.find((x) => x.type !== "image") ??
      selectedObject
    : selectedObject;

  objRef.current = frame;

  const [isOpen, setIsOpen] = useState(false);
  const fillType = useRef("solid");
  const [color, setColor] = useState(
    isList && listObj
      ? getColorFromObject(listObj, "fill").color
      : getColorFromObject(frame, type).color
  );
  const [inputValue, setInputValue] = useState(
    frame.stroke ? (frame.stroke as string) : "#000000"
  );

  const changeMadeTimeout = useRef<NodeJS.Timeout>();

  const [hasInitialized, setHasInitialized] = useState(false);

  const { getGradientObject, valueToHex } = useColorPicker(color, setColor);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const color = tinycolor(e.target.value);
    const hex = color.toHex();
    if (color.isValid()) {
      setColor(`#${hex}`);
    }
    setInputValue(e.target.value);
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function updateObjectColor() {
    if (!hasInitialized) {
      setHasInitialized(true);
      return;
    }
    if (selectedObject && selectedObject.type?.includes("text")) {
      const textObj = selectedObject as Textbox;

      if (textObj.isEditing) {
        const styles = textObj.getSelectionStyles();
        const fill = styles && styles.length && styles.find((x) => x.fill);
        if (fill === textObj.fill) {
          return;
        }
        textObj.setSelectionStyles({ fill: color });
        textObj.canvas?.renderAll();
        return;
      }
    }

    const gradientObj: IGradientObject = getGradientObject();
    let fill: string | Gradient | undefined = undefined;

    if (gradientObj.isGradient) {
      fill = getGradientFromCss(selectedObject, gradientObj);
    } else {
      const properties = tinycolor(color);
      if (properties.isValid()) {
        fill = color;
      }
    }
    if (!fill) return;
    if (isList) {
      const list = selectedObject as IBulletedList;
      if (listProperty === "bullet") {
        list.__bulletSettings.fill = fill;
        (list._objects as IListItem[]).forEach((obj) => {
          const bullet = obj._objects.find((x) => x.name === "bullet");
          if (bullet) {
            bullet.fill = fill;
          }
        });
      } else {
        list.__fontSettings.fill = fill;

        (list._objects as IListItem[]).forEach((obj) => {
          const text = obj._objects.find((x) => x.name === "text");

          if (text) {
            text.fill = fill;
          }
        });
      }
    }
    if (type === "fill" && !isList) {
      selectedObject.fill = fill;
    }
    if (type === "shadow") {
      if (!selectedObject.shadow) {
        selectedObject.shadow = new fabric.Shadow({
          offsetX: 0,
          offsetY: 0,
          blur: 0,
          color: fill as string,
        });
      } else {
        (selectedObject.shadow as Shadow).color = fill as string;
      }
    }
    if (type === "stroke") {
      if (isImage) {
        frame.stroke = fill as string;
      } else selectedObject.stroke = fill as string;
    }
    if (selectedObject.type === "path") selectedObject.set("dirty", true);

    selectedObject.canvas?.renderAll();
    if (changeMadeTimeout.current) clearTimeout(changeMadeTimeout.current);
    changeMadeTimeout.current = setTimeout(() => {
      selectedObject.canvas?.fire("object:modified");
    }, 500);
    //selectedObject.canvas?.fire("object:modified");

    if (!swatchOnly) {
      setInputValue(valueToHex(color));
    }

    if (selectedObject.type?.includes("text")) {
      const textObj = selectedObject as Textbox;
      textObj.cleanStyle("fill");
    }
    selectedObject?.set("dirty", true);
    selectedObject?.canvas?.renderAll();
  }

  function updateObjectColor2(c: string) {
    if (selectedObject && selectedObject.type?.includes("text")) {
      const textObj = selectedObject as Textbox;

      if (textObj.isEditing) {
        const styles = textObj.getSelectionStyles();
        const fill = styles && styles.length && styles.find((x) => x.fill);
        if (fill === textObj.fill) {
          return;
        }
        textObj.setSelectionStyles({ fill: c });
        textObj.canvas?.renderAll();
        return;
      }
    }

    const gradientObj: IGradientObject = getGradientObject();
    let fill: string | Gradient | undefined = undefined;

    if (gradientObj.isGradient) {
      fill = getGradientFromCss(selectedObject, gradientObj);
    } else {
      const properties = tinycolor(color);
      if (properties.isValid()) {
        fill = c;
      }
    }
    if (!fill) return;
    if (isList) {
      const list = selectedObject as IBulletedList;
      if (listProperty === "bullet") {
        list.__bulletSettings.fill = fill;
        (list._objects as IListItem[]).forEach((obj) => {
          const bullet = obj._objects.find((x) => x.name === "bullet");
          if (bullet) {
            bullet.fill = fill;
          }
        });
      } else {
        list.__fontSettings.fill = fill;

        (list._objects as IListItem[]).forEach((obj) => {
          const text = obj._objects.find((x) => x.name === "text");

          if (text) {
            text.fill = fill;
          }
        });
      }
    }
    if (type === "fill" && !isList) {
      selectedObject.fill = fill;
    }
    if (type === "shadow") {
      if (!selectedObject.shadow) {
        selectedObject.shadow = new fabric.Shadow({
          offsetX: 0,
          offsetY: 0,
          blur: 0,
          color: fill as string,
        });
      } else {
        (selectedObject.shadow as Shadow).color = fill as string;
      }
    }
    if (type === "stroke") {
      if (isImage) {
        frame.stroke = fill as string;
      } else selectedObject.stroke = fill as string;
    }
    if (selectedObject.type === "path") selectedObject.set("dirty", true);

    selectedObject.canvas?.renderAll();
    if (changeMadeTimeout.current) clearTimeout(changeMadeTimeout.current);
    changeMadeTimeout.current = setTimeout(() => {
      selectedObject.canvas?.fire("object:modified");
    }, 500);
    //selectedObject.canvas?.fire("object:modified");

    if (!swatchOnly) {
      setInputValue(valueToHex(c));
    }

    if (selectedObject.type?.includes("text")) {
      const textObj = selectedObject as Textbox;
      textObj.cleanStyle("fill");
    }
    selectedObject?.set("dirty", true);
    selectedObject?.canvas?.renderAll();
    setColor(c);
  }

  function handlePaletteClick(value: string) {
    setColor(value);
    updateObjectColor2(value);
  }

  useEffect(() => {
    if (hasInitialized) {
      if (isList && listObj) {
        setColor(getColorFromObject(listObj, type).color);
        return;
      }
      setColor(getColorFromObject(frame, type).color);
    }
  }, [selectedObject]);

  useEffect(() => {
    if (!isOpen && type === "stroke") {
      setColor(getColorFromObject(frame, type).color);
    }
  }, [selectedObject.stroke]);

  useEffect(() => {
    function onSelectionChange() {
      if (objRef.current) {
        const textObj = objRef.current as Textbox;
        const selection = textObj.getSelectedText();

        const styles = selection.length
          ? textObj.getSelectionStyles() //@ts-ignore
          : [textObj.getStyleAtPosition()];

        if (styles && styles.length && styles[0].fill) {
          setColor(styles[0].fill);
        } else {
          setColor(getColorFromObject(objRef.current, type).color);
        }
      }
    }
    if (selectedObject.name !== objRef.current?.name) {
      if (objRef.current?.type?.includes("text")) {
        objRef.current.off("selection:changed", onSelectionChange);
      }
      objRef.current = selectedObject;
    }
    if (selectedObject && selectedObject.type?.includes("text")) {
      selectedObject.on("selection:changed", onSelectionChange);
    }
    return () => {
      if (selectedObject && selectedObject.type?.includes("text")) {
        selectedObject.off("selection:changed", onSelectionChange);
      }
    };
  }, [selectedObject]);

  // useEffect(updateObjectColor, [color]);
  if (swatchOnly)
    return (
      <React.Fragment>
        <ColorPickerInput
          onClick={toggleOpen}
          color={color}
          isGradient={fillType.current === "gradient"}
          onChange={handleInputChange}
          toHex={valueToHex}
          prefix={prefix}
          swatchOnly={swatchOnly}
          swatchIcon={swatchIcon}
          inputValue={inputValue}
          gradient={getGradientObject() as IGradientObject | undefined}
        />
        <ColorPickerPopout
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          swatchOnly={swatchOnly}
        >
          <GradientColorPicker
            onChange={(c: string) => {
              if (selectedObject && selectedObject.type?.includes("text")) {
                const textObj = selectedObject as Textbox;
                if (!textObj.isEditing) {
                  if (textObj.styles) {
                    Object.values(textObj.styles).forEach((x: any) => {
                      Object.values(x).forEach((y: any) => {
                        if (y.fill) {
                          y.fill = c;
                        }
                      });
                    });
                  }

                  textObj.canvas?.renderAll();
                }
              }
              setColor(c);
              setTimeout(() => {
                updateObjectColor2(c);
              });
            }}
            value={color}
            hideControls={type === "stroke" || type === "shadow" || isList}
            hidePresets
            hideAdvancedSliders
            hideColorGuide
            hideInputType
            width={200}
            height={200}
          />
          <ColorPalette
            onClick={handlePaletteClick}
            hideGradients={type === "stroke" || type === "shadow" || isList}
          />
        </ColorPickerPopout>
      </React.Fragment>
    );
  return (
    <div css={styles.container(swatchOnly)}>
      {!swatchOnly && (
        <div css={propertiesStyles.label}>
          {type === "fill" || type === "shadow" ? "Fill" : "Color"}
        </div>
      )}
      <div css={styles.inputContainer}>
        {/* {type === "fill" || type === "shadow" ? (
          <div>
            <FillIcon size={4} styles={styles.icon} />
          </div>
        ) : (
          <div>
            <StrokeWidthIcon size={4} styles={styles.icon} />
          </div>
        )} */}
        <ColorPickerInput
          onClick={toggleOpen}
          color={color}
          isGradient={fillType.current === "gradient"}
          onChange={handleInputChange}
          inputValue={inputValue}
          toHex={valueToHex}
          prefix={prefix}
          swatchOnly={swatchOnly}
        />
      </div>
      <ColorPickerPopout
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        swatchOnly={false}
        positionOverride={positionOverride}
      >
        <GradientColorPicker
          onChange={setColor}
          value={color}
          hideControls={type === "stroke" || type === "shadow"}
          hidePresets
          hideAdvancedSliders
          hideColorGuide
          hideInputType
          width={200}
          height={200}
        />
        <ColorPalette
          onClick={handlePaletteClick}
          hideGradients={type === "stroke" || type === "shadow"}
        />
      </ColorPickerPopout>
    </div>
  );
};

export default ColorPicker;
