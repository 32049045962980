import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { fetchClient } from "../../../helpers/fetchClient";

import useGetData from "../../../hooks/dataFetchers/useGetData";
import AccountWrapper from "../components/AccountWrapper";
import {
  AccountInformationResponse,
  AddressModel,
  ContactInformationModel,
} from "../models/AccountModel";
import IAdvancedIntegrationSettings from "../models/IAdvancedIntegrationSettings";
import NCOAText from "./components/NCOAText";

enum NAMES {
  ALLOW_DUPLICATES = "allowDuplicates",
  DONT_WANT_NO_SCRUBS = "allowNoScrub",
  ENABLE_ADDRESS_VERIFICATION = "enableAddressVerification",
  BLOCK_DUPLICATE_REF_NBRS = "blockDuplicateRefNbrs",
  ENABLE_NCOA = "enableNCOA",
}

const IntegrationSettings: FC = (props) => {
  /**
   * State Objects and Refs
   */

  const {
    data: settings,
    isLoading: loading,
    error: loadError,
    updateData: setSettings,
  } = useGetData<IAdvancedIntegrationSettings>(
    `/api/integration/advanced-settings`
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ncoaOpen, setNcoaOpen] = useState(false);
  const [companyInformation, setCompanyInformation] = useState<{
    contactInfo: ContactInformationModel;
    address: AddressModel;
  }>();
  const [ncoaEvent, setNcoaEvent] =
    useState<React.ChangeEvent<HTMLInputElement>>();
  const [error, setError] = useState("");
  const [approveNcoa, setApproveNcoa] = useState(false);

  /**
   * Component Methods
   */

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    if (settings) {
      const setts: IAdvancedIntegrationSettings = {
        ...settings,
        [e.target.name]:
          e.target.name === NAMES.ENABLE_NCOA && approveNcoa
            ? !e.target.checked
            : e.target.checked,
      };

      setSettings(setts);
      setIsSubmitting(true);
      fetchClient.put({
        path: "/integration/advanced-settings",
        data: setts,
        onSuccess: onChangeSuccess,
        onError: () => onChangeError(e.target.name),
      });
    }
  }

  function onChangeSuccess() {
    setIsSubmitting(false);
  }

  function onChangeError(item: string) {
    if (settings) {
      setIsSubmitting(false);
      setSettings({
        ...settings,
        [item as keyof IAdvancedIntegrationSettings]:
          settings[item as keyof IAdvancedIntegrationSettings],
      });
      setError(item);
    }
  }

  function handleNCOACheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setNcoaOpen(true);
      setNcoaEvent(e);
    } else handleChange(e);
  }

  function submitNcoaApproval() {
    setIsSubmitting(true);
    fetchClient.post({
      path: `/integration/approve-ncoa`,
      onSuccess: onNcoaApprovalResponse,
      onError: () => onChangeError(NAMES.ENABLE_NCOA),
    });
  }

  function onNcoaApprovalResponse() {
    setNcoaOpen(false);
    setApproveNcoa(false);
    if (ncoaEvent) {
      handleChange(ncoaEvent);
    } else onChangeError(NAMES.ENABLE_NCOA);
  }

  function getCompanyInformation() {
    if (ncoaOpen && !companyInformation) {
      fetchClient.get({
        path: "/my-account",
        onSuccess: (data: AccountInformationResponse) => {
          setCompanyInformation({
            contactInfo: data.contactInformation,
            address: data.address,
          });
        },
      });
    }
  }

  /**
   * Component Effects
   */

  useEffect(getCompanyInformation, [ncoaOpen]);

  /**
   * Render
   */

  return (
    <AccountWrapper
      loading={loading}
      pageTitle="Mailing Settings"
      loadError={loadError.hasError}
    >
      <Box>
        <Box className="EnableAddressVerification" sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Enable Address Verification
          </Typography>
          <Typography variant="body1">
            Check the box below if you would like to enable automatic address
            verification for recipient addresses. (Only for orders that contain
            one recipient)
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings?.enableAddressVerification)}
                  onChange={handleChange}
                  name={NAMES.ENABLE_ADDRESS_VERIFICATION}
                  color="primary"
                  disabled={isSubmitting}
                />
              }
              label="Enable Address Verification"
            />
          </Box>
          {error === NAMES.ENABLE_ADDRESS_VERIFICATION && (
            <Typography variant="caption" sx={{ color: "red" }}>
              There was an error updating this setting, please try again later.
            </Typography>
          )}
        </Box>
        <Box className="DuplicateRecipients" sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Duplicate Recipients
          </Typography>
          <Typography variant="body1">
            Check the box below if you would like to send multiple orders to the
            same recipients
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings?.allowDuplicates)}
                  onChange={handleChange}
                  name={NAMES.ALLOW_DUPLICATES}
                  color="primary"
                  disabled={isSubmitting}
                />
              }
              label="Allow Duplicate Recipients"
            />
          </Box>
          {error === NAMES.ALLOW_DUPLICATES && (
            <Typography variant="caption" sx={{ color: "red" }}>
              There was an error updating this setting, please try again later.
            </Typography>
          )}
        </Box>
        <Box className="BlockDuplicateRefNbrs" sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Block Duplicate Reference Numbers
          </Typography>
          <Typography variant="body1">
            Check the box below if you would like to block placing orders with
            the same reference number.
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings?.blockDuplicateRefNbrs)}
                  onChange={handleChange}
                  name={NAMES.BLOCK_DUPLICATE_REF_NBRS}
                  color="primary"
                  disabled={isSubmitting}
                />
              }
              label="Block Duplicate Reference Numbers"
            />
          </Box>
          {error === NAMES.ALLOW_DUPLICATES && (
            <Typography variant="caption" sx={{ color: "red" }}>
              There was an error updating this setting, please try again later.
            </Typography>
          )}
        </Box>
        <Box className="DisableListScrubbing" sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            List Scrubbing
          </Typography>
          <Typography variant="body1">
            Check the box below if you would like to bypass list scrubbing, and
            send to your list as is.
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings?.allowNoScrub)}
                  onChange={handleChange}
                  name={NAMES.DONT_WANT_NO_SCRUBS}
                  color="primary"
                  disabled={isSubmitting}
                />
              }
              label="Disable List Scrubbing"
            />
          </Box>
          {error === NAMES.DONT_WANT_NO_SCRUBS && (
            <Typography variant="caption" sx={{ color: "red" }}>
              There was an error updating this setting, please try again later.
            </Typography>
          )}
        </Box>
        <Box className="EnableNCOA">
          <Typography variant="h6" gutterBottom>
            National Change Of Address
          </Typography>
          <Typography variant="body1">
            Check the box below to enable NCOA
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings?.enableNCOA)}
                  onChange={handleNCOACheckbox}
                  name={NAMES.ENABLE_NCOA}
                  color="primary"
                  disabled={isSubmitting}
                />
              }
              label="Enable NCOA"
            />
          </Box>
          {error === NAMES.ENABLE_NCOA && (
            <Typography variant="caption" sx={{ color: "red" }}>
              There was an error updating this setting, please try again later.
            </Typography>
          )}
        </Box>
      </Box>
      <Dialog
        open={ncoaOpen}
        fullWidth
        maxWidth="md"
        onClose={() => setNcoaOpen(false)}
      >
        <DialogTitle>
          National Change of Address Service Provider Required Information
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body1" sx={{ mb: 3 }}>
              If you have any questions or if any of the information below is
              not accurate, please contact us at 727-441-4704 or, please email
              corrected Mail List Owner details to{" "}
              <Link href="mailto:paf@postcardmania.com">
                PAF@postcardmania.com
              </Link>
              . If there are any corrections, we will update our system and send
              you a new email to approve.
            </Typography>
            <Box
              sx={{
                height: "250px",
                overflowY: "auto",
                mb: 3,
                backgroundColor: "#FDF9D8",
                p: 3,
              }}
            >
              {companyInformation && (
                <NCOAText
                  address={companyInformation.address}
                  contactInfo={companyInformation.contactInfo}
                />
              )}
            </Box>
            {companyInformation && (
              <Box>
                <FormControlLabel
                  sx={{ mb: 2, display: "block" }}
                  control={
                    <Checkbox
                      checked={approveNcoa}
                      onChange={(e) => setApproveNcoa(e.target.checked)}
                    />
                  }
                  label="I acknowledge having read the National Change of Address (NCOA) Service Provider Required Information"
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start" }}>
          <Button
            disabled={!approveNcoa || isSubmitting}
            onClick={submitNcoaApproval}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button onClick={() => setNcoaOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AccountWrapper>
  );
};

export default IntegrationSettings;
