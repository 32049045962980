export enum Tool {
  doodle = "doodle",
  dynamicImage = "dynamicImage",
  image = "image",
  shape = "shape",
  text = "text",
  bullet = "bullet",
  select = "select",
  layers = "layers",
  pen = "pen",
  pan = "pan",
  qrCode = "qrCode",
}

export interface ICanvasTool {
  type: Tool;
}
