import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Skeleton } from "@mui/lab";
import React, { useState, useEffect } from "react";
import { fetchClient } from "../../../helpers/fetchClient";

import SearchableDataTable from "../../../shared/SearchableDataTable";
import { DesignSize } from "../models/DesignCreationModel";
import EditDesignSizeModal from "./components/EditDesignSizeModal";
import NewDesignSizeModal from "./components/NewDesignSizeModal";

const AdminDesignSizes: React.FC = () => {
  /**
   * State Objects and Refs
   */

  const columns: any[] = [
    {
      name: "Size",
      selector: "sizeInfoSize",
      sortable: true,
    },
    {
      name: "Label",
      selector: "sizeLabel",
      sortable: true,
    },
    {
      name: "Inventory CD",
      selector: "inventoryCD",
      sortable: true,
    },
    {
      name: "",
      cell: (row: DesignSize) => (
        <div>
          <IconButton color="primary" onClick={() => handleEditModalOpen(row)}>
            <i className="fas fa-edit"></i>
          </IconButton>{" "}
          <IconButton color="warning" onClick={() => handleDeleteOpen(row)}>
            <i className="fas fa-trash"></i>
          </IconButton>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [designSizes, setDesignSizes] = useState<DesignSize[]>([]);
  const [activeSize, setActiveSize] = useState<DesignSize>();
  const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  /**
   * Component Methods
   */
  function getDesignSizes() {
    fetchClient.get({
      path: "/admin/integrations/design-sizes",
      onSuccess: (data: DesignSize[]) => {
        setDesignSizes(data);
        setLoading(false);
      },
    });
  }

  function handleDelete() {
    fetchClient.post({
      path: `/admin/integrations/design-sizes/${activeSize?.sizeInfoSize}/remove`,
      onSuccess: () => {
        getDesignSizes();
        handleDeleteOpen();
      },
    });
  }

  function handleDeleteOpen(size?: DesignSize) {
    setActiveSize(size);
    setDeleteModalOpen(!deleteModalOpen);
  }

  function handleEditModalOpen(size?: DesignSize) {
    setActiveSize(size);
    setNewModalOpen(!newModalOpen);
  }

  function handleNewModalOpen() {
    setNewModalOpen(!newModalOpen);
    if (newModalOpen) {
      setActiveSize(undefined);
    }
  }

  /**
   * Component Effects
   */
  useEffect(getDesignSizes, []);

  /**
   * Render
   */

  return (
    <Container sx={{ mb: 6, mt: 6 }}>
      <Button variant="contained" color="primary" onClick={handleNewModalOpen}>
        Add New Size
      </Button>
      {loading && (
        <Skeleton
          variant="rectangular"
          style={{ width: "100%", height: "300px" }}
        />
      )}
      {designSizes.length > 0 && (
        <SearchableDataTable
          tableTitle={"Design Sizes"}
          searchableColumns={["inventoryCD", "sizeInfoSize"]}
          columns={columns}
          data={designSizes}
        />
      )}
      {newModalOpen && (
        <NewDesignSizeModal
          open={newModalOpen}
          onClose={handleNewModalOpen}
          onSubmit={getDesignSizes}
          existingDesignSize={activeSize}
        />
      )}

      <Dialog open={deleteModalOpen} onClose={() => handleDeleteOpen()}>
        <DialogContent>
          Are you sure you want to delete this size?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteOpen()}>Cancel</Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminDesignSizes;
