// @ts-nocheck
import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";

import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import * as yup from "yup";
import { fetchClient } from "../../../helpers/fetchClient";
import LocalStorage, { setTokenExpires } from "../../../helpers/LocalStorage";
import SessionInfo from "../../../data/models/SessionInfo";
import LoadingButton from "../../../shared/LoadingButton";
import StateSelector from "../../../shared/StateSelector";
import LoginResponse from "../../Login/models/LoginResponse";
import { Link } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";

export interface SignUpFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  businessName: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface FormError {
  field: string;
  error: string;
}

const SignUpForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const query = useQuery();
  const refId = query.get("refid");

  const history = useHistory();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: yup
      .string()
      .required("Please confirm the password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    businessName: yup.string().required("Business name is required"),
    phone: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}|\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\d{3}-\d{4}$/,
        "Please enter phone number in this format: (###) ###-####"
      )
      .required("Phone number is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Zip code is not valid")
      .required("Zip code is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      businessName: "",
      phone: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },

    onSubmit: (values) => submitForm(values),
    validationSchema: validationSchema,
  });

  function submitForm(values: any) {
    fetchClient.post({
      path: "/account-setup/register",
      data: { ...values, bAccountNoteIDGuid: refId },
      onSuccess: () => onFormSuccess(values),
      onError: (e) => onFormFailure(e),
    });
  }

  function onFormSuccess(data: any) {
    setSuccess(true);
    fetchClient.post({
      path: "/auth/login",
      onSuccess: onLogin,
      data: { email: data.email, password: data.password },
      onError: onFormFailure,
    });
  }

  function onFormFailure(e) {
    if (
      e &&
      e.message &&
      (e.message.includes("provided email") || e.message.includes("short"))
    ) {
      setError(e.message);
    } else {
      setError("There was a problem registering your account.");
    }
    formik.setSubmitting(false);
  }

  function onLogin(data: LoginResponse) {
    // set all the local storage items
    LocalStorage.setToken(data.token);
    setTokenExpires(data.expires);
    LocalStorage.setCompanies(data.companies);
    LocalStorage.setCurrentCompany(data.currentCompanyId);
    getSessionInfo();
  }

  function getSessionInfo() {
    fetchClient.get({
      path: "/account/session",
      onSuccess: onSessionInfoResponse,
    });
  }

  function onSessionInfoResponse(data: SessionInfo) {
    LocalStorage.setSession(data);
    history.push("/");
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={formik.isSubmitting}
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              type="email"
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.touched.email === true && formik.errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              label="Password"
              name="password"
              size="small"
              value={formik.values.password}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              type="password"
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
              helperText={
                formik.touched.password === true && formik.errors.password
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              label="Confirm Password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              variant="outlined"
              type="password"
              fullWidth
              size="small"
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? true
                  : false
              }
              helperText={
                formik.touched.confirmPassword === true &&
                formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? true
                  : false
              }
              helperText={
                formik.touched.firstName === true && formik.errors.firstName
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName ? true : false
              }
              helperText={
                formik.touched.lastName === true && formik.errors.lastName
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              label="Business Name"
              size="small"
              name="businessName"
              value={formik.values.businessName}
              error={
                formik.touched.businessName && formik.errors.businessName
                  ? true
                  : false
              }
              helperText={
                formik.touched.businessName === true &&
                formik.errors.businessName
              }
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              disabled={formik.isSubmitting}
              format="(###) ###-####"
              customInput={TextField}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              type="tel"
              label="Phone Number"
              name="phone"
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone ? true : false}
              helperText={formik.touched.phone === true && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              label="Address"
              name="address"
              value={formik.values.address}
              error={
                formik.touched.address && formik.errors.address ? true : false
              }
              helperText={
                formik.touched.address === true && formik.errors.address
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              label="Address 2"
              name="address2"
              size="small"
              value={formik.values.address2}
              error={
                formik.touched.address2 && formik.errors.address2 ? true : false
              }
              helperText={formik.errors.address2}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              label="City"
              name="city"
              value={formik.values.city}
              error={formik.touched.city && formik.errors.city ? true : false}
              helperText={formik.touched.city === true && formik.errors.city}
            />
          </Grid>
          <Grid item xs={2}>
            <StateSelector
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              size="small"
              id="state-selector"
              label="State"
              name="state"
              value={formik.values.state}
              error={formik.touched.state && formik.errors.state ? true : false}
              helperText={formik.touched.state === true && formik.errors.state}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberFormat
              size="small"
              disabled={formik.isSubmitting}
              format="#####"
              customInput={TextField}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              type="tel"
              label="Zip"
              name="zip"
              value={formik.values.zip}
              error={formik.touched.zip && formik.errors.zip ? true : false}
              helperText={formik.touched.zip === true && formik.errors.zip}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="row">
              <div className="col-auto">
                <LoadingButton
                  disabled={formik.isSubmitting}
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={formik.isSubmitting}
                  success={success}
                >
                  Register Now
                </LoadingButton>
              </div>
              <div className="col-auto">
                <Button LinkComponent={Link} to="/login" color="primary">
                  I already have an account
                </Button>
              </div>
            </div>
            {Boolean(error) && <div className="error pt-2">{error}</div>}{" "}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SignUpForm;
