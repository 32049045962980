import { Object as IObject } from "fabric/fabric-impl";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import RESOLUTION from "../../../constants/RESOLUTION";

export default function updatePosition(
  obj: IObject,
  property: keyof IObjectProperties,
  value: number
) {
  const pixels = value * RESOLUTION;
  const background = obj.canvas?._objects.find((x) => x.name === "background");
  if (background && background.left && background.top) {
    if (property === "left") {
      obj.set({ left: background.left + pixels });
    } else {
      obj.set({ top: background.top + pixels });
    }
  }
}
