import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { ActionAlertsContext } from "../../../data/State/ActionAlerts";
import exportCsv from "../../../helpers/exportCsv";
import formattingHelpers from "../../../helpers/formattingHelpers";
import LocalStorage from "../../../helpers/LocalStorage";
import {
  PostDataStatus,
  useGetData,
  usePutData,
} from "../../../hooks/useFetch";
import UnstyledButton from "../../../shared/BasicHTML/UnstyledButton";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import MailTracking from "../../../shared/MailTracking/MailTracking";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { RecipientListRecord } from "../../Batches/models/Batch";
import NimbleBatch from "../../Batches/models/NimbleBatch";
import RecipientListTable from "./RecipientListTable";

interface BatchesProps {
  batches: NimbleBatch[];
  isCompleted?: boolean;
  isSandbox?: boolean;
  tableTitle: string;
  onSandboxStatusChange?: (batchId: number, direction: 1 | -1) => void;
  sandBoxChangeReqInfo?: PostDataStatus<unknown>;
  onCancelOrder?: (batchId: number) => void;
  cancelOrderStatus?: PostDataStatus<unknown>;
}

enum recipientFormatting {
  actions = "ACTIONS",
  recipientsNumber = "RECIPIENTS_NUMBER",
  deliverable = "DELIVERABLE",
  undeliverable = "UNDELIVERABLE",
}

const Batches: React.FC<BatchesProps> = ({
  batches,
  isCompleted,
  isSandbox,
  tableTitle,
  onSandboxStatusChange,
  sandBoxChangeReqInfo,
  onCancelOrder,
}) => {
  const [cancelOpen, setCancelOpen] = useState(-1);
  const [recipientsOpen, setRecipientsOpen] = useState({
    id: -1,
    formatting: recipientFormatting.actions,
  });

  const [getRecipientList, getRecipientListStatus] =
    useGetData<RecipientListRecord[]>(`/api/orders/:id/list`);
  const [recipientList, setRecipientList] = useState<RecipientListRecord[]>([]);
  const [toolMenuOpen, setToolMenuOpen] = useState<number | null>(null);
  const [toolMenuAchorEl, setToolMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [mailTrackingOpen, setMailTrackingOpen] = useState<number>();

  const session = LocalStorage.getSession();
  const enableRecipientPrivacy = session?.enableRecipientPrivacy;

  const [cancelOrder, cancelOrderStatus] = usePutData(`/api/batch/cancel`);

  const { actions } = useContext(ActionAlertsContext);

  function handleToolMenuOpen(
    e: React.SyntheticEvent<HTMLButtonElement>,
    batchId: number
  ) {
    setToolMenuAnchorEl(e.currentTarget);
    setToolMenuOpen(batchId);
  }

  function exportList() {
    exportCsv(recipientList, `Recipient List for Batch #${recipientsOpen.id}`);
  }

  function exportResults() {
    const exportData = batches.map((x) => {
      if (isSandbox)
        return {
          date: moment(x.orderDate).format("MM/DD/YYYY"),
          batchID: x.batchID,
          status: x.status,
          recipients: x.recipients ?? 0,
        };
      return {
        date: moment(x.orderDate).format("MM/DD/YYYY"),
        batchID: x.batchID,
        status: x.status,
        recipients: x.recipients ?? 0,
        deliverable: x.deliveredRecords ?? 0,
        undeliverable: x.undeliverableRecords ?? 0,
        canceled: x.canceledRecords ?? 0,
        total:
          x.quoteTotal !== undefined && x.quoteTotal !== null
            ? `$${formattingHelpers.formatThousands(x.quoteTotal.toFixed(2))}`
            : "TBD",
      };
    });
    exportCsv(exportData, `${tableTitle}`);
  }

  async function handleCancel() {
    if (onCancelOrder) {
      await cancelOrder(cancelOpen);
      setCancelOpen(-1);
      onCancelOrder(cancelOpen);
    }
  }

  async function handleListRetrieval() {
    if (recipientsOpen.id !== -1) {
      const recipients = await getRecipientList(undefined, {
        accessor: "id",
        value: recipientsOpen.id,
      });
      if (recipients && recipients.length) {
        if (
          recipientsOpen.formatting === recipientFormatting.actions ||
          recipientsOpen.formatting === recipientFormatting.recipientsNumber
        )
          setRecipientList(recipients);
        if (recipientsOpen.formatting === recipientFormatting.deliverable)
          setRecipientList(
            recipients.filter(
              //@ts-ignore
              (x) => !x.undeliverable && x.orderStatus != "Cancelled"
            )
          );
        if (recipientsOpen.formatting === recipientFormatting.undeliverable)
          setRecipientList(recipients.filter((x) => x.undeliverable));
      }
    }
  }

  let columns: TableColumn<NimbleBatch>[] = [
    {
      name: "Date",
      selector: (row) => row.orderDate,
      sortable: true,
      format: (row) => moment(row.orderDate).format("MM/DD/YYYY"),
    },
    {
      sortable: true,
      name: "Batch ID",
      selector: (row) => row.batchID,
      format: (order) => (
        <Link to={`/batches/${order.batchID}`} className="color-secondary">
          {order.batchID}
        </Link>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Recipients",
      selector: (row) => (row.recipients ? row.recipients : 0),
      sortable: true,
      format: (batch) =>
        enableRecipientPrivacy ? (
          batch.recipients
        ) : (
          <UnstyledButton
            sx={{
              color:
                batch.recipients && batch.recipients > 0
                  ? (theme) => theme.palette.secondary.main
                  : "inherit",
            }}
            onClick={() =>
              setRecipientsOpen({
                id: batch.batchID,
                formatting: recipientFormatting.recipientsNumber,
              })
            }
          >
            {batch.recipients}
          </UnstyledButton>
        ),
    },
    {
      name: "Deliverable",
      selector: (row) => row.deliveredRecords,
      sortable: true,
      format: (batch) =>
        enableRecipientPrivacy ? (
          batch.deliveredRecords
        ) : (
          <UnstyledButton
            sx={{
              color:
                batch.deliveredRecords > 0
                  ? (theme) => theme.palette.secondary.main
                  : "inherit",
            }}
            onClick={() =>
              setRecipientsOpen({
                id: batch.batchID,
                formatting: recipientFormatting.deliverable,
              })
            }
          >
            {batch.deliveredRecords}
          </UnstyledButton>
        ),
    },
    {
      name: "Undeliverable",
      selector: (row) => row.undeliverableRecords,
      sortable: true,
      format: (batch) =>
        enableRecipientPrivacy ? (
          batch.undeliverableRecords
        ) : (
          <UnstyledButton
            sx={{
              color:
                batch.undeliverableRecords > 0
                  ? (theme) => theme.palette.secondary.main
                  : "inherit",
            }}
            onClick={() =>
              setRecipientsOpen({
                id: batch.batchID,
                formatting: recipientFormatting.undeliverable,
              })
            }
          >
            {batch.undeliverableRecords}
          </UnstyledButton>
        ),
    },
    {
      name: "Canceled",
      selector: (row) => row.canceledRecords,
      sortable: true,
    },
    {
      name: "Total",
      sortable: true,
      selector: (row) => row.quoteTotal,

      format: (row) =>
        row.quoteTotal
          ? `$${formattingHelpers.formatThousands(row.quoteTotal.toFixed(2))}`
          : "TBD",
    },
    {
      name: "",
      cell: (batch) => (
        <div>
          <Button
            color="primary"
            onClick={(e) => handleToolMenuOpen(e, batch.batchID)}
            sx={{ minWidth: "120px" }}
          >
            Actions{" "}
            <Box
              sx={{ ml: 1 }}
              component="i"
              className="fas fa-chevron-down d-inline-block "
            />
          </Button>
          <Menu
            anchorEl={toolMenuAchorEl}
            open={toolMenuOpen === batch.batchID}
            onClose={() => setToolMenuOpen(null)}
          >
            <MenuItem>
              <Link
                to={`/batches/${batch.batchID}`}
                style={{ color: "#212121" }}
              >
                View Batch
              </Link>
            </MenuItem>
            {batch.status == "Failed Payment" && (
              <MenuItem
                onClick={() => actions.setActiveQuoteId(batch.quoteNoteID)}
              >
                Update Payment
              </MenuItem>
            )}
            {batch.status == "Pending Payment" && (
              <MenuItem
                key="approve"
                onClick={() => actions.setActiveQuoteId(batch.quoteNoteID)}
              >
                Pay Now
              </MenuItem>
            )}
            {isCompleted && batch.status == "Delivered" && (
              <MenuItem onClick={() => setMailTrackingOpen(batch.batchID)}>
                Mail Tracking
              </MenuItem>
            )}

            {batch.isSandbox &&
              batch.status != "Pending" &&
              onSandboxStatusChange && (
                <MenuItem
                  onClick={() => onSandboxStatusChange(batch.batchID, -1)}
                  disabled={sandBoxChangeReqInfo?.status === "loading"}
                >
                  Prev Status
                </MenuItem>
              )}
            {batch.isSandbox &&
              batch.status != "Delivered" &&
              onSandboxStatusChange && (
                <MenuItem
                  disabled={sandBoxChangeReqInfo?.status === "loading"}
                  onClick={() => onSandboxStatusChange(batch.batchID, 1)}
                >
                  Next Status
                </MenuItem>
              )}
            {batch.status == "Pending Payment" && (
              <MenuItem
                style={{ color: "red" }}
                role="button"
                key="cancel"
                onClick={() => setCancelOpen(batch.batchID)}
              >
                Cancel Order
              </MenuItem>
            )}
          </Menu>
        </div>
      ),
    },
  ];

  if (isSandbox) {
    columns = columns.filter(
      (x) =>
        x.name !== "Deliverable" &&
        x.name !== "Undeliverable" &&
        x.name !== "Total" &&
        x.name !== "Canceled"
    );
  }

  useEffect(() => {
    handleListRetrieval();
  }, [recipientsOpen]);

  useEffect(() => {
    setToolMenuOpen(null);
    setToolMenuAnchorEl(null);
  }, [cancelOpen, recipientsOpen, mailTrackingOpen]);

  return (
    <Box>
      <SearchableDataTable
        columns={columns}
        searchableColumns={["orderDate", "batchID", "status"]}
        data={batches}
        responsive
        tableTitle={tableTitle}
        fixedHeader={true}
        fixedHeaderScrollHeight="532px"
        customStyles={{
          tableWrapper: {
            style: {
              height: "532px",
            },
          },
        }}
      />
      <Box sx={{ mt: 2, textAlign: "right" }}>
        <Button color="primary" variant="outlined" onClick={exportResults}>
          Export {tableTitle}
        </Button>
      </Box>
      <Dialog
        open={Boolean(mailTrackingOpen)}
        maxWidth="lg"
        fullWidth
        onClose={() => setMailTrackingOpen(undefined)}
      >
        <DialogTitle>Batch #{mailTrackingOpen} Mail Tracking</DialogTitle>
        <DialogContent>
          {mailTrackingOpen !== undefined && (
            <MailTracking batchId={mailTrackingOpen} removeElevation />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMailTrackingOpen(undefined)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={recipientsOpen.id !== -1 || cancelOpen !== -1}
        fullWidth
        maxWidth={cancelOpen !== -1 ? "sm" : "md"}
      >
        {recipientsOpen.id !== -1 && (
          <React.Fragment>
            <DialogContent>
              <LoadingWrapper
                height={400}
                loading={getRecipientListStatus.status === "loading"}
              >
                <RecipientListTable
                  recipients={recipientList}
                  tableTitle={
                    recipientsOpen.formatting ===
                    recipientFormatting.undeliverable
                      ? "Undeliverable Recipients"
                      : recipientsOpen.formatting ===
                        recipientFormatting.deliverable
                      ? "Deliverable Recipients"
                      : "Recipients"
                  }
                />
              </LoadingWrapper>
            </DialogContent>
            <DialogActions>
              <Button onClick={exportList} variant="contained">
                Export
              </Button>
              <Button
                onClick={() => setRecipientsOpen({ ...recipientsOpen, id: -1 })}
              >
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
        {cancelOpen !== -1 && (
          <React.Fragment>
            <DialogTitle>Are you sure you want to cancel?</DialogTitle>
            <DialogContent dividers={false}>
              <DialogContentText>
                Are you sure you want to cancel Batch #{cancelOpen}?
                <br />
                <strong>This action cannot be undone.</strong>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={handleCancel}
                disabled={cancelOrderStatus.status === "loading"}
              >
                Confirm
              </Button>
              <Button
                color="primary"
                disabled={cancelOrderStatus.status === "loading"}
                onClick={() => setCancelOpen(-1)}
              >
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </Box>
  );
};

export default Batches;
