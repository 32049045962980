import { Button, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import Design from "../../../../../Designs/models/Design";
import { OrderRequestContext } from "../../../PlaceAnOrder";
import MapListToCustomField from "./MapListToCustomField";

interface CustomFieldsProps {
  design: Design;
}

const CustomFields: React.FC<CustomFieldsProps> = ({ design }) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  const orderRequest = orderRequestStore.orderRequest;
  /**
   * Component Methods
   */
  function addField() {
    orderRequestStore.addNewCustomVariableMapping();
  }

  function updateField(
    index: number,
    fieldName: string | undefined,
    userListFieldName: string | undefined
  ) {
    orderRequestStore.updateCustomVariableMapping(
      index,
      fieldName,
      userListFieldName
    );
  }
  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <div>
      <h4>Custom Variable Mapping (optional)</h4>
      <Typography sx={{ mb: 3 }}>
        If you would like some of the design variables to be dynamic based on
        data from your list, use custom variable mapping to assign fields from
        your list to design variable fields
      </Typography>

      {orderRequest.customVariableMapping.length > 0 && (
        <Grid container spacing={3} alignItems="center">
          {orderRequest.customVariableMapping.map((field, index) => (
            <MapListToCustomField
              field={field}
              index={index}
              key={index}
              onChange={updateField}
              design={design}
            />
          ))}
        </Grid>
      )}
      <Button
        onClick={addField}
        color="primary"
        variant="outlined"
        sx={{ mt: 3 }}
      >
        Add A New Field
      </Button>
    </div>
  );
};

export default CustomFields;
