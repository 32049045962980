import { Object as IObject } from "fabric/fabric-impl";

export default function isShape(object?: IObject) {
  if (!object) return false;
  if (object.name?.includes("image")) return false;
  const type = object.get("type")?.toLowerCase();
  if (!type) return false;
  if (
    ["rect", "circle", "ellipse", "triangle", "polygon", "path"].includes(type)
  )
    return true;
  return false;
}
