import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  DialogTitle,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import NumberFormat from "react-number-format";
import valid from "card-validator";

import formattingHelpers from "../helpers/formattingHelpers";
import { CreditCardSchema } from "../LegacyReactSite/Shared/helpers/yup-schemas";
import LoadingButton from "./LoadingButton";
import { apiCall } from "../LegacyReactSite/helpers";
import { fetchClient } from "../helpers/fetchClient";
import { makeStyles } from "@mui/styles";
import LoadingWrapper from "./LoadingWrapper";
import useQuery from "../hooks/useQuery";

const useStyles = makeStyles({
  totalDueSection: {
    background: "#f1f1f1",
  },
  price: {
    fontSize: "50px",
    fontWeight: 200,
    padding: "8px 0 32px",
    textAlign: "center",
  },
  totalDue: {
    fontSize: "32px",
    paddingTop: "32px",
    fontWeight: 600,
    textAlign: "center",
  },
  tabContainer: {
    marginBottom: "16px",
  },
  tab: {
    paddingBottom: "16px",
  },
});

interface QuotePayProps {
  mode?: string;
  quoteNoteId: string;
  handleClose: () => void;
  onSuccess: () => void;
}

interface Quote {
  bAccount: {
    acctCD: string;
    acctName: string;
    bAccountID: number;
  };
  details: {
    quoteID: string;
    quoteTotal: number;
    salesTax: number;
    subTotal: number;
    totalDeductions: number;
    totalDue: number;
    totalPaid: number;
  };
  paymentMethods: {
    key: string;
    value: string;
  }[];
  quote: {
    apiBatchID: number;
    bAccountID: number;
    noteID: string;
    quoteCD: string;
    quoteID: number;
    quoteTotal: number;
    subTotal: number;
    taxTotal: number;
    totalDeductions: number;
    totalDue: number;
    totalPayments: number;
  };
}

const QuotePay: React.FC<QuotePayProps> = ({
  mode,
  quoteNoteId,
  handleClose,
  onSuccess,
}) => {
  const query = useQuery();
  const classes = useStyles();
  const [quoteIsLoading, setQuoteIsLoading] = useState(true);
  const [quote, setQuote] = useState<Quote>({} as Quote);
  const [paymentMethod, setPaymentMethod] = useState("new");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [creditCardInfo, setCreditCardInfo] = useState({
    firstName: "",
    lastName: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    cvc: "",
    expiration: "",
  });
  const [cardType, setCardType] = useState<string | null>(null);
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setTab(newValue);
    if (newValue == 0) setPaymentMethod("new");
    if (newValue == 1) setPaymentMethod(quote.paymentMethods[1].key);
  };

  function getQuote() {
    if (mode == "iframe") {
      const token = query.get("token");
      fetch(`/api/payments/quote/${quoteNoteId}?apiGet=true`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          handleQuoteResponse(data);
        });
    } else {
      fetchClient.get({
        path: `/payments/quote/${quoteNoteId}?apiGet=true`,
        onSuccess: handleQuoteResponse,
      });
    }
  }

  function handleQuoteResponse(data: Quote) {
    setQuote(data);
    setQuoteIsLoading(false);
  }

  const handleError = (e: { message: string }) => {
    setIsSubmitting(false);
    setError({
      error: true,
      message: e.message,
    });
  };
  const handleSavedCardSubmit = () => {
    setError({
      error: false,
      message: "",
    });
    setIsSubmitting(true);
    if (mode == "iframe") {
      fetch(`/api/payments/quote/${quote.quote.quoteCD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${query.get("token")}`,
        },
        body: JSON.stringify({
          ...quote,
          quote: {
            ...quote.quote,
            description: "<span></span>",
          },
          paymentMethod: paymentMethod,
          creditCardInfo: {
            paymentMethod: paymentMethod,
          },
          totalDue: quote.quote.totalDue,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          onSuccess();
          setIsSubmitting(false);
          setSuccess(true);
        })
        .catch(handleError);
    } else {
      /* @ts-ignore */
      apiCall(
        `/payments/quote/${quote.quote.quoteCD}`,
        "POST",
        (data: any) => {
          onSuccess();
          setIsSubmitting(false);
          setSuccess(true);
        },
        {
          ...quote,
          quote: {
            ...quote.quote,
            description: "<span></span>",
          },
          paymentMethod: paymentMethod,
          creditCardInfo: {
            paymentMethod: paymentMethod,
          },
          totalDue: quote.quote.totalDue,
        },
        handleError
      );
    }
  };
  useEffect(getQuote, []);
  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="xs">
      {quoteIsLoading && (
        <DialogContent style={{ paddingTop: "132px", paddingBottom: "132px" }}>
          <LoadingWrapper loading={quoteIsLoading} height={300} />
        </DialogContent>
      )}
      {!quoteIsLoading && !quote && (
        <div>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            Looks like this order may not need payment. If you continue to see
            this alert, please give us a call at 1-800-628-1804 x455
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </div>
      )}
      {!quoteIsLoading && quote && (
        <div>
          {!success && (
            <div>
              <div className={classes.totalDueSection}>
                <h3 className={classes.totalDue}>Total Due</h3>
                <div className={classes.price}>
                  $
                  {formattingHelpers.formatThousands(
                    quote.quote.totalDue.toFixed(2)
                  )}
                </div>
              </div>

              <Formik
                initialValues={creditCardInfo}
                validationSchema={CreditCardSchema(cardType)}
                onSubmit={(values, { resetForm }) => {
                  setCreditCardInfo(values);

                  const cardNumber = values.cardNumber.replace(/\D/g, "");
                  const month = valid.expirationDate(values.expiration).month;
                  const year = valid.expirationDate(values.expiration).year;
                  setError({
                    error: false,
                    message: "",
                  });
                  setIsSubmitting(true);
                  /* @ts-ignore */
                  apiCall(
                    `/payments/quote/${quote.quote.quoteCD}`,
                    "POST",
                    (data: any) => {
                      console.log("success");
                      onSuccess();
                      setSuccess(true);
                      setIsSubmitting(false);
                    },
                    {
                      ...quote,
                      quote: {
                        ...quote.quote,
                        description: "<span></span>",
                      },
                      paymentMethod: "new",
                      creditCardInfo: {
                        paymentMethod: "new",
                        firstName: values.firstName,
                        lastName: values.lastName,
                        cardNumber: cardNumber,
                        expMonth: month,
                        expYear: year,
                        cvv: values.cvc,
                        digitalSignature: "signed",
                      },
                      totalDue: quote.quote.totalDue,
                    },
                    handleError
                  );
                }}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleChange,

                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Tabs
                        centered
                        value={tab}
                        onChange={handleTabChange}
                        className={classes.tabContainer}
                      >
                        <Tab label="New card" />
                        {quote.paymentMethods.length > 0 && (
                          <Tab label="Saved card" />
                        )}
                      </Tabs>
                      {tab == 0 && (
                        <React.Fragment>
                          <Grid container spacing={2} className={classes.tab}>
                            <Grid item xs={12}>
                              <TextField
                                error={
                                  errors.firstName && touched.firstName
                                    ? true
                                    : false
                                }
                                value={values.firstName}
                                label="First Name"
                                name="firstName"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                error={
                                  errors.lastName && touched.lastName
                                    ? true
                                    : false
                                }
                                value={values.lastName}
                                label="Last name"
                                name="lastName"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <NumberFormat
                                customInput={TextField}
                                format={
                                  values.cardNumber
                                    .toString()
                                    .startsWith("34") ||
                                  values.cardNumber.toString().startsWith("37")
                                    ? "#### - ###### - #####"
                                    : "#### - #### - #### - ####"
                                }
                                error={
                                  errors.cardNumber && touched.cardNumber
                                    ? true
                                    : false
                                }
                                type="tel"
                                mask="_"
                                value={values.cardNumber}
                                label="Credit card number"
                                name="cardNumber"
                                onChange={(e) => {
                                  if (
                                    values.cardNumber
                                      .toString()
                                      .startsWith("34") ||
                                    values.cardNumber
                                      .toString()
                                      .startsWith("37")
                                  ) {
                                    setCardType("amex");
                                  } else {
                                    setCardType("creditCard");
                                  }
                                  return handleChange(e);
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <NumberFormat
                                  customInput={TextField}
                                  error={
                                    errors.expiration && touched.expiration
                                      ? true
                                      : false
                                  }
                                  type="tel"
                                  format="##/##"
                                  value={values.expiration}
                                  label="Exp. (MM/YY)"
                                  name="expiration"
                                  onChange={handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <NumberFormat
                                  customInput={TextField}
                                  error={
                                    errors.cvc && touched.cvc ? true : false
                                  }
                                  value={values.cvc}
                                  type="tel"
                                  format={
                                    values.cardNumber.startsWith("34") ||
                                    values.cardNumber.startsWith("37")
                                      ? "####"
                                      : "###"
                                  }
                                  label="CVC"
                                  name="cvc"
                                  onChange={handleChange}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </Grid>
                          </Grid>
                          {errors.cardNumber &&
                            touched.cardNumber &&
                            values.cardNumber !== "" && (
                              <div>
                                <Typography
                                  variant="error"
                                  sx={{ mb: 1, mt: 3 }}
                                >
                                  Enter a valid credit card number
                                </Typography>
                              </div>
                            )}
                          {errors.expiration &&
                            touched.expiration &&
                            values.expiration !== "" && (
                              <div>
                                <Typography variant="error" sx={{ mb: 1 }}>
                                  Enter a valid expiration date. Ex: 01/22
                                </Typography>
                              </div>
                            )}
                          {errors.cvc && touched.cvc && values.cvc !== "" && (
                            <div>
                              <Typography variant="error" sx={{ mb: 1 }}>
                                Enter a valid cvc code
                              </Typography>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {tab == 1 && (
                        <div className={classes.tab}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>Credit Card</InputLabel>
                            <Select
                              value={paymentMethod}
                              onChange={(e) => {
                                setPaymentMethod(
                                  e.target && e.target.value
                                    ? (e.target.value as string)
                                    : ""
                                );
                              }}
                              input={<OutlinedInput />}
                              fullWidth
                            >
                              {quote.paymentMethods.map((pm) => {
                                if (pm.key == "new") return false;
                                return (
                                  <MenuItem value={pm.key} key={pm.key}>
                                    {pm.value}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                      {error.error && (
                        <div className="text-center error">{error.message}</div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        success={success}
                        loading={isSubmitting}
                        color="primary"
                        onClick={() =>
                          tab == 0 ? handleSubmit() : handleSavedCardSubmit()
                        }
                      >
                        {" "}
                        Checkout
                      </LoadingButton>
                      <Button color="primary" onClick={handleClose}>
                        Close
                      </Button>
                    </DialogActions>
                    {/* <DialogActions>
									<LoadingButton
										onClick={handleSubmit}
										isSubmitting={isSubmitting}
										success={success}
										buttonText={"Submit"}
									/>
									<Grow in={!isSubmitting}>
										<Button
											color="primary"
											onClick={() => {
												handleClose(false);
												setIsSubmitting(false);
												setSuccess(false);
												resetForm();
											}}
										>
											{!success ? "Cancel" : "Close"}
										</Button>
									</Grow>
								</DialogActions> */}
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
      {success && (
        <div>
          <DialogTitle>You&apos;re all set!</DialogTitle>
          <DialogContent>
            Your order has been processed successfully.
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default QuotePay;
