import { useDrop } from "react-dnd";
import tw, { css } from "twin.macro";
import pageSelectorStyles from "./pageSelectorStyles";
interface PageDropzoneProps {
  width: number;
  height: number;
  onDrop: (page: string, destination: number) => void;
  index: number;
  hide: boolean;
}

const PageDropzone = ({
  onDrop,
  index,
  width,
  height,
  hide,
}: PageDropzoneProps) => {
  const [{ canDrop, isOver, type, item: draggedItem }, drop] = useDrop({
    accept: ["page"],
    drop: (item: { name: string; index: number }, monitor) => {
      onDrop(item.name, index);
    },
    collect: (monitor) => ({
      type: monitor.getItemType(),
      item: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isDragging = type === "page";

  if (isDragging) {
    return (
      <div
        ref={drop}
        css={[
          pageSelectorStyles.pagePreviewContainer(false, 30, height),
          pageSelectorStyles.dropzone(hide, isOver, width),
        ]}
      ></div>
    );
  } else {
    return null;
  }
};

export default PageDropzone;
