import { RemoveFromQueueTwoTone } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import ActionAlert from "../../../data/models/NeedsAttention";
import { ActionAlertsContext } from "../../../data/State/ActionAlerts";
import { fetchClient } from "../../../helpers/fetchClient";
import LoadingButton from "../../../shared/LoadingButton";
import SearchableDataTable from "../../../shared/SearchableDataTable";

interface HomePageAlertsProps {
  searchTerm: string;
}

const HomepageAlerts: React.FC<HomePageAlertsProps> = ({ searchTerm }) => {
  /**
   * State Objects and Refs
   */
  const alertsContext = useContext(ActionAlertsContext);
  const alerts = alertsContext.alerts;
  const setActiveQuoteId = alertsContext.actions.setActiveQuoteId;
  const [disabledAlertButton, setDisabledAlertButton] = useState<number | null>(
    null
  );

  const columns: TableColumn<ActionAlert>[] = [
    {
      selector: (row) => row.alertMessage,
      wrap: true,
      width: "85%",
      format: (row) =>
        row.job === "API" ? (
          `We need payment for Batch #${row.entityID} with orders from ${moment(
            row.date
          ).format("MM/DD")}`
        ) : (
          <Tooltip
            disableFocusListener
            title="This order was placed with PostcardMania outside of the Direct Mail API. You will be redirected MyPostcardMania to handle this task."
            placement="left"
          >
            <Typography
              variant="caption"
              component="span"
              sx={{ cursor: "help" }}
            >
              {row.alertMessage}
              {row.alertMessage.endsWith(".") ? " " : ". "}
              Order #{row.job}
            </Typography>
          </Tooltip>
        ),
    },
    {
      name: "",
      width: "15%",
      right: true,
      cell: (row) => {
        return (
          <Box sx={{ textAlign: "right" }}>
            {row.job === "API" && (
              <Button
                color="primary"
                size="small"
                onClick={() => setActiveQuoteId(row.entityCD)}
              >
                Pay Now
              </Button>
            )}
            {row.job !== "API" && (
              <LoadingButton
                color="primary"
                size="small"
                variant="text"
                loading={disabledAlertButton === row.entityID}
                success={false}
                onClick={() => handleMyPCMAlertClick(row)}
              >
                View Task
              </LoadingButton>
            )}
          </Box>
        );
      },
    },
  ];

  /**
   * Component Methods
   */

  function handleMyPCMAlertClick(alert: ActionAlert) {
    setDisabledAlertButton(alert.entityID);
    fetchClient.post({
      path: "/alerts/generate-url",
      onSuccess: onMyPCMAlertSuccess,
      data: alert,
    });
  }
  function onMyPCMAlertSuccess(res: { url: string }) {
    setDisabledAlertButton(null);
    const fauxLink = document.createElement("a");
    fauxLink.href = res.url;
    fauxLink.target = "_blank";
    fauxLink.click();
    document.removeChild(fauxLink);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <SearchableDataTable
      columns={columns}
      noTableHead
      searchableColumns={["entityID", "job", "alertMessage"]}
      data={alerts}
      hideSearchBar
      overrideSearchTerm={searchTerm}
      paginationRowsPerPageOptions={[5, 10, 25, 50]}
      paginationPerPage={5}
      noDataComponent={alerts.length === 0 ? "You have no alerts" : undefined}
      pagination
      responsive
      elevation={0}
      customStyles={{
        table: {
          style: {
            maxHeight: alerts.length > 0 ? "215px" : "271px",
            height: alerts.length > 0 ? "215px" : "271px",
          },
        },
        rows: {
          style: { minHeight: "40px" },
        },
      }}
    />
  );
};

export default HomepageAlerts;
