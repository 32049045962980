import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SearchableDataTable from "../../shared/SearchableDataTable";
import { fetchClient } from "../../helpers/fetchClient";
import PricingPlanModel from "./models/PricingPlanModel";
import { PricingPlan } from "./models/PricingPlanModel";
import { TableColumn } from "react-data-table-component";
import LoadingWrapper from "../../shared/LoadingWrapper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Paper,
  Grid,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Container,
} from "@mui/material";
import useGetData from "../../hooks/dataFetchers/useGetData";

const PricingPlans: React.FC = () => {
  const { data, isLoading, error, updateData } =
    useGetData<PricingPlanModel>(`/api/pricing/plans`);

  const [activePlan, setActivePlan] = useState<PricingPlan>({
    planName: "",
    price: 0.0,
    priceClass: "",
  });
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const pricingPlans = data?.pricingPlans;
  const planType = data?.pricingPlans;
  const [toolMenuOpen, setToolMenuOpen] = useState<string | null>(null);
  const [toolMenuAnchor, setToolMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  function updatePricingPlans(pricingPlan: PricingPlan) {
    if (data && pricingPlans) {
      updateData({ ...data, pricingPlans: [...pricingPlans, pricingPlan] });
    }
  }

  function handleChange(
    e: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >
  ) {
    if (e.target.name) {
      setActivePlan({
        ...activePlan,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleAdd() {
    fetchClient.post({
      path: `/pricing/plans/add`,
      data: activePlan,
      onSuccess: (data: PricingPlan) => updatePricingPlans(data),
    });
    setAddModalOpen(false);
  }

  function handleUpdate() {
    fetchClient.put({
      path: `/pricing/plans/update`,
      data: activePlan,
      onSuccess: (data: PricingPlan) => updatePricingPlans(data),
    });
    setEditModalOpen(false);
  }

  function handleEditOpen(pricingPlan: PricingPlan) {
    setActivePlan(pricingPlan);
    setEditModalOpen(!editModalOpen);
  }

  function handleAddOpen(pricingPlan: PricingPlan) {
    setActivePlan(pricingPlan);
    setAddModalOpen(!addModalOpen);
  }

  function closeModal() {
    setEditModalOpen(false);
    setAddModalOpen(false);
  }

  function handleToolMenuClick(
    e: React.MouseEvent<HTMLButtonElement>,
    priceClass: string
  ) {
    setToolMenuAnchor(e.currentTarget);
    setToolMenuOpen(priceClass);
  }

  const columns: TableColumn<PricingPlan>[] = [
    {
      name: "Price Class",
      selector: (row) => row.priceClass,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: (row) => (row.planName ? row.planName : "N/A"),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price.toFixed(2),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <Button onClick={(e) => handleToolMenuClick(e, row.priceClass)}>
            Manage{" "}
            <Box
              component="i"
              sx={{ ml: 1 }}
              className="fas fa-chevron-down d-inline-block"
            ></Box>
          </Button>
          <Menu
            open={toolMenuOpen === row.priceClass}
            onClose={() => setToolMenuOpen(null)}
            anchorEl={toolMenuAnchor}
          >
            <MenuItem onClick={() => handleEditOpen(row)}>
              Edit Pricing Plan
            </MenuItem>
            <MenuItem>
              <Link
                to={`/admin/pricing/${row.priceClass}`}
                style={{ color: "#212121" }}
              >
                Manage Pricing
              </Link>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <Container sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper
        loading={isLoading}
        height={600}
        container={Paper}
        hasError={error.hasError}
        errorMessage={error.errorMessage}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Button variant="outlined" color="primary" href="/admin">
              Go Back
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleAddOpen({ planName: "", price: 0.0, priceClass: "" })
              }
            >
              Add New Pricing Plan
            </Button>
          </Grid>
        </Grid>
        {pricingPlans && (
          <SearchableDataTable
            tableTitle={"Pricing Plans"}
            searchableColumns={["priceClass", "planName", "price"]}
            columns={columns}
            data={pricingPlans}
          />
        )}
        <Dialog open={addModalOpen || editModalOpen} onClose={closeModal}>
          <DialogContent>
            {(addModalOpen || editModalOpen) && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  Price Class
                  <TextField
                    required
                    name="priceClass"
                    label="Required"
                    disabled={editModalOpen}
                    value={activePlan?.priceClass}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  Plan Name
                  <TextField
                    name="planName"
                    value={activePlan?.planName}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  Price
                  <TextField
                    required
                    name="price"
                    label="Required"
                    type="number"
                    value={activePlan?.price}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {editModalOpen && (
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            )}
            {addModalOpen && (
              <Button onClick={handleAdd} variant="contained" color="primary">
                Create
              </Button>
            )}
            <Button color="primary" onClick={closeModal}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </LoadingWrapper>
    </Container>
  );
};

export default PricingPlans;
