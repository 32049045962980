import { IEvent, Object as IObject, Text, Transform } from "fabric/fabric-impl";
import { Textbox, fabric } from "fabric";
import { createBleedClipPath } from "./createBackgroundClipPath";

export function resetActionHandlers(object: IObject) {}

export default function textResizer(text: Textbox) {
  text.controls = {
    mb: new fabric.Control({
      ...text.controls.mb,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeHeight),
    }),
    mt: new fabric.Control({
      ...text.controls.mt,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeHeight),
    }),
    tl: new fabric.Control({
      ...text.controls.tl,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(resize),
    }),
    tr: new fabric.Control({
      ...text.controls.tr,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(resize),
    }),
    bl: new fabric.Control({
      ...text.controls.bl,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(resize),
    }),
    br: new fabric.Control({
      ...text.controls.br,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(resize),
    }),
    ml: new fabric.Control({
      ...text.controls.ml,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeWidth),
    }),
    mr: new fabric.Control({
      ...text.controls.mr,
      actionName: "resizing",
      actionHandler: wrapWithFixedAnchor(changeWidth),
    }),
    mtr: new fabric.Control({
      ...text.controls.mtr,
    }),
  };

  // text.controls.mb.actionHandler = wrapWithFixedAnchor(changeHeight);
  // text.controls.mt.actionHandler = wrapWithFixedAnchor(changeHeight);
  // text.controls.tl.actionHandler = wrapWithFixedAnchor(resize);
  // text.controls.tr.actionHandler = wrapWithFixedAnchor(resize);
  // text.controls.br.actionHandler = wrapWithFixedAnchor(resize);
  // text.controls.bl.actionHandler = wrapWithFixedAnchor(resize);
  // text.controls.ml.actionHandler = wrapWithFixedAnchor(changeWidth);
  // text.controls.mr.actionHandler = wrapWithFixedAnchor(changeWidth);

  // text.setCoords();
  if (text.__height) {
    text.set("height", text.__height);
    text.setCoords();
    text.canvas?.renderAll();
  }
  // text.controls.tl.actionName = "resizing";
  // text.controls.tr.actionName = "resizing";
  // text.controls.br.actionName = "resizing";
  // text.controls.bl.actionName = "resizing";

  // text.onKeyDown = (e) => {
  //   heightWrapper(fabric.Textbox.prototype.onKeyDown, e as KeyboardEvent, text);
  // };

  text.onInput = (e) => {
    heightWrapper(fabric.Textbox.prototype.onInput, e as InputEvent, text);
  };

  updateClippingPath(text);
  text.on("editing:entered", onDoubleClick);
  // text.on("modified", () => {
  //   text.set("dirty", true);
  //   text.canvas?.renderAll();
  // });
  //text.on("modified", (e) => maintainHeight(text));
}

function onDoubleClick(e: IEvent) {
  const textbox = e.target as Textbox;
  if (textbox) {
    fabric.charWidthsCache[textbox.fontFamily ?? ""] = {};
    // @ts-ignore
    textbox.initDimensions();
    textbox.setCoords();
    textbox.canvas?.renderAll();
  }
}

function maintainHeight(obj: Textbox) {
  // @ts-ignore
  if (obj.__height) {
    // @ts-ignore
    obj.height = obj.__height;
    obj.canvas?.renderAll();
  }
}

function heightWrapper(
  f: (e: Event) => void | boolean,
  e: InputEvent,
  target: Textbox
) {
  // if (
  //   // @ts-ignore
  //   target.__height &&
  //   (target.height ?? 0) + 1 < target.calcTextHeight() &&
  //   e.inputType !== "deleteContentBackward"
  // ) {
  //   (e.target as HTMLInputElement).value = target.text ?? "";
  // }

  f.call(target, e);
  // @ts-ignore
  if (target.__height !== undefined) {
    // @ts-ignore
    target.set("height", target.__height);
    target.setCoords();
    updateClippingPath(target);
    target.canvas?.renderAll();
  }
}

function updateClippingPath(obj: Textbox) {
  // @ts-ignore
  if (obj.__height) {
    const clipPathRect = new fabric.Rect({
      left: 0,
      top: 0,
      originX: "center",
      originY: "center",
      width: obj.width,

      // @ts-ignore
      height: obj.__height,
    });

    obj.clipPath = clipPathRect;

    obj.canvas?.renderAll();
  }
}

function resize(
  eventData: MouseEvent,
  transform: Transform,
  x: number,
  y: number
) {
  const target = transform.target;
  if (!target) return false;

  const localPoint = getLocalPoint(
    transform,
    transform.originX,
    transform.originY,
    x,
    y
  );
  const strokePaddingY =
    (target.strokeWidth || 0) /
    (target.strokeUniform && target.scaleY ? target.scaleY : 1);
  const multiplier = isTransformCentered(transform) ? 2 : 1;
  const oldHeight =
    // @ts-ignore
    target.__height !== undefined ? target.__height : target.height;
  const newHeight =
    Math.abs((localPoint.y * multiplier) / (target.scaleY ?? 1)) -
    strokePaddingY;
  const strokePaddingX =
    (target.strokeWidth || 0) /
    (target.strokeUniform && target.scaleX ? target.scaleX : 1);
  const oldWidth = target.width;
  const newWidth =
    Math.abs((localPoint.x * multiplier) / (target.scaleX ?? 1)) -
    strokePaddingX;
  // @ts-ignore
  target.__height = Math.max(newHeight, 0);
  target.set("width", Math.max(newWidth));
  target.canvas?.renderAll();
  // @ts-ignore
  target.set({ height: target.__height });

  return oldHeight !== newHeight || oldWidth !== newWidth;
}

function changeHeight(
  eventData: MouseEvent,
  transform: Transform,
  x: number,
  y: number
) {
  const target = transform.target;
  if (!target) return false;
  const localPoint = getLocalPoint(
    transform,
    transform.originX,
    transform.originY,
    x,
    y
  );
  const strokePadding =
    (target.strokeWidth || 0) /
    (target.strokeUniform && target.scaleY ? target.scaleY : 1);
  const multiplier = isTransformCentered(transform) ? 2 : 1;
  const oldHeight =
    // @ts-ignore
    target.__height !== undefined ? target.__height : target.height;
  const newHeight =
    Math.abs((localPoint.y * multiplier) / (target.scaleY ?? 1)) -
    strokePadding;
  // @ts-ignore
  target.__height = Math.max(newHeight, 0);
  target.set("height", Math.max(newHeight, 0));

  return oldHeight !== newHeight;
}

function changeWidth(
  eventData: MouseEvent,
  transform: Transform,
  x: number,
  y: number
) {
  const target = transform.target;
  if (!target) return false;
  const localPoint = getLocalPoint(
    transform,
    transform.originX,
    transform.originY,
    x,
    y
  );
  const strokePadding =
    (target.strokeWidth || 0) /
    (target.strokeUniform && target.scaleX ? target.scaleX : 1);
  const multiplier = isTransformCentered(transform) ? 2 : 1;
  const oldWidth =
    // @ts-ignore
    target.width;
  const newWidth =
    Math.abs((localPoint.x * multiplier) / (target.scaleX ?? 1)) -
    strokePadding;
  // @ts-ignore

  target.set("width", Math.max(newWidth, 0));
  // @ts-ignore
  if (target.__height) {
    // @ts-ignore
    target.set("height", target.__height);
  }
  return oldWidth !== newWidth;
}

function getLocalPoint(
  transform: Transform,
  originX: string,
  originY: string,
  x: number,
  y: number
) {
  const target = transform.target;
  if (!target || !target.canvas) return { x: 0, y: 0 };
  const control = target.controls[transform.corner];
  const zoom = target.canvas.getZoom();
  const padding = (target.padding ?? 0) / zoom;
  const localPoint = target.toLocalPoint(
    new fabric.Point(x, y),
    originX,
    originY
  );
  if (localPoint.x >= padding) {
    localPoint.x -= padding;
  }
  if (localPoint.x <= -padding) {
    localPoint.x += padding;
  }
  if (localPoint.y >= padding) {
    localPoint.y -= padding;
  }
  if (localPoint.y <= padding) {
    localPoint.y += padding;
  }
  localPoint.x -= control.offsetX;
  localPoint.y -= control.offsetY;
  return localPoint;
}

function isTransformCentered(transform: Transform) {
  // @ts-ignore
  return transform.originX === "center" && transform.originY === "center";
}

function wrapWithFixedAnchor(
  actionHandler: (
    eventData: MouseEvent,
    transform: Transform,
    x: number,
    y: number
  ) => boolean
) {
  return function (
    eventData: MouseEvent,
    transform: Transform,
    x: number,
    y: number
  ) {
    const target = transform.target;

    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(
      centerPoint,
      transform.originX,
      transform.originY
    );
    const actionPerformed = actionHandler(eventData, transform, x, y);
    target.setPositionByOrigin(
      constraint,
      transform.originX,
      transform.originY
    );
    updateClippingPath(target as Textbox);

    return actionPerformed;
  };
}
