import { useHistory, useParams } from "react-router-dom";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import useLoadData from "../../../hooks/useLoadData";
import LoadingWrapper from "../../../shared/LoadingWrapper";

import Design, { DesignField } from "../models/Design";
import StandAloneProofGenerator from "./components/StandAloneProofGenerator";
import { postAsync } from "../../../helpers/asyncFetch";

interface IProofParams {
  designId: string;
}

const Proof = () => {
  const { designId } = useParams<IProofParams>();
  const history = useHistory();
  const {
    data: fields,
    isLoading,
    error,
  } = useGetData<DesignField[]>(
    `/api/designs/${designId}/fields`,
    [],
    (data) => data
  );
  const {
    data: design,
    isLoading: designLoading,
    error: designError,
  } = useGetData<Design>(`/api/designs/${designId}`);

  async function handleSaveProofs(proofs: string[]) {
    const design = await postAsync(`/designs/${designId}/proofs`, proofs);
    if (design) {
      history.push(`/designs/${designId}`);
    }
  }

  return (
    <LoadingWrapper
      height={500}
      loading={isLoading || designLoading}
      hasError={error.hasError || designError.hasError}
      errorMessage={error.errorMessage ?? designError.errorMessage}
    >
      {design && (
        <StandAloneProofGenerator
          design={design}
          designId={designId}
          fields={fields ?? []}
          designerCallback={handleSaveProofs}
          isDesigner={
            design.printPDFSource?.toLowerCase().includes("designer") ||
            design.printPDFSource?.toLowerCase().includes("html")
          }
        />
      )}
    </LoadingWrapper>
  );
};

export default Proof;
