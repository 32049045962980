import { createTheme } from "@mui/material/styles";
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

const colors = {
  primary: {
    main: "#0873CB",
    light: "#355D96",
    dark: "#355D96",
  },
  secondary: {
    main: "#757575",
    light: "#757575",
    dark: "#757575",
  },
};
const theme = createTheme({});
export const defaultSalesforceTheme = createTheme({
  palette: {
    secondary: colors.secondary,
  },
  typography: {
    fontSize: 11,
    h4: {
      color: "rgb(68, 68, 68)",
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      color: "rgb(68, 68, 68)",
      fontSize: 12,
      fontWeight: 600,
    },
    h6: {
      color: "rgb(68,68,68)",
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      color: "rgb(24,24,24)",
      fontSize: 12,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        focusVisibleClassName: ".Mui-focusVisible",
      },
      styleOverrides: {
        root: {
          width: 48,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(24px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: theme.palette.grey[100],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },

          "& .MuiSwitch-track": {
            borderRadius: 24 / 2,
            backgroundColor: "#959595",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
            "&:before, &:after": {
              content: '""',
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: 16,
              height: 16,
              left: "7px !important",
            },
            "&:before": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
              left: 12,
            },
          },
        },
      },
    },
  },
});

export default function createSalesforceTheme(
  primary?: string,
  secondary?: string
) {
  return defaultSalesforceTheme;
}

// overrides: {
// 	MuiDialogContent: {
// 		root: { overflowY: "visible" }
// 	},
// 	MuiTable: {
// 		root: { overflowX: "auto" }
// 	}
// }
