import { useRef } from "react";

const useDoubleClick = (eventHandler: () => void) => {
  const hasClicked = useRef(false);
  const timeout = useRef<NodeJS.Timeout>();
  function onDoubleClick() {
    if (hasClicked.current) {
      eventHandler();
      hasClicked.current = false;
    } else {
      if (!timeout.current) {
        hasClicked.current = true;
        timeout.current = setTimeout(() => {
          hasClicked.current = false;

          timeout.current = undefined;
        }, 500);
      }
    }
  }

  return onDoubleClick;
};

export default useDoubleClick;
