import { IPathPoint } from "fabric";

export default function getInverseQuadraticCoords(
  point: IPathPoint,
  inversePoint: IPathPoint,
  pivotPoint: IPathPoint
) {
  const activeCenterLine1 = {
    x: pivotPoint.x,
    y: pivotPoint.y,
  };
  const activeCenterLine2 = {
    x: pivotPoint.x,
    y: pivotPoint.y - 250,
  };

  const activeLine1 = {
    x: pivotPoint.x,
    y: pivotPoint.y,
  };

  const activeLine2 = {
    x: point.x,
    y: point.y,
  };

  const angleForActiveLine = getAngleFromTwoLines(
    { point1: activeLine1, point2: activeLine2 },
    { point1: activeCenterLine1, point2: activeCenterLine2 }
  );

  const inverseCenterLine1 = {
    x: pivotPoint.x,
    y: pivotPoint.y,
  };
  const inverseCenterLine2 = {
    x: pivotPoint.x,
    y: pivotPoint.y + 250,
  };

  const inverseLine1 = {
    x: pivotPoint.x,
    y: pivotPoint.y,
  };

  const inverseLine2 = {
    x: inversePoint.x,
    y: inversePoint.y,
  };
  const angleForInverseLine = getAngleFromTwoLines(
    { point1: inverseLine1, point2: inverseLine2 },
    { point1: inverseCenterLine1, point2: inverseCenterLine2 }
  );

  return rotate(
    pivotPoint.x,
    pivotPoint.y,
    inversePoint.x,
    inversePoint.y,
    angleForActiveLine - angleForInverseLine
  );
}

interface Line {
  point1: { x: number; y: number };
  point2: { x: number; y: number };
}

function getAngleFromTwoLines(line1: Line, line2: Line) {
  const a1 = line1.point1;
  const a2 = line1.point2;
  const b1 = line2.point1;
  const b2 = line2.point2;

  const dAx = a2.x - a1.x;
  const dAy = a2.y - a1.y;
  const dBx = b2.x - b1.x;
  const dBy = b2.y - b1.y;

  const angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  return angle * (180 / Math.PI);
}

function rotate(cx: number, cy: number, x: number, y: number, angle: number) {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;
  return {
    x: nx,
    y: ny,
  };
}
