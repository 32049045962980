import { Box, Button, Typography } from "@mui/material";

import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAsync } from "../../../helpers/asyncFetch";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import { numberWithCommas } from "../../../LegacyReactSite/helpers";
import Img from "../../../shared/BasicHTML/Img";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import IEnvelope, { ReplenishmentMethod } from "../models/IEnvelope";

const EnvelopeOverview = () => {
  const { data, isLoading, error } = useGetData<IEnvelope[]>(
    "/api/envelopes",
    []
  );

  const envelopeCols: TableColumn<IEnvelope>[] = [
    {
      name: "Preview",
      maxWidth: "200px",
      cell: (envelope) => {
        return (
          <Box
            sx={{
              maxWidth: "200px",
              maxHeight: "200px",
              overflow: "hidden",
              pt: 1,
              pb: 1,
            }}
          >
            <Img src={`/uploads/${envelope.front}`} />
          </Box>
        );
      },
    },
    {
      name: "ID",
      selector: (envelope) => envelope.envelopeID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (envelope) => envelope.friendlyName,
      sortable: true,
    },
    {
      name: "Type",
      selector: (envelope) => envelope.envelopeType,

      sortable: true,
    },
    {
      name: "Inventory",
      selector: (envelope) => envelope.stockQty,
      format: (envelope) => numberWithCommas(envelope.stockQty),

      sortable: true,
    },
    {
      name: "Reorder Threshold",

      selector: (envelope) => envelope.replenishmentValue,
      format: (envelope) =>
        envelope.replenishmentMethod === ReplenishmentMethod.Percentage
          ? `${envelope.replenishmentValue}%`
          : numberWithCommas(envelope.replenishmentValue),
    },
    {
      name: "Reorder Quantity",
      selector: (envelope) => envelope.orderQty,
      format: (envelope) => numberWithCommas(envelope.orderQty),
    },
    {
      name: "Envelope Status",
      selector: (envelope) =>
        envelope.stockQty === 0
          ? "Out of Stock"
          : envelope.envelopeOrderHistory.length > 0 &&
            envelope.envelopeOrderHistory.find(
              (x) => x.orderStatus !== "Completed"
            )
          ? "Reorder in Progress"
          : "Ready",
    },
    {
      name: "",
      right: true,
      maxWidth: "110px",
      width: "110px",
      cell: (envelope) => {
        return (
          <Button
            component={Link}
            to={`/envelopes/${envelope.envelopeID}`}
            color="primary"
            variant="outlined"
            size="small"
          >
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
        Envelopes
      </Typography>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={error.hasError}
      >
        <SearchableDataTable
          customHeaderComponent={
            <Box>
              <Button
                component={Link}
                to={`/envelopes/new`}
                color="primary"
                variant="contained"
                size="small"
              >
                Create New
              </Button>
            </Box>
          }
          data={data ? data : []}
          columns={envelopeCols}
          searchableColumns={["friendlyName", "envelopeID", "envelopeType"]}
        />
      </LoadingWrapper>
    </Box>
  );
};

export default EnvelopeOverview;
