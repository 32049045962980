import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { DesignField } from "../../Designs/models/Design";
import OrderRequest, {
  FieldMapping,
  ILetterConfig,
  Recipient,
  ReturnAddress,
} from "./models/OrderRequest";
import { LetterFoldTypes } from "../../../postcard-designer/state/slices/letterSettings";

export interface OrderRequestStore {
  orderRequest: OrderRequest;
  createBaseDesignVariables: CreateBaseDesignVariables;
  updateGlobalDesignVariable: UpdateGlobalDesignVariable;
  changeExtRefNbr: ChangeExtRefNbr;
  changeMailClass: ChangeMailClass;
  updateList: UpdateList;
  createBaseListMapping: CreateBaseListMapping;
  updateListMapping: UpdateListMapping;
  clearListFile: ClearListFile;
  addNewCustomVariableMapping: AddNewCustomVariableMapping;
  updateCustomVariableMapping: UpdateCustomVariableMapping;
  removeCustomVariableMapping: RemoveCustomVariableMapping;
  updateApiKey: UpdateApiKey;
  addRecipients: AddRecipients;
  updateLetterConfig: UpdateLetterConfig;
  updateReturnAddress: (name: keyof ReturnAddress, value: string) => void;
  changeFoldType: (foldType: string) => void;
}

/**
 * @todo Option 1 Break state up into individual objects, return them in tuple?
 * ex:
 *  orderConfig = { designId, mailClass, extRefNbr, globalDesignVar }
 *  list = {listFileId, listCount, listHeaders, listName}
 *  listMapping = listMapping
 *  customVariableMapping = customVariableMapping
 *  appId = appId
 *  orderRequestReducer = all the methods
 *  [orderConfig, list, listMapping, customVariableMapping, appId, orderRequestReducer] = useOrderRequestStore();
 * @todo Option 2 Break state up into individual Contexts with same pattern as above, but each item is it's own
 * @todo Option 3 useReducer: https://dmitripavlutin.com/react-usereducer/
 * https://hswolff.com/blog/how-to-usecontext-with-usereducer/
 * https://reactjs.org/docs/hooks-reference.html#usereducer
 * Leaning toward Option 3 with two Providers for state and dispatch
 */

const useOrderRequestStore = (designId: number): OrderRequestStore => {
  const [orderRequest, setOrderRequest] = useState<OrderRequest>({
    extRefNbr: "",
    orderconfig: {
      designID: designId,
      mailClass: "",
      globalDesignVariables: [],
    },
    listFileId: null,
    listMapping: [],
    customVariableMapping: [],
    apiKey: "",
    recipients: [],
    foldType: LetterFoldTypes.TRIFOLD,
  });

  /**
   * Updates the extRefNbr
   */
  const changeExtRefNbr: ChangeExtRefNbr = (extRefNbr) => {
    setOrderRequest({ ...orderRequest, extRefNbr });
  };

  const changeFoldType = (foldType: string) => {
    setOrderRequest({ ...orderRequest, foldType });
  };

  const updateReturnAddress = (name: keyof ReturnAddress, value: string) => {
    let returnAddress = orderRequest.returnAddress;
    if (!returnAddress)
      returnAddress = {
        company: "",
        firstName: "",
        lastName: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
      };
    returnAddress[name] = value;
    setOrderRequest({ ...orderRequest, returnAddress });
  };

  const changeMailClass: ChangeMailClass = (mailClass) => {
    setOrderRequest({
      ...orderRequest,
      orderconfig: { ...orderRequest.orderconfig, mailClass },
    });
  };

  const createBaseDesignVariables: CreateBaseDesignVariables = (
    designFields,
    mailClass
  ) => {
    const mapFieldsToKeyValueFormat = designFields.map((field) => ({
      key: field.fieldKey,
      value: "",
    }));
    setOrderRequest({
      ...orderRequest,
      orderconfig: {
        ...orderRequest.orderconfig,
        globalDesignVariables: mapFieldsToKeyValueFormat,
        mailClass: mailClass,
      },
    });
  };
  const updateApiKey: UpdateApiKey = (apiKey) => {
    setOrderRequest({ ...orderRequest, apiKey });
  };
  const updateGlobalDesignVariable: UpdateGlobalDesignVariable = (variable) => {
    const globalDesignVariables =
      orderRequest.orderconfig.globalDesignVariables;
    globalDesignVariables.forEach((field) => {
      if (field.key === variable.key) {
        field.value = variable.value;
      }
    });
    setOrderRequest({
      ...orderRequest,
      orderconfig: { ...orderRequest.orderconfig, globalDesignVariables },
    });
  };

  const createBaseListMapping: CreateBaseListMapping = (designFields = []) => {
    const fieldNames = [
      "firstName",
      "lastName",
      "company",
      "address",
      "address2",
      "city",
      "state",
      "zipCode",
      "extRefNbr",
    ];
    const customVars: string[] = [];
    let baseCustomMap: FieldMapping[] = [];
    if (designFields.length > 0) {
      designFields.forEach((field) => customVars.push(field.fieldKey));
    }
    if (orderRequest.listAttributes) {
      const baseMap = fieldNames.map((field) => {
        const headers = orderRequest.listAttributes?.listHeaders || [];

        const index = headers.findIndex(
          (header) => header.toLowerCase() === field.toLowerCase()
        );

        return index !== -1
          ? { fieldName: field, userListFieldName: headers[index] }
          : { fieldName: field, userListFieldName: "" };
      });
      if (customVars.length > 0) {
        baseCustomMap = customVars.map((field) => {
          const headers = orderRequest.listAttributes?.listHeaders || [];

          const index = headers.findIndex(
            (header) => header.toLowerCase() === field.toLowerCase()
          );

          return index !== -1
            ? { fieldName: field, userListFieldName: headers[index] }
            : { fieldName: field, userListFieldName: "" };
        });
      }
      setOrderRequest({
        ...orderRequest,
        listMapping: baseMap,
        customVariableMapping: baseCustomMap,
      });
    }
  };

  const updateListMapping: UpdateListMapping = (
    fieldName: string,
    userListFieldName: string
  ) => {
    const listMapping = orderRequest.listMapping;
    let found = false;
    for (let i = 0; i < listMapping.length; i++) {
      if (listMapping[i].fieldName === fieldName) {
        listMapping[i].userListFieldName = userListFieldName;
        found = true;
        break;
      }
    }
    if (!found) {
      listMapping.push({
        userListFieldName: userListFieldName,
        fieldName: fieldName,
      });
    }
    setOrderRequest({ ...orderRequest, listMapping });
  };

  const clearListFile: ClearListFile = () => {
    setOrderRequest({
      ...orderRequest,
      listFileId: null,
      listAttributes: undefined,
      listMapping: [],
    });
  };

  const updateList: UpdateList = (
    listFileId,
    listCount,
    listName,
    listHeaders,
    records
  ) => {
    setOrderRequest({
      ...orderRequest,
      listFileId: listFileId,
      listAttributes: {
        listCount,
        listName,
        listHeaders,
        records,
      },
    });
  };

  const addNewCustomVariableMapping: AddNewCustomVariableMapping = () => {
    const customVariables = orderRequest.customVariableMapping;
    customVariables.push({ fieldName: "", userListFieldName: "" });
    setOrderRequest({
      ...orderRequest,
      customVariableMapping: [...customVariables],
    });
  };

  const updateCustomVariableMapping: UpdateCustomVariableMapping = (
    index,
    fieldName = "",
    userListFieldName = ""
  ) => {
    const customVariables = orderRequest.customVariableMapping;
    customVariables[index] = {
      fieldName: fieldName ? fieldName : customVariables[index].fieldName,
      userListFieldName: userListFieldName
        ? userListFieldName
        : customVariables[index].userListFieldName,
    };
    setOrderRequest({
      ...orderRequest,
      customVariableMapping: customVariables,
    });
  };

  const removeCustomVariableMapping: RemoveCustomVariableMapping = (
    index: number
  ) => {
    const customVariables = orderRequest.customVariableMapping;
    customVariables.splice(index, 1);
    setOrderRequest({
      ...orderRequest,
      customVariableMapping: customVariables,
    });
  };

  const addRecipients: AddRecipients = (recipients) => {
    setOrderRequest({ ...orderRequest, recipients });
  };

  function getBoolean(val: string) {
    if (val === "true") return true;
    return false;
  }

  const updateLetterConfig: UpdateLetterConfig = (e) => {
    const config: ILetterConfig = orderRequest.orderconfig.letterConfig
      ? { ...orderRequest.orderconfig.letterConfig }
      : {};
    const name = e.target.name as keyof ILetterConfig;
    if (name === "color") {
      config.color = getBoolean(e.target.value);
      if (getBoolean(e.target.value) === false) {
        config.envelopeFontColor = undefined;
      }
    } else if (name === "printOnBothSides") {
      config.printOnBothSides = getBoolean(e.target.value);
    } else if (name === "insertAddressingPage") {
      config.insertAddressingPage = getBoolean(e.target.value);
    } else if (name === "liveStamping") {
      config.liveStamping = getBoolean(e.target.value);
    } else if (name === "envelopeID") {
      config.envelopeID = Number(e.target.value);
    } else {
      config[name] = e.target.value;
    }
    if (name === "envelopeType" && e.target.value !== "Regular") {
      config.liveStamping = false;
    }
    if (name === "envelopeType" && e.target.value !== "Custom") {
      config.envelopeID = undefined;
    }
    if (name === "envelopeType" && e.target.value === "Regular") {
      config.envelopeFontColor = undefined;
    }
    setOrderRequest({
      ...orderRequest,
      orderconfig: { ...orderRequest.orderconfig, letterConfig: config },
    });
  };

  const orderRequestReturnObject = {
    changeExtRefNbr,
    changeMailClass,
    createBaseDesignVariables,
    updateGlobalDesignVariable,
    createBaseListMapping,
    updateListMapping,
    updateList,
    orderRequest: orderRequest,
    clearListFile,
    addNewCustomVariableMapping,
    updateCustomVariableMapping,
    removeCustomVariableMapping,
    updateApiKey,
    addRecipients,
    updateLetterConfig,
    updateReturnAddress,
    changeFoldType,
  };

  return orderRequestReturnObject;
};

interface CreateBaseDesignVariables {
  /**
   * Creates the base design variables from the provided array of designFields
   */
  (designFields: DesignField[], mailClass: string): void;
}

interface CreateBaseListMapping {
  /**
   * Creates the base list mapping and checks if any matches already exist
   */
  (designFields?: DesignField[]): void;
}

interface UpdateGlobalDesignVariable {
  (variable: { key: string; value: string }): void;
}

interface ChangeExtRefNbr {
  (extRefNbr: string): void;
}

interface AddNewCustomVariableMapping {
  (): void;
}

interface UpdateCustomVariableMapping {
  (
    index: number,
    variableName: string | undefined,
    listColumn: string | undefined
  ): void;
}

interface ChangeMailClass {
  (mailClass: string): void;
}

interface UpdateListMapping {
  (fieldName: string, userListFieldName: string): void;
}

interface ClearListFile {
  (): void;
}

interface UpdateList {
  (
    listFileId: number,
    listCount: number,
    listName: string,
    listFileHeaders: string[],
    records: any[]
  ): void;
}

interface RemoveCustomVariableMapping {
  (index: number): void;
}

interface UpdateApiKey {
  (apiKey: string): void;
}

interface AddRecipients {
  (recipients: Recipient[]): void;
}

interface UpdateLetterConfig {
  (e: SelectChangeEvent): void;
}

export default useOrderRequestStore;
