import { Box, FormControlLabel, Switch, SwitchProps } from "@mui/material";

interface ILabelSwitchProps extends SwitchProps {
  label: string;
}

const LabelSwitch = ({ label, ...rest }: ILabelSwitchProps) => {
  return (
    <FormControlLabel
      labelPlacement="bottom"
      sx={{ ml: 0, mr: 0, alignItems: "flex-start" }}
      label={<Box sx={{ fontSize: "10px", mt: 0.5 }}>{label}</Box>}
      value={rest.value}
      control={<Switch {...rest} />}
    />
  );
};

export default LabelSwitch;
