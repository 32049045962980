import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import { postAsync, putAsync } from "../../helpers/asyncFetch";
import useGetData from "../../hooks/dataFetchers/useGetData";
import Img from "../../shared/BasicHTML/Img";

import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import LoadingButton from "../../shared/LoadingButton";
import LoadingWrapper from "../../shared/LoadingWrapper";
import SearchableDataTable from "../../shared/SearchableDataTable";
import DesignDelete from "./components/DesignDelete";
import DesignFriendlyName from "./components/DesignFriendlyName";
import Design from "./models/Design";

interface IDesignsProps {
  type: "postcard" | "letter" | "brochure";
}

const Designs = ({ type }: IDesignsProps) => {
  const { data, isLoading, error, getData, updateData } = useGetData<Design[]>(
    `/api/designs?type=${type}`,
    [type]
  );
  const history = useHistory();
  const [copyDesign, setCopyDesign] = useState<Design>();
  const [copyLoading, setCopyLoading] = useState(false);

  async function handleCopyDesign() {
    if (!copyDesign) return;
    setCopyLoading(true);
    const reqObj = {
      size: copyDesign.size,
      duplicateID: copyDesign.designID,
      name: copyDesign.friendlyName,
      isTemplate: false,
    };
    const res: { design: Design } | null | undefined = await postAsync(
      `/designer`,
      reqObj
    );
    if (res && res.design && res.design.designID) {
      history.push(`/designs/designer/${res.design.designID}/edit`);
    }
  }

  async function handleRenameDesign(designID: number, newName: string) {
    if (!data) return;
    await putAsync(`/designs/${designID}/name`, {
      name: newName,
    });
    updateData(
      data.map((x) =>
        x.designID === designID ? { ...x, friendlyName: newName } : x
      )
    );
  }

  const getDesignType = (design: Design): string => {
    if (design.isUpload) {
      return "Upload Your Own";
    } else {
      switch (design.printPDFSource) {
        case "uProduce":
          return "Legacy";
        case "LAS":
          return "Letter";
        case "HTML-PC":
        case "HTML-LETTER":
        case "HTML-BRO":
          return "HTML";
        case "DESIGNER-PC":
        case "DESIGNER-LETTER":
        case "DESIGNER-BRO":
          return "Custom";
        default:
          return "Other";
      }
    }
  };

  const designCols: TableColumn<Design>[] = [
    {
      name: "Proof",
      maxWidth: "200px",
      cell: (design) => {
        return (
          <Box
            sx={{
              maxWidth: "200px",
              width: "100%",
              maxHeight: "200px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              pt: 1,
              pb: 1,
            }}
          >
            <Img
              src={design.proofFront ? design.proofFront : design.proofPDF}
            />
          </Box>
        );
      },
    },
    {
      name: "ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (design) => design.friendlyName,
      sortable: true,
    },
    {
      name: "Design Type",
      cell: (design) => getDesignType(design),
      selector: (design) => getDesignType(design),
      sortable: true,
    },
    {
      name: "Size",
      selector: (design) => design.size,
      sortable: true,
    },
    {
      name: "",
      right: true,
      maxWidth: "105px",
      width: "105px",
      cell: (design) => {
        return (
          <React.Fragment>
            <Button
              component={Link}
              to={`/designs/${design.designID}`}
              color="primary"
              variant="outlined"
              size="small"
            >
              Details
            </Button>
          </React.Fragment>
        );
      },
    },
    {
      name: "",
      right: true,
      maxWidth: "96px",
      width: "96px",
      cell: (design) => {
        return design.printPDFSource?.toLowerCase().includes("designer") ||
          (design.printPDFSource?.toLowerCase().includes("html") &&
            !design.isUpload) ? (
          <Button
            component={Link}
            to={
              design.printPDFSource?.toLowerCase().includes("designer")
                ? `/designs/designer/${design.designID}/edit`
                : `/designs/html/${design.designID}/edit`
            }
            color="primary"
            variant="outlined"
            size="small"
            sx={{ marginRight: 2 }}
          >
            Edit
          </Button>
        ) : (
          <Tooltip title="This design can not edited because it is not custom">
            <div>
              <Button
                disabled
                variant="outlined"
                size="small"
                sx={{ marginRight: 2 }}
              >
                Edit
              </Button>
            </div>
          </Tooltip>
        );
      },
    },
    {
      name: "",
      right: true,
      maxWidth: "96px",
      width: "96px",
      cell: (design) => {
        return (
          <DesignFriendlyName
            designID={design.designID}
            friendlyName={design.friendlyName}
            callback={handleRenameDesign}
          />
        );
      },
    },
    {
      name: "",
      right: true,
      width: "190px",
      cell: (design) => {
        return (
          <React.Fragment>
            {design.printPDFSource?.toLowerCase().includes("designer") ? (
              <IconButton
                aria-label="Copy Design"
                title="Copy Design"
                size="small"
                color="primary"
                onClick={() =>
                  setCopyDesign({
                    ...design,
                    friendlyName: `${design.friendlyName} - Copy`,
                  })
                }
              >
                <ContentCopyIcon />
              </IconButton>
            ) : (
              <Tooltip title="This design can not be cloned">
                <div>
                  <IconButton disabled color="primary" size="small">
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {design.safeToDelete ? (
              <DesignDelete
                designId={design.designID}
                callback={() => getData(true)}
              />
            ) : (
              <Tooltip title="This design has already been ordered">
                <div>
                  <IconButton disabled color="primary" size="small">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
        {`${type.split("")[0].toUpperCase()}${type.slice(1)}s`}
      </Typography>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={error.hasError}
      >
        <SearchableDataTable
          data={data ? data : []}
          columns={designCols}
          searchableColumns={["friendlyName", "designID", "size"]}
        />
      </LoadingWrapper>
      <Dialog
        open={Boolean(copyDesign)}
        onClose={() => setCopyDesign(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Copy Design</DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <InputLabel>Design Name</InputLabel>
              <TextField
                fullWidth
                name="designName"
                value={copyDesign?.friendlyName ?? ""}
                disabled={copyLoading}
                onChange={(e) =>
                  copyDesign
                    ? setCopyDesign({
                        ...copyDesign,
                        friendlyName: e.target.value,
                      })
                    : null
                }
              />
            </Box>
            {copyDesign &&
              copyDesign.printPDFSource?.toLowerCase().includes("pc") && (
                <Box>
                  <InputLabel>Size</InputLabel>
                  <Select
                    value={copyDesign?.size ?? "46"}
                    onChange={(e) =>
                      copyDesign
                        ? setCopyDesign({ ...copyDesign, size: e.target.value })
                        : null
                    }
                    fullWidth
                    disabled={copyLoading}
                  >
                    <MenuItem value="46">4.25&quot; x 6&quot;</MenuItem>
                    <MenuItem value="68">6&quot; x 8.5&quot;</MenuItem>
                    <MenuItem value="611">6&quot; x 11&quot;</MenuItem>
                  </Select>
                </Box>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={copyLoading}
            onClick={handleCopyDesign}
            disabled={!copyDesign || !copyDesign.friendlyName.trim()}
          >
            Create Copy
          </LoadingButton>
          <Button onClick={() => setCopyDesign(undefined)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Designs;
