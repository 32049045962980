const formattingHelpers = {
  formatThousands: formatThousands,
  truncateString: truncateString,
};

function formatThousands(x: string | number | null): string {
  if (x === null || x === "" || x === undefined) return "";
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch {
    return "";
  }
}

function truncateString(str: string, numChar: number): string {
  if (str === null) return "";
  if (str.length <= numChar) return str;
  return str.substring(0, numChar);
}

export function trimPhoneNumber(number: string): string {
  if (number == null || number == "" || typeof number !== "string") {
    return "";
  }
  if (!number.match(/[a-z]/i)) {
    if (number.length > 10) {
      return number
        .slice(number.length % 10)
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return number;
}

export default formattingHelpers;
