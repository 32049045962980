import { Box, BoxProps } from "@mui/material";

interface SectionProps extends BoxProps {
  spacing?: number;
}

const Section: React.FC<SectionProps> = ({
  spacing = 3,
  children,
  ...props
}) => {
  return (
    <Box {...props} sx={{ ...props.sx, mb: spacing }}>
      {children}
    </Box>
  );
};

export default Section;
