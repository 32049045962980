import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import IDesign from "../../../../data/models/IDesign";
import IHtmlVersion from "../../../../data/models/IHtmlVersion";
import { ExpandMore } from "@mui/icons-material";
import { fetchClient } from "../../../../helpers/fetchClient";
import moment from "moment";
import LoadingButton from "../../../../shared/LoadingButton";
import HtmlVersionBody from "./HtmlVersionBody";
import { useHistory } from "react-router-dom";
import Design from "../../../Designs/models/Design";
import useGetData from "../../../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../../../shared/LoadingWrapper";

interface IHtmlVersionHistoryProps {
  designID: number;
  updateDesign: (newVersion: IHtmlVersion) => void;
  currentVersionId: string;
}

const bodyStyles = {
  fontSize: "12px",
};

const HtmlVersionHistory = ({
  designID,
  updateDesign,
  currentVersionId,
}: IHtmlVersionHistoryProps) => {
  const { data, isLoading } = useGetData<Design>(
    `/api/html/${designID}/version-history`
  );
  const versions = data?.htmlVersionHistory ?? [];
  const [newVersion, setNewVersion] = useState<IHtmlVersion | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const history = useHistory();
  function changeCurrentVersion() {
    if (newVersion) {
      setIsUpdating(true);
      fetchClient.post({
        path: `/html/${newVersion.designID}/${newVersion.htmlVersionID}/set-current-version`,
        onSuccess: (data: Design) => {
          updateDesign(newVersion);
          setIsUpdating(false);
          setNewVersion(null);
        },
      });
    }
  }

  function handleUpdateVersionClick(
    e: React.MouseEvent,
    version: IHtmlVersion
  ) {
    e.stopPropagation();
    setNewVersion(version);
  }

  return (
    <Box>
      <LoadingWrapper loading={isLoading} height={500}>
        {versions.map((version) => (
          <Accordion key={version.htmlVersionID}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs="auto" md={3} lg={2}>
                  <Box>Name</Box>
                  <Box sx={bodyStyles}>{version.versionName}</Box>
                </Grid>
                <Grid item xs="auto" md={3} lg={2}>
                  <Box>Created</Box>
                  <Box sx={bodyStyles}>
                    {moment(version.createdDateTime).format(
                      "MM/DD/YYYY hh:mm a"
                    )}
                  </Box>
                </Grid>
                <Grid item xs="auto" md={3} lg={2}>
                  <Box>Published</Box>
                  <Box sx={bodyStyles}>
                    {Boolean(version.publishedDateTime)
                      ? moment(version.publishedDateTime).format(
                          "MM/DD/YYYY hh:mm a"
                        )
                      : "Unpublished"}
                  </Box>
                </Grid>
                <Grid item xs="auto" sx={{ ml: "auto", mr: "10px" }}>
                  {version.htmlVersionID === currentVersionId && (
                    <Box sx={{ fontWeight: 600 }}>Active Version</Box>
                  )}
                  {version.htmlVersionID !== currentVersionId &&
                    !version.publishedDateTime && (
                      <Button
                        variant="outlined"
                        onClick={() =>
                          history.push(`/designs/html/${version.designID}/edit`)
                        }
                        size="small"
                      >
                        Edit
                      </Button>
                    )}
                  {Boolean(version.publishedDateTime) &&
                    currentVersionId !== version.htmlVersionID && (
                      <Box>
                        <Button
                          size="small"
                          onClick={(e) => handleUpdateVersionClick(e, version)}
                          color="primary"
                          variant="outlined"
                        >
                          Set As Current Version
                        </Button>
                      </Box>
                    )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <HtmlVersionBody
                version={version}
                currentVersionId={currentVersionId}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </LoadingWrapper>
      <Dialog open={Boolean(newVersion)} onClose={() => setNewVersion(null)}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          {newVersion?.versionName} (published{" "}
          {moment(newVersion?.publishedDateTime).format("MM/DD/YYYY hh:mm a")})
          will be set as the new default version.
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isUpdating}
            color="primary"
            variant="contained"
            onClick={changeCurrentVersion}
          >
            Confirm Version Change
          </LoadingButton>
          <Button color="primary" onClick={() => setNewVersion(null)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HtmlVersionHistory;
