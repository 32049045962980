import { Paper } from "@mui/material";
import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  CSSProperties,
} from "react";
import { Style } from "util";
import Buttons from "./Buttons";

interface SlidesProps {
  slides: string[];
  current: number;
  next: () => void;
  prev: () => void;
  speed: number;
  showCount: boolean;
}

const Slides: React.FC<SlidesProps> = ({
  slides,
  current,
  next,
  prev,
  speed,
  showCount,
}) => {
  /**
   * State Objects and Refs
   */
  const slideRefs = useRef(slides.map(() => createRef<HTMLImageElement>()));
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const [carouselInner, setCarouselInner] = useState<CSSProperties>({
    width: "auto",
    transition: `ease-in-out ${speed}ms all`,
    overflowX: "visible",
  });

  const [imageWidth, setImageWidth] = useState<CSSProperties>({});

  const [carouselContainer, setCarouselContainer] = useState<CSSProperties>({
    margin: "auto",
    overflow: "hidden",
  });

  const containerWidth = useRef<HTMLDivElement>(null);

  /**
   * Component Methods
   */

  function calcWidths(): void {
    if (containerWidth.current) {
      const width = containerWidth.current.getBoundingClientRect().width;
      setImageWidth({
        maxWidth: width,
        width: "100%",
      });
      let carouselInnerWidth = 0;
      slideRefs.current.forEach(() => (carouselInnerWidth += width));
      setCarouselInner({
        ...carouselInner,
        width: carouselInnerWidth,
        transform: `translateX(-${current * width}px)`,
      });
    }
  }
  function initialize(): void {
    window.onresize = calcWidths;
    calcWidths();
    //setTimeout(calcWidths, 1);
    //setTimeout(() => window.dispatchEvent(new Event("resize")), 1);
  }

  function checkIfAllImagesLoaded() {
    if (imagesLoaded === slides.length) {
      calcWidths();
    }
  }

  function handleImageLoad() {
    setImagesLoaded(imagesLoaded + 1);
  }

  /**
   * Component Effects
   */
  useEffect(initialize, []);

  useEffect(checkIfAllImagesLoaded, [imagesLoaded]);

  useEffect(calcWidths, [slideRefs, current]);

  /**
   * Render
   */

  return (
    <div className="carousel--container" style={carouselContainer}>
      <Paper
        style={{ overflow: "hidden", width: "100%" }}
        elevation={3}
        ref={containerWidth}
      >
        <div className="carousel--inner" style={carouselInner}>
          {slides.map((slide, index) => (
            <img
              key={slide}
              ref={slideRefs.current[index]}
              src={slide}
              onLoad={handleImageLoad}
              className={imagesLoaded >= index + 1 ? "" : "d-none"}
              style={imageWidth}
            />
          ))}
        </div>
      </Paper>
      <Buttons
        next={next}
        prev={prev}
        showCount={showCount}
        count={slides.length}
        current={current + 1}
      />
    </div>
  );
};

export default Slides;
