import * as Yup from "yup";
import valid from "card-validator";
import { INewPaymentMethod } from "../../routes/Account/models/AccountModel";
import { Assign, ObjectShape } from "yup/lib/object";

export interface NewCardValidationErrors {
  cardNumber?: string;
  cvc?: string;
  expiration?: string;
}

export function creditCardValidation(
  creditCard: INewPaymentMethod
): NewCardValidationErrors {
  const cardType =
    creditCard.cardNumber.startsWith("34") ||
    creditCard.cardNumber.startsWith("37")
      ? "amex"
      : "cc";
  const validationErrors: NewCardValidationErrors = {};
  if (!valid.number(creditCard.cardNumber).isValid) {
    validationErrors.cardNumber = "Credit card number is invalid";
  }
  if (!valid.expirationDate(creditCard.expiration).isValid) {
    validationErrors.expiration = "Enter a valid expiration";
  }
  if (cardType === "amex" && !valid.cvv(creditCard.cvc, 4).isValid) {
    validationErrors.cvc = "Enter a valid cvc";
  }
  if (cardType !== "amex" && !valid.cvv(creditCard.cvc).isValid) {
    validationErrors.cvc = "Enter a valid cvc";
  }

  return validationErrors;
}
