import { Path } from "fabric";
import { Object as IObject } from "fabric/fabric-impl";
import { ICircleOptions, IPathOptions } from "fabric/fabric-impl";

export type PenToolState = "active" | "edit" | "inactive";

export type PenToolMode = "draw" | "grab" | "add" | "remove";

export interface IPenTool {
  editModeOn: (path: Path) => void;
  editModeOff: () => void;
  discardDrawing: () => void;
  isEditing: boolean;
  targetPath?: IObject;
  penToolActive: boolean;
}

export const controlStroke = "rgb(178,204,255)";
export const controlStrokeWidth = 5;
export const controlRadius = 8;
export const controlSize = controlRadius * 2;

export const lineStroke = controlStroke;
export const lineStrokeWidth = controlStrokeWidth;

export const controlCircleOptions: ICircleOptions = {
  fill: "#FFFFFF",
  stroke: controlStroke,
  strokeWidth: controlStrokeWidth,
  radius: controlRadius,
  originX: "center",
  originY: "center",
  width: controlSize,
  height: controlSize,
  name: "PathControl-point",
  selectable: false,
  objectCaching: false,
  strokeUniform: true,
};

export const pathOutlineOptions: IPathOptions = {
  stroke: lineStroke,
  strokeWidth: lineStrokeWidth,
  selectable: false,
  objectCaching: false,
  fill: "",
  strokeUniform: true,
  name: "GhostLine",
};

export const controlLineOptions: IPathOptions = {
  ...pathOutlineOptions,
  name: "PathControl-line",
};
