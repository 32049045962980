import SessionInfo from "../../../data/models/SessionInfo";
import isAdminUser from "../../../helpers/isAdminUser";
import LocalStorage, { setTokenExpires } from "../../../helpers/LocalStorage";
import nFetchClient from "../../../helpers/nFetchClient";
import LoginResponse from "../models/LoginResponse";

export default function handleLogin(data: LoginResponse, email: string) {
  LocalStorage.setEmail(email);
  // set all the local storage items

  LocalStorage.setToken(data.token);
  setTokenExpires(data.expires);
  LocalStorage.setCompanies(data.companies);
  LocalStorage.setCurrentCompany(data.currentCompanyId);
  LocalStorage.setUserCategory(data.userCategory);
}

export async function getSessionInfo() {
  return nFetchClient<SessionInfo, undefined>(`/api/account/session`, "get");
}
