import { Box, Button, Paper } from "@mui/material";
import { useState } from "react";
import { fetchClient } from "../../../../helpers/fetchClient";
import Img from "../../../../shared/BasicHTML/Img";
import DropzoneUploader from "../../../../shared/DropzoneUploader";
import UploadImageResponse from "../models/UploadImageResponse";

interface ImageUploadProps {
  pdfOnly?: boolean;
  useIframePreview?: boolean;
  helpText?: string;
  onUploadSuccess: (imagePath: string) => void;
  defaultImage?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  pdfOnly = false,
  useIframePreview = false,
  helpText,
  onUploadSuccess,
  defaultImage = "",
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState(defaultImage);
  function handleUpload(files: File[]) {
    setIsUploading(true);
    const request = fetchClient.uploadFile({
      onSuccess: handleUploadSuccess,
      fileType: "CUSTOM_POSTCARD",
      files,
      onError: handleUploadError,
    });
  }

  function handleUploadError() {}

  function handleUploadSuccess(res: UploadImageResponse) {
    const img = res.data[0];
    setImage(img);
    setIsUploading(false);
    onUploadSuccess(img);
  }

  function handleRejection(files: File[]) {}

  function handleImageClearing() {
    setImage("");
    onUploadSuccess("");
  }

  const fileTypes = pdfOnly
    ? ["application/pdf"]
    : ["image/png", "image/jpeg", "image/bmp", "application/pdf"];

  if (image)
    return (
      <Box>
        <Paper>
          {useIframePreview ? (
            <iframe
              src={`/uploads/${image}#toolbar=0&navpanes=0`}
              title="Preview"
              style={{ width: "100%", height: "340px" }}
            />
          ) : (
            <Img src={`/uploads/${image}`} showLoadingScreen />
          )}
        </Paper>
        <Box sx={{ py: 2 }}>
          <Button color="primary" onClick={handleImageClearing}>
            Change Image
          </Button>
        </Box>
      </Box>
    );
  return (
    <Box sx={{ py: 2 }}>
      <DropzoneUploader
        style={{ height: "340px" }}
        onUpload={handleUpload}
        onRejections={handleRejection}
        fileTypes={fileTypes}
        disabled={isUploading}
        additionalHelpText={helpText || ""}
        allowedExtensions={
          pdfOnly ? [".pdf"] : [".png", ".jpg", ".jpeg", ".bmp", ".pdf"]
        }
      />
    </Box>
  );
};

export default ImageUpload;
