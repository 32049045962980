import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { fetchClient } from "../../../../../helpers/fetchClient";
import useRequestCleanup from "../../../../../hooks/useRequestCleanup";
import Img from "../../../../../shared/BasicHTML/Img";
import NewCarousel from "../../../../../shared/Carousel/NewCarousel";
import LoadingWrapper from "../../../../../shared/LoadingWrapper";
import Design from "../../../../Designs/models/Design";
import { OrderRequestContext } from "../../PlaceAnOrder";

interface ProofGeneratorProps {
  design: Design;
  generateOnLoad?: boolean;
  onProofLoaded?: (success: boolean) => any;
}

const ProofGenerator: React.FC<ProofGeneratorProps> = ({
  design,
  generateOnLoad = false,
  onProofLoaded,
}) => {
  /**
   * State Objects and Refs
   */
  const isOffsetLetter = design.printPDFSource == "LAS";
  const [currentProof, setCurrentProofs] = useState<string[]>([
    design.proofFront,
    design.proofBack,
  ]);
  const [proofError, setProofError] = useState<boolean>(false);
  const [proofLoading, setProofLoading] = useState(
    generateOnLoad ? true : false,
  );

  const [proofPage, setProofPage] = useState(0);

  const [proofsOpen, setProofsOpen] = useState(false);

  const { orderRequest } = useContext(OrderRequestContext);

  const requestCleanup = useRequestCleanup();

  /**
   * Component Methods
   */

  function generateProof() {
    setProofLoading(true);
    setProofError(false);
    // send data to portal API to send to real API
    const { recipientDesignVariables, ...rec } = orderRequest.recipients[0];

    const request = fetchClient.post({
      path: `/designs/${design.designID}/generate-proof`,
      data: {
        recipient: {
          ...rec,
          variables: recipientDesignVariables,
        },
        returnAddress: orderRequest.returnAddress,
        designID: design.designID,
        color: orderRequest.orderconfig.letterConfig?.color ?? true,
        htmlVersionID: design.htmlVersionID,
        envelopeConfig: {
          type: design.printPDFSource?.includes("BRO")
            ? orderRequest.foldType
            : orderRequest.orderconfig.letterConfig?.envelopeType ??
              "doubleWindow",
          font: orderRequest.orderconfig.letterConfig?.envelopeFont,
          fontColor: orderRequest.orderconfig.letterConfig?.envelopeFontColor,
        },
      },
      onError: onProofFailure,
      onSuccess: onProofGenerated,
    });
    requestCleanup.addRequest(request);
  }
  function onProofGenerated(proof: {
    front: string;
    back: string;
    pdf: string;
  }) {
    if (onProofLoaded) onProofLoaded(true);
    const proofs: string[] = [];
    if (proof.front) proofs.push(proof.front);
    if (proof.back) proofs.push(proof.back);
    if (proof.pdf) proofs.push(proof.pdf);
    setCurrentProofs(proofs);
    setProofLoading(false);
  }

  function onProofFailure(message: string) {
    if (onProofLoaded) onProofLoaded(false);

    setProofError(true);
    setProofLoading(false);
  }

  function handleProofsOpenClose() {
    setProofsOpen(!proofsOpen);
  }

  /**
   * Component Effects
   */
  // cleanup the requests before component unmounts
  useEffect(() => {
    if (generateOnLoad && !isOffsetLetter) {
      generateProof();
    }

    requestCleanup.abortAllRequests;
  }, []);

  /**
   * Render
   */

  return (
    <div className="proofPreview">
      <LoadingWrapper
        height={325}
        message={<h5 className="text-center">Generating Your Proof</h5>}
        loading={proofLoading}
        container={({ children }) => (
          <Paper elevation={3} sx={{ width: "100%" }}>
            {children}
          </Paper>
        )}
      >
        {currentProof && currentProof.length > 1 && (
          <Box onClick={handleProofsOpenClose} style={{ cursor: "zoom-in" }}>
            <NewCarousel
              images={currentProof}
              showCount
              slide={proofPage}
              setSlide={(slide: number) => setProofPage(slide)}
            />
            <Typography variant="body1" marginTop={2} textAlign="center">
              Click On The Image To Enlarge
            </Typography>
          </Box>
        )}
        {currentProof && currentProof.length === 1 && (
          <Box
            onClick={handleProofsOpenClose}
            sx={{ cursor: "zoom-in", width: "100%" }}
          >
            <Img src={currentProof[0]} canZoom log />
          </Box>
        )}
      </LoadingWrapper>
      {!generateOnLoad && (
        <div className="mt-3">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={generateProof}
            disabled={proofLoading}
          >
            Generate Proof
          </Button>
        </div>
      )}
      <Dialog
        open={proofsOpen}
        onClose={handleProofsOpenClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {currentProof.length > 1 && (
            <NewCarousel
              images={currentProof}
              showCount
              slide={proofPage}
              setSlide={(slide: number) => setProofPage(slide)}
            />
          )}
          {currentProof.length === 1 && (
            <Box sx={{ width: "100%" }}>
              <Img src={currentProof[0]} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProofsOpenClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProofGenerator;
