import IPosition from "../postcard-designer/features/Canvas/models/IPosition";

export const getBleedElement = (canvas: fabric.Canvas) => {
  return canvas._objects.find((x) => x.name === "bleed");
};

export const outsideBleed = (bleed: fabric.Object, pos: IPosition) => {
  if (!bleed.width || !bleed.height || !bleed.left || !bleed.top) {
    return [false, false];
  }
  const xOutside = pos.x < bleed.left || pos.x > bleed.left + bleed.width;
  const yOutside = pos.y < bleed.top || pos.y > bleed.top + bleed.height;
  return [xOutside, yOutside];
};

export const getClampedPosition = (bleed: fabric.Object, pos: IPosition) => {
  if (!bleed.width || !bleed.height || !bleed.left || !bleed.top) {
    return pos;
  }
  return {
    x: Math.min(Math.max(pos.x, bleed.left), bleed.left + bleed.width),
    y: Math.min(Math.max(pos.y, bleed.top), bleed.top + bleed.height),
  };
};
