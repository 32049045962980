import moment from "moment";
import { TableColumn } from "react-data-table-component";
import { numberWithCommas } from "../../../../LegacyReactSite/helpers";
import SearchableDataTable from "../../../../shared/SearchableDataTable";
import TabPanel from "../../../../shared/TabPanel";
import IEnvelopeOrder from "../../models/IEnvelopeOrder";
import IEnvelopeTabProps from "../../models/IEnvelopeTabProps";

const EnvelopeOrderHistory = ({
  activeTab,
  tabValue,
  envelope,
}: IEnvelopeTabProps) => {
  const columns: TableColumn<IEnvelopeOrder>[] = [
    {
      name: "Date",
      selector: (order) => order.orderDate,
      format: (order) => moment(order.orderDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (order) => order.orderStatus,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (order) => order.orderQty,
      format: (order) => numberWithCommas(order.orderQty),
      sortable: true,
    },
    // {
    //   name: "Order Total",
    //   selector: (order) => order.orderTotal,
    //   format: (order) => `$${numberWithCommas(order.orderTotal.toFixed(2))}`,
    //   sortable: true,
    // },
    // {
    //   name: "Unit Price",
    //   selector: (order) =>
    //     order.orderTotal !== 0 && order.orderQty !== 0
    //       ? (order.orderTotal / order.orderQty).toFixed(2)
    //       : 0,
    //   format: (order) =>
    //     order.orderTotal !== 0 && order.orderQty !== 0
    //       ? `$${(order.orderTotal / order.orderQty).toFixed(2)} per piece`
    //       : "$0.00 per piece",
    //   sortable: true,
    // },
  ];
  return (
    <TabPanel activeTab={activeTab} value={tabValue}>
      <SearchableDataTable
        data={
          envelope && envelope.envelopeOrderHistory
            ? envelope.envelopeOrderHistory
            : []
        }
        columns={columns}
        searchableColumns={["orderDate", "status"]}
      />
    </TabPanel>
  );
};

export default EnvelopeOrderHistory;
