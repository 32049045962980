import svgShapes from "./svgShapes";
import tw, { css } from "twin.macro";
import Shape from "./Shape";
import propertiesStyles from "../Properties/propertiesStyles";
import PropertyDropdown from "../Properties/PropertyDropdown";

const styles = {
  container: [tw` w-full px-2`],
  text: [tw` px-4 pt-2 pb-0.5`],
  shapes: [tw` pb-4 flex items-center flex-wrap w-full`],
};

const Shapes = () => {
  return (
    <div css={styles.container}>
      <PropertyDropdown
        defaultValue={true}
        disabled
        label="Drag a shape onto the canvas"
      >
        <div css={styles.shapes}>
          {svgShapes.map((shape) => (
            <Shape shape={shape} key={shape.name} />
          ))}
        </div>
      </PropertyDropdown>
    </div>
  );
};

export default Shapes;
