import { useContext } from "react";

import useLayers from "../../../hooks/useLayers";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { TrashIcon } from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import IPropertiesProps from "../Properties/IPropertiesProps";
import toolbarStyles from "./toolbarStyles";

const DeleteButton = ({ selectedObject }: IPropertiesProps) => {
  const { removeSelectedObjectLayers } = useLayers();
  const canvas = useContext(CanvasContext);

  function onDelete() {
    if (!canvas || !selectedObject) return;
    //canvas.remove(selectedObject);

    removeSelectedObjectLayers();

    canvas.renderAll();
  }

  return (
    <ToolButton
      css={toolbarStyles.utilityButton}
      title="Delete [del]"
      onClick={onDelete}
    >
      <TrashIcon size={5} />
    </ToolButton>
  );
};

export default DeleteButton;
