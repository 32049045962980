import { useParams } from "react-router-dom";
import StateProvider from "../../../postcard-designer/state/StateProvider";
import QuotePay from "../../../shared/QuotePay";

const IntegratedQuotePay = () => {
  const { quoteId } = useParams<{ quoteId: string }>();

  function handleClose() {
    window.parent?.postMessage(
      {
        type: "QUOTE_PAY_CLOSED",
      },
      "*"
    );
  }

  function onSuccess() {
    window.parent?.postMessage(
      {
        type: "QUOTE_PAY_SUCCESS",
      },
      "*"
    );
  }

  return (
    <StateProvider>
      <QuotePay
        mode="iframe"
        quoteNoteId={quoteId}
        handleClose={handleClose}
        onSuccess={onSuccess}
      />
    </StateProvider>
  );
};

export default IntegratedQuotePay;
