import { Box, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import App from "../../../../data/models/App";

interface ApiKeyProps {
  app: App;
}

const ApiKey: FC<ApiKeyProps> = ({ app }) => {
  /**
   * State Objects and Refs
   */
  const [copied, setCopied] = useState(false);

  /**
   * Component Methods
   */

  function handleClick(e: React.MouseEvent<HTMLInputElement>) {
    setCopied(true);
    const input = e.currentTarget.querySelector("input");
    if (input) {
      input.select();
    }
    navigator.clipboard.writeText(app.apiKey).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 6000);
    });
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{ textTransform: "capitalize" }}
        gutterBottom
      >
        {app.appType} API Key
      </Typography>
      <TextField
        value={app.apiKey}
        onChange={(e) => e.preventDefault()}
        fullWidth
        variant="outlined"
        onClick={handleClick}
      />
      {copied === true && (
        <Typography variant="caption" sx={{ fontStyle: "italic" }}>
          Copied to clipboard
        </Typography>
      )}
    </Box>
  );
};

export default ApiKey;
