import React, { useEffect, useRef, useState } from "react";

import tw, { css } from "twin.macro";
import WebFont from "webfontloader";
import LazyLoad from "react-lazy-load";
import IToolSettings from "../../state/models/IToolSettings";
import { useDesignerDispatch, useDesignerSelector } from "../../state/store";
import { loadFont, selectFontManager } from "../../state/slices/fontManager";
import { SelectOption } from "../DesignerInterface/shared/DesignerInput/SelectOption";
import Font from "./components/Font";
import { IFont } from "./googleFontsService/types";
import DesignerInput from "../DesignerInterface/shared/DesignerInput/DesignerInput";
import { IText } from "fabric/fabric-impl";
import { DesignerSelect } from "../DesignerInterface/shared/NuDesignerInputs/DesignerSelect";
import toolbarStyles from "../DesignerInterface/TopToolbar/toolbarStyles";
import generateGuid from "../../../helpers/generateGuid";
import { IBulletedList } from "fabric";
import { updateListAndListItems } from "../Canvas/functions/createBulletedList";
import { cleanStyles } from "../Canvas/functions/textStyleHelpers";
import nonGoogleFontSafeList, {
  FontFamilyLabels,
} from "./nonGoogleFontSafeList";

interface IFontSelectorProps {
  textObject: IText;
  onClick: () => void;

  bulletedList?: IBulletedList;
}

const styles = (fontFamily: string, fontWeight = "400") =>
  css(`font-family: ${fontFamily}; font-weight: ${fontWeight} `);

const FontSelector = ({
  textObject,
  onClick,
  bulletedList,
}: IFontSelectorProps) => {
  const fontManager = useDesignerSelector(selectFontManager);
  const fonts = fontManager.fonts;
  const dispatch = useDesignerDispatch();
  const [fontFamily, setFontFamily] = useState("Roboto");
  const [fontWeight, setFontWeight] = useState("400");
  const textRef = useRef(textObject);

  const font = fonts.find(
    (x) => x.family === textObject.fontFamily ?? "Roboto"
  );
  function getFamilyOptions() {
    return fonts.map((font) => (
      <SelectOption value={font.family} key={font.family}>
        <div>
          <LazyLoad offset={500}>
            <Font font={font} />
          </LazyLoad>
        </div>
      </SelectOption>
    ));
  }

  function getWeightOptions() {
    if (!fontFamily) return [];
    const family = fonts.find((x) => x.family === fontFamily);

    if (!family) return [];
    return family.variants
      .filter((x) => !x.includes("italic"))
      .map((weight) => (
        <SelectOption
          value={weight === "regular" ? "400" : weight.toString()}
          key={weight}
        >
          <div css={styles(family.family, weight)}>
            {getWeightCommonName(weight, family)}
          </div>
        </SelectOption>
      ));
  }

  function getWeightCommonName(weight: string, family: IFont) {
    switch (weight) {
      case "regular":
      case "400":
        return "Normal";
      case "100":
        return "Thin";
      case "200":
        return "Extra Light";
      case "300":
        return "Light";
      case "500":
        return "Medium";
      case "600":
        return "Semibold";
      case "700": {
        if (family.boldWeight === weight) {
          return "Bold";
        } else return "Semibold";
      }
      case "800": {
        if (family.boldWeight === weight) {
          return "Bold";
        } else {
          return "Extra Bold";
        }
      }
      case "900":
        return "Black";
    }
  }

  async function handleFamilyChange(property: string, value: string) {
    const font = fonts.find((x) => x.family === value);
    if (!font) return;
    if (!font.loaded) {
      let fontString = `${value}:`;
      font.variants.forEach((v, i) => {
        fontString += v;
        if (i !== font.variants.length) fontString += ",";
      });

      WebFont.load({
        google: { families: [fontString] },
      });
      dispatch(loadFont({ ...font, loaded: true }));
    }

    setFontFamily(value);
    setFontWeight("400");
    if (textObject.isEditing) {
      if (textObject.getSelectedText()) {
        textObject.setSelectionStyles({ fontFamily: value, fontWeight: 400 });
        textObject.canvas?.renderAll();
      }
      return;
    }
    textObject.fontFamily = value;
    textObject.fontWeight = 400;
    cleanStyles(textObject, "fontFamily", value);
    cleanStyles(textObject, "fontWeight", 400);
    textObject.canvas?.renderAll();
  }

  function handleWeightChange(property: string, value: string) {
    const val = parseInt(value);
    setFontWeight(value);

    if (bulletedList) {
      updateListAndListItems(bulletedList, "text", { fontWeight: val });
    } else if (textObject.isEditing) {
      if (textObject.getSelectedText()) {
        textObject.setSelectionStyles({ fontWeight: val });
        textObject.canvas?.renderAll();
        textObject.canvas?.fire("object:modified");
      }
      return;
    }
    textObject.fontWeight = val;
    cleanStyles(textObject, "fontWeight", value);
    textObject.canvas?.renderAll();
    textObject.canvas?.fire("object:modified");
  }

  useEffect(() => {
    setFontFamily(textObject.fontFamily ?? "Roboto");
    setFontWeight(textObject.fontWeight?.toString() ?? "400");
  }, [textObject]);

  function handleSync() {
    if (textRef.current.isEditing) {
      const styles = textRef.current.getSelectedText().length
        ? textRef.current.getSelectionStyles() //@ts-ignore
        : [textRef.current.getStyleAtPosition()];
      const style = styles.find((x) => x.fontFamily);

      if (style) {
        setFontFamily(style.fontFamily);
      } else {
        setFontFamily(textRef.current.fontFamily ?? "Roboto");
      }
      const wgt = styles.find((x) => x.fontWeight);
      if (wgt) {
        setFontWeight(wgt.fontWeight.toString());
      } else {
        setFontWeight(textRef.current.fontWeight?.toString() ?? "400");
      }

      return;
    }
    setFontFamily(textRef.current.fontFamily ?? "Roboto");
    setFontWeight(textRef.current.fontWeight?.toString() ?? "400");
  }

  useEffect(() => {
    if (textObject.name !== textRef.current.name) {
      textRef.current.off("selection:changed", handleSync);
      textRef.current = textObject;
    }
    textObject.canvas?.off("object:modified", handleSync);

    textRef.current.on("selection:changed", handleSync);
    textObject.canvas?.on("object:modified", handleSync);

    return () => {
      textObject.canvas?.off("object:modified", handleSync);
      textRef.current.off("selection:changed", handleSync);
    };
  }, [textObject]);

  return (
    <React.Fragment>
      <div css={toolbarStyles.property}>
        <DesignerSelect
          name="fontFamily"
          onChange={handleFamilyChange}
          // inputStyle={[
          //   tw` min-w-min`,
          //   width !== undefined && css({ width: `${width}px` }),
          // ]}
          // small
          value={
            nonGoogleFontSafeList.includes(fontFamily)
              ? FontFamilyLabels[fontFamily as keyof typeof FontFamilyLabels]
              : fontFamily
          }
          width={175}
          onClick={onClick}
          key={generateGuid()}
        >
          <SelectOption value={fontFamily}>
            <div>{font && <Font font={font} />}</div>
          </SelectOption>
        </DesignerSelect>
      </div>
      <div css={toolbarStyles.property}>
        <DesignerSelect
          name="fontWeight"
          onChange={handleWeightChange}
          // inputStyle={[
          //   tw` min-w-min`,
          //   width !== undefined && css({ width: `${width}px` }),
          // ]}
          // small
          value={fontWeight}
          width={175}
        >
          {getWeightOptions()}
        </DesignerSelect>
      </div>
    </React.Fragment>
  );
};
export default FontSelector;
