import {
  Box,
  Container,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import NotificationIcon from "@mui/icons-material/Notifications";
import { useContext, useEffect, useState } from "react";
import { ActionAlertsContext } from "../../data/State/ActionAlerts";
import useLoadData from "../../hooks/useLoadData";
import LoadingWrapper from "../../shared/LoadingWrapper";
import GridItem from "../../shared/MaterialWrappers/GridItem";
import GridRow from "../../shared/MaterialWrappers/GridRow";
import BatchResponse from "./models/BatchResponse";
import PendingTransaction from "./models/PendingTransaction";
import TabPanel from "../../shared/TabPanel";
import HomepageAlerts from "./components/HomepageAlerts";
import PendingTransactions from "./components/PendingTransactions";

import { usePostData } from "../../hooks/useFetch";
import QuickLinks from "./components/QuickLinks";
import QuickStats from "./components/QuickStats";
import Batches from "./components/Batches";
import NimbleBatch from "../Batches/models/NimbleBatch";
import useGetData from "../../hooks/dataFetchers/useGetData";

const Home = () => {
  const { alerts } = useContext(ActionAlertsContext);

  const {
    data: batches,
    updateData: setBatches,
    isLoading: isBatchesLoading,
    error: batchesError,
  } = useGetData<BatchResponse>("/api/batch/homepage");

  const {
    data: pendingTransactions,
    updateData: setPendingTransactions,
    isLoading: isPendingTransactionsLoading,
    error: pendingTransactionError,
  } = useGetData<PendingTransaction[]>("/api/orders/pending-transactions", []);

  const [pendingAlertsTab, setPendingAlertsTab] = useState(
    alerts && alerts.length > 0 ? 1 : 0
  );
  const [pendingAlertsSearchTerm, setPendingAlertsSearchTerm] = useState("");

  const [changeSandboxStatus, changeSandboxStatusInfo] = usePostData(
    "/api/batch/:id/change-sandbox-status"
  );

  async function handleChangeSandboxStatus(batchId: number, direction: -1 | 1) {
    if (batches) {
      const newStatus = await changeSandboxStatus(
        undefined,
        { accessor: "id", value: batchId },
        `direction=${direction}`
      );

      const sandboxBatches = batches.sandboxBatches.map((batch) => {
        if (batch.batchID === batchId) return { ...batch, status: newStatus };
        return batch;
      }) as NimbleBatch[];

      setBatches({ ...batches, sandboxBatches });
    }
  }

  function pendingTransactionCancelCallback(transaction: PendingTransaction) {
    if (pendingTransactions) {
      const newPendings = pendingTransactions.filter(
        (x) => x.orderID !== transaction.orderID
      );
      setPendingTransactions(newPendings);
    }
  }

  function batchCancelCallback(batchId: number) {
    if (batches && batches.activeBatches.length) {
      const activeBatches = batches.activeBatches.filter(
        (x) => x.batchID !== batchId
      );
      setBatches({ ...batches, activeBatches });
    }
  }

  /**
   * Use our alerts context to check if one of our pending payments has been cleared out, then remove it from our state.
   */
  function checkIfBatchNeedsToBeRemoved() {
    if (batches) {
      // find all of our active batches with failed or pending payment status.
      const batchesPendingPayment = batches.activeBatches.filter(
        (x) => x.status === "Failed Payment" || x.status === "Pending Payment"
      );
      if (batchesPendingPayment.length) {
        // loop through the batchesPendingPayment until we find one that has no matching alert.
        const badBatch = batchesPendingPayment.find(
          (batch) =>
            alerts.find((alert) => alert.entityID === batch.batchID) ===
            undefined
        );
        if (badBatch !== undefined) {
          // remove the bad batch from our state
          const activeBatches = batches.activeBatches.filter(
            (x) => x.batchID !== badBatch.batchID
          );
          setBatches({ ...batches, activeBatches });
        }
      }
    }
  }

  useEffect(checkIfBatchNeedsToBeRemoved, [alerts]);

  useEffect(() => {
    setPendingAlertsSearchTerm("");
  }, [pendingAlertsTab]);

  return (
    <Box sx={{ my: 4 }}>
      <Box sx={{ height: "100%", py: 2 }}>
        <Paper>
          <LoadingWrapper
            loading={isPendingTransactionsLoading}
            hasError={pendingTransactionError.hasError}
            errorMessage={pendingTransactionError.errorMessage}
            height={335}
          >
            <GridRow
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              sx={{}}
            >
              <GridItem xs="auto">
                <Tabs
                  value={pendingAlertsTab}
                  onChange={(e, val) => setPendingAlertsTab(val)}
                >
                  <Tab value={0} label="Pending Transactions" />
                  <Tab
                    value={1}
                    label={
                      <Typography sx={{ fontWeight: 500 }}>
                        Alerts{" "}
                        {alerts && alerts.length > 0 ? (
                          <NotificationIcon
                            color="primary"
                            sx={{
                              fontSize: 17,
                              position: "relative",
                              top: "2.5px",
                            }}
                          />
                        ) : null}
                      </Typography>
                    }
                  />
                </Tabs>
              </GridItem>
              <GridItem xs="auto" md={2} lg={3} xl={4}>
                <Box sx={{ pr: 2 }}>
                  <TextField
                    value={pendingAlertsSearchTerm}
                    onChange={(e) => setPendingAlertsSearchTerm(e.target.value)}
                    label="Search"
                    size="small"
                    InputLabelProps={{ sx: { lineHeight: "1" } }}
                    inputProps={{ sx: { fontSize: "12px" } }}
                    fullWidth
                  />
                </Box>
              </GridItem>
            </GridRow>
            <TabPanel activeTab={pendingAlertsTab} value={0} sx={{ p: 0 }}>
              <PendingTransactions
                pendingTransactions={
                  pendingTransactions ? pendingTransactions : []
                }
                searchTerm={pendingAlertsSearchTerm}
                cancelCallback={pendingTransactionCancelCallback}
              />
            </TabPanel>
            <TabPanel activeTab={pendingAlertsTab} value={1} sx={{ p: 0 }}>
              <HomepageAlerts searchTerm={pendingAlertsSearchTerm} />
            </TabPanel>
          </LoadingWrapper>
        </Paper>
      </Box>
      <Box sx={{ py: 2 }}>
        <QuickStats isLoading={isBatchesLoading} batches={batches} />
      </Box>
      <Box sx={{ py: 2 }}>
        <LoadingWrapper
          loading={isBatchesLoading}
          height={500}
          container={Paper}
          hasError={batchesError.hasError}
          errorMessage={batchesError.errorMessage}
        >
          <Batches
            batches={batches ? batches.activeBatches : []}
            tableTitle="Active Batches"
            onCancelOrder={batchCancelCallback}
          />
        </LoadingWrapper>
      </Box>
      {Boolean(batches && batches.completedBatches.length) && (
        <Box sx={{ py: 2 }}>
          <Batches
            batches={batches ? batches.completedBatches : []}
            tableTitle="Completed Batches"
            isCompleted
          />
        </Box>
      )}
      {Boolean(batches && batches.sandboxBatches.length) && (
        <Box sx={{ py: 2 }}>
          <Batches
            batches={batches ? batches.sandboxBatches : []}
            tableTitle="Sandbox Batches"
            isSandbox
            sandBoxChangeReqInfo={changeSandboxStatusInfo}
            onSandboxStatusChange={handleChangeSandboxStatus}
          />
        </Box>
      )}
    </Box>
  );
};

export default Home;
