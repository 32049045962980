import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup/lib/locale";
import { Tool } from "../models/ICanvasTool";
import IToolSettings, {
  FontStyle,
  Shape,
  TextAlign,
} from "../models/IToolSettings";
import { DesignerState } from "../store";

const initialState: IToolSettings = {
  currentTool: Tool.select,
  shape: Shape.rect,
  fill: "#000000",
  stroke: "#000000",
  strokeWidth: 5,
  fontFamily: "Roboto",
  fontWeight: 400,
  textAlign: TextAlign.left,
  fontSize: 32,
  fontStyle: FontStyle.normal,
  lineHeight: 1,
  kerning: 1,
  cornerRadius: 0,
  shadow: {
    offsetX: 0,
    offsetY: 0,
    blur: 0,
    color: "#000000",
  },
  showGuides: false,
  showOverlay: false,
};

export const toolSettingsSlice = createSlice({
  name: "toolSettings",
  initialState,
  reducers: {
    setToolSettings: (state, action: PayloadAction<IToolSettings>) => {
      state.cornerRadius = action.payload.cornerRadius;
      state.fill = action.payload.fill;
      state.fontFamily = action.payload.fontFamily;
      state.fontSize = action.payload.fontSize;
      state.stroke = action.payload.stroke;
      state.fontStyle = action.payload.fontStyle;
      state.strokeWidth = action.payload.strokeWidth;
      state.fontWeight = action.payload.fontWeight;
      state.shape = action.payload.shape;
      state.svgShape = action.payload.svgShape;
      state.textAlign = action.payload.textAlign;
      state.lineHeight = action.payload.lineHeight;
      state.kerning = action.payload.kerning;
      state.shadow = { ...state.shadow, ...action.payload.shadow };
    },
    toggleGuides: (state, action: Action) => {
      state.showGuides = true;
    },
    toggleOverlay: (state, action: Action) => {
      state.showOverlay = !state.showOverlay;
    },
    setCurrentTool: (state, action: PayloadAction<Tool>) => {
      state.currentTool = action.payload;
    },
    resetTools: (state, action: Action) => {
      return initialState;
    },
  },
});

export const {
  setToolSettings,
  setCurrentTool,
  toggleGuides,
  toggleOverlay,
  resetTools,
} = toolSettingsSlice.actions;

export const selectToolSettings = (state: DesignerState) => state.toolSettings;
export const selectCurrentTool = (state: DesignerState) =>
  state.toolSettings.currentTool;

export default toolSettingsSlice.reducer;
