import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { ToolbarContext } from "../../../hooks/useTools";
import ButtonReset from "../shared/ButtonReset";
import DesignerInput from "../shared/DesignerInput/DesignerInput";
import tw, { css } from "twin.macro";
import {
  IEvent,
  Object as IObject,
  IObjectOptions,
  Polygon,
  Rect,
} from "fabric/fabric-impl";
import styles from "./propertiesStyles";
import ToolButton from "../shared/ToolButton";
import {
  AngleIcon,
  LockIcon,
  OpenLockIcon,
  SkewXIcon,
  SkewYIcon,
} from "../shared/SvgComponents";
import RESOLUTION from "../../../constants/RESOLUTION";
import IPropertiesProps from "./IPropertiesProps";
import { ribbonScaling } from "../../Canvas/functions/shapeFunctions/ribbonShape";
import generateGuid from "../../../../helpers/generateGuid";

import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";

interface IDimensionPropertiesProps {
  selectedObject: IObject;
  properties: IObjectProperties;
  updateProperties: (property: string, value: string) => void;
}

const DimensionProperties = ({
  selectedObject,
  properties,
  updateProperties,
}: IDimensionPropertiesProps) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    updateProperties(e.target.name as keyof IObjectProperties, e.target.value);
  }

  return (
    <div css={styles.section}>
      <div css={styles.sectionHeading}>Dimensions</div>
      <div css={styles.propertiesGroup}>
        <div css={styles.property}>
          <DesignerInput
            name="left"
            prefix="X"
            value={properties.left}
            onChange={handleChange}
            type="number"
            suffix={`"`}
            inputStyle={styles.input}
          />
        </div>
        <div css={styles.property}>
          <DesignerInput
            name="top"
            prefix="Y"
            value={properties.top}
            onChange={handleChange}
            type="number"
            suffix={`"`}
            inputStyle={styles.input}
          />
        </div>
      </div>
      {selectedObject && !selectedObject.type?.includes("text") && (
        <div css={styles.propertiesGroup}>
          <div css={styles.property}>
            <DesignerInput
              name="width"
              prefix="Width"
              value={properties.width}
              onChange={handleChange}
              type="number"
              suffix={`"`}
              inputStyle={styles.input}
            />
          </div>
          <div css={styles.property}>
            <DesignerInput
              name="height"
              prefix="Height"
              value={properties.height}
              onChange={handleChange}
              type="number"
              suffix={`"`}
              inputStyle={styles.input}
              disabled={
                selectedObject && selectedObject.name?.includes("bulletedList")
              }
            />
          </div>

          {/* <div css={styles.property}>
          <ToolButton isActive={constrained} onClick={toggleConstrainShape}>
            {constrained ? <LockIcon /> : <OpenLockIcon />}
          </ToolButton>
        </div> */}
        </div>
      )}

      {!selectedObject?.name?.includes("qrcode") && (
        <div css={styles.propertiesGroup}>
          <div css={styles.property}>
            <DesignerInput
              name="angle"
              preIcon={AngleIcon}
              value={properties.angle}
              onChange={handleChange}
              type="number"
              suffix={`°`}
              inputStyle={styles.input}
              disabled={selectedObject === undefined}
              tooltip="Rotation"
            />
          </div>
          <div css={styles.property}>
            <DesignerInput
              name="skewX"
              preIcon={SkewXIcon}
              value={properties.skewX}
              onChange={handleChange}
              type="number"
              inputStyle={styles.input}
              disabled={selectedObject === undefined}
              tooltip="Skew Left/Right"
            />
          </div>
          <div css={styles.property}>
            <DesignerInput
              name="skewY"
              preIcon={SkewYIcon}
              value={properties.skewY}
              onChange={handleChange}
              type="number"
              inputStyle={styles.input}
              disabled={selectedObject === undefined}
              tooltip="Skew Top/Bottom"
            />
          </div>
        </div>
      )}
      {!selectedObject?.name?.includes("qrcode") && (
        <div css={styles.propertiesGroup}>
          <div css={styles.property}>
            <div css={styles.sectionHeading}>Opacity</div>
            <DesignerInput
              name="opacity"
              value={properties.opacity}
              suffix="%"
              type="number"
              max={100}
              min={0}
              inputStyle={styles.input}
              disabled={selectedObject === undefined}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DimensionProperties;
