import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import LocalStorage from "../../helpers/LocalStorage";

const Logout: React.FC = (props) => {
  /**
   * State Objects and Refs
   */
  const history = useHistory();
  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */
  LocalStorage.clearToken();
  LocalStorage.clearSession();
  history.push("/login");
  return <div />;
};

export default Logout;
