import { Path } from "fabric";

import ToolButton from "../shared/ToolButton";
import { CheckIcon, EditIcon } from "../shared/SvgComponents";
import toolbarStyles from "./toolbarStyles";
import React from "react";
import { IPenTool } from "../../../hooks/usePenTool/constants";

interface IPathEditButtonProps {
  path: Path;
  penTool: IPenTool;
}

const PathEditButton = ({ path, penTool }: IPathEditButtonProps) => {
  const { editModeOn, editModeOff, isEditing } = penTool;
  return (
    <React.Fragment>
      {!isEditing && (
        <ToolButton
          onClick={() => editModeOn(path)}
          css={toolbarStyles.utilityButton}
        >
          <EditIcon size={5} />
        </ToolButton>
      )}
      {isEditing && (
        <>
          <ToolButton onClick={editModeOff} css={toolbarStyles.utilityButton}>
            <CheckIcon size={5} />
          </ToolButton>
        </>
      )}
    </React.Fragment>
  );
};

export default PathEditButton;
