import { IPathPoint } from "fabric";
import coordsIntersectPoint from "./coordsIntersectPoint";

/**
 * @description - Check if we can close the path at the origin point.
 * @param x - mouse x position
 * @param y - mouse y position
 * @param points - Array of points to check
 * @param radius - radius of the controls
 * @returns
 */
export default function canClosePath(
  x: number,
  y: number,
  points: IPathPoint[],
  radius: number
) {
  if (points.length < 2) return false;

  return coordsIntersectPoint(x, y, points[0], radius);
}
