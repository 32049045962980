import moment from "moment";
import Account from "../data/models/Account";
import SessionInfo from "../data/models/SessionInfo";
import OrderCreationResponse, {
  OldOrderCreationResponse,
} from "../routes/PlaceAnOrder/PlaceAnOrder/models/OrderCreationResponse";

const LSConstants = {
  COMPANIES: "companies",
  CURRENT_COMPANY: "currentCompany",
  TOKEN: "token",
  TOKEN_EXPIRES: "tokenExpires",
  SAVED_EMAIL: "email",
  SESSION: "session",
  CAN_LISTEN: "canListen",
  ORDER_DETAILS: "orderDetails",
  USER_CATEGORY: "userCategory",
  PRIMARY_TOKEN: "primeToken",
  PRIMARY_BACCOUNT: "primeId",
  EMAIL: "user_email",
  EXPIRES: "expires",
  V3TOKEN: "v3token",
  V3CONFIG: "v3config",
  API_BASE_URL: "apiBaseUrl",
};

export default {
  getToken: (): string | null => getToken(),
  setToken: (token: string): string => setToken(token),
  clearToken: (): boolean => clearToken(),
  getCurrentCompany: (): number | null => getCurrentCompany(),
  setCurrentCompany: (companyId: number): string =>
    setCurrentCompany(companyId),
  getCurrentCompanyInformation: (): Account | null =>
    getCurrentCompanyInformation(),
  getSavedEmail: (): string => getSavedEmail(),
  setSavedEmail: (email: string): string => setSavedEmail(email),
  clearSavedEmail: (): boolean => clearSavedEmail(),
  getCompanies: (): Account[] => getAvailableCompanies(),
  setCompanies: (companies: Account[]): Account[] =>
    setAvailableCompanies(companies),
  setSession: (session: SessionInfo): SessionInfo => setSessionInfo(session),
  getSession: (): SessionInfo | null => getSessionInfo(),
  clearSession: (): boolean => clearSession(),
  getCanListen: (): boolean | null => getCanListen(),
  setCanListen: (canListen: string): void => {
    setCanListen(canListen);
  },
  clearCanListen: (): void => {
    clearCanListen();
  },
  setOrderDetails: setOrderDetails,
  getOrderDetails: getOrderDetails,
  clearOrderDetails: clearOrderDetails,
  setUserCategory,
  getUserCategory,
  setPrimaryBAccountId,
  getPrimaryBAccountId,
  setPrimaryToken,
  getPrimaryToken,
  clearPrimaryToken,
  getEmail,
  setEmail,
  completeRegistration,
  setV3Token,
  getV3Token,
  setV3Config,
  getV3Config,
  setApiBaseUrl,
  getApiBaseUrl,
};

function getEmail() {
  return window.localStorage.getItem(LSConstants.EMAIL);
}

function setEmail(email: string) {
  window.localStorage.setItem(LSConstants.EMAIL, email);
}

function getToken(): string | null {
  return window.localStorage.getItem(LSConstants.TOKEN);
}

function setToken(token: string): string {
  window.localStorage.setItem(LSConstants.TOKEN, token);
  return token;
}

function setV3Token(token: string): string {
  window.localStorage.setItem(LSConstants.V3TOKEN, token);
  return token;
}

function getV3Token(): string | null {
  return window.localStorage.getItem(LSConstants.V3TOKEN);
}

function setV3Config(config: string): string {
  window.localStorage.setItem(LSConstants.V3CONFIG, config);
  return config;
}
function getV3Config(): string | null {
  return window.localStorage.getItem(LSConstants.V3CONFIG);
}

export function setTokenExpires(expires: string) {
  window.localStorage.setItem(LSConstants.EXPIRES, expires);
}

export function checkIfTokenExpired(): boolean {
  const expires = window.localStorage.getItem(LSConstants.EXPIRES);
  if (!expires) return true;
  const expiresDate = moment(expires);
  const today = moment(new Date());
  if (expiresDate.isBefore(today)) {
    return true;
  }
  return false;
}

function clearToken(): boolean {
  window.localStorage.removeItem(LSConstants.TOKEN);
  return true;
}

function getCurrentCompany(): number | null {
  const lsCompany = window.localStorage.getItem(LSConstants.CURRENT_COMPANY);
  if (lsCompany) return parseInt(lsCompany);
  return null;
}

function setCurrentCompany(companyId: number) {
  const lsCompanyId = companyId.toString();
  window.localStorage.setItem(LSConstants.CURRENT_COMPANY, lsCompanyId);
  return lsCompanyId;
}

function getSavedEmail(): string {
  const lsSavedEmail = window.localStorage.getItem(LSConstants.SAVED_EMAIL);
  if (lsSavedEmail) return lsSavedEmail;
  return "";
}

function setSavedEmail(email: string): string {
  window.localStorage.setItem(LSConstants.SAVED_EMAIL, email);
  return email;
}

function clearSavedEmail(): boolean {
  window.localStorage.removeItem(LSConstants.SAVED_EMAIL);
  return true;
}

function getAvailableCompanies(): Account[] {
  const lsAvailableCompanies = window.localStorage.getItem(
    LSConstants.COMPANIES
  );
  if (lsAvailableCompanies) {
    return JSON.parse(lsAvailableCompanies) as Account[];
  }
  return [] as Account[];
}

function setAvailableCompanies(companies: Account[]) {
  window.localStorage.setItem(LSConstants.COMPANIES, JSON.stringify(companies));
  return companies;
}

function setSessionInfo(session: SessionInfo): SessionInfo {
  window.localStorage.setItem(LSConstants.SESSION, JSON.stringify(session));
  return session;
}

function getSessionInfo(): SessionInfo | null {
  const lsSession: string | null = window.localStorage.getItem(
    LSConstants.SESSION
  );
  if (lsSession) {
    return JSON.parse(lsSession) as SessionInfo;
  }
  return null;
}

function getCurrentCompanyInformation(): Account | null {
  const companyId = window.localStorage.getItem(LSConstants.CURRENT_COMPANY);
  const companies = window.localStorage.getItem(LSConstants.COMPANIES);
  if (companies !== null && companyId !== null) {
    const companiesArray = JSON.parse(companies) as Account[];
    const currentCompany = companiesArray.filter(
      (x) => x.baccountId === parseInt(companyId)
    )[0];
    return currentCompany;
  }
  return null;
}

function getCanListen(): boolean {
  const lsCanListen = window.localStorage.getItem(LSConstants.CAN_LISTEN);
  if (lsCanListen) {
    if (lsCanListen === "true") return true;
    return false;
  }
  return true;
}

function setCanListen(canListen: string) {
  window.localStorage.setItem(LSConstants.CAN_LISTEN, canListen);
}

function clearCanListen() {
  window.localStorage.removeItem(LSConstants.CAN_LISTEN);
}

function clearSession() {
  window.localStorage.clear();
  return true;
}

function setOrderDetails(order: OldOrderCreationResponse) {
  window.localStorage.setItem(LSConstants.ORDER_DETAILS, JSON.stringify(order));
}

function getOrderDetails(): OldOrderCreationResponse {
  const lsOrderDetails = window.localStorage.getItem(LSConstants.ORDER_DETAILS);
  if (!lsOrderDetails) return {} as OldOrderCreationResponse;
  const orderDetails: OldOrderCreationResponse = JSON.parse(lsOrderDetails);
  return orderDetails;
}

function clearOrderDetails(): void {
  window.localStorage.removeItem(LSConstants.ORDER_DETAILS);
}

function setUserCategory(role: string): void {
  window.localStorage.setItem(LSConstants.USER_CATEGORY, role);
}

function getUserCategory(): string {
  const category = window.localStorage.getItem(LSConstants.USER_CATEGORY);
  if (category) return category;
  return "";
}

function setPrimaryToken(token: string): void {
  window.localStorage.setItem(LSConstants.PRIMARY_TOKEN, token);
}

function getPrimaryToken(): string | null {
  return window.localStorage.getItem(LSConstants.PRIMARY_TOKEN);
}

export function getAdminToken(): string | null {
  return window.localStorage.getItem(LSConstants.PRIMARY_TOKEN);
}

function clearPrimaryToken(): void {
  window.localStorage.removeItem(LSConstants.PRIMARY_TOKEN);
}

function setPrimaryBAccountId(id: number): void {
  window.localStorage.setItem(LSConstants.PRIMARY_BACCOUNT, id.toString());
}

function completeRegistration() {
  const companies = getAvailableCompanies();
  const currentId = getCurrentCompany();

  const index = companies.findIndex((x) => x.baccountId === currentId);
  if (index !== -1) {
    companies[index] = { ...companies[index], hasCompletedSetup: true };
    setAvailableCompanies(companies);
  }
}

function getPrimaryBAccountId(): number | null {
  const prime = window.localStorage.getItem(LSConstants.PRIMARY_BACCOUNT);
  if (prime) {
    const primeNum = parseInt(prime);
    if (!isNaN(primeNum)) return primeNum;
  }
  return null;
}

function setApiBaseUrl(url: string): string {
  window.localStorage.setItem(LSConstants.API_BASE_URL, url);
  return url;
}

function getApiBaseUrl(): string | null {
  return window.localStorage.getItem(LSConstants.API_BASE_URL);
}
