import { useContext, useEffect, useRef, useState } from "react";
import { CanvasObject, IPage } from "../../../state/contexts/PagesContext";
import tw, { css } from "twin.macro";
import { fabric } from "fabric";
import { StaticCanvas } from "fabric/fabric-impl";
import DATALESS_PROPERTIES from "../../../constants/DATALESS_PROPERTIES";
import { loadFromJSON } from "../../Canvas/functions/loadSavedCanvasData";
import RESOLUTION from "../../../constants/RESOLUTION";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import styles from "./pageSelectorStyles";
import { EditIcon, TimesIcon, TrashIcon } from "../shared/SvgComponents";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import PageDropzone from "./PageDropzone";

interface PagePreviewProps {
  page: IPage;
  canDelete: boolean;
  isActive: boolean;
  width: number;
  height: number;
  sizeMulti: number;
  onClick: (page: string) => void;
  onDelete: (page: string) => void;
  canDrag: boolean;
  onDrop: (page: string, destination: number) => void;
  index: number;
  isLastItem: boolean;
  originalWidth: number;
  originalHeight: number;
}

const PagePreview = ({
  page,
  canDelete,
  onClick,
  isActive,
  width,
  height,
  sizeMulti,
  onDelete,
  index,
  onDrop,
  canDrag,
  isLastItem,
  originalWidth,
  originalHeight,
}: PagePreviewProps) => {
  const canvasWidth = width;
  const canvasHeight = height;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pageRef = useRef(page);
  const [collected, drag, dragPreview] = useDrag({
    type: "page",
    item: { name: page.name, index: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
  });

  const { item } = collected;

  const [staticCanvas, setStaticCanvas] = useState<StaticCanvas>();

  const isActiveRef = useRef(isActive);
  isActiveRef.current = isActive;
  async function initializeCanvas() {
    if (canvasRef.current && !staticCanvas) {
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = canvasHeight;
      const staticCanvas = new fabric.StaticCanvas(canvasRef.current, {
        width: canvasWidth,
        height: canvasHeight,
        backgroundColor: "white",
        renderOnAddRemove: false,
      });

      await updateCanvas(
        staticCanvas,
        page.objects
          .filter(
            (x) =>
              (x.name !== "overlay" ||
                (x.name === "overlay" &&
                  // @ts-ignore
                  x.__addressBlock === true)) &&
              x.name !== "bleed"
          )
          .map((x) => x.toDatalessObject(DATALESS_PROPERTIES))
      );

      setStaticCanvas(staticCanvas);
    }
  }

  async function updateCanvas(
    staticCanvas: StaticCanvas,
    objects: CanvasObject[]
  ) {
    staticCanvas.remove(...staticCanvas.getObjects());
    const jsonCanvas = staticCanvas.toDatalessJSON(DATALESS_PROPERTIES);

    jsonCanvas.objects = objects;

    await loadFromJSON(staticCanvas, jsonCanvas, true);
    const objs = staticCanvas.getObjects().map((x) => {
      x.clipPath = undefined;
      return x;
    });

    staticCanvas.remove(...objs);

    staticCanvas.add(new fabric.Group(objs, { left: 0, top: 0 }));

    const ratio = canvasWidth / (originalWidth * RESOLUTION);
    staticCanvas.setZoom(ratio);

    staticCanvas.renderAll();
  }

  useEffect(() => {
    initializeCanvas();
  }, [canvasRef.current]);

  useEffect(() => {
    if (canvasRef.current && staticCanvas) {
      updateCanvas(
        staticCanvas,
        page.objects
          .filter(
            (x) =>
              (x.name !== "overlay" ||
                (x.name === "overlay" &&
                  // @ts-ignore
                  x.__addressBlock === true)) &&
              x.name !== "bleed"
          )
          .map((x) => x.toDatalessObject(DATALESS_PROPERTIES))
      );
    }
  }, [page]);

  return (
    <React.Fragment>
      <div css={tw`mr-3`}>
        <div css={tw`flex items-center`}>
          {collected.isDragging && (
            <div ref={dragPreview} css={styles.dragPreview} />
          )}

          <PageDropzone
            index={isLastItem && !collected.isDragging ? index - 1 : index}
            onDrop={onDrop}
            width={width}
            height={height}
            hide={index !== 0 && item?.index + 1 === index}
          />

          <div
            className="group"
            css={[
              styles.pagePreviewContainer(isActive, width, height),
              tw`bg-white`,
              collected.isDragging && tw`hidden`,
            ]}
            onClick={() => (!collected.isDragging ? onClick(page.name) : null)}
            ref={canDrag ? drag : undefined}
          >
            <div css={styles.pagePreviewCanvasWrapper(width, height, isActive)}>
              <canvas ref={canvasRef}></canvas>
            </div>
            {canDelete && !collected.isDragging && (
              <div
                css={styles.deleteButton}
                onClick={() => onDelete(page.name)}
              >
                <TimesIcon
                  styles={[css({ fill: "inherit" }), tw`w-3.5 h-3.5`]}
                />
              </div>
            )}
          </div>

          <PageDropzone
            index={index + 1}
            onDrop={onDrop}
            width={width}
            height={height}
            hide={!isLastItem || collected.isDragging}
          />

          {/* {isLastItem && item && item.index !== index && (
        <PageDropzone
          index={index}
          onDrop={onDrop}
          width={width}
          height={height}
        />
      )} */}
        </div>
        {(page.name === "Front" || page.name === "Back") && (
          <div css={styles.pageName}>{page.name}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PagePreview;
