import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useReducer, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import ComponentActivityStatus from "../../../constants/ComponentActivityStatus";
import { postAsync } from "../../../helpers/asyncFetch";
import { getNumberFromString } from "../../../helpers/getNumberFromString";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import { numberWithCommas } from "../../../LegacyReactSite/helpers";
import Img from "../../../shared/BasicHTML/Img";
import LoadingButton from "../../../shared/LoadingButton";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import { IPaymentMethod } from "../../Account/models/AccountModel";
import AddNewCreditCard from "../../Account/PaymentAndBilling/components/AddNewCreditCard";
import ImageUpload from "../../Designs/UploadADesign/components/ImageUpload";
import { Pricing } from "../../PricingPlans/models/PricingPlanModel";
import envelopeSettingsValidation from "../helpers/envelopeSettingsValidation";
import IEnvelope, { ReplenishmentMethod } from "../models/IEnvelope";
import {
  initialNewEnvelopeState,
  NewEnvelopeActions,
  newEnvelopeReducer,
} from "./NewEnvelopeReducer";

const { UPDATE_ERRORS, UPDATE_FIELD, UPDATE_STATUS, UPDATE_SUBMISSION } =
  NewEnvelopeActions;

const NewEnvelope = () => {
  const [state, dispatch] = useReducer(
    newEnvelopeReducer,
    initialNewEnvelopeState
  );

  const [creditCardOpen, setCreditCardOpen] = useState(false);
  const history = useHistory();
  const {
    data: paymentMethods,
    isLoading,
    updateData: setPaymentMethods,
    error: pmError,
  } = useGetData<IPaymentMethod[]>(
    "/api/payments/payment-methods-expanded",
    []
  );

  const {
    data: envelopePricing,
    isLoading: pricingLoading,
    error: pricingError,
  } = useGetData<Pricing[]>("/api/envelopes/pricing", []);

  //#region Dispatch Functions

  function updateNewEnvelopeField(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    dispatch({
      type: UPDATE_FIELD,
      payload: { [e.target.name]: e.target.value },
    });
  }

  function updateStatus(status: ComponentActivityStatus) {
    dispatch({ type: UPDATE_STATUS, payload: status });
  }

  function updateErrors(errors: string[]) {
    dispatch({ type: UPDATE_ERRORS, payload: errors });
  }
  //#endregion

  function creditCardCallback(paymentMethod: IPaymentMethod) {
    if (paymentMethods) {
      setPaymentMethods([paymentMethod, ...paymentMethods]);
      dispatch({
        type: UPDATE_FIELD,
        payload: {
          paymentMethod: paymentMethod.id?.toString(),
        },
      });
    }
  }

  function getPricePerPiece() {
    if (envelopePricing) {
      let priceModel = envelopePricing[0];
      const qty = getNumberFromString(state.newEnvelope.orderQty);
      envelopePricing.forEach((price) => {
        if (qty >= price.breakQty) {
          priceModel = price;
        }
      });
      return priceModel.price;
    }
    return 0;
  }

  function getOrderTotal() {
    if (envelopePricing) {
      const pricePerPiece = getPricePerPiece();
      const qty = getNumberFromString(state.newEnvelope.orderQty);
      return `$${numberWithCommas((qty * pricePerPiece).toFixed(2))}`;
    }
    return "$0.00";
  }

  function handleImageUpload(path: string) {
    dispatch({ type: UPDATE_FIELD, payload: { front: path } });
  }

  function clearImageUpload() {
    dispatch({ type: UPDATE_FIELD, payload: { front: "" } });
  }

  async function handleSubmit() {
    dispatch({ type: UPDATE_SUBMISSION, payload: undefined });
    const errors = envelopeSettingsValidation(state.newEnvelope);
    if (errors.length) {
      updateErrors(errors);
      return;
    }
    updateStatus(ComponentActivityStatus.Submitting);
    const values = state.newEnvelope;
    const paymentMethod = paymentMethods?.find(
      (x) => x.id?.toString() === values.paymentMethod
    );
    let friendlyPaymentMethod = "";
    if (paymentMethod) {
      friendlyPaymentMethod = `Card ending in ${paymentMethod.lastFour} | Exp. ${paymentMethod.expiration}`;
    }
    const body = {
      friendlyName: values.friendlyName,
      envelopeType: values.envelopeType,
      front: values.front,
      back: values.back,
      orderQty: getNumberFromString(values.orderQty),
      paymentMethod: getNumberFromString(values.paymentMethod),
      friendlyPaymentMethod: friendlyPaymentMethod,
      replenishmentMethod: values.replenishmentMethod,
      replenishmentValue: getNumberFromString(values.replenishmentValue),
      pricePerPiece: getPricePerPiece(),
      frontURL: `${window.location.origin}/uploads/${values.front}`,
      backURL: values.back
        ? `${window.location.origin}/uploads/${values.back}`
        : null,
    };
    const res = await postAsync<IEnvelope>(`/envelopes`, body);
    if (res) {
      history.push(`/envelopes/${res.envelopeID}`);
    } else {
      updateStatus(ComponentActivityStatus.Error);
    }
  }

  useEffect(() => {
    if (state.hasSubmitted) {
      const errors = envelopeSettingsValidation(state.newEnvelope);
      updateErrors(errors);
    }
  }, [state.newEnvelope]);

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h2">
          Create A New Envelope
        </Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1">
          Use the form below to place an order for custom envelopes. Once your
          envelopes have been printed and stocked you will be able to use them
          for you letter orders.
        </Typography>
      </Box>
      <LoadingWrapper
        loading={isLoading || pricingLoading}
        height={500}
        hasError={pmError.hasError || pricingError.hasError}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Envelope Name"
                value={state.newEnvelope.friendlyName}
                onChange={updateNewEnvelopeField}
                fullWidth
                name="friendlyName"
              />
              {state.errors.includes("friendlyName") && (
                <FormHelperText error>
                  Please enter a name for your envelope
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <NumberFormat
                thousandSeparator=","
                customInput={TextField}
                label="Envelope Quantity"
                value={state.newEnvelope.orderQty}
                name="orderQty"
                onChange={updateNewEnvelopeField}
                fullWidth
              />
              {state.errors.includes("orderQty") && (
                <FormHelperText error>
                  The minimum envelope quantity is 5,000
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Automatic Reorder Setting</InputLabel>
                <Select
                  label="Automatic Reorder Setting"
                  value={state.newEnvelope.replenishmentMethod}
                  onChange={updateNewEnvelopeField}
                  fullWidth
                  name="replenishmentMethod"
                >
                  <MenuItem value="" disabled>
                    Select An Option
                  </MenuItem>
                  <MenuItem value={ReplenishmentMethod.None}>
                    Disable Automatic Reorder
                  </MenuItem>
                  <MenuItem value={ReplenishmentMethod.Percentage}>
                    Reorder When Inventory Is Below A Percentage
                  </MenuItem>
                  <MenuItem value={ReplenishmentMethod.Static}>
                    Reorder When Inventory Is Below A Fixed Number
                  </MenuItem>
                </Select>
              </FormControl>

              {state.errors.includes("replenishmentMethod") && (
                <FormHelperText error>Please select an option</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {state.newEnvelope.replenishmentMethod &&
                state.newEnvelope.replenishmentMethod !==
                  ReplenishmentMethod.None && (
                  <Box>
                    <NumberFormat
                      customInput={TextField}
                      value={state.newEnvelope.replenishmentValue}
                      onChange={updateNewEnvelopeField}
                      label="Minium Threshold Before Reorder"
                      disabled={state.status !== ComponentActivityStatus.Idle}
                      suffix={
                        state.newEnvelope.replenishmentMethod ===
                        ReplenishmentMethod.Percentage
                          ? "%"
                          : undefined
                      }
                      thousandSeparator={
                        state.newEnvelope.replenishmentMethod ===
                        ReplenishmentMethod.Static
                          ? ","
                          : undefined
                      }
                      fullWidth
                      name="replenishmentValue"
                    />
                    {Boolean(state.errors.includes("replenishmentValue")) &&
                      !Boolean(state.errors.includes("greaterThanQty")) && (
                        <FormHelperText error>
                          {state.newEnvelope.replenishmentMethod ===
                          ReplenishmentMethod.Percentage
                            ? "Please enter a number between 1 and 100"
                            : "Please enter a number greater than 0"}
                        </FormHelperText>
                      )}
                    {Boolean(state.errors.includes("greaterThanQty")) && (
                      <FormHelperText error>
                        Your reorder amount must be less than your order
                        quantity.
                      </FormHelperText>
                    )}
                  </Box>
                )}
            </Grid>
            <Grid item xs={12} sx={{ overflow: "hidden" }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Upload Your Envelope
              </Typography>
              {!state.newEnvelope.front && (
                <Box>
                  <ImageUpload
                    onUploadSuccess={handleImageUpload}
                    helpText="PDF Images must be a single page"
                  />
                  {Boolean(state.errors.includes("front")) && (
                    <FormHelperText error>
                      You must upload an image
                    </FormHelperText>
                  )}
                </Box>
              )}
              {Boolean(state.newEnvelope.front) && (
                <Box sx={{ overflow: "hidden" }}>
                  <Img
                    src={`/uploads/${state.newEnvelope.front}`}
                    style={{ maxWidth: "100%", width: "1000px" }}
                  />
                  <Box>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={clearImageUpload}
                    >
                      Change Image
                    </Button>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select A Payment Method</InputLabel>
                <Select
                  label="Select A Payment Method"
                  value={state.newEnvelope.paymentMethod}
                  onChange={updateNewEnvelopeField}
                  fullWidth
                  name="paymentMethod"
                >
                  <MenuItem value="" disabled>
                    Select An Option
                  </MenuItem>
                  {paymentMethods?.map((method) => (
                    <MenuItem key={method.id} value={method.id?.toString()}>
                      Card ending in {method.lastFour} |{" "}
                      {Boolean(method.expiration) && (
                        <React.Fragment>
                          Expires: {method.expiration}
                        </React.Fragment>
                      )}
                    </MenuItem>
                  ))}
                </Select>
                {state.errors.includes("paymentMethod") && (
                  <FormHelperText error>
                    Please select a payment method
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setCreditCardOpen(true)}
              >
                Add A New Credit Card
              </Button>
            </Grid>
            <Grid item xs={12}>
              {/* <Typography variant="h6" sx={{ mb: 2 }}>
                Pricing
              </Typography>
              <Box sx={{ mb: 3 }}>
                <EnvelopePricingTable
                  pricing={envelopePricing ? envelopePricing : []}
                />
              </Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Order Total: {getOrderTotal()}
              </Typography> */}
              <Typography variant="body1" sx={{ mb: 2 }}>
                <strong>Note:</strong> Once we receive your order it may take up
                to 7 business days before your envelopes are ready for use.
              </Typography>
              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                loading={state.status === ComponentActivityStatus.Submitting}
                disabled={state.hasSubmitted && state.errors.length > 0}
                onClick={handleSubmit}
              >
                Confirm Envelope Order
              </LoadingButton>
            </Grid>
          </Grid>
          <AddNewCreditCard
            isOpen={creditCardOpen}
            callback={creditCardCallback}
            onClose={() => {
              setCreditCardOpen(false);
            }}
          />
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default NewEnvelope;
