export default function hideLiveChat() {
  const interval = setInterval(removeLiveChat, 200);

  function removeLiveChat() {
    let liveChat = document.querySelector("div[title='Contact us button']");
    if (!liveChat) {
      liveChat = document.querySelector("div[title='Live chat button']");
    }
    if (liveChat) {
      document.body.removeChild(liveChat);
      clearInterval(interval);
    }
  }
}
