import { Box, SxProps, Theme } from "@mui/material";
import React, { useState, useEffect } from "react";

interface TabPanelProps {
  activeTab: any;
  value: any;
  sx?: SxProps<Theme>;
}

const TabPanel: React.FC<TabPanelProps> = ({
  activeTab,
  value,
  children,
  sx = { p: 2 },
}) => {
  if (activeTab !== value) return null;

  return <Box sx={sx}>{children}</Box>;
};

export default TabPanel;
