import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import getLabelFromMailClassId from "../../../../helpers/getLabelFromMailClassId";
import { DesignField } from "../../models/Design";
import DesignPricing from "../../models/DesignPricing";

interface DesignPricingTableProps {
  pricing: DesignPricing[];
  mailClass: string;
  isLetter?: boolean;
}

const DesignPricingTable: React.FC<DesignPricingTableProps> = ({
  pricing,
  mailClass,
  isLetter = false,
}) => {
  /**
   * State Objects and Refs
   */

  const label = getLabelFromMailClassId(mailClass);
  const mailClassPricing = getMailClassPricing(mailClass, pricing);
  /**
   * Component Methods
   */

  function getMailClassPricing(
    mailClass: string,
    pricing: DesignPricing[]
  ): DesignPricing[] {
    return pricing.filter((x) => x.mailClass === mailClass);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Box>
      <h5 style={{ marginBottom: "16px" }}>{label}</h5>
      <Box sx={{ border: "solid 1px rgba(0,0,0,.125)", borderRadius: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Price per piece</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mailClassPricing.map((pricing) => (
              <TableRow key={pricing.qty + pricing.inventoryCD}>
                <TableCell>{pricing.qty}+</TableCell>
                <TableCell>
                  {isLetter
                    ? `Starting at $${pricing.price?.toFixed(2)}`
                    : `$${pricing.price?.toFixed(2)}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default DesignPricingTable;
