import { Box, Button, Typography } from "@mui/material";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAsync } from "../../helpers/asyncFetch";

import useGetData from "../../hooks/dataFetchers/useGetData";
import Img from "../../shared/BasicHTML/Img";
import LoadingWrapper from "../../shared/LoadingWrapper";
import SearchableDataTable from "../../shared/SearchableDataTable";
import Design from "../Designs/models/Design";

const PlaceAnOrderPage = () => {
  const { data, isLoading, error } = useGetData<Design[]>(
    `/api/designs?type=order`,
    []
  );

  // async function getDesigns() {
  //   const designs = await getAsync<Design[]>(`/designs?type=order`);
  //   if (designs) {
  //     return designs;
  //   } else return [] as Design[];
  // }

  const designCols: TableColumn<Design>[] = [
    {
      name: "Proof",
      maxWidth: "200px",
      width: "200px",
      cell: (design) => {
        return (
          <Box
            sx={{
              maxWidth: "200px",
              maxHeight: "200px",

              width: "200px",
              overflow: "hidden",
              pt: 1,
              pb: 1,
            }}
          >
            <Box sx={{ maxHeight: "180px", overflow: "hidden" }}>
              <Img
                src={design.proofFront ? design.proofFront : design.proofPDF}
                maxWidth={168}
                width={168}
              />
            </Box>
          </Box>
        );
      },
    },
    {
      name: "ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (design) => design.friendlyName,
      sortable: true,
    },
    {
      name: "Size",
      selector: (design) => design.size,
      right: true,
      sortable: true,
    },
    {
      name: "",
      right: true,
      cell: (design) => {
        return (
          <Button
            component={Link}
            to={`/place-an-order/${design.designID}`}
            color="primary"
            variant="outlined"
            size="small"
          >
            Place An Order
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
        Select A Design
      </Typography>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={error.hasError}
      >
        <SearchableDataTable
          data={data ? data : []}
          columns={designCols}
          searchableColumns={["friendlyName", "designID", "size"]}
        />
      </LoadingWrapper>
    </Box>
  );
};

export default PlaceAnOrderPage;
