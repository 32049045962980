import { useContext, useEffect, useRef } from "react";
import { useDesignerSelector } from "../state/store";
import { CanvasContext } from "../state/contexts/CanvasContext";
import {
  CanvasObject,
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../state/contexts/PagesContext";
import {
  SelectedPageContext,
  SelectedPageDispatchContext,
} from "../state/contexts/SelectedPageContext";
import useSaveData from "./useSaveData";
import reArrangeObjects from "../features/Canvas/functions/reArrangeObjects";

const usePageUpdate = (initialized: boolean) => {
  const { layers, designInformation } = useDesignerSelector((state) => ({
    layers: state.layers,
    designInformation: state.designInformation,
  }));
  const canvas = useContext(CanvasContext);
  const { handleUpdate, saveData } = useSaveData();
  const pages = useContext(PagesContext);
  const selectedPage = useContext(SelectedPageContext);
  const selectedPageDispatch = useContext(SelectedPageDispatchContext);
  const pagesDispatch = useContext(PagesDispatchContext);
  const pagesRef = useRef(pages);

  useEffect(updatePage, [layers]);
  useEffect(() => {
    if (!initialized) {
      pagesRef.current = pages;
      return;
    }
    if (pagesRef.current.length !== pages.length) {
      pagesRef.current = pages;
      const page = pages[pages.length - 1].name;
      changePage(page);
    }
  }, [pages]);

  function changePage(
    page: string,
    prevPage?: string,
    from?: number,
    destination?: number
  ) {
    if (!canvas) return;
    canvas.discardActiveObject();

    handleUpdate();
    const pageObj = pages.find((x) => x.name === page);
    const pageLayers = layers.pageLayers.find((x) =>
      x.name === prevPage ? prevPage : page
    );
    if (!pageObj || !pageLayers) return;
    canvas.remove(...canvas.getObjects());
    canvas.add(...pageObj.objects);

    reArrangeObjects(pageLayers.layers, canvas);
    canvas.renderAll();
    selectedPageDispatch({ type: "", payload: page });
  }

  function updatePage() {
    if (!canvas || !initialized) return;
    const objects = canvas.getObjects();
    if (layers.pageLayers.length !== pages.length) {
      if (pages.length < layers.pageLayers.length) {
        const backgroundAndOverlayObjects = canvas.getObjects().filter(
          (x) =>
            (x.name === "background" ||
              x.name === "overlay" ||
              x.name === "bleed") &&
            // @ts-ignore
            !x.__addressBlock
        );
        const name = `Page ${pages.length + 1}`;
        pagesDispatch({
          type: PageActions.setPages,
          payload: [
            ...pages,
            {
              name,
              objects: backgroundAndOverlayObjects,
            },
          ],
        });
      }
    } else {
      if (
        designInformation.canvasSettings?.width === 8.5 &&
        designInformation.canvasSettings?.height === 11
      ) {
        let moveAddressBlock = false;
        let addressBlock: CanvasObject[] | undefined = undefined;
        const newPages = pages.map((x) => ({ ...x }));
        pages.forEach((page, i) => {
          if (page.name === "Page 1") {
            const exists = page.objects.filter(
              (x) =>
                x.name === "overlay" &&
                //@ts-ignore
                x.__addressBlock
            );
            if (!exists.length) {
              moveAddressBlock = true;
            }
          } else {
            const exists = page.objects.filter(
              (x) =>
                x.name === "overlay" &&
                //@ts-ignore
                x.__addressBlock
            );
            if (exists.length) {
              addressBlock = exists;
              newPages[i].objects = newPages[i].objects.filter(
                (x) =>
                  // @ts-ignore
                  !x.__addressBlock
              );
            }
          }
        });
        if (moveAddressBlock && addressBlock) {
          newPages[0].objects = [...newPages[0].objects, ...addressBlock];
          pagesDispatch({
            type: PageActions.setPages,
            payload: newPages,
          });
          // re-render selectedPage
          const page = newPages.find((x) => x.name === selectedPage);
          const pageLayers = layers.pageLayers.find(
            (x) => x.name === selectedPage
          );
          if (page && pageLayers) {
            canvas.remove(...canvas._objects);
            canvas.add(...page.objects);
            reArrangeObjects(pageLayers.layers, canvas);
            canvas.renderAll();
          }
          return;
        }
      }
      pagesDispatch({
        type: PageActions.updatePage,
        payload: { name: selectedPage, objects: objects },
      });
    }
    // handleUpdate();
  }
};

export default usePageUpdate;
