import { configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import history from "./slices/history";
import designerState from "./slices/designerState";
import designInformation from "./slices/designInformation";
import toolSettings from "./slices/toolSettings";
import layers from "./slices/layers";
import currentSelection from "./slices/currentSelection";
import availableShapes from "./slices/availableShapes";
import qrCode from "./slices/qrCode";

import canvasPointerPosition from "./slices/canvasPointerPosition";
import assetLibrary from "./slices/assetLibrary";
import colorPalette from "./slices/colorPalette";
import fontManager from "./slices/fontManager";
import objectProperties from "./slices/objectProperties";
import letterSettings from "./slices/letterSettings";
export const store = configureStore({
  reducer: {
    toolSettings,
    history,
    designerState,
    designInformation,
    layers,
    currentSelection,
    availableShapes,
    canvasPointerPosition,
    assetLibrary,
    colorPalette,
    fontManager,
    objectProperties,
    qrCode,
    letterSettings,
  },
});

export type DesignerState = ReturnType<typeof store.getState>;

export type DesignerDispatch = typeof store.dispatch;

export const useDesignerDispatch: () => DesignerDispatch = useDispatch;
export const useDesignerSelector: TypedUseSelectorHook<DesignerState> =
  useSelector;
