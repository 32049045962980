import { Group, Object as IObject } from "fabric/fabric-impl";
import { Tool } from "../../../state/models/ICanvasTool";
import ISvgShape from "../../../state/models/ISvgShape";
import IToolSettings from "../../../state/models/IToolSettings";
import isShape from "../../Canvas/functions/isShape";
import DimensionProperties from "./DimensionProperties";
import ShapeProperties from "./ShapeProperties";
import tw, { css } from "twin.macro";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ToolbarContext, UpdateToolSetting } from "../../../hooks/useTools";

import ShadowProperties from "./ShadowProperties";
import CustomImageProperties from "./CustomImageProperties";
import ImageProperties from "./ImageProperties";
import CanvasProperties from "./CanvasProperties";
import { IBulletedList, fabric } from "fabric";
import generateGuid from "../../../../helpers/generateGuid";
import { createBleedClipPath } from "../../Canvas/functions/createBackgroundClipPath";
import Alignment from "./Alignment";
import propertiesStyles from "./propertiesStyles";
import DuplicateButton from "./DuplicateButton";
import AdvancedStrokeProperties from "./AdvancedStrokeProperties";
import FontSelector from "../../Fonts/FontSelector";
import TextProperties from "./TextProperties";
import { Textbox } from "fabric";
import FontSidebar from "../FontSidebar/FontSidebar";
import { IImageMasking } from "../../../hooks/useImageMasking/useImageMasking";
import PropertyDropdownProvider from "../../../state/contexts/PropertyDropdownContext";
import useProperties from "../../../hooks/useProperties/useProperties";
import BulletedListProperties from "./BulletedListProperties";

interface IPropertiesProps {
  selectedObject?: IObject;
  fontsOpen: boolean;
  closeFonts: () => void;
}

const styles = {
  container: [],
  no: [
    tw`border-l border-solid border-border`,
    css`
      width: 300px;
      height: 100%;
    `,
  ],
};

const Properties = ({
  selectedObject,
  fontsOpen,
  closeFonts,
}: IPropertiesProps) => {
  const { properties, updateProperties } = useProperties(selectedObject);

  function getPropertiesPanel() {
    if (!selectedObject) return null;
    if (selectedObject.name?.includes("bulletedList")) {
      return <BulletedListProperties list={selectedObject as IBulletedList} />;
    }
    if (isShape(selectedObject)) {
      return <ShapeProperties selectedObject={selectedObject} />;
    }

    if (selectedObject.type === "textbox" && !fontsOpen) {
      return <TextProperties textObject={selectedObject as Textbox} />;
    }

    if (selectedObject?.name?.includes("image")) {
      return <ImageProperties selectedObject={selectedObject} />;
    }
    if (!selectedObject) return null;
    if (selectedObject.type === "group") {
      const group = selectedObject as Group;
      const customImage = group._objects.find((x) => x.name === "customImage");
      if (customImage) {
        return <CustomImageProperties selectedObject={selectedObject} />;
      }
    }
    return null;
  }

  const propertiesPanel = useMemo(getPropertiesPanel, [selectedObject]);

  useEffect(() => {
    closeFonts();
  }, [selectedObject]);

  return (
    <PropertyDropdownProvider>
      <div css={styles.container}>
        {fontsOpen &&
          (selectedObject?.type === "textbox" ||
            selectedObject?.name?.includes("bulletedList")) && (
            <FontSidebar
              textObject={selectedObject as Textbox}
              onClose={closeFonts}
            />
          )}
        {!fontsOpen && (
          <React.Fragment>
            <div css={propertiesStyles.sidebarContainer}>
              {selectedObject && selectedObject.canvas && (
                <Alignment selectedObject={selectedObject} />
              )}
              {selectedObject !== undefined && (
                <DimensionProperties
                  selectedObject={selectedObject}
                  properties={properties}
                  updateProperties={updateProperties}
                />
              )}
              {propertiesPanel}
              {selectedObject !== undefined &&
                selectedObject !== null &&
                selectedObject.name &&
                !selectedObject.name.includes("qrcode") && (
                  <AdvancedStrokeProperties
                    selectedObject={selectedObject}
                    showStroke={Boolean(selectedObject.type?.includes("text"))}
                  />
                )}
              {selectedObject !== undefined &&
                selectedObject !== null &&
                selectedObject.name &&
                !selectedObject.name.includes("qrcode") && (
                  <ShadowProperties
                    selectedObject={selectedObject}
                    properties={properties}
                    updateProperties={updateProperties}
                  />
                )}
            </div>
          </React.Fragment>
        )}
      </div>
    </PropertyDropdownProvider>
  );
};

export default Properties;
